import {readAsArrayBuffer} from './async-reader';
import { getAsset } from './prepare-assets';
import { MdCheckBoxOutlineBlank,MdOutlineCheckBox } from 'react-icons/md';
import {AttachmentTypes} from "../constants/contract";
import {createFile} from "../helpers";


async function processPdfAttachments (pdfFile, objects) {
    const PDFLib = await getAsset('PDFLib');
    let pdfDoc;

    try {
        pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
    } catch (e) {
        console.log('Failed to load PDF.');
        throw e;
    }
    console.log(pdfDoc.getPages(),"pdfDoc")
    const pagesProcesses = pdfDoc.getPages() && pdfDoc.getPages()?.map(async (page, pageIndex) => {
        const pageObjects = objects[pageIndex];
        // 'y' starts from bottom in PDFLib, use this to calculate y
        const pageHeight = page.getHeight();
        const embedProcesses = pageObjects.length > 0 && pageObjects?.map(async (object) => {
            if (object.type === AttachmentTypes.SIGNATURE || object.type === AttachmentTypes.INITIALS) {
                const { x, y, width, height,media } = object;
                let img;
                let file = await createFile(media,`file.${media.split(".")[1]}`)
                try {
                    if (media.split(".")[1] === 'jpeg') {
                        img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
                    } else {
                        img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
                    }
                    return () =>
                        page.drawImage(img, {
                            x,
                            y: pageHeight - y - height,
                            width,
                            height,
                        });
                } catch (e) {
                    console.log('Failed to embed image.', e);
                    throw e;
                }
            }
            else if (object.type === AttachmentTypes.TEXT || object.type === AttachmentTypes.DATE) {
                const {
                    x,
                    y,
                    text,
                    lineHeight,
                    size,
                    fontFamily,
                    width,
                    height,
                } = object ;
                const pdfFont = await pdfDoc.embedFont(fontFamily);
                return () =>
                    page.drawText(text, {
                        maxWidth: width,
                        font: pdfFont,
                        size,
                        lineHeight,
                        x,
                        y: pageHeight - y - height,
                    });
            }
            else if (object.type === AttachmentTypes.CHECKBOX) {
                const {
                    x,
                    y,
                    text,
                    height,
                } = object ;
                const currentCheckbox = Boolean(text) ? 'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z'
                    : 'M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'

                return () =>
                    page.drawSvgPath(currentCheckbox, {
                        width:20,
                        height:20,
                        x:x+50,
                        y: (pageHeight - y - height) + 25,

                    });
            }
        });
        // embed objects in order
        if (!!embedProcesses){
            const drawProcesses = await Promise.all(embedProcesses);
            drawProcesses.forEach((p) => p());
        }
    });
    await Promise.all(pagesProcesses);

    return pdfDoc
}

export async function returnFileWithAttachments(pdfFile, objects, name) {
    try {
        const pdfDoc = await processPdfAttachments(pdfFile,objects)
        const pdfBytes = await pdfDoc.save();

        let pdfBlob = new Blob([new Uint8Array([...new Uint8Array(pdfBytes)])],{type:'application/pdf'})
        return new File([pdfBlob],name,{type:'application/pdf'})
    } catch (e) {
        console.log('Failed to return PDF.');
        throw e;
    }
}
export async function save(
    pdfFile,
    objects,
    name
) {
    const download = await getAsset('download');

    try {
        const pdfDoc = await processPdfAttachments(pdfFile,objects)
        const pdfBytes = await pdfDoc.save();

        download(pdfBytes, name, 'application/pdf');
    } catch (e) {
        console.log('Failed to save PDF.');
        throw e;
    }
}
