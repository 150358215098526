import React from "react";
import styles from "../../../styles/common/container.module.css"
import ButtonLoader from "../loaders/button-loader";
import propTypes from "prop-types";

const EmptyContainer = ({isLoading,clickHandler,title,description,buttonText}) => {
    return (
        <>
            <div className={styles.empty__container__wrapper}>
                <div className={styles.empty__container__information}>
                    <h2>{title}</h2>
                    {description && <p>{description}</p>}
                    {(isLoading && buttonText) && <ButtonLoader/>}
                    {(!isLoading && buttonText) && <button onClick={clickHandler}>{buttonText}</button>}
                </div>
            </div>
        </>
    )
};
export default EmptyContainer;

EmptyContainer.propType = {
    isLoading : propTypes.bool,
    clickHandler : propTypes.func,
    title : propTypes.string.isRequired,
    description : propTypes.string.isRequired,
    buttonText : propTypes.string
}
