import React, {useContext, useEffect, useState} from "react";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";
import ApplicationListingService from "../../../../../services/application-listing.service";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

const BackgroundCheckForm = ({applicationData}) => {
    const {previousStep} = useWizard();
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)
    const location = useLocation()
    const navigate = useNavigate()

    const {useHandleUpdateBackgroundCheckService,useHandleSubmitApplicationForListingService} = ApplicationListingService()

    const [fields,setFields] = useState({
        causedDamage: false,
        convicted: false,
        bankruptcy: true
    })

    const {details,inputEvent} = useForm(fields)

    useEffect(()=>{
        setFields(applicationData?.backgroundCheck)
    },[applicationData?.backgroundCheck])

    const {mutate,isLoading,isSuccess} = useHandleUpdateBackgroundCheckService(details)
    const {mutate : submitApplicationForListing, isLoading : isSubmitApplicationLoading} = useHandleSubmitApplicationForListingService(location?.state?.unitId || "")
    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()
        if(location?.state?.unitId){
            if(applicationData?.seventhStepperCompleted){
                submitApplicationForListing()
            }else{
                mutate()
            }
        }
        else{
            mutate()
        }
    }

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    seventhStepperCompleted:true
                }
            })
            if(location?.state?.unitId){
                if(applicationData?.seventhStepperCompleted){
                    submitApplicationForListing()
                }
            }else{
                navigate("/application")
            }
        }
    },[isSuccess])

    return(
        <>
            <Form className={styles.create__listing__form__alternate} onSubmit={goToNextStep}>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Group className={styles.form__check__group}>
                        <label>Have you ever caused property damage in the last 7 years?</label>
                        <div className={styles.background__check__boxes}>
                            <div className="form-check">
                                <label className={details?.causedDamage?.toString() === `true` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="causedDamage"
                                        value={true}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.causedDamage?.toString() === `true`}
                                        required
                                    />
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <label className={details?.causedDamage?.toString() === `false` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="causedDamage"
                                        value={false}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.causedDamage?.toString() === `false`}
                                        required
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                    </Form.Group>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Group className={styles.form__check__group}>
                        <label>Have you ever been convicted of a Felony / Indictable Offence<br/> in the last 7 years?</label>
                        <div className={styles.background__check__boxes}>
                            <div className="form-check">
                                <label className={details?.convicted?.toString() === `true` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="convicted"
                                        value={true}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.convicted?.toString() === `true`}
                                        required
                                    />
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <label className={details?.convicted?.toString() === `false` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="convicted"
                                        value={false}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.convicted?.toString() === `false`}
                                        required
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                    </Form.Group>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Group className={styles.form__check__group}>
                        <label>Have you ever declared bankruptcy in the last 7 years?</label>
                        <div className={styles.background__check__boxes}>
                            <div className="form-check">
                                <label className={details?.bankruptcy?.toString() === `true` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="bankruptcy"
                                        value={true}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.bankruptcy?.toString() === `true`}
                                        required
                                    />
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <label className={details?.bankruptcy?.toString() === `false` ? styles.checked : ""}>
                                    <input
                                        type="radio"
                                        name="bankruptcy"
                                        value={false}
                                        onChange={inputEvent}
                                        className={`form-check-input`}
                                        checked={details?.bankruptcy?.toString() === `false`}
                                        required
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                    </Form.Group>
                </Form.Group>
                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading || isSubmitApplicationLoading}
                    isApplying={!!(location?.state?.unitId)}
                />
            </Form>

        </>
    )
}
export default BackgroundCheckForm
