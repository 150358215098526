export const DragActions = Object.freeze({
    MOVE : 'MOVE',
    SCALE : 'SCALE',
    NO_MOVEMENT : 'NO_MOVEMENT',
})

export const Color = Object.freeze({
    RED : 'red',
    ORANGE : 'orange',
    YELLOW : 'yellow',
    OLIVE : 'olive',
    GREEN : 'green',
    TEAL : 'teal',
    BLUE : 'blue',
    VIOLOET : 'violet',
    PURPLE : 'purple',
    BROWN : 'brown',
    GREY : 'grey',
    BLACK : 'black',
})

export const AttachmentTypes = Object.freeze({
    IMAGE : 'image',
    DRAWING : 'drawing',
    TEXT : 2,
    SIGNATURE : 1,
    INITIALS:3,
    DATE:4,
    CHECKBOX:5
})

export const EDIT_BUTTON_CLICKED = Object.freeze({
    SAVE_AS_DRAFT:1,
    CONFIRM_EDIT:2
})

export const TextMode = Object.freeze( {
    INSERT : 'insert',
    COMMAND : 'command',
})

export const SIGNATURE_TYPE = Object.freeze({
    DRAW:"draw",
    TEXT:"text",
    IMAGE:"image"
})
