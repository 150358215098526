import React from "react";
import {RiErrorWarningLine} from "react-icons/ri";
import styles from "../../../styles/common/icons.module.css"

const CustomInfo = ({onMouseEnter,onMouseLeave}) => {
    return(
        <>
            <h6 className={styles.custom__info} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div>
                    <span><RiErrorWarningLine/></span>
                </div>
            </h6>
        </>
    )
}
export default CustomInfo