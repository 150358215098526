import React from "react"
import axios from "../config/axios-instance";
import TokenService from "./token.service";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {viewError} from "../utils/helpers";
import {useNavigate, useParams} from "react-router";

const ApplicationListingService = () => {
    const navigate = useNavigate()

    //Fetch All Users Applications
    const useFetchUserListing = (page,enabled) => {
        const userId = TokenService.getUser()?._id

        const handleFetchUserListingRequest = (userId,page) => {
            const limit = 99
            return axios.get(`/listing-request/all?userId=${userId}&page=${page}&limit=${limit}`)
        }

        return useQuery(
            [`user-application-listing`,userId,page],
            () => handleFetchUserListingRequest(userId,page),
            {
                retry:0,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                enabled:(enabled && !!page && !!userId)
            }
        )
    }

    //Fetch Renter Application Status
    const useFetchUserApplicationStatus = (enabled = true) => {
        const userId = TokenService.getUser()?._id

        const handleFetchApplicationStatusRequest = () => {
            // const limit = 12
            return axios.get(`/application/status?userId=${userId}`)
        }
        const onSuccess = (response) => {
            TokenService.saveRenterApplicationId(response?.data?.data?.applicationId)
        }
        return useQuery(
            [`renter-application-status`,userId],
            handleFetchApplicationStatusRequest,
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                onSuccess,
                enabled:enabled
            }
        )
    }

    //Fetch Listing By ID
    const useFetchListingById = (listingId) => {
        const onError = (error) => {
            if(error.response.status === 400){
                toast.error("Access Denied")
                navigate("/application")
            }
            else{
                toast.error(viewError(error.response.data.message))
            }
        }

        const handleFetchListingByIdRequest = (listingId) => {
            return axios.get(`/application?applicationId=${listingId}`)
        }

        return useQuery(
            [`application-listing-by-id`,listingId],
            () => handleFetchListingByIdRequest(listingId),
            {
                onError,
                retry:1,
                refetchOnWindowFocus:false,
            }
        )
    }

    //Fetch Application Info By Applicant Id
    const useFetchApplicationByApplicantId = (applicantId) => {

        const onError = (error) => {
            if(error.response.status === 400){
                toast.error("Access Denied")
                navigate("/home")
            }
            else{
                toast.error(viewError(error.response.data.message))
            }
        }

        const handleFetchApplicationByApplicantIdRequest = (id) => {
            return axios.get(`/listing/request/application?applicantId=${id}`)
        }

        return useQuery(
            [`application-by-applicant-id`,applicantId],
            () => handleFetchApplicationByApplicantIdRequest(applicantId),
            {
                onError,
                retry:1,
                refetchOnWindowFocus:false,
                select : (response) => response?.data?.data,
                enabled:!!applicantId
            }
        )
    }

    //Exit Listing
    const useHandleExitListing = (listingId,completedListingInThisSession) => {
        const userId = TokenService.getUser()?._id

        const handleExitListingService = (id,data) => {
            return axios.post(`/application/exit?userId=${userId}&applicationId=${id}`,data)
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleExitListingService(listingId,completedListingInThisSession),
            {
                onError,
                retry:0
            }
        )
    }

    //Withdraw Application
    const useHandleWithdrawApplicationListingService = (listingId) => {
        const userId = TokenService.getUser()?._id
        const queryClient = useQueryClient()

        const handleWithdrawApplicationListingRequest = (id) => {
            return axios.post(`/listing/withdraw?renterId=${userId}&listingId=${id}`)
        }

        const onSuccess = () => {
            toast.success("Application Withdrawn")
            queryClient.invalidateQueries([`user-application-listing`,userId,1])
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleWithdrawApplicationListingRequest(listingId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Handle List Application
    const useHandleListApplicationService = () => {
        const userId = TokenService.getUser()?._id

        const handleListApplicationService = () => {
            return axios.post(`/application/?userId=${userId}`)
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            handleListApplicationService,
            {
                onError,
            }
        )
    }

    // Create/Update Update Applicant Information
    const useHandleUpdateApplicantInformationService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const HandleUpdateApplicantInformationRequest = (data) => {
            return axios.put(`/application/applicant?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }
                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateApplicantInformationRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    // Create/Update Occupants Information
    const useHandleUpdateOccupantsInformationService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const HandleUpdateOccupantsInformationRequest = (data) => {
            return axios.put(`/application/occupant?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateOccupantsInformationRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Create/Update Address History
    const useHandleUpdateAddressHistoryService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateAddressHistoryRequest = (data) => {
            return axios.put(`/application/address-history?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateAddressHistoryRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Create/Update Financial Information
    const useHandleUpdateFinancialInformationService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateFinancialInformationRequest = (data) => {
            return axios.put(`/application/financial?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateFinancialInformationRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Create/Update Transportation
    const useHandleUpdateTransportationService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateTransportationRequest = (data) => {
            return axios.put(`/application/transportation?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateTransportationRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Delete Application Media
    const useHandleDeleteApplicationMediaService = (mediaName) => {
        const userId = TokenService.getUser()?._id
        const queryClient = useQueryClient()
        const {applicationId} = useParams()

        const handleDeleteApplicationMediaRequest = (name) => {
            return axios.delete(`application/documents?userId=${userId}&applicationId=${applicationId}`, {data:{mediaName:name}})
        }

        const onSuccess = (response) => {
            toast.success("File Deleted")
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleDeleteApplicationMediaRequest(mediaName),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Create/Update Background Check
    const useHandleUpdateBackgroundCheckService = (data) => {
        const {applicationId} = useParams()
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const HandleUpdateBackgroundCheckRequest = (data) => {
            return axios.put(`/application/background-check?userId=${userId}&applicationId=${applicationId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        data:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateBackgroundCheckRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Submit Application For Listing
    const useHandleSubmitApplicationForListingService = (listingId) => {
        const userId = TokenService.getUser()?._id

        const HandleSubmitApplicationForListingRequest = () => {
            return axios.post(`/listing/submit?renterId=${userId}&listingId=${listingId}`)
        }

        const onSuccess = (response) =>{
            // if(typeof response?.data?.data === "string"){
            //     window.location.href = response?.data?.data
            // }else{
            //     navigate("/success", {state:true} )
            // }
            navigate("/success", {state:true} )
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            HandleSubmitApplicationForListingRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }


    return {useFetchUserListing,useFetchListingById,useFetchUserApplicationStatus,
        useHandleListApplicationService,useHandleUpdateApplicantInformationService,
        useHandleUpdateOccupantsInformationService,useHandleUpdateAddressHistoryService
        ,useHandleExitListing,useHandleSubmitApplicationForListingService
        ,useHandleUpdateFinancialInformationService,useHandleUpdateTransportationService
        ,useHandleUpdateBackgroundCheckService,useHandleWithdrawApplicationListingService
        ,useHandleDeleteApplicationMediaService,useFetchApplicationByApplicantId}
};

export default ApplicationListingService;
