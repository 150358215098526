import React from "react"
import styles from "../../../styles/common/tables.module.css"

const MobileTable = ({children}) => {
  return(
      <>
        <div className={styles.mobile__table__wrapper}>
            {children}
        </div>
      </>
  )
}
export default MobileTable
