import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import setup from "./app/config/axios-interceptors";
import { BrowserRouter as Router } from "react-router-dom";
import {prepareAssets} from "./app/utils/contract-signing/prepare-assets";

/**
 * .
    Renter -> Application -> Address History prev address to 1, then back to 0, it's removed from ui but the data is not removed
    View Unit Preview --> Image fullscreen ui bug
 */
prepareAssets();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <App />
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
setup();
