import React, {Fragment, useEffect, useMemo, useState} from "react"
import styles from "../../styles/contract.module.css";
import UnitTitle from "../../components/common/text/unit-title";
import {useLocation, useNavigate, useOutletContext} from "react-router";
import UploadDocumentBox from "../../components/presentational/listing/single-unit/contract/upload-document-box";
import UnitContractService from "../../services/unit-contract.service";
import DefaultLoader from "../../components/common/loaders/default-loader";
import ApplicantCard from "../../components/common/cards/applicant-card";
import {IoIosAddCircleOutline} from "react-icons/io"
import {Container} from "react-bootstrap";
import UnitDocumentCard from "../../components/common/cards/unit-document-card";
import UnitListingService from "../../services/unit-listing.service";
import {AiOutlineClose} from "react-icons/ai";
import {DOCUMENT_ACTIONS} from "../../utils/constants/listing";
import {createFile, mergeDuplicateLabels} from "../../utils/helpers";
import AddCoApplicantsContainer
    from "../../components/presentational/listing/single-unit/contract/add-co-applicants-container";
import EditApplicantCardContainer from "../../components/presentational/listing/single-unit/contract/edit-applicant-card-container";
import ButtonLoader from "../../components/common/loaders/button-loader";
import useWindowSize from "../../hooks/use-window-size";
import UnitDocumentCardMobile
    from "../../components/presentational/listing/single-unit/contract/unit-document-card-mobile";
import useInitializeContractAttachments from "../../hooks/use-initialize-contract-attachments";
import {usePdf} from "../../hooks/use-pdf";
import {useAttachments} from "../../hooks/use-attachments";
import {MEDIA_BASEURL} from "../../utils/constants";


const componentsState = {
    IS_APPLICANT_ACCEPTED_STATE:false,
    IS_DOCUMENT_UPLOADED_STATE:false,
    IS_DOCUMENTS_EXIST_STATE:false,
    IS_READY_TO_SEND_STATE:false,
}

const Contract = () => {
    //Api is called from the parent component i.e. index.js
    const {data:unitData} = useOutletContext()
    const navigate = useNavigate()
    const {width} = useWindowSize()
    const {state} = useLocation()
    const {useHandleDeleteUnitContract,useFetchUnitContracts,useHandleAddUnitDocument,useFetchContractById,useHandleDeleteCoApplicant,useHandleSendContractForSignature} = UnitContractService()
    const {useFetchAcceptedListingApplicants} = UnitListingService()

    const {data:acceptedClientData,isLoading:isAcceptedClientLoading,isSuccess:isAcceptedClientSuccess} = useFetchAcceptedListingApplicants()

    //Current Component In The View
    const componentObjectKeys = useMemo(()=>Object.keys(componentsState),[])
    const [currentComponent,setCurrentComponent] = useState(componentsState)

    //Adding New Co-Applicant
    const [isAddCoApplicant,setIsAddCoApplicant] = useState(false)

    //Editing Applicant Card
    const [editData,setEditData] = useState({
        fields:{
            name:"",
            email:"",
        },
        signerId:"",
    })
    const [isEditApplicant,setIsEditApplicant] = useState(false)

    const [documentId,setDocumentId] = useState("")

    const [mutatedContractData,setMutatedContractData] = useState([])

    //If the applicant is accepted try to render documents else no use in calling fetch docs api
    const {data: contractsList, isFetching: isContractsListLoading,isSuccess: isContractsListSuccess} = useFetchUnitContracts(currentComponent.IS_APPLICANT_ACCEPTED_STATE ? unitData?._id : null)

    //Uploading Document
    const {data: uploadedContractData,mutate: uploadDocument, isSuccess: isUploadContractSuccess, isLoading: isUploadContractLoading} = useHandleAddUnitDocument()
    //Fetch Document By ID
    const {data: contractData, isFetching: isContractDataFetching,isSuccess: isContractDataSuccess} = useFetchContractById(documentId)
    //Delete Co-Applicant
    const {mutate: deleteCoApplicant, isLoading: isDeleteCoApplicantLoading} = useHandleDeleteCoApplicant(documentId)
    //Delete A Contract
    const {mutate: deleteContract} = useHandleDeleteUnitContract(documentId)
    //Send Contract For Signature
    const {mutate: sendContract, isLoading: isSendContractLoading,isSuccess: isSendContractSuccess} = useHandleSendContractForSignature(documentId)


    //Is There Any Accepted Clients?
    useEffect(()=>{
        if(isAcceptedClientSuccess && acceptedClientData?.data?.length > 0){
            setCurrentComponent({
                ...currentComponent,
                IS_APPLICANT_ACCEPTED_STATE:true,
            })
        }
    },[isAcceptedClientSuccess])

    //Is Document Uploaded?
    useEffect(()=>{
        if(isUploadContractSuccess){
            setDocumentId(uploadedContractData.data?.data?._id)
            setCurrentComponent({
                ...currentComponent,
                IS_DOCUMENT_UPLOADED_STATE:true,
            })
        }
    },[isUploadContractSuccess])

    //Are Documents Fetched
    useEffect(()=>{
        if(isContractsListSuccess && contractsList?.length > 0 && !isContractsListLoading){
            // setContractsListData(contractsList)
            setCurrentComponent({
                ...currentComponent,
                IS_DOCUMENTS_EXIST_STATE:true,
            })
        }
    },[isContractsListSuccess,contractsList?.length,isContractsListLoading])

    //Is Single Document Fetched
    useEffect(()=>{
        if(isContractDataSuccess && !isContractDataFetching){
            setIsAddCoApplicant(false)
            setIsEditApplicant(false)

            setMutatedContractData(mergeDuplicateLabels([...contractData?.signers]))

        }
    },[isContractDataSuccess,isContractDataFetching,contractData?.signers?.length])

    //When the document is uploaded show the applicant cards screen
    const onDocumentUploaded = (e,documentData) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("image",documentData.file)
        formData.append("name",documentData.name)
        formData.append("isLeaseContract",documentData.isLeasedContract)
        uploadDocument(formData)
    }

    //
    const onDeleteDocument = (contractId) => {
      deleteContract({contractId})
    }


    //On the applicant cards screen, back button takes back to the upload document screen
    const onBackAddApplicant = () => {
        setCurrentComponent({
            ...currentComponent,
            IS_DOCUMENT_UPLOADED_STATE:false,
            IS_READY_TO_SEND_STATE:false,
        })
    }
    const onRevertEverything = () => {
        setCurrentComponent({
            IS_APPLICANT_ACCEPTED_STATE:true,
            IS_DOCUMENT_UPLOADED_STATE:false,
            IS_READY_TO_SEND_STATE:false,
            IS_DOCUMENTS_EXIST_STATE:false,
        })
    }



    const onEditApplicantCard = (cardData,isEdit) => {
        setIsEditApplicant(isEdit)
        //Initial Data For Edit
        setEditData({
            fields: {
                name:cardData?.name,
                email:cardData?.email
            },
            signerId: cardData?._id,
        })
    }

    const onDeleteApplicantCard = (signerId) => {
        deleteCoApplicant(signerId)
    }

    //Show & Hide Add CoApplicant Form
    const onAddCoApplicant = () => {
        setIsAddCoApplicant(prev => !prev)
    }


    //When Applicants are confirmed redirect to the edit documents screen
    const onConfirmAddApplicant = () => {
        if(!currentComponent.IS_READY_TO_SEND_STATE){
            navigate(`/edit-contract/${documentId}`,{state:true})
        }else{
            sendContract()
        }

    }

    //Document Card Button Actions
    const onDocumentAction = async (actionType,actionObj) => {
        if(actionType === DOCUMENT_ACTIONS.CONTINUE){
            setDocumentId(actionObj.id)
            setCurrentComponent({
                IS_APPLICANT_ACCEPTED_STATE:true,
                IS_DOCUMENT_UPLOADED_STATE:true,
                IS_READY_TO_SEND_STATE:false,
                IS_DOCUMENTS_EXIST_STATE:false,
            })
        }else if (actionType === DOCUMENT_ACTIONS.SIGN){
            navigate(`/sign-contract?contractId=${actionObj.id}&signerId=${actionObj?.signerId}`,{state:true})
        }else if (actionType === DOCUMENT_ACTIONS.DOWNLOAD){
            window.open(`${MEDIA_BASEURL}${actionObj.media}`, "_blank")

        }
    }
    //
    useEffect(()=>{
        if(!!state){
            setDocumentId(state)
            setCurrentComponent({
                IS_APPLICANT_ACCEPTED_STATE:true,
                IS_DOCUMENT_UPLOADED_STATE:true,
                IS_READY_TO_SEND_STATE:true,
                IS_DOCUMENTS_EXIST_STATE:true,
            })
        }
    },[])
    useEffect(()=>{
        if (isSendContractSuccess){
            window.history.replaceState(null, document.title)
            setCurrentComponent({
                IS_APPLICANT_ACCEPTED_STATE:true,
                IS_DOCUMENT_UPLOADED_STATE:false,
                IS_READY_TO_SEND_STATE:false,
                IS_DOCUMENTS_EXIST_STATE:true,
            })
        }
    },[isSendContractSuccess])
    return(
      <>
          <section className={styles.single__unit__contract__wrapper}>
              <div className={styles.single__unit__contract__header}>
                  <UnitTitle title={unitData?.name}/>
                  {/*If we are on the second screen or more, or if documents doesn't exist yet, go back to the first screen*/}
                  {(componentObjectKeys.filter(el => currentComponent[el])?.length > 1 && !currentComponent.IS_DOCUMENTS_EXIST_STATE) &&
                      <button type={"button"} onClick={onRevertEverything}><AiOutlineClose/></button>}
              </div>
              {(isContractsListLoading || isAcceptedClientLoading || isContractDataFetching) && <DefaultLoader/>}
              {!(isContractsListLoading || isAcceptedClientLoading || isContractDataFetching) && <div>
                  {/*If An Applicant Is Accepted Or Not Accepted, and no other state is true yet*/}
                  {/*If none or only 1 state is true*/}
                  {componentObjectKeys.filter(el => currentComponent[el])?.length <= 1 && <div className={styles.uploader__wrapper}>
                      <h2 className={styles.sub__title}>Send & Sign</h2>
                      <UploadDocumentBox onFileUploaded={onDocumentUploaded} isApplicantAccepted={currentComponent.IS_APPLICANT_ACCEPTED_STATE} isUploadContractLoading={isUploadContractLoading}/>
                  </div>}
                  {/*An Applicant Is Accepted And A Document Is Uploaded*/}

                  {(currentComponent.IS_APPLICANT_ACCEPTED_STATE && (currentComponent.IS_DOCUMENT_UPLOADED_STATE || currentComponent.IS_READY_TO_SEND_STATE)) && <div className={styles.receivers__card__list}>

                      <div className={styles.cards__and__receivers}>

                          {/*Only Hide the card which was clicked through checking from index from .map*/}
                          {!isEditApplicant && (
                              <>
                                  {mutatedContractData?.map((el,index) => (
                                      <Fragment key={`${el?.label}_${index}`}>
                                          <h3>{el?.label}</h3>
                                          {el?.data?.map(innerEl => (
                                              <Fragment key={innerEl?._id}>
                                                  <ApplicantCard
                                                      currentLabel={el?.label}
                                                      applicantData={innerEl}
                                                      onEditApplicantCard={onEditApplicantCard}
                                                      onDeleteApplicantCard={onDeleteApplicantCard}
                                                      isLoading={isDeleteCoApplicantLoading}
                                                  />
                                              </Fragment>
                                          ))}
                                      </Fragment>
                                  ))}
                              </>
                          )}
                          {isEditApplicant &&
                              <EditApplicantCardContainer
                                  editData={editData}
                                  onEditApplicantCard={onEditApplicantCard}
                                  contractId={documentId}
                              />
                          }
                      </div>
                      {isAddCoApplicant && <AddCoApplicantsContainer contractId={documentId} onAddCoApplicant={onAddCoApplicant}/>}

                      {(!isAddCoApplicant && !isEditApplicant && !currentComponent.IS_READY_TO_SEND_STATE) && <div className={styles.add__co__button__wrapper}>
                          <button type={"button"} onClick={onAddCoApplicant}>
                              Add a co-applicant <IoIosAddCircleOutline/>
                          </button>
                      </div>}
                      {!(isAddCoApplicant || isEditApplicant || isSendContractLoading) && <div className={styles.contract__button__wrapper}>
                          <button type={"button"} onClick={onBackAddApplicant} className={styles.prev__btn}>
                              Back
                          </button>
                          <button type={"button"} onClick={onConfirmAddApplicant} className={styles.next__btn}>
                              {currentComponent.IS_READY_TO_SEND_STATE ? 'Ready To Send' : 'Next'}
                          </button>
                      </div>}
                      {isSendContractLoading && <ButtonLoader/>}
                  </div>}
                  {(currentComponent.IS_APPLICANT_ACCEPTED_STATE && currentComponent.IS_DOCUMENTS_EXIST_STATE && !currentComponent.IS_READY_TO_SEND_STATE) && <div className={styles.documents__main__wrapper}>
                      <Container>
                          <div className={styles.documents__title}>
                              <h3>Documents</h3>
                              <button type={"button"} onClick={onRevertEverything}>Add a document <IoIosAddCircleOutline/></button>
                          </div>
                          {width > 767 && <div className={styles.documents__list}>
                              {contractsList.map((contract, index) => {
                                  return (
                                      <Fragment key={contract?._id}>
                                          <UnitDocumentCard
                                              index={index}
                                              contract={contract}
                                              onDeleteDocument={onDeleteDocument}
                                              onAction={onDocumentAction}
                                          />
                                      </Fragment>
                                  )
                              })}
                          </div>}
                          {width <= 767 && <UnitDocumentCardMobile
                              contractsListData={contractsList}
                              onDeleteDocument={onDeleteDocument}
                              onAction={onDocumentAction}
                          />}
                      </Container>
                  </div>}
              </div>}
          </section>
      </>
  )
}
export default Contract
