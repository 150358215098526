import React, {Fragment} from "react"
import styles from "../../../../../styles/listing.module.css"
import PageLoader from "../../../../common/loaders/page-loader";
import Title from "../../../../common/text/title";
import {AiOutlineMail,AiOutlineUser,AiOutlineCar} from 'react-icons/ai'
import {BsTelephone} from "react-icons/bs"
import {IoDocumentTextOutline} from "react-icons/io5"
import CreditScoreContainer from "../../../../common/container/credit-score-container";
import {Col, Row} from "react-bootstrap";
import {ANIMAL_TYPE} from "../../../../../utils/constants/application-listing";
import {stringTruncate} from "../../../../../utils/helpers";

const ApplicationReportBody = ({applicationData,isLoading}) => {
    return(
        <>
            <div className={styles.application__body__wrapper}>
                {isLoading && <PageLoader/>}
                {!isLoading && <div>
                    {/*TITLE*/}
                    <div className={styles.title}>
                        <Title title={applicationData?.application?.applicantInformation?.name}/>
                    </div>
                    {/*TITLE ROW*/}
                    <div className={styles.sub__title__row}>
                        <div className={styles.sub__title__info}>
                            <span><AiOutlineMail/></span>
                            <p title={applicationData?.application?.applicantInformation?.email}>{stringTruncate(applicationData?.application?.applicantInformation?.email,20)}</p>
                        </div>
                        <div className={styles.border}/>
                        <div className={styles.sub__title__info}>
                            <span><BsTelephone/></span>
                            <p>{applicationData?.application?.applicantInformation?.phone}</p>
                        </div>
                        <div className={styles.border}/>
                        <div className={styles.sub__title__info}>
                            <span>Credit score:</span>
                            <p><CreditScoreContainer score={applicationData?.application?.applicantInformation?.creditScore} scoreType={applicationData?.application?.applicantInformation?.scoreType}/></p>
                        </div>
                        <div className={styles.border}/>
                        <div className={styles.sub__title__info}>
                            <span>Start renting: </span>
                            <p>{applicationData?.application?.applicantInformation?.leaseStartDate}</p>
                        </div>
                    </div>
                    {/*APPLICANT BACKGROUND INFORMATION*/}
                    <div className={styles.applicant__background__info}>
                        {applicationData?.application?.applicantInformation?.about && <div>
                            <h5><span><AiOutlineUser/></span>About:</h5>
                            <p>{applicationData?.application?.applicantInformation?.about}</p>
                        </div>}
                        <div>
                            <h5><span><IoDocumentTextOutline/></span>Background Check:</h5>
                            <ul>
                                <li>Caused property damage in the last 7 years: <span>{`  ${applicationData?.application?.backgroundCheck?.causedDamage ? 'Yes' : 'No'}`}</span></li>
                                <li>Conviction for a crime within the last 7 years: <span>{`  ${applicationData?.application?.backgroundCheck?.convicted ? 'Yes' : 'No'}`}</span></li>
                                <li>Bankruptcy declarations in the last 7 years: <span>{`  ${applicationData?.application?.backgroundCheck?.bankruptcy ? 'Yes' : 'No'}`}</span></li>
                            </ul>
                        </div>
                        <div>
                            <h5><span><AiOutlineCar/></span>Transportation:</h5>
                            <ul>
                                <li>Availability of a vehicle: <span>{`  ${applicationData?.application?.transportation?.length > 0 ? 'Yes' : 'No'} / ${applicationData?.application?.transportation?.length}`}</span></li>
                                {applicationData?.application?.transportation?.length > 0  && <li>Monthly vehicle
                                    payment: <span>{`  $${applicationData?.application?.transportation.reduce(function(prev, current) {
                                        return prev + +current?.monthlyPayment
                                    }, 0)}`}</span>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    {/*OCCUPANTS/PET INFORMATION*/}
                    {(applicationData?.application?.occupants?.adults?.length > 0 || applicationData?.application?.occupants?.pets?.length > 0)&&<div className={styles.applicant__meta__information}>
                        <div className={styles.applicant__meta__info__title}>
                            <h1>Occupants Information</h1>
                        </div>
                        <Row>
                            {applicationData?.application?.occupants?.adults?.map((adult, index) => {
                                return (
                                    <Fragment key={adult?._id}>
                                        <Col md={6} className={styles.col}>
                                            <div className={styles.applicant__info}>
                                                <h5>Occupant #{index + 1}</h5>
                                                <div className={styles.applicant__info__list}>
                                                    <div className={styles.info__row}>
                                                        <p>Name: </p>
                                                        <span>{adult?.name}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Phone: </p>
                                                        <span>{adult?.phone}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Email: </p>
                                                        <span
                                                            title={adult?.email}>{stringTruncate(adult?.email, 20)}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Amount Of Payment: </p>
                                                        <span>{`$${adult?.willPay}`}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Relationship: </p>
                                                        <span>{adult?.relationship}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Fragment>
                                )
                            })}
                            {applicationData?.application?.occupants?.pets?.map((pet, index) => {
                                return (
                                    <Fragment key={pet?._id}>
                                        <Col md={6} className={styles.col}>
                                            <div className={styles.applicant__info}>
                                                <h5>Pet #{index + 1}</h5>
                                                <div className={styles.applicant__info__list}>
                                                    <div className={styles.info__row}>
                                                        <p>Type Of Animal: </p>
                                                        <span>{pet?.animalType === ANIMAL_TYPE.CAT ? 'Cat'
                                                            : pet?.animalType === ANIMAL_TYPE.DOG ? 'Dog'
                                                                : pet?.animalType === ANIMAL_TYPE.OTHER ? 'Other'
                                                                    : ''}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Breed: </p>
                                                        <span>{pet?.breed}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Weight: </p>
                                                        <span>{pet?.weight} Kg</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Fragment>
                                )
                            })}
                        </Row>
                    </div>}
                    {/*ADDRESS HISTORY*/}
                    <div className={styles.applicant__meta__information}>
                        <div className={styles.applicant__meta__info__title}>
                            <h1>Address History</h1>
                        </div>
                        <Row>
                            <Col md={6} className={styles.col}>
                                <div className={styles.applicant__info}>
                                    <h5>Current Address</h5>
                                    <div className={styles.applicant__info__list}>
                                        <div className={styles.info__row}>
                                            <p>Address: </p>
                                            <span>{stringTruncate(`${applicationData?.application?.addressHistory?.currentAddress?.zipOrPostal} ${applicationData?.application?.addressHistory?.currentAddress?.street} ${applicationData?.application?.addressHistory?.currentAddress?.city}`,25)}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>State: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.stateOrProvince}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Renting or owning property: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.onRent ? 'Rent' : 'Own'}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Reason for relocation: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.leavingReason}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Moving date: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.moveInDate}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Rent amount per month: </p>
                                            <span>${applicationData?.application?.addressHistory?.currentAddress?.payedPerMonthly}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Owner's name: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.ownerInformation?.name}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Owner's phone: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.ownerInformation?.phone}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Owner's email: </p>
                                            <span title={applicationData?.application?.addressHistory?.currentAddress?.ownerInformation?.email}>{stringTruncate(applicationData?.application?.addressHistory?.currentAddress?.ownerInformation?.email,20)}</span>
                                        </div>
                                        <div className={styles.info__row}>
                                            <p>Permission to contact: </p>
                                            <span>{applicationData?.application?.addressHistory?.currentAddress?.ownerInformation?.permissionToContact ? 'Yes' : 'No'}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {applicationData?.application?.addressHistory?.previousAddress?.map((address,index)=>{
                                return(
                                    <Fragment key={address?._id}>
                                        <Col md={6} className={styles.col}>
                                            <div className={styles.applicant__info}>
                                                <h5>Previous Address #{index+1}</h5>
                                                <div className={styles.applicant__info__list}>
                                                    <div className={styles.info__row}>
                                                        <p>Address: </p>
                                                        <span>{`${address?.zipOrPostal} ${address?.street} ${address?.city}`}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>State: </p>
                                                        <span>{address?.stateOrProvince}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Renting or owning property: </p>
                                                        <span>{address?.onRent ? 'Rent' : 'Own'}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Reason for relocation: </p>
                                                        <span>{address?.leavingReason}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Moving date: </p>
                                                        <span>{address?.moveInDate}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Rent amount per month: </p>
                                                        <span>${address?.payedPerMonthly}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Fragment>
                                )
                            })}
                        </Row>
                    </div>
                    {/*FINANCIAL INFORMATION*/}
                    {applicationData?.application?.financialInformation?.incomeSource?.length > 0 && <div className={styles.applicant__meta__information}>
                        <div className={styles.applicant__meta__info__title}>
                            <h1>Financial Information</h1>
                        </div>
                        <Row>
                            {applicationData?.application?.financialInformation?.incomeSource?.map((incomeSource, index) => {
                                return (
                                    <Fragment key={incomeSource?._id}>
                                        <Col md={6} className={styles.col}>
                                            <div className={styles.applicant__info}>
                                                <h6>Income Source</h6>
                                                <h5>{index + 1}. {stringTruncate(incomeSource?.name, 15)}</h5>
                                                <div className={styles.applicant__info__list}>
                                                    <div className={styles.info__row}>
                                                        <p>Type of Employment: </p>
                                                        <span>{incomeSource?.employmentType}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Employer's name: </p>
                                                        <span>{incomeSource?.employerName}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Employer's address: </p>
                                                        <span>{incomeSource?.employerAddress}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Job position: </p>
                                                        <span>{incomeSource?.position}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Start date: </p>
                                                        <span>{incomeSource?.startedWorking}</span>
                                                    </div>
                                                    <div className={styles.info__row}>
                                                        <p>Annual income: </p>
                                                        <span>${incomeSource?.annualIncome}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Fragment>
                                )
                            })}
                        </Row>
                    </div>}
                </div>}
            </div>
        </>
    )
}
export default ApplicationReportBody
