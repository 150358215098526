import React, {useState} from "react";
import propTypes from "prop-types";
import styles from "../../../styles/common/container.module.css"
import {creditScoreCriteria} from "../../../utils/helpers";
import CustomTooltip from "../tooltips/custom-tooltip";
import {CREDIT_SCORE} from "../../../utils/constants/application-listing";
import useWindowSize from "../../../hooks/use-window-size";


const TooltipDescription = ({scoreType}) => {
    return(
        <>
            <h6>{scoreType} Score</h6>
            <p>This score is given by the applicant and may need to be verified.
                Applicants are recommended to provide credit report to support their credit score claim.</p>
        </>
    )
}
const CreditScoreContainer = ({score,scoreType}) => {
    const highlighted = creditScoreCriteria(score,scoreType)
    const {width}= useWindowSize()
    const [isShowToolTip,setIsShowToolTip] = useState(false)
    const mouseEnter = () => {
        setIsShowToolTip(true)
    }
    const mouseLeave = () => {
        setIsShowToolTip(false)
    }
    return(
        <>
            <div className={styles.credit__score__wrapper}>
                <div className={styles.score__bar}>
                    <span className={highlighted === 'red' ? styles.highlighted : ''}/>
                    <span className={highlighted === 'orange' ? styles.highlighted : ''}/>
                    <span className={highlighted === 'yellow' ? styles.highlighted : ''}/>
                    <span className={highlighted === 'lightGreen' ? styles.highlighted : ''}/>
                    <span className={highlighted === 'darkGreen' ? styles.highlighted : ''}/>
                </div>
                <h6
                    className={styles[highlighted]}
                    onMouseLeave={mouseLeave}
                    onMouseEnter={mouseEnter}
                >
                    {score}
                    {isShowToolTip && <CustomTooltip extraClass={width <= 768 && "mobile__credit__score__tooltip"}>
                        <TooltipDescription scoreType={scoreType === CREDIT_SCORE.EQUIFAX ? "Equifax" : "Transunion"}/>
                    </CustomTooltip>}
                </h6>
            </div>
        </>
    )
}
export default CreditScoreContainer

CreditScoreContainer.propType = {
    score : propTypes.number.isRequired,
    scoreType : propTypes.string.isRequired
}
