import React from "react"
import axios from "../config/axios-instance";
import TokenService from "./token.service";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {viewError} from "../utils/helpers";
import {useNavigate, useParams} from "react-router";
import {SCREENING_PAY} from "../utils/constants/listing";

const UnitListingService = () => {
    const navigate = useNavigate()

    //Fetch All Users Listings
    const useFetchUserListing = (sort,page,search,limit,status) => {
        const userId = TokenService.getUser()?._id

        const handleFetchUserListingRequest = (userId,page,sort,search,status) => {
            return axios.get(`/listing/owners/?userId=${userId}&page=${page}&limit=${limit}&sort=${sort}&searchQuery=${search}&status=${parseInt(status)}`)
        }

        return useQuery(
            [`listing-user-listing`,userId,page,sort,search,parseInt(status)],
            () => handleFetchUserListingRequest(userId,page,sort,search,status),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                enabled:(!!sort && !!page && !!userId)
            }
        )
    }

    //Fetch Listing By ID
    const useFetchListingById = (listingId) => {
        const userEmail = TokenService.getUser()?.email

        const onError = (error) => {

            if(error.response.status === 400){
                toast.error("Access Denied")
                navigate("/home")
            }
            else{
                toast.error(viewError(error.response.data.message))
            }
        }

        const handleFetchListingByIdRequest = (listingId,email) => {
            return axios.get(`/listing/?listingId=${listingId}&email=${email}`)
        }

        return useQuery(
            [`listing-by-id`,listingId,userEmail],
            () => handleFetchListingByIdRequest(listingId,userEmail),
            {
                onError,
                retry:1,
                refetchOnWindowFocus:false,
            }
        )
    }

    //Fetch Listing Applicants
    const useFetchListingApplicants = (search,page,limit,unitId) => {

        const onError = (error) => {
            if(error.response.status === 400){
                toast.error("Access Denied")
                navigate("/home")
            }
            else{
                toast.error(viewError(error.response.data.message))
            }
        }

        const handleFetchListingApplicantsRequest = (listingId,page,search) => {
            return axios.get(`/listing/applicants?listingId=${listingId}&searchQuery=${search}&page=${page}&limit=${limit}`)
        }

        return useQuery(
            [`listing-applicants`,unitId,page,search],
            () => handleFetchListingApplicantsRequest(unitId,page,search),
            {
                onError,
                retry:1,
                refetchOnWindowFocus:false,
                enabled:!!unitId,
                select : (response) => response.data,
            }
        )
    }

    //Fetch Listing Accepted Applicants
    const useFetchAcceptedListingApplicants = () => {
        const {unitId} = useParams()
        const handleFetchListingApplicantsRequest = (listingId) => {
            return axios.get(`/listing/accepted-applicant?listingId=${listingId}`)
        }

        return useQuery(
            [`accepted-listing-applicants`,unitId],
            () => handleFetchListingApplicantsRequest(unitId),
            {
                retry:1,
                refetchOnWindowFocus:false,
                select : (response) => response.data,
            }
        )
    }

    //Update Listing Status
    const useHandleUpdateListingStatus = () => {
        const queryClient = useQueryClient()
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email

        const handleApplicantsDecisionRequest = ({status}) => {
            return axios.put(`/listing/status?listingId=${unitId}`,{status})
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`listing-by-id`,unitId,userEmail])
        }

        return useMutation(
            handleApplicantsDecisionRequest,
            {
                onError,
                onSuccess,
                retry:0,
            }
        )
    }


    //Handle Applicant Decision
    const useHandleApplicantsDecision = ({status,applicantId},page,search) => {
        const queryClient = useQueryClient()
        const {unitId} = useParams()

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`listing-applicants`,unitId,page,search])
        }

        const handleApplicantsDecisionRequest = (status,applicantId) => {

            return axios.post(`/listing/request?applicantId=${applicantId}`,{status})
        }

        return useMutation(
            () => handleApplicantsDecisionRequest(status,applicantId),
            {
                onError,
                onSuccess,
                retry:1,
            }
        )
    }

    //Handle Un-accept Applicant
    const useHandleUnAcceptApplicant = (page,search) => {
        const queryClient = useQueryClient()
        const {unitId} = useParams()

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`listing-applicants`,unitId,page,search])
        }

        const handleUnAcceptApplicantRequest = (unitId) => {
            return axios.put(`/listing/request?listingId=${unitId}`)
        }

        return useMutation(
            () => handleUnAcceptApplicantRequest(unitId),
            {
                onError,
                onSuccess,
                retry:1,
            }
        )
    }

    //Handle Un-deny Applicant
    const useHandleUnDenyApplicant = (page,search,applicantId) => {
        const queryClient = useQueryClient()
        const {unitId} = useParams()

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`listing-applicants`,unitId,page,search])
        }

        const handleUnDenyApplicantRequest = (applicantId) => {
            return axios.post(`/listing/request/un-deny?applicantId=${applicantId}`)
        }

        return useMutation(
            () => handleUnDenyApplicantRequest(applicantId),
            {
                onError,
                onSuccess,
                retry:1,
            }
        )
    }

    //Apply For Listing
    const useHandleApplyForListingService = () => {
        const userId = TokenService.getUser()?._id
        const {unitId} = useParams()
        const applicationId = TokenService.getRenterApplicationId()
        const HandleApplyForListingRequest = () => {
            return axios.post(`/listing/apply?renterId=${userId}&listingId=${unitId}`)
        }

        const onError = (error) => {
            if(error.response.status === 400){
                navigate(`/create-application-listing/${applicationId}`,{state:{unitId}})
            }else if(error.response.status === 406){
                toast.error(viewError(error.response.data.message))
            }
        }

        return useMutation(
            HandleApplyForListingRequest,
            {
                onError,
                retry:0
            }
        )
    }

    //Delete Listing
    const useHandleDeleteListing = (listingId,metaData) => {
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const handleDeleteListingService = (id) => {
            return axios.delete(`/listing/?userId=${userId}&listingId=${id}`)
        }

        const onSuccess = () =>{
            queryClient.invalidateQueries([`listing-user-listing`,userId,metaData?.page || 1,metaData?.filterValue || 3,metaData?.searchValue || "",metaData?.status || 4])
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleDeleteListingService(listingId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Exit Listing
    const useHandleExitListing = (listingId,completedListingInThisSession) => {
        const userId = TokenService.getUser()?._id

        const handleExitListingService = (id,data) => {
            return axios.post(`/listing/exit?userId=${userId}&listingId=${id}`,data)
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleExitListingService(listingId,completedListingInThisSession),
            {
                onError,
                retry:0
            }
        )
    }

    //Duplicate Listing
    const useHandleDuplicateListing = (listingId,metaData) => {
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const handleDuplicateListingService = (id) => {
            return axios.post(`/listing/duplicate?userId=${userId}&listingId=${id}`)
        }

        const onSuccess = () =>{
            queryClient.invalidateQueries([`listing-user-listing`,userId,metaData.page,metaData.filterValue,metaData.searchValue,metaData?.status || 4])
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            ()=>handleDuplicateListingService(listingId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Handle List Unit
    const useHandleListUnitService = () => {
        const userId = TokenService.getUser()?._id

        const handleListUnitService = () => {
            return axios.post(`/listing/?userId=${userId}`)
        }

        const onSuccess = (response) =>{
            navigate(`/create-unit-listing/${response.data?.data?.listingId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            handleListUnitService,
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateListingInformationService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateListingInformationRequest = (data) => {
            return axios.put(`/listing/listing-information?listingId=${unitId}&userId=${userId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            // expiryYear:cardExpYear,
                            // last4:trimmedCardNumber
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateListingInformationRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateUnitDetailsService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const queryClient = useQueryClient()
        const userId =TokenService.getUser()?._id
        const HandleUpdateUnitDetailsRequest = (data) => {
            return axios.put(`/listing/unit-details?listingId=${unitId}&userId=${userId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateUnitDetailsRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateAppliancesService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateAppliancesRequest = (data) => {
            return axios.put(`/listing/facilities?listingId=${unitId}&userId=${userId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateAppliancesRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleDeleteMediaService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id

        const HandleDeleteMediaRequest = (data) => {
            return axios.delete(`/listing/media/?listingId=${unitId}&userId=${userId}`,{data:{mediaName:data}})
        }

        const onSuccess = (response) =>{
            // toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleDeleteMediaRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateThumbnailService = (data) => {
        const {unitId} = useParams()
        const userId = TokenService.getUser()?._id

        const HandleUpdateThumbnailRequest = (data) => {
            return axios.put(`/listing/media-thumbnail/?listingId=${unitId}&userId=${userId}`,{thumbnailName:data})
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateThumbnailRequest(data),
            {
                onError,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateMediaService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateMediaRequest = (data) => {
            return axios.put(`/listing/media/?listingId=${unitId}&userId=${userId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateMediaRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //
    const useHandleUpdateInvitationService = (data,unitData) => {
        const {unitId} = useParams()
        // const userEmail = TokenService.getUser()?.email
        // const queryClient = useQueryClient()
        const userId = TokenService.getUser()?._id
        const HandleUpdateMediaRequest = (data) => {
            return axios.put(`/listing/invite-share/?listingId=${unitId}&userId=${userId}`,data)
        }

        const onSuccess = (response) =>{
            if(parseInt(data?.screeningPay) === SCREENING_PAY.OWNER && !unitData?.screeningFeePayed){
                window.location.href = response.data.data
            }else{
                navigate("/home")
            }
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateMediaRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }


    //
    const useHandleUpdateScreeningFeesService = (data) => {
        const {unitId} = useParams()
        const userEmail = TokenService.getUser()?.email
        const userId =TokenService.getUser()?._id
        const queryClient = useQueryClient()
        const HandleUpdateScreeningFeesRequest = (data) => {
            return axios.put(`/listing/pay-screening/?userId=${userId}&listingId=${unitId}`,data)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData([`listing-by-id`,unitId,userEmail], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {
                        listing:{
                            ...response?.data?.data
                        }

                    }
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => HandleUpdateScreeningFeesRequest(data),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    return {useFetchUserListing,useFetchListingById,
        useHandleListUnitService,useHandleUpdateListingInformationService,
        useHandleUpdateUnitDetailsService,useHandleUpdateAppliancesService,useHandleUpdateInvitationService,
        useHandleUpdateScreeningFeesService,useHandleUpdateMediaService,useHandleDeleteListing
        ,useHandleDuplicateListing,useHandleExitListing,useHandleDeleteMediaService,useHandleUpdateThumbnailService
    ,useHandleApplyForListingService,useFetchListingApplicants,useHandleApplicantsDecision,useHandleUnAcceptApplicant
    ,useHandleUnDenyApplicant,useFetchAcceptedListingApplicants,useHandleUpdateListingStatus}
};

export default UnitListingService;
