import React, {useEffect} from "react"
import styles from "../../../styles/common/modal.module.css"
import propTypes from "prop-types";
import ButtonLoader from "../loaders/button-loader";
import {CloseButton, Modal} from "react-bootstrap";


const SchedulerModal = ({handleModalClose,Content,isCloseIcon,isFooter=true,title,isLoading,mutate,isSuccess,confirmButtonText,cancelButtonText,onSuccess,reset}) => {

    const handleConfirmation = () =>{
        mutate()
    }
    useEffect(()=>{
        if(isSuccess){
            handleModalClose(false)
            if(reset){
                reset()
            }
            if(!!onSuccess){
                onSuccess()
            }
        }
    },[isSuccess])

    return(
        <>
            <section className={styles.scheduler__modal__wrapper}>
                <div className={styles.title__wrapper}>
                    {isCloseIcon &&
                        <Modal.Header className={styles.title__header__wrapper}>
                            <h2>{title}</h2>
                            <CloseButton aria-label="Hide" onClick={() => handleModalClose(false)}/>
                        </Modal.Header>
                    }
                    {!isCloseIcon && <h2>{title}</h2>}
                </div>
                {Content}
                {isFooter && <div className={styles.confirmation__button__wrapper}>
                    {isLoading ? <ButtonLoader/> : (
                        <>
                            <button
                                onClick={() => handleModalClose(false)}>{cancelButtonText ? cancelButtonText : 'Cancel'}</button>
                            <button onClick={handleConfirmation}>{confirmButtonText}</button>
                        </>
                    )}
                </div>}
            </section>
        </>
    )
}
export default SchedulerModal;

SchedulerModal.propType = {
    handleModalClose : propTypes.func.isRequired,
    title : propTypes.string.isRequired,
    isLoading : propTypes.bool.isRequired,
    isSuccess : propTypes.bool.isRequired,
    mutate : propTypes.func.isRequired,
    confirmButtonText: propTypes.string.isRequired,
    onSuccess : propTypes.func,
    reset : propTypes.func
}
