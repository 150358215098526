import React from "react"
import axios from "../config/axios-instance";
import TokenService from "./token.service";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {handleChangeDateTimezone, viewError} from "../utils/helpers";
import {useParams} from "react-router";

const UnitViewingService = () => {
    const queryClient = useQueryClient()

    //Fetch Unit's Availabilities
    const useFetchUnitAvailabilities = (unitId) => {


        const handleFetchUserListingRequest = () => {
            return axios.get(`/listing-viewing/?listingId=${unitId}`)
        }

        return useQuery(
            [`unit-viewing-availability`, unitId],
            () => handleFetchUserListingRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!unitId,
                select: (response) => response.data?.data
            }
        )
    }


    //Fetch Unit's Viewing's (Upcoming)
    const useFetchUnitViewings = () => {
        const {unitId} = useParams()

        const handleFetchUnitViewingsRequest = () => {
            return axios.get(`/viewing-event/listing?listingId=${unitId}`)
        }

        return useQuery(
            [`unit-viewings-list`,unitId],
            () => handleFetchUnitViewingsRequest(),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                enabled:!!unitId,
                select:(response)=>response.data?.data
            }
        )
    }

    //Handle Add Viewing
    const useHandleAddViewing = () => {
        const userId = TokenService.getUser()?._id

        const handleAddViewingRequest = (data,renterId,unitId,ownerId) => {
            return axios.post(`/viewing-event?listingId=${unitId}&renterId=${renterId}&ownerId=${!!ownerId ? ownerId : userId}`,data)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            toast.success('Viewing Successfully Added')
        }
        return useMutation(
            ({data,renterId,unitId,ownerId}) => handleAddViewingRequest(data,renterId,unitId,ownerId),
            {
                retry:0,
                onError,
                onSuccess
            }
        )
    }

    //Handle Save Unit's Availability
    const useHandleSaveUnitAvailabilities = () => {
        const {unitId} = useParams()
        const userId = TokenService.getUser()?._id

        const handleFetchUserListingRequest = (data) => {
            return axios.put(`/listing-viewing/?userId=${userId}&listingId=${unitId}`,data)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = (response) => {
            toast.success(response.data.message)
        }

        return useMutation(
            (data) => handleFetchUserListingRequest(data),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Fetch Owner's Viewings (Upcoming) Filtered By Dates
    const useFetchOwnerViewings = (isNotEnabled) => {
        const userId = TokenService.getUser()?._id

        const handleFetchOwnerViewingsRequest = () => {
            return axios.get(`/viewing-event/dates?ownerId=${userId}`)
        }

        return useQuery(
            [`owner-viewings-by-date`,userId],
            () => handleFetchOwnerViewingsRequest(),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                enabled:!isNotEnabled,
                select:(response)=>response.data?.data
            }
        )
    }


    //Fetch Owner's Viewings (Upcoming)
    const useFetchOwnerUpcomingViewings = () => {
        const userId = TokenService.getUser()?._id

        const handleFetchOwnerViewingsRequest = () => {
            return axios.get(`/viewing-event/upcoming?ownerId=${userId}&page=1&limit=99`)
        }

        return useQuery(
            [`owner-viewings`,userId],
            () => handleFetchOwnerViewingsRequest(),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                select:(response)=>response.data?.data
            }
        )
    }

    //Fetch Owner's Viewings (Upcoming) Filtered By Month & Year For Calendar
    const useFetchOwnerViewingsForCalendar = (month,year) => {
        const userId = TokenService.getUser()?._id

        const handleFetchOwnerViewingsForCalendarRequest = () => {
            return axios.get(`/viewing-event/monthly?ownerId=${userId}&month=${month}&year=${year}`)
        }

        return useQuery(
            [`owner-viewings-by-month-year`,userId,month,year],
            () => handleFetchOwnerViewingsForCalendarRequest(),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                select:(response)=>{
                    return response?.data?.data.map(el => ({
                            title: el?.listing?.name,
                            start: handleChangeDateTimezone(new Date(el?.startDateTime),el?.timezone),
                            end:  handleChangeDateTimezone(new Date(el?.endDateTime),el?.timezone),
                            _id:el?._id
                        }))
                }
            }
        )
    }

    //Fetch Owner's Viewings By A Specific Date
    const useFetchOwnerViewingsByDate = (date) => {
        const userId = TokenService.getUser()?._id

        const handleFetchOwnerViewingsByDateRequest = (date) => {
            return axios.get(`/viewing-event/date?ownerId=${userId}&date=${date}&page=1&limit=999`)
        }

        return useQuery(
            [`owner-viewings-by-date`,userId,date],
            () => handleFetchOwnerViewingsByDateRequest(date),
            {
                retry:1,
                keepPreviousData:true,
                refetchOnWindowFocus:false,
                enabled:!!date,
                select:(response)=>([{
                    _id:date,
                    events:response.data?.data
                }])
            }
        )
    }

    //Delete An Owner's Viewing
    const useHandleDeleteOwnersViewing = () => {
        const userId = TokenService.getUser()?._id

        const handleDeleteOwnersViewingRequest = (viewingId) => {
            return axios.delete(`/viewing-event?eventId=${viewingId}`)
        }

        const onSuccess = () => {
            queryClient.invalidateQueries([`owner-viewings`,userId])
        }

        return useMutation(
            (id) => handleDeleteOwnersViewingRequest(id),
            {
                retry:1,
                onSuccess,
            }
        )
    }

    return {useFetchUnitAvailabilities,useHandleSaveUnitAvailabilities,useFetchOwnerViewings,
        useHandleDeleteOwnersViewing,useFetchOwnerViewingsForCalendar,useFetchOwnerViewingsByDate
        ,useFetchUnitViewings,useHandleAddViewing,useFetchOwnerUpcomingViewings}
};

export default UnitViewingService;
