import React from "react"
import styles from "../../../styles/messages.module.css"
import {MEDIA_BASEURL} from "../../../utils/constants";

const MessageTop = ({messageThread}) => {
  return(
      <>
        <div className={styles.message__top__wrapper}>
            <img src={`${MEDIA_BASEURL}${messageThread?.data?.senderData?.profilePicture}`} alt={"Profile Picture"}/>
            <p>{`${messageThread?.data?.senderData?.firstName} ${messageThread?.data?.senderData?.lastName}`}</p>
        </div>
      </>
  )
}
export default MessageTop
