import React, {useContext, useEffect, useState} from "react";
import useRedirectToCurrentStep from "../../../../../hooks/use-redirect-to-current-step";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import TokenService from "../../../../../services/token.service";
import useForm from "../../../../../hooks/use-form";
import DatePicker from "react-datepicker";
import {AGREEMENT_TYPE, RENT_TYPE} from "../../../../../utils/constants/listing";
import {CREDIT_SCORE} from "../../../../../utils/constants/application-listing";
import ApplicationListingService from "../../../../../services/application-listing.service";
import {formatDateToISO} from "../../../../../utils/helpers";
import {LISTING} from "../../../../../utils/constants";
import {toast} from "react-toastify";

const ApplicationInformationForm = ({applicationData,count,setCount}) => {
    const {previousStep, nextStep,goToStep} = useWizard();
    // const userData = TokenService?.getUser()
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)
    const {useHandleUpdateApplicantInformationService} = ApplicationListingService()

    const [fields,setFields] = useState({
        name: "",
        phone: "",
        email: "",
        leaseStartDate: "",
        creditScore: "",
        scoreType: "",
        about: ""
    })
    const [leaseStartDate,setLeaseStartDate] = useState(new Date())
    useEffect(()=>{
        setFields({
            name: applicationData?.applicantInformation?.name || "",
            phone: applicationData?.applicantInformation?.phone || "",
            email:  applicationData?.applicantInformation?.email || "",
            creditScore: applicationData?.applicantInformation?.creditScore || "",
            scoreType: applicationData?.applicantInformation?.scoreType || "",
            about: applicationData?.applicantInformation?.about || ""
        })
        setLeaseStartDate(new Date(applicationData?.applicantInformation?.leaseStartDate || new Date()))
    },[applicationData?.applicantInformation])

    const {details,inputEvent,validateForms,error} = useForm(fields)

    const {isLoading,mutate,isSuccess} = useHandleUpdateApplicantInformationService({...details,leaseStartDate:formatDateToISO(leaseStartDate)})

    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()
        if(validateForms(details,[])){
            return null;
        }
        if(details?.creditScore < 300 || details?.creditScore > 900){
            toast.error("Score range is between 300 and 900")
            return null;
        }
        mutate()
    }

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    firstStepperCompleted:true
                }
            })
            nextStep().then()
        }
    },[isSuccess])


    // //Redirecting To The Designated Stepper
    const {redirectStepperIndex} = useRedirectToCurrentStep(applicationData,LISTING.APPLICATION_LISTING)
    useEffect(()=>{
        setCount((prev)=>prev+1)
        //So It doesn't redirect on every render
        if(count <= 1){
            goToStep(redirectStepperIndex)
        }
    },[redirectStepperIndex])
    return(
        <>
            <Form className={styles.create__listing__form} onSubmit={goToNextStep}>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control className={error["name"] ? "error" : ""} type={"text"} value={details.name} name={"name"} onChange={inputEvent} placeholder={"John Doe"}/>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label>Contact Phone</Form.Label>
                    <Form.Control className={error["phone"] ? "error" : ""}  type="number" name="phone" value={details.phone} onChange={inputEvent} placeholder={"(123) 456 7890"}/>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label>Contact Email</Form.Label>
                    <Form.Control className={error["email"] ? "error" : ""} type="email" name="email" value={details.email} onChange={inputEvent} placeholder={"example@example.com"}/>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label>When would you like your lease to start?</Form.Label>
                    <DatePicker
                        onChange={(date) => setLeaseStartDate(date)}
                        selected={leaseStartDate}
                        dateFormat={"yyyy-MM-dd"}
                        // minDate={new Date()}
                        placeholderText={"DD / MM / YYYY"}
                    />
                </Form.Group>
                <Form.Group className={styles.form__check__wrapper}>
                    <Form.Label>What is you credit score?</Form.Label>
                    <div className={styles.form__check}>
                        <Form.Control
                            type={"number"}
                            className={`${error["creditScore"] ? "error" : ""} ${styles.check__input}`}
                            value={details.creditScore}
                            name={"creditScore"}
                            onChange={inputEvent}
                            placeholder={"350"}
                        />
                        <div className="form-check">
                            <label htmlFor="scoreType_1" className={parseInt(details.scoreType) === CREDIT_SCORE.EQUIFAX ? styles.checked : ""}>
                                <input
                                    type="radio"
                                    name="scoreType"
                                    value={CREDIT_SCORE.EQUIFAX}
                                    onChange={inputEvent}
                                    className={`form-check-input`}
                                    id="scoreType_1"
                                    checked={parseInt(details.scoreType) === CREDIT_SCORE.EQUIFAX}
                                    required
                                />
                                Equifax
                            </label>
                        </div>
                        <div className="form-check">
                            <label htmlFor="scoreType_2" className={parseInt(details.scoreType) === CREDIT_SCORE.TRANSUNION ? styles.checked : ""}>
                                <input
                                    type="radio"
                                    name="scoreType"
                                    value={CREDIT_SCORE.TRANSUNION}
                                    onChange={inputEvent}
                                    className={`form-check-input`}
                                    id="scoreType_2"
                                    checked={parseInt(details.scoreType) === CREDIT_SCORE.TRANSUNION}
                                    required
                                />
                                TransUnion
                            </label>
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label>About You</Form.Label>
                    <Form.Control as={"textarea"} rows={4} name="about" value={details.about} onChange={inputEvent} placeholder={"Say something about yourself to the landlord"}/>
                </Form.Group>
                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading}
                />
            </Form>

        </>
    )
}
export default ApplicationInformationForm