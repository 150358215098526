import React, {useEffect, useRef, useState} from "react"
// import paperClip from "../../../assets/images/paper-clip.png"
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import propTypes from "prop-types";
import image from "../../../assets/images/uploader-icon.png"
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileEncode,FilePondPluginFileValidateType);

const AssetUploader = (props) => {
    // const {register,watch} = useForm()
    const [previewAsset,setPreviewAsset] = useState([])
    const filePondRef = useRef(null)
    // const assetFormData = new FormData()
    // const asset = watch("images")
    const passAssetData = (files) => {
        // console.log(files,"asset")
        if(props.singleImage){
            for (let i = 0; i < files.length; i++) {
                // console.log(files[i].name, files[i])
                // console.log(files[i],"hehe")
                props.getUploadedAsset(files[i])
            }
        }else{
            props.getUploadedAsset(files)
        }
    }

    // const imageToBase64 = (e)=>{
    //     const tgt = e.target ,
    //         files = tgt.files;
    //     passAssetData(e.target.files)
    //     // FileReader support
    //     if (FileReader && files && files.length) {
    //         const fr = new FileReader();
    //         fr.onload = function () {
    //
    //             setPreviewAsset([...previewAsset,fr.result])
    //         }
    //         fr.readAsDataURL(files[0]);
    //     }
    //
    //     else {
    //         throw new Error("Conversion Failed");
    //     }
    // }
    // const [files,setFiles] = useState([])
    // useEffect(()=>{
    //     props.files().then((data)=>{
    //         setFiles((prev)=>{
    //             return [
    //                 ...prev,
    //                 data
    //             ]
    //         })
    //     })
    // },[props.files])

    const removeFiles = (error) => {
        if (error) {
            return;
        }
        filePondRef.current.removeFiles()
    }
    return(
        <>
            {/*{previewAsset.length > 0 && previewAsset.map((asset,i)=>{*/}
            {/*    return(*/}
            {/*        <Fragment key={i}>*/}
            {/*            <img src={asset} alt={"testing"} style={{width:"100%"}}/>*/}
            {/*        </Fragment>*/}
            {/*    )*/}
            {/*})}*/}
            {/*<input type="file" {...register("images")} multiple onChange={imageToBase64}/>*/}
            <FilePond
                type="file"
                ref={filePondRef}
                className={`file filepond__file__wrapper ${props.customClasses}`}
                name="images"
                allowFileEncode={true}
                acceptedFileTypes={props.customFileTypes ? props.customFileTypes : props?.isVideo ? [`video/mp4`] : ['image/png','image/jpg','image/jpeg']}
                fileValidateTypeDetectType={(source, type) => new Promise((resolve, reject) => {
                    resolve(type);
                })
                }
                allowRemove={true}
                beforeRemoveFile={item =>{
                    for( let i = 0; i < previewAsset.length; i++){
                        if (previewAsset[i] === item.file) {
                            previewAsset.splice(i, 1);
                        }
                    }
                    passAssetData(props.singleImage ? [] : previewAsset)
                }}
                onpreparefile= {(item) => {
                    setPreviewAsset([...previewAsset,item.file])
                    passAssetData([...previewAsset,item.file])
                }}
                onprocessfile={props.removeFilesOnUpload ? removeFiles : ()=>{}}
                allowMultiple={!props.singleImage}
                maxFiles={10}
                // files={props.files ? [] : null}
                required={!props.notRequired}
                server={props.server || {}}
                labelIdle={`${props.placeholder ? props.placeholder :  `Drop Here To Add Your Images or <span className="filepond--label-action">Browse</span>`}`}
                // labelIdle={`<img className="filepond__asset__icon" src="${paperClip}"/> <br/> Drop Here To Add Your Images or <span className="filepond--label-action">Browse</span> <br/> <p className="filepond__asset__list">(Image/Video)</p>`}

            />
        </>
    )
}
export default AssetUploader

AssetUploader.PropType = {
    getUploadedAsset: propTypes.func.isRequired,
    notRequired: propTypes.bool,
    placeholder : propTypes.string,
    singleImage : propTypes.bool,
    isVideo : propTypes.bool,
    customClasses : propTypes.string,
    customFileTypes : propTypes.array,
    server : propTypes.func,
    files : propTypes.array,
    removeFilesOnUpload : propTypes.bool
}