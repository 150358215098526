import React from "react";
import {ROLES} from "../../utils/constants";
import TokenService from "../../services/token.service";
import {useNavigate} from "react-router";
import notFound from "../../assets/images/not-found.png"
import styles from "../../styles/common/fallback.module.css"

const NotFoundFallback = () => {
    const loggedInUserRole = TokenService.getUser()?.role
    const navigate = useNavigate()
    return(
        <>
            <div className={styles.not__found__wrapper}>
                <div className={styles.not__found__content}>
                    <img src={notFound} alt={"404 Not Found"}/>
                    <h1>Page Not Found</h1>
                    <p>The page you requested does not exist. The URL may have been mistyped or has moved elsewhere</p>
                    <button type={"button"} onClick={() => navigate(`${loggedInUserRole === ROLES.OWNER ? "/home" : loggedInUserRole === ROLES.RENTER ? "/application" : "/login"}`)}>Home</button>
                </div>
            </div>
        </>
    )
}
export default NotFoundFallback
