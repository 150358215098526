import React, {useState} from 'react'
import styles from "../../../styles/account-settings.module.css"
import checked from "../../../assets/images/Checked-box.png"
import {Image} from "react-bootstrap";
import TokenService from "../../../services/token.service";
import PrimaryModal from "../../common/modals/primary-modal";
import InfoModal from "../../common/modals/info-modal";
import AccountSettingsService from "../../../services/account-settings.service";
import {NavLink} from "react-router-dom";
import DefaultLoader from "../../common/loaders/default-loader";

const CancelAccount = () => {
    const userRole = TokenService.getUser()?.role
    const {useHandleDeleteAccountService,useFetchAccountUsageService} = AccountSettingsService()
    const [isOpen,setIsOpen] = useState(false)
    const {isLoading,mutate,isSuccess} = useHandleDeleteAccountService()
    const {isLoading:isAccountUsageLoading,data:accountUsage} = useFetchAccountUsageService()
    const handleAccountConfirmationModal = (isModalOpen) => {
        setIsOpen(isModalOpen)
    }
    return(
        <>
            <section className={styles.account__details__wrapper}>
               <div className={styles.cancel__account}>
                   <div className={styles.account__role}>
                       <Image src={checked} alt={'checked'}/>
                       <p className={styles.role__info}>Your account is {userRole === "Renter" ? "a" : "an"} {userRole} account</p>
                   </div>
                   <h4>Permanently delete and close your account</h4>
                   <p className={styles.delete__info}>Account deletion is permanent, and you will not be able to
                       reactivate your account or retrieve any content after the deletion.</p>
                   {isAccountUsageLoading && <DefaultLoader/>}
                   {!isAccountUsageLoading && (
                       <>
                           {(accountUsage?.restrictions?.listings?.length > 0) && (
                               <>
                                   <p className={styles.restriction__text}>
                                       Before Deleting your account, you need to dis-associate yourself with the following published/ leased listings:
                                   </p>
                                   <ul className={styles.account__usage__list}>
                                       {accountUsage.restrictions.listings.map(el => (
                                           <li>
                                               <NavLink to={`/unit/${el?.listing?._id}/preview`}>
                                                   {el?.listing?.name}
                                               </NavLink>
                                           </li>
                                       ))}
                                   </ul>
                               </>
                           )}
                           {(accountUsage?.warnings?.listings?.length > 0 || accountUsage?.warnings?.events?.length > 0) && (
                               <>
                                   <h2 className={styles.warning__heading}>Warning:</h2>
                                   <p className={styles.warning__text}>
                                       {`You are associated with the following 
                                           ${accountUsage?.warnings?.listings?.length > 0 ? 'listings' : ''} 
                                           ${accountUsage?.warnings?.listings?.length > 0 && accountUsage?.warnings?.events?.length > 0 ? 'and' : ''}
                                           ${accountUsage?.warnings?.events?.length > 0 ? 'viewings' : ''}.
                                           Please make sure to review them before moving forward to cancel your account.
                                       `}
                                   </p>
                                   {accountUsage?.warnings?.listings?.length > 0 && (
                                       <>
                                           <h3 className={styles.account__usage__heading}>Listings:</h3>
                                           <ul className={styles.account__usage__list}>
                                               {accountUsage.warnings.listings.map(el => (
                                                   <li>
                                                       <NavLink to={`/unit/${el?.listing?._id}/preview`}>
                                                           {el?.listing?.name}
                                                       </NavLink>
                                                   </li>
                                               ))}
                                           </ul>

                                       </>
                                   )}
                                   {accountUsage?.warnings?.events?.length > 0 && (
                                       <>
                                           <h3 className={styles.account__usage__heading}>Viewings:</h3>
                                           <ul className={styles.account__usage__list}>
                                               {accountUsage.warnings.events.map(el => (
                                                   <li>
                                                       <NavLink to={`/unit/${el?.listing?._id}/preview`}>
                                                           {el?.listing?.name}
                                                       </NavLink>
                                                   </li>
                                               ))}
                                           </ul>

                                       </>
                                   )}
                               </>
                           )}
                           <button disabled={accountUsage?.restrictions?.listings?.length > 0} onClick={() => handleAccountConfirmationModal(true)}>Cancel Account</button>
                       </>
                   )}

               </div>
            </section>
            {isOpen &&
                <PrimaryModal
                    size={"lg"}
                    customClass={"half__modal"}
                    isOpen={isOpen}
                    modalContent={
                        <InfoModal
                            handleModalClose={handleAccountConfirmationModal}
                            isLoading={isLoading}
                            mutate={mutate}
                            isSuccess={isSuccess}
                            title={"Account Deletion"}
                            description={"Are you sure you want to delete your account? This action cannot be undone."}
                            confirmButtonText={"Delete"}
                        />}
                />
            }
        </>
    )
}
export default CancelAccount;
