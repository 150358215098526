import React from "react"
import styles from "../../../styles/common/card.module.css"

const TransactionCard = ({colorCode,cardTitle,amount,numberOfTransactions}) => {
    return(
        <>
            <div className={`${styles.transaction__card__wrapper}`} style={{borderLeft:`10px solid ${colorCode}`}}>
                <div className={styles.transaction__card__content}>
                    <h5>{cardTitle}</h5>
                    <div className={styles.transaction__information}>
                        <h3>{`$${amount}`}</h3>
                        <p>{`${numberOfTransactions} ${numberOfTransactions > 1 ? "Transactions" : "Transaction"}`}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TransactionCard
