import React, {useCallback, useEffect, useRef, useState} from 'react'
import styles from "../../../../../styles/listing.module.css"
import {useOutletContext} from "react-router";
import useReverseGeocode from "../../../../../hooks/use-reverse-geocode";
import SingleUnitMetaInformation from "../../../../presentational/listing/single-unit/preview/single-unit-meta-information";
import SingleUnitMedia from "../../../../presentational/listing/single-unit/preview/single-unit-media";
import SingleUnitDetails from "../../../../presentational/listing/single-unit/preview/single-unit-details";

const SingleUnitPreview = () => {
    //Api is called from the parent component i.e. index.js
    const {data:unitData} = useOutletContext()

    const reverseLatLng = useReverseGeocode()

    const unitLatitude = unitData?.location?.coordinates[1] || 0;
    const unitLongitude = unitData?.location?.coordinates[0] || 0

    const [filteredImages,setFilteredImages] = useState({})
    const [address,setAddress] = useState("")


    const filterImages = useCallback((unitData)=>{
        if(unitData?.media.length > 9){
            const copyImages = [...unitData?.media];
            const newImages = copyImages.splice(0,8)
            setFilteredImages({array:newImages,mergedArray: [unitData?.thumbnail,...unitData?.media],thumbnail:unitData?.thumbnail,originalArray:unitData?.media,remainingArrayLength :unitData?.media.length-9})
        }else{
            setFilteredImages({array:unitData?.media,mergedArray: [unitData?.thumbnail,...unitData?.media],thumbnail:unitData?.thumbnail,originalArray:unitData?.media,remainingArrayLength :null})
        }
    },[])

    const initialRef = useRef(false)
    useEffect(()=>{
        if(initialRef){
            (async ()=>{
                setAddress(await reverseLatLng(unitData?.location?.coordinates[1],unitData?.location?.coordinates[0]))
            })()
            if(unitData?.media){
                filterImages(unitData)
            }
        }
        initialRef.current = true
    },[unitData])

    return(
        <>
            <section className={styles.single__unit__preview__wrapper}>
                <SingleUnitMetaInformation unitData={unitData} address={address}/>
                <SingleUnitMedia
                    unitData={unitData}
                    addressGeo={{lat:unitLatitude, lng: unitLongitude}}
                    filteredImages={filteredImages}
                />
                <SingleUnitDetails unitData={unitData}/>
            </section>
        </>
    )
}
export default SingleUnitPreview
