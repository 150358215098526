import React, {useEffect, useRef, useState} from "react";
import { io } from "socket.io-client";
import Cookies from "js-cookie";
import {cookieAuth} from "../utils/constants/cookies";
import TokenService from "../services/token.service";
import {useNavigate} from "react-router";
import axios from "../config/axios-instance";
import {toast} from "react-toastify";
import {ROLES, SOCKET_CONNECTION_URL} from "../utils/constants";

const useSocketInitialization = () => {
    const URL = SOCKET_CONNECTION_URL;
    const socketRef = useRef(io(URL,{autoConnect:false}));

    const userId = TokenService.getUser()?._id
    const retries = TokenService.getTokenRetries()
    const userRole = TokenService.getUser()?.role
    const navigate = useNavigate()
    const isUserLoggedIn = !!userId && !!TokenService.getLocalAccessToken() && !!TokenService.getLocalRefreshToken()
    const [users,setUsers] = useState(null)

    //Connect To Socket
    function connectToSocket() {
        socketRef.current.auth = {
            "x-access-token": Cookies.get(cookieAuth)
        }

        socketRef.current.connect()
    }

    //Fetch New Access Token
    async function fetchAccessToken() {
        if(retries > 0 && retries){

            // Access Token was expired
            TokenService.setTokenRetries(retries-1)

            try {
                const rs = await axios.get(`/user-auth/refresh-tokens?userId=${userId}`)

                const { accessToken,refreshToken } = rs.data.data.tokens;
                TokenService.saveLocalRefreshToken(refreshToken)
                TokenService.saveLocalAccessToken(accessToken);
                TokenService.setTokenRetries(5)

            } catch (error) {
                console.log(error,"Error from socket")
            }
        }else{
            toast.info("Your session has expired.")
            TokenService.clearStorage()
            //Call for new refresh token
            navigate("/login")
        }
    }

    //Disconnect From Socket
    function closeSocketConnection() {
        socketRef.current.disconnect()
    }

    //Send Message Emit
    function onSendMessage(content,receiverId,unitId) {
        socketRef.current.emit("sendMessage",{
            content:content,
            receiverId,
            listingId:unitId
        }, (err) => {
            toast.error(err.message)
        });
    }

    //Send Media Emit
    function onSendMedia(media,receiverId,unitId) {
        socketRef.current.emit("sendMedia",{
            media,
            receiverId,
            listingId:unitId
        }, (err) => {
            toast.error(err.message)
        });
    }

    //Search For Unit
    function onSearchUnitName(searchQuery) {
        socketRef.current.emit("search", {
            searchQuery
        }, (err) => {
            toast.error(err.message)
        });
    }

    //Fetch a specific message thread
    function fetchMessageThread(userId,page,limit,unitId) {
        socketRef.current.emit("getConversation",{
            page,
            limit,
            receiverId:userId,
            listingId:unitId
        }, (err) => {
            toast.error(err.message)
        });
    }


    //Listening To Events
    useEffect(()=>{
        if (isUserLoggedIn){
            connectToSocket()
        }

        socketRef.current.onAny((event, ...args) => {
            console.log(event, args);
        });

        socketRef.current.on("connect", _ => {
            console.log("Connected")
        });

        socketRef.current.on("disconnect",  _ => {
            setUsers(null)
            console.log("Disconnected")
        });

        socketRef.current.on("connect_error", async (err) => {
            if (err.message === "Invalid Token") {
                await fetchAccessToken()
                connectToSocket()
            }
        });


        //All Users for messaging --> will receive this as soon as you connect to socket
        socketRef.current.on("users", (allUsers) => {
            // if (userRole === ROLES.RENTER){
            //     setUsers(allUsers.map(el => ({
            //         ...el?.listing,
            //         users:[{...el?.user,...el?.conversation}]
            //     })))
            // }else{
            //     setUsers(allUsers)
            // }
            setUsers(allUsers)
        });

        //Listener for the searching event
        socketRef.current.on("searchResult", (searchResult) => {
            // if (userRole === ROLES.RENTER){
            //     setUsers(searchResult.map(el => ({
            //         ...el?.listing,
            //         users:[{...el?.user,...el?.conversation}]
            //     })))
            // }else{
            //     setUsers(searchResult)
            // }
            setUsers(searchResult)
            // console.log(searchResult,"searchResult")
        });


        //Users that have just connected
        socketRef.current.on("userConnected", (connectedUser) => {
            setUsers(prev => prev?.map(el => ({
                ...el,
                users : el?.users?.map(innerEl => innerEl?._id === connectedUser?.userId ? {
                    ...innerEl,
                    isConnected : true
                } : innerEl)
            })))
        });

        //Users that have just disconnected
        socketRef.current.on("userDisconnected", (disconnectedUser) => {
            setUsers(prev => prev?.map(el => ({
                ...el,
                users : el?.users?.map(innerEl => innerEl?._id === disconnectedUser?.userId ? {
                    ...innerEl,
                    isConnected : false
                } : innerEl)
            })))
        });

        // //Receive a thread, map the thread with the users of the above event to find a specific profile picture, name etc.
        // socketRef.current.on("receiveConversation", (conversation) => {
        //     console.log(socketRef.current,"socket")
        //     console.log(conversation,"conversation")
        //     setThreadConversation(conversation)
        // });




        // return () => {
        //     socket.disconnect()
        // }
    },[])

    return {socket:socketRef.current,connectToSocket,closeSocketConnection,users,fetchMessageThread,onSendMessage,onSendMedia,onSearchUnitName}
}
export default useSocketInitialization
