import React from "react"
import styles from "../../../styles/account-settings.module.css"
import AccountSettingsService from "../../../services/account-settings.service";
import ButtonLoader from "../../common/loaders/button-loader";
import DefaultLoader from "../../common/loaders/default-loader";
import propTypes from "prop-types";

const CardDetails = ({notAllowDelete}) => {
    const {useHandleDeleteSavedCardService,useFetchSavedCardService} = AccountSettingsService()


    const {isLoading,data} = useFetchSavedCardService()
    const {isLoading : cardDeleteLoading, mutate} = useHandleDeleteSavedCardService()
    
    return(
        <>
            {isLoading ? <DefaultLoader/> :(
                <>
                    {data && <div className={styles.card__details__wrapper}>
                        <h2>Card Details</h2>
                        <p>Your Credit Card Number Ends With {data?.last4} And Expires In {data?.expiryYear.toString()}</p>
                        {!notAllowDelete && <div className={styles.delete__button__wrapper}>
                            {cardDeleteLoading ? <ButtonLoader/> :
                                <button type={"button"} onClick={mutate}>Delete Card</button>}
                        </div>}
                    </div>}
                </>
            )}
        </>
    )
}
export default CardDetails;

CardDetails.propType = {
    notAllowDelete : propTypes.bool
}