import React, {useState} from "react";
import styles from "../../../../styles/listing.module.css";
import {LISTING} from "../../../../utils/constants";
import UnitExit from "../../../stateful/listing/create-listing/create-unit/unit-exit";
import ApplicationExit from "../../../stateful/listing/create-listing/create-application/application-exit";

const ExitListing = ({listing,completedStepperInThisSession}) => {
    const [isOpen,setIsOpen] = useState(false)
    return(
        <>
            <div className={styles.exit}>
                <button onClick={() => setIsOpen(true)}>Exit</button>
            </div>
            {LISTING.UNIT_LISTING === listing && <UnitExit completedStepperInThisSession={completedStepperInThisSession} openModal={isOpen}
                                                           setOpenModal={setIsOpen}/>}
            {LISTING.APPLICATION_LISTING === listing && <ApplicationExit completedStepperInThisSession={completedStepperInThisSession} openModal={isOpen}
                                                                         setOpenModal={setIsOpen}/>}
        </>
    )
}
export default ExitListing
