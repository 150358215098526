import React, {useEffect, useState} from "react"
import {LISTING} from "../utils/constants";

const useRedirectToCurrentStep = (listingData,listing) => {

    const [redirectStepperIndex,setStep] = useState(0)
    useEffect(()=>{
        if(!listingData?.firstStepperCompleted){
            setStep(0)
        }
        else if(!listingData?.secondStepperCompleted){
            setStep(1)
        }
        else if(!listingData?.thirdStepperCompleted){
            setStep(2)
        }
        else if(!listingData?.fourthStepperCompleted){
            setStep(3)
        }
        else if(listing === LISTING.APPLICATION_LISTING){
            if(!listingData?.fifthStepperCompleted){
                setStep(4)
            }
            else if(!listingData?.sixthStepperCompleted){
                setStep(5)
            }
            else{
                setStep(6)
            }
        }
        else{
            setStep(4)
        }
    },[listingData])

    return {redirectStepperIndex}
}
export default useRedirectToCurrentStep