import React, {useContext, useEffect, useState} from "react";
import styles from "../../styles/messages.module.css"
import MessageTop from "../../components/presentational/messages/message-top";
import MessagesList from "../../components/presentational/messages/messages-list";
import SendMessage from "../../components/presentational/messages/send-message";
import {useLocation, useParams} from "react-router";
import {SocketInstanceContext} from "../../../App";
import TokenService from "../../services/token.service";
import {ROLES} from "../../utils/constants";

const totalMessages = 50
const SingleMessage = () => {
    const {fetchMessageThread,users:unSanitizedUsers,socket,onSendMessage,onSendMedia} = useContext(SocketInstanceContext)
    const [users,setUsers] = useState(null)
    const userRole = TokenService.getUser()?.role
    const {messengerId,unitId} = useParams()
    const userId = TokenService.getUser()?._id
    const [conversation,setConversation] = useState(null)
    const [newMessage,setNewMessage] = useState(null)
    const location = useLocation()
    // let messageTopBoundaryRef = useRef(null);
    // const [pagination,resetPostPagination] = useApiPager(messageTopBoundaryRef)
    const [pagination,setPagination] = useState(null)

    const onApiPagination = (paginationCallback) => {
        setPagination(paginationCallback)
    }

    useEffect(()=>{
        if (!!unSanitizedUsers){
            if (userRole === ROLES.RENTER){
                setUsers(unSanitizedUsers.map(el => ({
                    ...el?.listing,
                    users:[{...el?.user,...el?.conversation}]
                })))
            }else{
                setUsers(unSanitizedUsers)
            }
        }
    },[unSanitizedUsers?.length])
    useEffect(()=>{
        //Initial Thread Call
        if (users?.length > 0 && socket.connected && (pagination <= 1 || !pagination)){
            setConversation(null)
            fetchMessageThread(messengerId,1,totalMessages,unitId)
        }else if (conversation?.page < conversation?.lastPage && pagination <= conversation?.lastPage){
            fetchMessageThread(messengerId,pagination,totalMessages,unitId)
        }

    },[location.pathname,messengerId,socket.connected,users?.length,pagination])

    useEffect(()=>{
        let currentPathName = ""
            //If the pathname has changed recall the api
        if (!!conversation && currentPathName !== location.pathname){
            // currentPathName = location.pathname
            setConversation(null)
            fetchMessageThread(messengerId,1,totalMessages,unitId)
        }
    },[location.pathname])

    useEffect(()=>{
        //Receive a thread, map the thread with the users of the above event to find a specific profile picture, name etc.
        socket.on("connect", _ => {
            setConversation(null)
        });


        if (users?.length > 0){
            socket.on("receiveConversation", (response) => {
                console.log(response,"response")
                if (response?.isConversationEmpty){
                    setConversation({
                        data:{
                            senderData:users?.find(el => el?._id === unitId)?.users?.find(innerEl => innerEl?._id === messengerId)
                        }
                    })
                }else{
                    let responseConversation = {
                        ...response,
                        data:{
                            ...response.data,
                            senderData:users?.find(el => el?._id === unitId)?.users?.find(innerEl => innerEl?._id === messengerId)
                        }
                    }
                    let computedConversation = handleComputeConversation(responseConversation)

                    // computedConversation = {
                    //     ...computedConversation,
                    //     data:{
                    //         ...computedConversation?.data,
                    //         messageThread:computedConversation?.data?.messageThread?
                    //     }
                    // }

                    setConversation(prev =>{
                        if (prev?.data?.messageThread?.length > 0){
                            return {
                                ...computedConversation,
                                data:{
                                    ...computedConversation?.data,
                                    messageThread:[
                                        ...computedConversation?.data?.messageThread,
                                        ...prev?.data?.messageThread
                                    ]
                                }
                            }
                        }else{
                            return computedConversation
                        }
                    })
                }
            });
        }

    },[users?.length])
    useEffect(()=>{
        //Receive a message after sending one (show notification on messaging sidebar when a new message is received)
        socket.on("receiveMessage", (message) => {
            console.log("here")
            setNewMessage(message)
        });
    },[])
    useEffect(()=>{
        if (!!newMessage && !!conversation){
            handleAddMessageToCurrentThread(newMessage.content,messengerId,newMessage.isMedia)
        }
    },[conversation,newMessage])

    function handleComputeConversation(responseConversation) {
        console.log("handleComputeConversation")
        let lastDate = null
        // let lastFilterDate = null
        let messageThread = []
        let resultConversation = {
            data:{
                messageThread:[]
            }
        }

        responseConversation.data?.messageThread.forEach(thread => {
            let currentDate = new Date(thread?.createdAt).getDate()
            // console.log(currentDate,"currentDate")
            // console.log(lastDate,"lastDate")
            if (lastDate === currentDate){
                messageThread.push(thread)

                resultConversation = {
                    ...responseConversation,
                    data:{
                        ...responseConversation?.data,
                        messageThread:[
                            ...resultConversation?.data?.messageThread,
                            {
                                date:new Date(thread?.createdAt),
                                messages:messageThread
                            }
                        ]
                    }
                }
            }else{
                messageThread = [thread]

                resultConversation = {
                    ...responseConversation,
                    data:{
                        ...responseConversation?.data,
                        messageThread:[
                            ...resultConversation?.data?.messageThread,
                            {
                                date:new Date(thread?.createdAt),
                                messages:messageThread
                            }
                        ]
                    }
                }

            }
            lastDate = new Date(thread?.createdAt).getDate()
        })

        resultConversation = {
            ...responseConversation,
            data:{
                ...responseConversation?.data,
                messageThread: resultConversation.data?.messageThread?.filter((value, index, self) =>
                    index === self.findIndex(el => new Date(el.date).getDate() ===  new Date(value.date).getDate())
                )
                // .filter(el => {
                //     let date = lastFilterDate
                //     lastFilterDate = el?.date
                //     return new Date(date).getDate() !== new Date(el?.date).getDate()
                // })
            }
        }
        return resultConversation
    }

    function handleSendMessage(message) {
        onSendMessage(message,messengerId,unitId)
        if (conversation?.data?.messageThread?.length > 0){
            handleAddMessageToCurrentThread(message,userId,false)
        }else{
            setTimeout(()=>{
                fetchMessageThread(messengerId,1,totalMessages,unitId)
            },1000)

        }

    }

    function handleSendMedia(file) {
        onSendMedia([{ name: file.name, type: file.type, size: file.size, file }],messengerId,unitId)
        setTimeout(()=>{
            setConversation(null)
            fetchMessageThread(messengerId,conversation?.page,totalMessages,unitId)
        },100)
    }

    function handleAddMessageToCurrentThread (message,senderId,isMedia){
        if(conversation?.data?.messageThread?.length > 0){
            //Message Thread Last Date
            if (new Date(conversation?.data?.messageThread[conversation?.data?.messageThread?.length-1]?.date).getDate() === new Date().getDate()){
                setConversation(prev => ({
                    ...prev,
                    data:{
                        ...prev?.data,
                        messageThread:prev?.data?.messageThread?.map((el,index,arr) => {
                            if (index === conversation?.data?.messageThread?.length-1){
                                return {
                                    date:el?.date,
                                    messages:[...el?.messages,{
                                        content:message,
                                        isMedia,
                                        senderId,
                                        createdAt: new Date()
                                    }]
                                }
                            }else return el
                        })
                    }
                }))
            }else{
                setConversation(prev => ({
                        ...prev,
                        data:{
                            ...prev?.data,
                            messageThread:[
                                ...prev?.data?.messageThread,
                                {
                                    date:new Date(),
                                    messages:[
                                        {
                                            content:message,
                                            senderId,
                                            isMedia,
                                            createdAt: new Date()
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ))
            }
            setNewMessage(null)
        }
    }

    return(
      <>
          <section className={styles.messages__content__wrapper}>
              {!!conversation && <MessageTop messageThread={conversation}/>}
              <MessagesList isLoading={!conversation} messageThread={conversation} onApiPagination={onApiPagination}/>
              {!!conversation && <SendMessage onSendMessage={handleSendMessage} onSendMedia={handleSendMedia}/>}

          </section>

      </>
  )
}
export default SingleMessage
