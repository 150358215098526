import React from "react"
import PrimaryModal from "../../../../common/modals/primary-modal";
import InfoModal from "../../../../common/modals/info-modal";
import {useNavigate, useParams} from "react-router";
import UnitListingService from "../../../../../services/unit-listing.service";

const UnitExit = ({completedStepperInThisSession,openModal,setOpenModal}) => {
    const {unitId} = useParams()
    const navigate = useNavigate()

    const {useHandleExitListing} = UnitListingService()

    const {isLoading,mutate,isSuccess} = useHandleExitListing(unitId,completedStepperInThisSession)

    const handleExitConfirmationModal = (isModalOpen) => {
        setOpenModal(isModalOpen)
    }

    const onExitSuccess = () => {
        navigate("/home")
    }
    return(
        <>
            {openModal &&
                <PrimaryModal
                    size={"lg"}
                    customClass={"half__modal"}
                    isOpen={openModal}
                    modalContent={
                        <InfoModal
                            handleModalClose={handleExitConfirmationModal}
                            onSuccess={onExitSuccess}
                            isLoading={isLoading}
                            mutate={mutate}
                            isSuccess={isSuccess}
                            title={"Unsaved Changes"}
                            description={"Are you sure you want to exit without saving? This action cannot be undone."}
                            confirmButtonText={"Discard"}
                        />}
                />
            }
        </>
    )
}
export default UnitExit
