export function ggID() {
    let id = 0;
    return function genId() {
        return id++;
    };
}

export const getMovePosition = (
    x,
    y,
    dragX,
    dragY,
    width,
    height,
    pageWidth,
    pageHeight,
    allPageAttachments,
    pageIndex,
    id
) => {


    let otherAttachments = allPageAttachments[pageIndex].filter(el => el.id !== id)
    const newPositionTop = y + dragY;
    const newPositionLeft = x + dragX;
    const newPositionRight = newPositionLeft + width;
    const newPositionBottom = newPositionTop + height;

    let top =
        newPositionTop < 0
            ? 0
            : newPositionBottom > pageHeight
                ? pageHeight - height
                : newPositionTop;
    let left =
        newPositionLeft < 0
            ? 0
            : newPositionRight > pageWidth
                ? pageWidth - width
                : newPositionLeft;



    if (otherAttachments.length > 0) {
        for (let i = 0; i < otherAttachments.length; i++) {
            if (((newPositionRight >= otherAttachments[i].x && newPositionRight <= otherAttachments[i].x+otherAttachments[i].width)
                    || (newPositionLeft >= otherAttachments[i].x && newPositionLeft <= otherAttachments[i].x+otherAttachments[i].width))
                && ((newPositionBottom <= (otherAttachments[i].y +  otherAttachments[i].height) &&  newPositionBottom >= otherAttachments[i].y)
                || (newPositionTop <= (otherAttachments[i].y +  otherAttachments[i].height) &&  newPositionTop >= otherAttachments[i].y)
            )){
                return {
                    top:y,
                    left:x,
                };
            }
        }
    }
    return {
        top,
        left,
    };
};

export const normalize = (value) =>
    parseFloat((value / 255).toFixed(1));
