import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router";
import styles from "../../../styles/contract.module.css"
import DashboardNavbar from "../../../components/widgets/dashboard-navbar";
import {toast} from "react-toastify";
import EditContractSidebar from "../../../components/widgets/edit-contract-sidebar";
import UnitContractService from "../../../services/unit-contract.service";
import PageLoader from "../../../components/common/loaders/page-loader";
import {mergeDuplicateLabels} from "../../../utils/helpers";
import {usePdf} from "../../../hooks/use-pdf";
import {useAttachments} from "../../../hooks/use-attachments";
import {AttachmentTypes} from "../../../utils/constants/contract";
import {ggID} from "../../../utils/contract-signing/helpers";
import useUrlQuery from "../../../hooks/use-url-query";
import TokenService from "../../../services/token.service";
import {ROLES} from "../../../utils/constants";

/***
 * Edit Contract Route --> Protected, Covers the basic widgets and auth
 * @returns {JSX.Element}
 * @constructor
 */

const SignContractLayout = () => {
    const navigate = useNavigate()
    const loggedInUserRole = TokenService.getUser()?.role

    let query = useUrlQuery()
    const contractId = query.get("contractId")
    const signerId = query.get("signerId")

    const {useFetchContractById,useFetchContractDocumentByName,useVerifySignerId} = UnitContractService()
    const handleUsePdf = usePdf();
    const handleUseAttachments = useAttachments();

    //Fetch Document By ID
    const {data: contractData, isFetching: isContractDataFetching} = useFetchContractById(contractId)
    //Fetch Contract Document By Name
    const {data: file, isLoading: isFileLoading} = useFetchContractDocumentByName(contractData?.media)
    //Verify Signer ID
    const {isLoading: isVerifySignerIdLoading} = useVerifySignerId(contractId,signerId)

    useEffect(()=>{
        if(!signerId || !contractId){
            toast.error("Access Denied")
            navigate(loggedInUserRole === ROLES.RENTER ? "/application" : "/home",{replace:true})
        }
    },[])


    return (
        <>
            <section className={styles.sign__contract__router__wrapper}>
                <DashboardNavbar/>
                {(isContractDataFetching || isFileLoading || isVerifySignerIdLoading) && <PageLoader/>}
                {!(isContractDataFetching || isFileLoading || isVerifySignerIdLoading) && <main className={styles.router__content}>
                    <div className={styles.router__inner__content}>
                        <Outlet context={{
                            documentFile:file,
                            usePdfFromParent:handleUsePdf,
                            useAttachmentsFromParent:handleUseAttachments,
                            contractData,
                            signerId,
                            contractId
                        }}/>
                    </div>
                </main>}
            </section>
        </>
    );
};

export default SignContractLayout;
