import React, {useEffect, useContext, useState} from "react"
import {useWizard} from "react-use-wizard";
import styles from "../../../../styles/listing.module.css";
import propTypes from "prop-types";
import {ActiveStepContext} from "../../../../routes/private/create-listing/create-listing-layout";
import CustomInfo from "../../../common/icons/custom-info";
import CustomTooltip from "../../../common/tooltips/custom-tooltip";

const CreateListingFormWrapper = ({title,form,TooltipDescription}) => {

    const {activeStep,goToStep} = useWizard();
    const {setActiveStep,goTo,setGoTo} = useContext(ActiveStepContext)

    useEffect(()=>{
        setActiveStep(activeStep)
        window.scrollTo(0,0)
    },[])

    useEffect(()=>{
        if(goTo >= 0 && goTo !== null){
            goToStep(goTo)
            setGoTo(null)
        }
    },[goTo])

    const [isShowToolTip,setIsShowToolTip] = useState(false)
    const mouseEnter = () => {
        setIsShowToolTip(true)
    }
    const mouseLeave = () => {
        setIsShowToolTip(false)
    }
    return(
        <>
            <section className={styles.create__listing__form__wrapper}>
                <h2>{title}
                    {TooltipDescription && <span>
                        <CustomInfo onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}/>
                    </span>}
                    {isShowToolTip && <CustomTooltip>
                        {TooltipDescription}
                    </CustomTooltip>}
                </h2>
                <div className={styles.form__main__wrapper}>{form}</div>
            </section>
        </>
    )
}
export default CreateListingFormWrapper

CreateListingFormWrapper.propType = {
    title : propTypes.string.isRequired,
}
