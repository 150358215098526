import {CREDIT_SCORE} from "../constants/application-listing";
import {MEDIA_BASEURL} from "../constants";

export const viewError = (err) => {
    if (Array.isArray(err)){
        return err[0]
    }else{
        return err
    }
}

export async function createFile(fileUrl,fileName){
    let response = await fetch(`${MEDIA_BASEURL}${fileUrl}`);
    let data = await response.blob();
    let metadata = {
        type: `image/${fileUrl.split(".")[1]}`
    };
    return new File([data], !!fileName ? fileName : `file.${fileUrl.split(".")[1]}`, metadata);
}

export const stringTruncate = (string, n) => {
    return (string.length > n) ? string.substr(0, n-1) + '...' : string;
};

export const debounce = (func, ms) => {

    let timer = null;

    return function (...args) {
        const onComplete = () => {
            func.apply(this, args);
            timer = null;
        }

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };
}

export const replaceLineBreaksWithSeeMore = (string ) => {
    return string.split("\r")[0]
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

//
export const receiversRole = (isApplicant,isOwner) => {
    if(isOwner){
        return "Owner"
    }else if(isApplicant){
        return "Applicant"
    }else{
        return "Co-Applicant"
    }
}

//Highlighting Current Credit Score For User
export const creditScoreCriteria = (score,scoreType) => {
    return scoreType === CREDIT_SCORE.TRANSUNION ?
        (score >= 300 && score <= 599) ? 'red' :
            (score >= 600 && score <= 649) ? 'orange' :
                (score >= 650 && score <= 719) ? 'yellow' :
                    (score >= 720 && score <= 799) ? 'lightGreen' :
                        (score >= 800 && score <= 900) ? 'darkGreen' : '' :
        scoreType === CREDIT_SCORE.EQUIFAX ?
            (score >= 300 && score <= 574) ? 'red' :
                (score >= 575 && score <= 659) ? 'orange' :
                    (score >= 660 && score <= 712) ? 'yellow' :
                        (score >= 713 && score <= 740) ? 'lightGreen' :
                            (score >= 741 && score <= 900) ? 'darkGreen' : '' : null
}

//Applying comma to price
export const commaToPrice = (price = 0) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

//Merging Duplicate Labels
export const mergeDuplicateLabels = (data) => {
    return data.reduce((acc, current) => {
        const x = acc.find(item => item.label === current.label);
        if (!x) {
            const newCurr = {
                label: current.label,
                data: [current.data]
            }
            return acc.concat([newCurr]);
        } else {
            const currData = x.data.filter(d => d === current.data);
            if (!currData.length) {
                x.data.push(current.data);
                // const newCurr = {
                //     label: current.label,
                //     data: newData
                // }
                return acc;
            } else {
                return acc;
            }

        }
    }, [])
}

//
export const appendingZeroToTime = (time) => {
    if(time < 10){
        return `0${time}`
    }else if(time >= 10){
        return `${time}`
    }
}

const toMinutes = str => str.split(":").reduce((h, m) => h * 60 + +m);

const dateToString = min => (Math.floor(min / 60) + ":" + (min % 60))
    .replace(/\b\d\b/, "0$&");

const timeToDateObject = time => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), time.split(":")[0], time.split(":")[1], 0, 0);

export const viewingSlots = (startStr, endStr,duration,endDate) => {
    // console.log(startStr, endStr,"1")
    let start = toMinutes(startStr);
    let end = toMinutes(endStr);

    return Array.from({length: Math.floor((end - start) / duration)}, (_, i) =>
        dateToString(start + i * duration)).map(el => {
            let endTime = `${appendingZeroToTime(new Date(timeToDateObject(el).getTime() + duration*60000).getHours())}:${appendingZeroToTime(new Date(timeToDateObject(el).getTime() + duration*60000).getMinutes())}`
            // console.log(endDate,"endDate")
            //     let date = new Date(endDate)
            // console.log(date,"date")
            return {
                    startTime:el.split(":")[1].length > 1 ? el : `${el}0`,
                    endTime,
                    //if endTime is less than startTime that means date has changed
                        //(for eg: if ST is 23:45 and ET is 00:00 it means date has changed, but if ST is 23:00 and ET is 23:15 it means date hasn't changed)
                    // endDate:endTime?.split(":")[0] < el?.split(":")[0] ? date.setDate(date.getDate() + 1) : endDate
                }
        })
}

export const trimUserObject = (userData) => {
    return {
        dob:userData.dob,
        domain:userData.domain._id,
        university:userData.university._id,
        gender:+userData.gender,
        firstName:userData.firstName,
        lastName:userData.lastName,
        bio:userData?.bio,
        _id:userData._id
    }
}

export const millisecondsToTimeConversion = (timeOfCreation) => {
    const publishDate = new Date(timeOfCreation)
    const differenceTime = Date.now() - publishDate

    const minutes = Math.floor(differenceTime / 60000);
    const seconds = ((differenceTime % 60000) / 1000).toFixed(0);
    const hours = Math.floor(minutes/60);
    const days = Math.floor(minutes/1440)
    if(seconds < 60 && minutes <= 0){
        return `${seconds < 10 ? `0${seconds} seconds` : `${seconds} seconds`}`
    }else if (minutes > 0 && minutes <= 60){
        return `${minutes < 10 ? `0${minutes} minutes` : `${minutes} minutes`}`
    }else if(minutes > 60 && minutes <= 1440){
        return `${hours > 1 ? `${hours} hours` : `${hours} hour`}`
    }else if(minutes > 1440){
        return `${days > 1 ? `${days} days` : `${days} day`}`
    }
}

export const dateFromISOString = (isoString) => {
    let date = new Date(isoString);
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return(year+'/' + month + '/'+dt);
}

export const formatToISOTimeData = (actualDate) => {
    let date = new Date(actualDate.toString())

    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();
    let hours = date.getHours() == "0" ? "00" : date.getHours()
    let minutes = date.getMinutes() == "0" ? "00" : date.getMinutes()

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (hours < 10 && hours > 0) {
        hours = '0' + hours;
    }
    if (month < 10) {
        month = '0' + month;
    }
    if (minutes < 10 && minutes > 0) {
        minutes = '0' + minutes;
    }

    return (`${year}-${month}-${dt}T${hours}:${minutes}:00.000Z`)
}

export function timeConverter (time) {
    // Check correct time format and split into components
    time = time.match(/\d\d:\d\d/).toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
}

export function userCurrentTimezone ()  {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
export function handleChangeDateTimezone (date,timezone = userCurrentTimezone()) {
    return new Date(date.toLocaleString('en-US', { timeZone: timezone }))
}

export const formatDateToISO = (actualDate) => {
    let date = new Date(actualDate.toString())

    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return (`${year}-${month}-${dt}`)
}

export function deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(deepClone(key, hash),
            deepClone(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(deepClone(key, hash)) );
    // Register in hash
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: deepClone(obj[key], hash) }) ));
}
