import React from "react";
import styles from "../../../../../styles/rent-collection.module.css";
import Title from "../../../../common/text/title";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";
import ButtonLoader from "../../../../common/loaders/button-loader";


const RentUserInformation = ({title,subtitle,fields,handleConfirmRenterCallback,isHalf,isNotEditable,isLoading}) => {
    const {inputEvent,details} = useForm(fields)
  return(
      <>
          <div className={`${isHalf ? styles.half__width : ""} ${styles.participant__data__wrapper}`}>
              {title && <Title title={title}/>}
              <Form className={styles.participant__data__form} onSubmit={handleConfirmRenterCallback}>
                  {subtitle && <h4>{subtitle}</h4>}
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                          type={"text"}
                          value={details.firstName}
                          name={"firstName"}
                          onChange={inputEvent}
                          placeholder={"First Name"}
                          disabled={isNotEditable}
                          required
                      />
                  </Form.Group>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                          type={"text"}
                          value={details.lastName}
                          name={"lastName"}
                          onChange={inputEvent}
                          placeholder={"Last Name"}
                          disabled={isNotEditable}
                          required
                      />
                  </Form.Group>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                          type={"email"}
                          value={details.email}
                          name={"email"}
                          onChange={inputEvent}
                          disabled={isNotEditable}
                          placeholder={"Email Address"}
                          required
                      />
                  </Form.Group>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                          type={"number"}
                          value={details.phone}
                          name={"phone"}
                          disabled={isNotEditable}
                          onChange={inputEvent}
                          placeholder={"Contact Number"}
                          required
                      />
                  </Form.Group>
                  {handleConfirmRenterCallback && <div className={styles.rent__button__wrapper}>
                      {!isLoading && <button type={"submit"} className={styles.next__btn}>
                          Next
                      </button>}
                      {isLoading && <ButtonLoader/>}
                  </div>}

              </Form>
          </div>
      </>
  )
}
export default RentUserInformation
