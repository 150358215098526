import React from "react"
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
const useReverseGeocode = () => {
    return (lat,lng)=>{
        return Geocode.fromLatLng(lat, lng).then(
            (response) => {
                return response.results[0].formatted_address;
            },
            () => {
                return null
            }
        );
    }
}
export default useReverseGeocode