import React from "react"
import styles from "../../../styles/contract.module.css"

const HighlightingLabel = ({label,positionTop,positionLeft}) => {
    return(
        <div className={styles.highlighting__label__wrapper}>
            <p
                className={styles.highlighting__label}
                style={{
                    top: positionTop,
                    left: positionLeft
                }}
            >
                <span>{label}</span>
                <div className={styles.arrow__right}/>
            </p>
        </div>
    )
}
export default HighlightingLabel
