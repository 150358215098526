import React, {useState, useCallback, useEffect} from "react"
import {FORM_TYPE} from "../utils/constants";

const FormData = (fields,incrementerFields) => {
    const [details,setDetails] = useState(fields)
    const [incrementer,setIncrementer] = useState(incrementerFields)
    const [error,setError] = useState(fields)

    //To Update the states in-case initial fields change!!
    useEffect(()=>{
        setDetails(fields)
    },[fields])
    useEffect(()=>{
        setIncrementer(incrementerFields)
    },[incrementerFields])


    //Validation For Input Field
    const validateForms = (fields,notRequiredFields) => {
        const errors = {};
        for (const value in fields) {
            for(let notRequired of notRequiredFields){
                if(value === notRequired){
                    fields[value] = "dummy"
                }
            }
            errors[value] = fields[value] === "" ? `${value} is a required field` : "";
        }
        setError(errors)
        for (const value in errors){
            if(errors[value]){
                return true
            }
        }
        return false
    };
    //Change event for text-box, text-area, select, check-box and radio-buttons
    const inputEvent = useCallback((e,type) => {
            const {name, value,checked} = e.target;
            setDetails((preValue) => {
                return {
                    ...preValue,
                    [name]: (type === FORM_TYPE.CHECKBOX) ? checked : value,
                };
            });
        },[details])

    //Change event for increment and decrement form fields
    const incrementerEvent = (type,key) => {
        if(type === "increment"){
            setIncrementer((prevValue)=>{
                return{
                    ...prevValue,
                    [key]:prevValue[key] + 1
                }
            })
        }else if(type === "decrement"){
            setIncrementer((prevValue)=>{
                return{
                    ...prevValue,
                    [key]:prevValue[key] - 1
                }
            })
        }
    }

    const resetFields = (formFields) => {
        setDetails(formFields);
    }

    return {details,inputEvent,resetFields,incrementer,incrementerEvent,validateForms,error}
}
export default FormData;
