import React from "react"
import {Spinner} from "react-bootstrap";
import propTypes from "prop-types";

const ButtonLoader = (props) => {
    return(
        <>
            <button disabled className={props.class}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
            </button>
        </>
    )
}
export default ButtonLoader

ButtonLoader.propType=  {
    class : propTypes.string
}