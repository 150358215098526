import React from 'react'
import styles from "../../../styles/account-settings.module.css"
const PaypalBox = () => {
    return(
        <>
            <section className={styles.payPal__box__wrapper}>
                <p>Click the button below to log into your PayPal account and authorize your payments.</p>
                <button>Pay With PayPal</button>
            </section>
        </>
    )
}
export default PaypalBox;