import React, {useEffect} from "react";
import {Outlet, useNavigate} from "react-router";
import styles from "../../styles/dashboard.module.css"
import {NavLink, useLocation} from "react-router-dom";
import {ROLES} from "../../utils/constants";
import WebsiteLogo from "../../components/widgets/website-logo";
import TokenService from "../../services/token.service";
import {Container} from "react-bootstrap";

/***
 * Dashboard Route --> Protected, Covers the basic widgets and auth
 * @returns {JSX.Element}
 * @constructor
 */

const ResponseLayout = () => {
    const userData = TokenService?.getUser()
    // const {state} = useLocation()
    // const navigate = useNavigate()

    useEffect(()=>{
        // if (!state){
        //     navigate(userData?.role === ROLES.OWNER ? "/home" : "/application")
        // }
    },[])
    return (
        <>
            <section className={styles.router__wrapper}>
                <Container style={{paddingTop:"40px"}}>
                    <NavLink to={`${userData?.role === ROLES.OWNER ? "/home" : "/application"}`} className={styles.nav__logo}><WebsiteLogo/></NavLink>
                </Container>
                <main>
                    <Outlet/>
                </main>
            </section>
        </>
    );
};

export default ResponseLayout;
