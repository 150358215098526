import useUrlQuery from "./use-url-query";
import {EXTERNAL_RESPONSE, EXTERNAL_RESPONSE_TYPE} from "../utils/constants";
import {toast} from "react-toastify";


const useExternalResponse = (successMessage, errorMessage, externalResponse = EXTERNAL_RESPONSE_TYPE.STRIPE) => {
    let query = useUrlQuery()
    const stripeResponse = query.get(externalResponse)

    const responseCallback = () => {
        if(stripeResponse === EXTERNAL_RESPONSE.SUCCESS){
            toast.success(successMessage)

        }else if(stripeResponse === EXTERNAL_RESPONSE.ERROR){
            toast.error(errorMessage)
        }
        return null;
    }

    return responseCallback
}
export default useExternalResponse
