import React, {useState} from "react";
import {AiOutlinePaperClip} from 'react-icons/ai'
import styles from "../../../styles/messages.module.css"
import {IoSendSharp} from "react-icons/io5"
import useWindowSize from "../../../hooks/use-window-size";

const SendMessage = ({onSendMessage,onSendMedia}) => {
    const [message,setMessage] = useState("")
    const {width} = useWindowSize()
    const handleSendMessage = (e) => {
      e.preventDefault()
        onSendMessage(message)
        setMessage("")
    }
    const fileHandler = (e) => {
        const file = e.target.files[0]
        console.log("Only file", file)
        onSendMedia(file)
        // socket.emit("sendMedia", { receiverId: "63371e55d4116247a1753e6e", media: [{ name: file.name, type: file.type, size: file.size, file }] }, (status) => {
        //     console.log(status);
        // });
    }
    const onUploadMedia = () => {
        document.getElementById("fileUpload").click()
    }
    return(
        <>
            <form className={styles.send__message__wrapper} onSubmit={handleSendMessage}>
                <div className={styles.send__message}>
                    <input type={"text"} onChange={(e) =>  setMessage(e.target.value)} value={message} placeholder={"Your Message"} required/>
                    <button type={"button"} onClick={onUploadMedia}>
                        <AiOutlinePaperClip/>
                    </button>
                </div>
                <input onChange={fileHandler} id="fileUpload" type="file" hidden/>
                <button type={"submit"} className={styles.submit__button}>{width > 991 ? "Send"  : <IoSendSharp/>}</button>
            </form>
        </>
    )
}
export default SendMessage
