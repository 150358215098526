import React, {useEffect, useState} from "react"
import styles from "../../../styles/account-settings.module.css"
import {Form} from "react-bootstrap";
import useForm from "../../../hooks/use-form";
import TokenService from "../../../services/token.service";
import PersonalDetailsForm from "../../presentational/account-settings/personal-details-form";
import LoginDetailsForm from "../../presentational/account-settings/login-details-form";
import NotificationsSettings from "../../presentational/account-settings/notifications-settings";
import ButtonLoader from "../../common/loaders/button-loader";
import AccountSettingsService from "../../../services/account-settings.service";
import VerificationWarning from "../../common/container/verification-warning";
import { EXTERNAL_RESPONSE_TYPE, ROLES} from "../../../utils/constants";
import PrimaryModal from "../../common/modals/primary-modal";
import InfoModal from "../../common/modals/info-modal";
import useExternalResponse from "../../../hooks/use-external-response";
import UserService from "../../../services/user.service";
import PageLoader from "../../common/loaders/page-loader";


const AccountDetailsContainer = () => {
    const userData = TokenService.getUser()
    const [fields] = useState({
        firstName: userData?.firstName,
        lastName:userData?.lastName,
        email: userData?.email,
        phone: userData?.phone,
        isSubscribedForMarketing : userData?.isSubscribedForMarketing || false,
    })

    const {useHandleUpdatePersonalInformationService,useHandleVerifyOwnerService} = AccountSettingsService()
    const {useFetchUserByIdService} = UserService()
    const personaResponseCallback = useExternalResponse('User Verified','Something Went Wrong',EXTERNAL_RESPONSE_TYPE.PERSONA)
    const {details,inputEvent} = useForm(fields)

    const {isLoading,mutate} = useHandleUpdatePersonalInformationService(details)
    const {isLoading : isVerifyUserLoading, mutate : verifyUser,isSuccess} = useHandleVerifyOwnerService()
    const {isLoading : isUserByIdLoading} = useFetchUserByIdService(!userData?.isVerified && userData?.role === ROLES.OWNER)

    const updateChanges = async (e) => {
        e.preventDefault()
        mutate()
    }

    const [isVerifyUserModal,setIsVerifyUserModal] = useState(false)
    const handleVerifyUser = (isModal) => {
        setIsVerifyUserModal(isModal)
    }

    useEffect(()=>{
        personaResponseCallback()
    },[])

    if(isUserByIdLoading) return <PageLoader/>
    return(
        <>
            <section className={styles.account__details__wrapper}>
                <Form onSubmit={updateChanges}>
                    <div className={styles.form__group__main__wrapper}>
                        <h2>Personal Details</h2>
                        <PersonalDetailsForm inputEvent={inputEvent} details={details}/>
                    </div>
                    <div className={styles.form__group__main__wrapper}>
                        <h2>Login Details</h2>
                        <LoginDetailsForm inputEvent={inputEvent} details={details}/>
                    </div>
                    <div className={styles.form__group__main__wrapper}>
                        <h2>Notifications</h2>
                        <NotificationsSettings inputEvent={inputEvent} details={details}/>
                    </div>
                    {(!userData?.isVerified && userData?.role === ROLES.OWNER) && <VerificationWarning
                        tooltipDescription='To abide by credit compliance and government regulations,
                            we use Persona Identities Inc. to verify your identity.
                            We do this for security and anti-fraud purposes. '
                        text='To gain access to tenants’ reports, we need to verify your identity, please click here.'
                        clickHandlerCallback={() => handleVerifyUser(true)}
                    />}
                    <div className={styles.button__wrapper}>
                        {isLoading ? <ButtonLoader/> : <button type={"submit"}>Save Changes</button>}
                    </div>
                </Form>
            </section>
            {isVerifyUserModal &&
                <PrimaryModal
                    size={"lg"}
                    customClass={"half__modal"}
                    isOpen={isVerifyUserModal}
                    modalContent={
                        <InfoModal
                            handleModalClose={()=>handleVerifyUser(false)}
                            isLoading={isVerifyUserLoading}
                            mutate={verifyUser}
                            isSuccess={isSuccess}
                            title={"Important"}
                            description={`Is your name on the profile exactly as it appears on your identification?`}
                            cancelButtonText={'No'}
                            confirmButtonText={"Yes"}
                            isConfirmationModal
                        />}
                />
            }
        </>
    )
}
export default AccountDetailsContainer
