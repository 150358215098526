import React from "react"
import RootFallback from "../components/fallback/root-fallback";

class RootErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Post state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <RootFallback/>
        }

        return this.props.children;
    }
}
export default RootErrorBoundary;