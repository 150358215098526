import React, {Fragment, useState} from "react"
import styles from "../../../../../styles/listing.module.css";
import {CloseButton, Col, Modal, Row} from "react-bootstrap";
import {MEDIA_BASEURL} from "../../../../../utils/constants";
import GoogleMapReact from "google-map-react";
import propTypes from "prop-types"
import {FaMapMarkerAlt} from "react-icons/fa";
import Carousel from 'react-multi-carousel';
import SingleUnitPreviewInteractions from "../../../../stateful/listing/single-unit/preview/single-unit-preview-interactions";


const mimeTypes ={
    image:["jpeg","jpg","png",'webp'],
    video:["mp4","mkv","webm"]
}

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const Marker = () => {
    return(
        <>
            <div className={styles.marker}>
                <FaMapMarkerAlt/>
            </div>
        </>
    )
}

const SingleUnitMedia = ({unitData,addressGeo,filteredImages}) => {
    const [isOpenImages,setIsOpenImages] = useState(false)
    const [mutatedMergedArray,setMutatedMergedArray] = useState([])
    const openMoreImages = (index) => {
        // setStartingIndex(index)
        setMutatedMergedArray([...filteredImages?.mergedArray.slice(index),...filteredImages?.mergedArray.slice(0, index)])
        setIsOpenImages(true)
    }
    const handleModalClose = () => {
        // setStartingIndex(0)
        setIsOpenImages(false)
    }

    return(
        <>
            <div className={styles.preview__images__wrapper}>
                <Row>
                    <Col md={12} lg={9}>
                        {(!filteredImages?.array?.length > 0 && !filteredImages?.thumbnail) && <div className={styles.no__image}>
                            <p>No Image Added</p>
                        </div> }
                        {(filteredImages?.array?.length > 0 || filteredImages?.thumbnail) && (
                            <>
                                <div className={styles.preview__images}>
                                    <div className={styles.featured__image}  onClick={()=>openMoreImages(0)}>
                                        <img src={`${MEDIA_BASEURL}${filteredImages.thumbnail}`} alt={"Featured image"}/>
                                    </div>
                                    {filteredImages?.array?.length > 0 && <div className={styles.normal__images}>
                                        {filteredImages.array
                                            .filter((el) => el !== filteredImages.thumbnail)
                                            .map((image, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        {/*{console.log(filteredImages.thumbnail,"image")}*/}
                                                        <div
                                                            className={`${((i === filteredImages.array.length - 1 && filteredImages.remainingArrayLength) ? styles.last__image : "")} ${styles.clickable__media}`}
                                                            // onClick={(i === filteredImages.array.length-1 && filteredImages.remainingArrayLength) ? () => openMoreImages() : () => {}}
                                                            onClick={()=>openMoreImages(i+1)}
                                                        >
                                                            {mimeTypes.image.includes(image.split(".")[1].toLowerCase()) &&
                                                                <img src={`${MEDIA_BASEURL}${image}`} alt={`Units`}
                                                                     loading={"lazy"}/>}
                                                            {mimeTypes.video.includes(image.split(".")[1].toLowerCase()) &&
                                                                <video controls>
                                                                    <source src={`${MEDIA_BASEURL}${image}`}
                                                                            type="video/mp4"/>
                                                                </video>
                                                            }
                                                            {(i === filteredImages.array.length - 1 && filteredImages.remainingArrayLength)
                                                                && <h6>+{filteredImages.remainingArrayLength}</h6>}
                                                        </div>

                                                    </Fragment>
                                                )
                                            })}
                                    </div>}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col md={12} lg={3}>
                        {unitData?.location?.coordinates?.length > 0 && <div className={styles.unit__location}>
                            <div className={styles.unit__map}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAP_KEY}}
                                    defaultCenter={addressGeo}
                                    defaultZoom={5}
                                >
                                    <Marker lat={addressGeo.lat} lng={addressGeo.lng}/>
                                </GoogleMapReact>
                            </div>
                            <SingleUnitPreviewInteractions ownerId={unitData?.ownerId}/>
                        </div>}
                        {!unitData?.location?.coordinates?.length > 0 &&  <div className={styles.no__image}>
                            <p>No Location Added</p>
                        </div>}
                    </Col>
                </Row>
            </div>
            {isOpenImages &&
                <Modal
                    size={"lg"}
                    dialogClassName="modal-120w"
                    className={`modal__wrapper unit__preview__modal`}
                    fullscreen={true}
                    show={isOpenImages}
                    backdrop="static"
                    scrollable={true}
                    keyboard={false}
                    centered
                >
                    <Modal.Header className="modal__header">
                        <CloseButton aria-label="Hide" onClick={handleModalClose}/>
                    </Modal.Header>

                    <Modal.Body className="modal__body">
                        <Carousel responsive={responsive}>
                            {/*<div className={"unit__listing__image"}>*/}
                            {/*    <img src={`${MEDIA_BASEURL}${filteredImages.thumbnail}`} alt={`Units`} loading={"lazy"}/>*/}
                            {/*</div>*/}
                            {mutatedMergedArray
                                .map((asset,i)=>{
                              return(
                                  <Fragment key={i}>
                                      <div className={"unit__listing__image"}>
                                          {mimeTypes.image.includes(asset.split(".")[1].toLowerCase()) && <img src={`${MEDIA_BASEURL}${asset}`} alt={`Units`} loading={"lazy"}/>}
                                          {mimeTypes.video.includes(asset.split(".")[1].toLowerCase()) &&
                                              <video controls>
                                                  <source src={`${MEDIA_BASEURL}${asset}`} type="video/mp4"/>
                                              </video>
                                          }
                                      </div>
                                  </Fragment>
                              )
                            })}
                        </Carousel>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}
export default SingleUnitMedia

SingleUnitMedia.propType = {
    unitData: propTypes.object.isRequired
}
