import React from "react"
import axios from "../config/axios-instance";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import { viewError} from "../utils/helpers";
import {useParams} from "react-router";
import TokenService from "./token.service";


const UnitRentCollectionService = () => {
    const queryClient = useQueryClient()

    //Create Listing Payment
    const useHandleCreateListingPayment = () => {
        const {unitId} = useParams()
        const userId = TokenService.getUser()?._id
        const handleCreateListingPaymentRequest = () => {
            return axios.post(`/payment?listingId=${unitId}&userId=${userId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        }

        return useMutation(
            handleCreateListingPaymentRequest,
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Fetch Listing Payment Data
    const useFetchListingPaymentData = (unitId) => {
        const fetchListingPaymentDataRequest = () => {
            return axios.get(`/payment?listingId=${unitId}`)
        }

        return useQuery(
            [`unit-listing-payment-data`, unitId],
            () => fetchListingPaymentDataRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!unitId,
                select: (response) => response.data?.data
            }
        )
    }

    //Fetch Listing Payment Data
    const useFetchRenterListingPaymentData = (unitId) => {
        const fetchRenterListingPaymentDataRequest = () => {
            return axios.get(`/payment/renter-payment-details?listingId=${unitId}`)
        }

        return useQuery(
            [`renter-listing-payment-data`, unitId],
            () => fetchRenterListingPaymentDataRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!unitId,
                select: (response) => response.data?.data?.[0]
            }
        )
    }


    //Confirm Renter Detail
    const useHandleConfirmRenterDetail = () => {
        const {unitId} = useParams()

        const handleConfirmRenterDetailRequest = (paymentId) => {
            return axios.put(`payment/confirm-renter?paymentId=${paymentId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        }

        return useMutation(
            ({paymentId})=>handleConfirmRenterDetailRequest(paymentId),
            {
                onSuccess,
                onError,
            }
        )
    }

    //Fetch Payment Transaction
    const useFetchPaymentTransaction = () => {
        const {unitId} = useParams()
        const fetchListingPaymentDataRequest = () => {
            return axios.get(`/payment/transaction?listingId=${unitId}`)
        }

        return useQuery(
            [`unit-listing-transactions`, unitId],
            () => fetchListingPaymentDataRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!unitId,
                select: (response) => response.data?.data
            }
        )
    }

    //Mark As Paid Transaction
    const useHandleMarkAsPaidTransaction = () => {
        const {unitId} = useParams()
        const handleMarkAsPaidTransactionRequest = (schedulePaymentId) => {
            return axios.put(`/payment/status?schedulePaymentId=${schedulePaymentId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-listing-transactions`, unitId])
        }

        return useMutation(
            ({schedulePaymentId})=>handleMarkAsPaidTransactionRequest(schedulePaymentId),
            {
                onError,
                onSuccess
            }
        )
    }

    //Fetch Renter's Payment Transaction
    const useFetchRenterPaymentTransaction = () => {
        const userId = TokenService.getUser()?._id
        const fetchFetchRenterPaymentTransactionRequest = () => {
            return axios.get(`payment/renter-transaction?userId=${userId}`)
        }

        return useQuery(
            [`unit-listing-renter-transactions`, userId],
            () => fetchFetchRenterPaymentTransactionRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!userId,
                select: (response) => response.data?.data
            }
        )
    }

    //Confirm Payment Detail
    const useHandleConfirmPaymentDetail = () => {
        const {unitId} = useParams()

        const handleConfirmPaymentDetailRequest = (paymentId,paymentDetail) => {
            return axios.put(`payment/details?paymentId=${paymentId}`,paymentDetail)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        }

        return useMutation(
            ({paymentId,paymentDetail})=>handleConfirmPaymentDetailRequest(paymentId,paymentDetail),
            {
                onSuccess,
                onError,
            }
        )
    }

    //Fetch VoPay Url
    const useFetchVoPayUrl = (unitId) => {
        const fetchVoPayUrl = () => {
            return axios.get(`/payment/generate-token?listingId=${unitId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            fetchVoPayUrl,
            {
                onError,
            }
        )
    }

    //Fetch Renter VoPay Url
    const useFetchRenterVoPayUrl = (unitId) => {
        const fetchRenterVoPayUrl = () => {
            return axios.get(`/payment/generate-renter-token?listingId=${unitId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            fetchRenterVoPayUrl,
            {
                onError,
            }
        )
    }

    //Add Banking Account
    const useHandleAddBankAccount = (voPayToken,paymentId) => {
        // const {unitId} = useParams()

        const handleAddBankAccountRequest = () => {
            return axios.put(`payment/account?paymentId=${paymentId}`,{voPayToken})
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        // const onSuccess = () => {
        //     queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        // }

        return useQuery(
            ["add-bank-account",paymentId,voPayToken],
            handleAddBankAccountRequest,
            {
                onError,
                enabled:!!voPayToken && !!paymentId
            }
        )
    }

    //Add Renter Banking Account
    const useHandleAddRenterBankAccount = (voPayToken) => {
        // const {unitId} = useParams()
        const userId = TokenService.getUser()?._id
        const handleAddRenterBankAccountRequest = () => {
            return axios.put(`payment/renter-account?userId=${userId}`,{voPayToken})
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        // const onSuccess = () => {
        //     queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        // }

        return useQuery(
            ["add-renter-bank-account",userId,voPayToken],
            handleAddRenterBankAccountRequest,
            {
                onError,
                enabled:!!voPayToken && !!userId
            }
        )
    }

    //Fetch Bank Data
    const useFetchBankData = (voPayToken,isEnabled) => {
        const fetchBankData = () => {
            return axios.get(`/payment/token-info?voPayToken=${voPayToken}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }


        return useQuery(
            ["bank-account",voPayToken],
            fetchBankData,
            {
                onError,
                retry:0,
                enabled:!!voPayToken && isEnabled,
                select:(response)=>response?.data?.data
            }
        )
    }

    //Fetch Renter Payment Token
    const useFetchRenterPaymentToken = (isEnabled) => {
        const userId = TokenService.getUser()?._id
        const fetchRenterPaymentTokenRequest = () => {
            return axios.get(`/payment/renter-payment-account?renterId=${userId}`)
        }


        return useQuery(
            ["renter-payment-token",userId],
            fetchRenterPaymentTokenRequest,
            {
                retry:0,
                enabled:!!userId && !!isEnabled,
                select:(response)=>response?.data?.data
            }
        )
    }


    //Confirm Banking Account
    const useHandleConfirmBankingAccount = () => {
        const {unitId} = useParams()
        const handleConfirmBankingAccountRequest = (paymentId) => {
            return axios.put(`/payment/confirm-account?paymentId=${paymentId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-listing-payment-data`, unitId])
        }

        return useMutation(
            ({paymentId})=>handleConfirmBankingAccountRequest(paymentId),
            {
                onError,
                onSuccess
            }
        )
    }



    return {useFetchListingPaymentData,useHandleCreateListingPayment,useHandleConfirmRenterDetail,
        useHandleConfirmPaymentDetail,useHandleAddBankAccount,useFetchPaymentTransaction,
        useFetchRenterPaymentTransaction,useFetchVoPayUrl,useFetchBankData,useHandleConfirmBankingAccount,
        useHandleMarkAsPaidTransaction,useFetchRenterListingPaymentData,useHandleAddRenterBankAccount,
        useFetchRenterPaymentToken,useFetchRenterVoPayUrl}
};

export default UnitRentCollectionService;
