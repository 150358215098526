import React from "react";
import styles from "../../../styles/common/card.module.css"
import {BiTimeFive} from "react-icons/bi"
import {FaTrashAlt} from "react-icons/fa";
import {timeConverter} from "../../../utils/helpers";

const ViewingCard = ({deleteHandler,unitName,applicantName,startTime,endTime}) => {
  return(
      <>
        <div className={styles.viewing__card__wrapper}>
            <div className={styles.card__title}>
                <p>{unitName}</p>
                <button onClick={deleteHandler}>
                    <FaTrashAlt/>
                </button>
            </div>
            <div className={styles.card__info}>
                <p>{applicantName}</p>
                <span><BiTimeFive/> {timeConverter(startTime)} - {timeConverter(endTime)}</span>
            </div>
        </div>
      </>
  )
}
export default ViewingCard
