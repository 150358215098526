import React from "react"
import styles from "../../../styles/common/loader.module.css"

const PageLoader = () => {
    return(
        <>
            <div className={styles.preloader__main__wrapper}>
                <div className={styles.preloader__wrapper}>
                    <div className={styles.preloader__border}></div>
                    <div className={styles.preloader}></div>
                </div>
            </div>
        </>
    )
}
export default PageLoader;