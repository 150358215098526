import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router";
import styles from "../../../styles/contract.module.css"
import DashboardNavbar from "../../../components/widgets/dashboard-navbar";
import {toast} from "react-toastify";
import EditContractSidebar from "../../../components/widgets/edit-contract-sidebar";
import UnitContractService from "../../../services/unit-contract.service";
import PageLoader from "../../../components/common/loaders/page-loader";
import {mergeDuplicateLabels} from "../../../utils/helpers";
import {usePdf} from "../../../hooks/use-pdf";
import {useAttachments} from "../../../hooks/use-attachments";

/***
 * Edit Contract Route --> Protected, Covers the basic widgets and auth
 * @returns {JSX.Element}
 * @constructor
 */

const EditContractLayout = () => {
    const {state} = useLocation()
    const navigate = useNavigate()
    const {contractId} = useParams()
    const {useFetchContractById,useFetchContractDocumentByName} = UnitContractService()
    const [mutatedContractData,setMutatedContractData] = useState([])
    const handleUsePdf = usePdf();
    const handleUseAttachments = useAttachments();

    //Fetch Document By ID
    const {data: contractData, isFetching: isContractDataFetching,isSuccess: isContractDataSuccess} = useFetchContractById(contractId)
    //Fetch Contract Document By Name
    const {data: file, isLoading: isFileLoading} = useFetchContractDocumentByName(contractData?.media)

    useEffect(()=>{
        if(!state){
            toast.error("Access Denied")
            navigate("/home",{replace:true})
        }
    },[])

    //Is Single Document Fetched
    useEffect(()=>{
        if(isContractDataSuccess && !isContractDataFetching){
            setMutatedContractData(mergeDuplicateLabels([...contractData?.signers]))
        }
    },[isContractDataSuccess,isContractDataFetching,contractData?.signers?.length])

    //These list of signers have already entered their signature box
    const [signerListForSignature,setSignerListForSignature] = useState([])

    const handleSignerListForSignature = (enteredSignerId) => {
        setSignerListForSignature(enteredSignerId)
    }
    // useEffect(()=>{
    //     console.log(signerListForSignature,"signerListForSignature")
    // },[signerListForSignature])
    return (
        <>
            <section className={styles.router__wrapper}>
                <DashboardNavbar/>
                {(isContractDataFetching || isFileLoading) && <PageLoader/>}
                {!(isContractDataFetching || isFileLoading) && <main className={styles.router__content}>
                    <div className={styles.contract__router__inner__content}>
                        <Outlet context={{
                            documentFile:file,
                            usePdfFromParent:handleUsePdf,
                            useAttachmentsFromParent:handleUseAttachments,
                            contractData,
                            signerData:mutatedContractData,
                            handleSignerListForSignature,
                        }}/>
                    </div>
                    {mutatedContractData?.length > 0 &&
                        <EditContractSidebar
                            useAttachmentsFromParent={handleUseAttachments}
                            contractData={mutatedContractData}
                            usePdfFromParent={handleUsePdf}
                            disableSignatureForSigner={signerListForSignature}
                        />
                    }
                </main>}
            </section>
        </>
    );
};

export default EditContractLayout;
