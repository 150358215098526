import React, {Fragment, useContext, useEffect, useState} from "react";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import useForm from "../../../../../hooks/use-form";
import {AiOutlineClose, AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {PERMISSION_TO_CONTACT, STATE_PROVINCE} from "../../../../../utils/constants/application-listing";
import {formatDateToISO} from "../../../../../utils/helpers";
import ApplicationListingService from "../../../../../services/application-listing.service";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";

const stateList = [
    {
        value:STATE_PROVINCE.ALBERTA,
    },
    {
        value:STATE_PROVINCE.MANITOBA,
    },
    {
        value:STATE_PROVINCE.QUEBEC,
    },
    {
        value:STATE_PROVINCE.ONTARIO,
    },
    {
        value:STATE_PROVINCE.BRITISH_COLUMBIA,
    },
    {
        value:STATE_PROVINCE.NEW_BRUNSWICK,
    },
    {
        value:STATE_PROVINCE.NEWFOUND_LAND_AND_LABRADOR,
    },
    {
        value:STATE_PROVINCE.NOVA_SCOTIA,
    },
    {
        value:STATE_PROVINCE.PRINCE_EDWARD_ISLAND,
    },
    {
        value:STATE_PROVINCE.SASKATCHEWAN,
    },
]

const AddressHistoryForm = ({applicationData}) => {
    const {previousStep, nextStep} = useWizard();
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)
    const {useHandleUpdateAddressHistoryService} = ApplicationListingService()

    const [incrementerFields,setIncrementerFields] = useState({
        previousAddress : 0
    })
    const [fields,setFields] = useState({
        street: "",
        unitNumber: "",
        city: "",
        stateOrProvince: "",
        zipOrPostal: "",
        onRent: false,
        leavingReason: "",
        payedPerMonthly: "",
    })
    const [ownerInfoFields,setOwnerInfoFields] = useState({
        name: "",
        phone: "",
        email: "",
        permissionToContact: false
    })
    const [moveInDate,setMoveInDate] = useState(new Date())

    useEffect(()=>{
        setIncrementerFields({
            previousAddress: applicationData?.addressHistory?.previousAddress?.length || 0
        })
        setFields({
            unitNumber:applicationData?.addressHistory?.currentAddress?.unitNumber || "" ,
            payedPerMonthly:applicationData?.addressHistory?.currentAddress?.payedPerMonthly || "" ,
            stateOrProvince : applicationData?.addressHistory?.currentAddress?.stateOrProvince || STATE_PROVINCE.ALBERTA,
            street: applicationData?.addressHistory?.currentAddress?.street,
            city: applicationData?.addressHistory?.currentAddress?.city,
            zipOrPostal: applicationData?.addressHistory?.currentAddress?.zipOrPostal,
            onRent: applicationData?.addressHistory?.currentAddress?.onRent.toString(),
            leavingReason:applicationData?.addressHistory?.currentAddress?.leavingReason,

        })
        setOwnerInfoFields({
            name: applicationData?.addressHistory?.currentAddress?.ownerInformation?.name,
            phone: applicationData?.addressHistory?.currentAddress?.ownerInformation?.phone,
            email: applicationData?.addressHistory?.currentAddress?.ownerInformation?.email,
            permissionToContact: applicationData?.addressHistory?.currentAddress?.ownerInformation?.permissionToContact.toString()
        })
        setPreviousAddress(applicationData?.addressHistory?.previousAddress?.map((el)=>{
            return{
                ...el,
                stateOrProvince : el?.stateOrProvince || STATE_PROVINCE.ALBERTA,
                moveInDate : new Date(el?.moveInDate),
                onRent: el.onRent.toString(),
            }
        }) || [])
    },[applicationData?.addressHistory])

    const {details,inputEvent,incrementerEvent,incrementer} = useForm(fields,incrementerFields)
    const {details : ownerInformation,inputEvent : ownerInfoInputEvent} = useForm(ownerInfoFields)

    const [previousAddress,setPreviousAddress] = useState([])

    const removeAddressesField = (i) => {
        let newFormValues = [...previousAddress];
        newFormValues.splice(i, 1);
        setPreviousAddress(newFormValues);
        incrementerEvent("decrement","previousAddress")
    }
    const incrementAddress = () => {
        incrementerEvent("increment","previousAddress")
        addAddressFormFields()
    }
    const addAddressFormFields = () => {
        const id = Math.floor(Math.random() * 100)
        setPreviousAddress([
            ...previousAddress,
            // generating unique keys for each object
            {
                street: "",
                unitNumber: "",
                city: "",
                stateOrProvince: STATE_PROVINCE.ALBERTA,
                zipOrPostal: "",
                onRent: false,
                leavingReason: "",
                payedPerMonthly: "",
                moveInDate : new Date(),
                id
            },
        ]);
    };
    const handleAddressesChange = (index, clickedInput) => (e) => {
        previousAddress[index] = {
            ...previousAddress[index],
            [`${clickedInput}`]: e.target.value,
        };
        setPreviousAddress([...previousAddress]);
    };
    const handleAddressesDataChange = (index,date)  => {
        previousAddress[index] = {
            ...previousAddress[index],
            [`moveInDate`]: date,
        };
        setPreviousAddress([...previousAddress]);
    }
    const {mutate,isLoading,isSuccess} = useHandleUpdateAddressHistoryService({
        currentAddress:{
            ...details,
            moveInDate: formatDateToISO(moveInDate),
            ownerInformation
        },
        previousAddress : [...previousAddress.map((el)=>{
            return {
                ...el,
                moveInDate : formatDateToISO(el.moveInDate),
            }
        })] //Using different variable to avoid change in the original state
    })


    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()


        mutate()
    }

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    thirdStepperCompleted:true
                }
            })
            nextStep().then()
        }
    },[isSuccess])

    return(
        <>
            <Form className={styles.create__listing__form__alternate} onSubmit={goToNextStep}>

                {/*CURRENT ADDRESS*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Current Address</Form.Label>
                </Form.Group>

                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Street</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"text"} name={"street"} value={details.street}
                                          onChange={inputEvent} placeholder={"123 Name Street"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Unit Number (Optional)</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"text"} name={"unitNumber"} value={details.unitNumber}
                                          onChange={inputEvent} placeholder={"1"}
                                          />
                        </Form.Group>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>City</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"text"} name={"city"} value={details.city}
                                          onChange={inputEvent} placeholder={"Toronto"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>

                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>State/Province</Form.Label>
                            <Form.Select
                                name={"stateOrProvince"}
                                onChange={inputEvent}
                                value={details.stateOrProvince}
                                required
                                // className={error["unitType"] ? "error" : ""}
                            >
                                {stateList.map((unit, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <option value={unit.value}>{unit.value}</option>
                                        </Fragment>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Zip/Postal Code</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"text"} name={"zipOrPostal"} value={details.zipOrPostal}
                                          onChange={inputEvent} placeholder={"A1A1 A1A1"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__check__group}>
                        <label>Do you own or rent this property</label>
                        <div className="form-check">
                            <label className={(details.onRent === "true") ? styles.checked : ""}>

                                <input
                                    type="radio"
                                    name="onRent"
                                    value={true}
                                    onChange={inputEvent}
                                    className={`form-check-input`}
                                    checked={details.onRent === "true"}
                                    required
                                />
                                Rent
                            </label>
                        </div>
                        <div className="form-check">
                            <label className={details.onRent === "false" ? styles.checked : ""}>
                                <input
                                    type="radio"
                                    name="onRent"
                                    value={false}
                                    onChange={inputEvent}
                                    className={`form-check-input`}
                                    checked={details.onRent === "false"}
                                    required
                                />
                                Own
                            </label>
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>What is the reason you are moving out?</Form.Label>
                            <Form.Control as={"textarea"} rows={4} name="leavingReason" value={details.leavingReason} onChange={inputEvent} placeholder={"Write here"}/>
                        </Form.Group>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>When did you move in?</Form.Label>
                            <DatePicker
                                onChange={(date) => setMoveInDate(date)}
                                selected={moveInDate}
                                dateFormat={"yyyy-MM-dd"}
                                // minDate={new Date()}
                                placeholderText={"Date Of Birth"}
                            />
                        </Form.Group>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>How much did you pay per month?</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"number"} name={"payedPerMonthly"} value={details.payedPerMonthly}
                                          onChange={inputEvent} placeholder={"$"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>

                {/*Enter the Owner's Information*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label} style={{fontSize:"13px"}}>Enter the Owner's Information</Form.Label>
                </Form.Group>

                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Name</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"text"} name={"name"} value={ownerInformation.name}
                                          onChange={ownerInfoInputEvent} placeholder={"John Doe"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__group__outer__wrapper}>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Phone</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"number"} name={"phone"} value={ownerInformation.phone}
                                          onChange={ownerInfoInputEvent} placeholder={"123 456 7890"}
                                          required/>
                        </Form.Group>
                        <Form.Group className={styles.form__group}>
                            <Form.Label>Email</Form.Label>
                            {/*className={error["unitSize"] ? "error" : ""}*/}
                            <Form.Control type={"email"} name={"email"} value={ownerInformation.email}
                                          onChange={ownerInfoInputEvent} placeholder={"example@example.com"}
                                          required/>
                        </Form.Group>
                    </div>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <div className={styles.form__check__group}>
                        <label>Permission To Contact</label>
                        <div className="form-check">
                            <label className={ownerInformation.permissionToContact === `${PERMISSION_TO_CONTACT.YES}` ? styles.checked : ""}>
                                <input
                                    type="radio"
                                    name="permissionToContact"
                                    value={PERMISSION_TO_CONTACT.YES}
                                    onChange={ownerInfoInputEvent}
                                    className={`form-check-input`}
                                    checked={ownerInformation.permissionToContact === `${PERMISSION_TO_CONTACT.YES}`}
                                    required
                                />
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <label className={ownerInformation.permissionToContact === `${PERMISSION_TO_CONTACT.NO}` ? styles.checked : ""}>
                                <input
                                    type="radio"
                                    name="permissionToContact"
                                    value={PERMISSION_TO_CONTACT.NO}
                                    onChange={ownerInfoInputEvent}
                                    className={`form-check-input`}
                                    checked={ownerInformation.permissionToContact === `${PERMISSION_TO_CONTACT.NO}`}
                                    required
                                />
                                No
                            </label>
                        </div>
                    </div>
                </Form.Group>


                {/*PREVIOUS ADDRESS*/}
                <Form.Group className={styles.form__group__wrapper} style={{marginTop:"30px"}}>
                    <Form.Group className={styles.form__check__group}>
                        <label id={styles.main__label}>Previous Addresses</label>
                        <div className={styles.incrementer__wrapper}>
                            <button disabled={incrementer.previousAddress <= 0} type={"button"} onClick={() => incrementerEvent("decrement","previousAddress")}><AiOutlineMinus/></button>
                            <span>{incrementer.previousAddress}</span>
                            <button type={"button"} onClick={() => incrementAddress()}><AiOutlinePlus/></button>
                        </div>
                    </Form.Group>
                </Form.Group>
                {incrementer.previousAddress > 0 && previousAddress.map((address,index)=>{
                    return(
                        <Fragment key={address?.id}>
                            <div style={{marginBottom:"10px"}}>
                                <button type={"button"} onClick={() => removeAddressesField(index)}
                                        className={styles.close__field}><AiOutlineClose/></button>

                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Street</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"text"} name={"street"} value={address.street}
                                                          onChange={handleAddressesChange(index,"street")} placeholder={"123 Name Street"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Unit Number (Optional)</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"text"} name={"unitNumber"} value={address.unitNumber}
                                                          onChange={handleAddressesChange(index,"unitNumber")} placeholder={"1"}
                                            />
                                        </Form.Group>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>City</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"text"} name={"city"} value={address.city}
                                                          onChange={handleAddressesChange(index,"city")} placeholder={"Toronto"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>

                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>State/Province</Form.Label>
                                            <Form.Select
                                                name={"stateOrProvince"}
                                                onChange={handleAddressesChange(index,"stateOrProvince")}
                                                value={address.stateOrProvince}
                                                required
                                                // className={error["unitType"] ? "error" : ""}
                                            >
                                                {stateList.map((unit, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <option value={unit.value}>{unit.value}</option>
                                                        </Fragment>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Zip/Postal Code</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"text"} name={"zipOrPostal"} value={address.zipOrPostal}
                                                          onChange={handleAddressesChange(index,"zipOrPostal")} placeholder={"A1A1 A1A1"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__check__group}>
                                        <label>Do you own or rent this property</label>
                                        <div className="form-check">
                                            <label className={(address.onRent === "true") ? styles.checked : ""}>

                                                <input
                                                    type="radio"
                                                    name={`onRent${index}`}
                                                    value={true}
                                                    onChange={handleAddressesChange(index,"onRent")}
                                                    className={`form-check-input`}
                                                    checked={address.onRent === "true"}
                                                    required
                                                />
                                                Rent
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className={address.onRent === "false" ? styles.checked : ""}>
                                                <input
                                                    type="radio"
                                                    name={`onRent${index}`}
                                                    value={false}
                                                    onChange={handleAddressesChange(index,"onRent")}
                                                    className={`form-check-input`}
                                                    checked={address.onRent === "false"}
                                                    required
                                                />
                                                Own
                                            </label>
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>What is the reason you are moving out?</Form.Label>
                                            <Form.Control as={"textarea"} rows={4} name="leavingReason"
                                                          value={address.leavingReason} onChange={handleAddressesChange(index,"leavingReason")}
                                                          placeholder={"Write here"}/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>When did you move in?</Form.Label>
                                            <DatePicker
                                                name={"moveInDate"}
                                                onChange={(date)=>handleAddressesDataChange(index,date)}
                                                selected={address.moveInDate}
                                                dateFormat={"yyyy-MM-dd"}
                                                // minDate={new Date()}
                                                placeholderText={"Date Of Birth"}
                                            />
                                        </Form.Group>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>How much did you pay per month?</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"number"} name={"payedPerMonthly"} value={address.payedPerMonthly}
                                                          onChange={handleAddressesChange(index,"payedPerMonthly")} placeholder={"$"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                            </div>
                        </Fragment>
                    )
                })}

                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading}
                />
            </Form>

        </>
    )
}
export default AddressHistoryForm
