/***
 * General Imports
 */
import React, {createContext, lazy, Suspense, useEffect} from "react"
import { Routes, Route} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {QueryClient,QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";

/***
 * CSS Imports
 */
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./app/styles/general.css"
import "./app/styles/common/modal.css"
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-multi-carousel/lib/styles.css';
import "react-big-calendar/lib/css/react-big-calendar.css"
import "react-big-calendar/lib/sass/styles.scss"
import './app/styles/common/custom-calendar.css'
import './app/styles/common/schedular-datepicker.css'

/***
 * Utility Imports
 */
import ScrollToTop from "./app/utils/scroll-to-top";
import RootErrorBoundary from "./app/error-boundaries/root";
import {ROLES} from "./app/utils/constants";
import useSocketInitialization from "./app/hooks/use-socket-initialization";

/***
 * Routes & Layouts Imports
 */
import CreateApplicationListing from "./app/pages/create-application-listing";
import ResponseLayout from "./app/routes/private/response-layout";
import SignContractLayout from "./app/routes/private/contract-module/sign-contract-layout";
import EditContractLayout from "./app/routes/private/contract-module/edit-contract-layout";

/***
 * Page Imports
 */
import NotFound from "./app/pages/404";
import AccountDetailsContainer from "./app/components/stateful/account-settings/account-details-container";
import PaymentDetailsContainer from "./app/components/stateful/account-settings/payment-details-container";
import CancelAccount from "./app/components/presentational/account-settings/cancel-account";
import Preview from "./app/pages/single-unit/preview";
import Applicants from "./app/pages/single-unit/applicants";
import Contract from "./app/pages/single-unit/contract";
import RentCollection from "./app/pages/single-unit/rent-collection";
import Viewing from "./app/pages/single-unit/viewing";
import Success from "./app/pages/success";
import PageLoader from "./app/components/common/loaders/page-loader";
import EditContract from "./app/pages/contract-module/edit-contract";
import SignContract from "./app/pages/contract-module/sign-contract";
import SingleMessage from "./app/pages/messages/single-message";

const Home = lazy(()=>import('./app/pages/home'))
const Calendar = lazy(()=>import('./app/pages/calendar'))
const Message = lazy(()=>import('./app/pages/messages'))
const Login = lazy(()=>import('./app/pages/auth/login'))
const ForgetPasswordConfirmation = lazy(()=>import('./app/pages/auth/forget-password-confirmation'))
const ForgetPasswordChangePassword = lazy(()=>import('./app/pages/auth/forget-password-change-password'))
const ForgetPassword = lazy(()=>import('./app/pages/auth/forget-password'))
const Signup = lazy(()=>import('./app/pages/auth/signup'))
const EmailVerification = lazy(()=>import('./app/pages/auth/email-verification'))
const AccountSettings = lazy(()=>import('./app/pages/account-settings'))
const SingleUnit = lazy(()=>import('./app/pages/single-unit'))
const CreateUnitListing = lazy(()=>import('./app/pages/create-unit-listing'))
const Application = lazy(()=>import('./app/pages/application'))
const RenterPayments = lazy(()=>import('./app/pages/payments'))
const RenterContract = lazy(()=>import('./app/pages/contract'))

const CreateApplicationListingLayout = lazy(()=>import('./app/routes/private/create-listing/create-application-listing-layout'))
const CreateUnitListingLayout = lazy(()=>import('./app/routes/private/create-listing/create-unit-listing-layout'))

const PrivateRoute = lazy(()=>import('./app/routes/private'))
const AuthRoute = lazy(()=>import('./app/routes/public/auth-route'))
const DashboardLayout = lazy(()=>import('./app/routes/private/dashboard-layout'))
const PublicDashboardLayout = lazy(()=>import('./app/routes/public/public-dashboard-route'))

const queryClient = new QueryClient()
export const SocketInstanceContext = createContext(null)



const App = () => {
    const socketInstance = useSocketInitialization()

  return (
      <>
          <QueryClientProvider client={queryClient}>
              <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
              <ScrollToTop/>
              {/*Root Error Boundary for the entire app*/}
              <RootErrorBoundary>
                  <SocketInstanceContext.Provider value={socketInstance}>
                      <Routes>

                          {/*-----------------RENTER ROUTES--------------*/}
                          <Route path="/" element={<Suspense fallback={<PageLoader/>}><PrivateRoute role={ROLES.RENTER}/></Suspense>}>
                              <Route element={<DashboardLayout/>}>
                                  <Route path="/application" element={<Suspense fallback={<PageLoader/>}><Application/></Suspense>}/>
                                  <Route path="/payments" element={<Suspense fallback={<PageLoader/>}><RenterPayments/></Suspense>}/>
                                  <Route path="/contract" element={<Suspense fallback={<PageLoader/>}><RenterContract/></Suspense>}/>
                                  <Route path="/renter-account-settings" element={<Suspense fallback={<PageLoader/>}><AccountSettings/></Suspense>}>
                                      <Route path="account-details" element={<AccountDetailsContainer/>}/>
                                      <Route path="payment-details" element={<PaymentDetailsContainer/>}/>
                                      <Route path="cancel-account" element={<CancelAccount/>}/>
                                  </Route>
                              </Route>
                              <Route element={<Suspense fallback={<PageLoader/>}><CreateApplicationListingLayout/></Suspense>}>
                                  <Route path={"create-application-listing/:applicationId"} element={<CreateApplicationListing/>}/>
                              </Route>
                          </Route>

                          {/*-----------------OWNER ROUTES--------------*/}
                          <Route path="/" element={<Suspense fallback={<PageLoader/>}><PrivateRoute role={ROLES.OWNER}/></Suspense>}>
                              <Route element={<DashboardLayout/>}>
                                  <Route path="/home" element={<Suspense fallback={<PageLoader/>}><Home/></Suspense>}/>
                                  <Route path="/calendar" element={<Suspense fallback={<PageLoader/>}><Calendar/></Suspense>}/>
                                  <Route path="/owner-account-settings" element={<Suspense fallback={<PageLoader/>}><AccountSettings/></Suspense>}>
                                      <Route path="account-details" element={<AccountDetailsContainer/>}/>
                                      <Route path="payment-details" element={<PaymentDetailsContainer/>}/>
                                      <Route path="cancel-account" element={<CancelAccount/>}/>
                                  </Route>
                                  <Route path="/unit/:unitId" element={<Suspense fallback={<PageLoader/>}><SingleUnit/></Suspense>}>
                                      <Route path={"applicants"} element={<Applicants/>}/>
                                      <Route path={"contract"} element={<Contract/>}/>
                                      <Route path={"rent-collection"} element={<RentCollection/>}/>
                                      <Route path={"viewing"} element={<Viewing/>}/>
                                  </Route>
                              </Route>
                              <Route element={<Suspense fallback={<PageLoader/>}><CreateUnitListingLayout/></Suspense>}>
                                  <Route path={"create-unit-listing/:unitId"} element={<CreateUnitListing/>}/>
                              </Route>
                              <Route element={<Suspense fallback={<PageLoader/>}><EditContractLayout/></Suspense>}>
                                  <Route path={"edit-contract/:contractId"} element={<EditContract/>}/>
                              </Route>
                          </Route>

                          {/*-----------------ROUTES All User Can Visit--------------*/}
                          <Route path="/" element={<Suspense fallback={<PageLoader/>}><PrivateRoute/></Suspense>}>
                              <Route element={<DashboardLayout/>}>
                                  <Route path="/message" element={<Suspense fallback={<PageLoader/>}><Message/></Suspense>}>
                                      <Route path={":unitId/:messengerId"} element={<SingleMessage/>}/>
                                  </Route>
                              </Route>
                              <Route element={<ResponseLayout/>}>
                                  <Route path={"success"} element={<Success/>}/>
                              </Route>
                          </Route>

                          {/*-----------------PUBLIC/AUTH ROUTES--------------*/}
                          <Route path="/" element={<Suspense fallback={<PageLoader/>}><PublicDashboardLayout/></Suspense>}>
                              <Route path="/unit/:unitId" element={<Suspense fallback={<PageLoader/>}><SingleUnit/></Suspense>}>
                                  <Route path={"preview"} element={<Preview/>}/>
                              </Route>
                          </Route>
                          <Route path={"/"} element={<Suspense fallback={<PageLoader/>}><SignContractLayout/></Suspense>}>
                              <Route path={"sign-contract"} element={<SignContract/>}/>
                          </Route>
                          <Route element={<Suspense fallback={<PageLoader/>}><AuthRoute/></Suspense>}>
                              <Route path="/login" element={<Login/>}/>
                              <Route path="/signup" element={<Signup/>}/>
                              <Route path="/email-verification" element={<EmailVerification/>}/>
                              <Route path="/forget-password">
                                  <Route index element={<ForgetPassword/>}/>
                                  <Route path="change-password" element={<ForgetPasswordChangePassword/>}/>
                              </Route>
                              <Route path="/forget-password-confirmation" element={<ForgetPasswordConfirmation/>}/>
                          </Route>
                          {/*Fallbacks*/}
                          <Route element={<ResponseLayout/>}>
                              <Route path="*" element={<NotFound/>}/>
                          </Route>

                      </Routes>
                  </SocketInstanceContext.Provider>

                  <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"}/>
              </RootErrorBoundary>
          </QueryClientProvider>
      </>

  )
}

export default App;
