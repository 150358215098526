import {useEffect, useState} from "react";

function useIsInViewport(refList) {
    const [intersectingElement,setIntersectingElement] = useState(0)

    function isInViewport(offset = 0) {
        refList.forEach(el => {
            const top = el.current.getBoundingClientRect().top;
            if(top >= 0 && top <= window.innerHeight){
                displayIds(el.current.id)
            }

        })
    }
    const displayIds = (target) => {
        setIntersectingElement(parseInt(target.split("page")[1]));
    }

    useEffect(()=>{
        window.addEventListener("scroll",isInViewport)
        return () => window.removeEventListener("scroll",isInViewport)
    })

    return intersectingElement;
}
export default useIsInViewport
