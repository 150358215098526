import React, {useState} from "react";
import styles from "../../../../../styles/viewing.module.css"
import UnitViewingService from "../../../../../services/unit-viewing.service";
import PrimaryModal from "../../../../common/modals/primary-modal";
import InfoModal from "../../../../common/modals/info-modal";
import ViewingsList from "../../../../stateful/calendar/viewings-list";

const ScheduledViewing = () => {
    const {useFetchUnitViewings,useHandleDeleteOwnersViewing} = UnitViewingService()

    const [isOpen,setIsOpen] = useState({
        open:false,
        viewingId:""
    })

    const {data: viewingsData, isLoading: isViewingsLoading, isFetching: isViewingsFetching} = useFetchUnitViewings()
    const {mutate: deleteViewing, isLoading:isViewingDeleteLoading, isSuccess:isDeleteViewingSuccess} = useHandleDeleteOwnersViewing()
    const handleConfirmDeleteViewing = (open,id) => {
        setIsOpen({open, viewingId:id})
    }
    if (viewingsData?.length <= 0) return null
  return(
      <>
        <div className={styles.scheduled__viewing__section}>
            <h2>Scheduled Viewing</h2>
            <ViewingsList
                isLoading={isViewingsLoading && isViewingsFetching}
                viewingsData={viewingsData}
                handleConfirmDeleteViewing={handleConfirmDeleteViewing}
            />
            {isOpen.open &&
                <PrimaryModal
                    size={"lg"}
                    customClass={"half__modal"}
                    isOpen={isOpen.open}
                    modalContent={
                        <InfoModal
                            handleModalClose={(open)=>handleConfirmDeleteViewing(open,"")}
                            isLoading={isViewingDeleteLoading}
                            mutate={()=>deleteViewing(isOpen.viewingId)}
                            isSuccess={isDeleteViewingSuccess}
                            title={"Delete Scheduled Viewing"}
                            description={`Are you sure that you would like to delete the viewing? This action cannot be undone.`}
                            confirmButtonText={"Delete"}
                        />}
                />
            }
        </div>
      </>
  )
}
export default ScheduledViewing
