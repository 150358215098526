import React from 'react';
import {TextMode} from "../../../../utils/constants/contract";
import styles from "../../../../styles/contract.module.css";
import {AiOutlineClose} from "react-icons/ai";
import DatePicker from "react-datepicker";

export const DateInput = ({
                       text,
                       width,
                       height,
                       inputRef,
                       mode,
                       size,
                       fontFamily,
                       positionTop,
                       positionLeft,
                       onChangeText,
                       toggleEditMode,
                       handleMouseDown,
                       handleMouseMove,
                       handleMouseOut,
                       handleMouseUp,
                       lineHeight,
                              colorTheme,
                              removeAttachment,
                              isEditable,
    id
                     }) => {
  return (
      <>
          {isEditable && (
              <div
                  style={{
                      width,
                      height,
                      lineHeight,

                      top: positionTop,
                      left: positionLeft,
                      wordWrap: 'break-word',
                      padding: 0,
                      position: 'absolute',
                  }}
              >
                  <DatePicker
                      onChange={(date) => onChangeText(date)}
                      selected={new Date(text)}
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Select Date"}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      ref={inputRef}
                      className={`${styles.disabled__pdf__input} ${styles.disabled__pdf__input__color} ${styles.date__pdf__input}`}
                      style={{
                          height,
                          fontSize:size,
                      }}
                  />
              </div>)}
          {!isEditable && (<div
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseOut={handleMouseOut}
              onDoubleClick={toggleEditMode}
              id={id}
              style={{
                  width,
                  cursor: mode === TextMode.COMMAND ? 'move' : 'default',
                  top: positionTop,
                  left: positionLeft,
                  padding: 0,
                  position: 'absolute',
              }}
          >
              <input
                  type="text"
                  ref={inputRef}
                  disabled
                  onChange={onChangeText}
                  readOnly
                  style={{
                      cursor: mode === TextMode.COMMAND ? 'move' : 'text',
                      border:`1px solid ${colorTheme}`,
                      color:colorTheme,
                      fontSize:size,
                  }}
                  className={styles.disabled__pdf__input}
                  value={"Date"}
              />
              <button type={"button"} onClick={removeAttachment} className={styles.remove__pdf__field}>
                  <AiOutlineClose/>
              </button>
          </div>)}
      </>
  );
};
