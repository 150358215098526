import React from "react"
import styles from "../../../styles/common/tooltip.module.css"
import propTypes from "prop-types";

const CustomTooltip = ({children,extraClass}) => {
    return(
        <>
            <div className={`${styles.tooltip__wrapper} ${styles[extraClass]}`}>
                {children}
            </div>
        </>
    )
}
export default CustomTooltip

CustomTooltip.propType = {
    extraClass : propTypes.string
}
