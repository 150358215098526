import React, {Fragment, useRef, useState} from "react"
import styles from "../../../styles/common/card.module.css";
import {BiEditAlt} from "react-icons/bi";
import {BsThreeDotsVertical} from "react-icons/bs";
import useOutsideAlerter from "../../../hooks/use-outside-alerter";
import {receiversRole} from "../../../utils/helpers";
import {DOCUMENT_ACTIONS, SIGNER_STATUS} from "../../../utils/constants/listing";
import {nanoid} from "nanoid";
import TokenService from "../../../services/token.service";


const UnitDocumentCard = ({onAction,index,contract,isRenter,onDeleteDocument,onDeclineDocument}) => {
    const loggedInUserId = TokenService.getUser()?._id
    const loggedInUserSignerId = contract?.signers.find(el => el?.userId === loggedInUserId)?._id

    const isAllDraft = contract?.signers?.every(el => el?.status === SIGNER_STATUS.DRAFT)
    const isAllSigned = contract?.signers?.every(el => el?.status === SIGNER_STATUS.SIGNED)
    // const isMixed =  !(contract?.signers?.every(el => el?.status === SIGNER_STATUS.DRAFT) || contract?.signers?.every(el => el?.status === SIGNER_STATUS.SIGNED))
    const isLoggedInUserSent = contract?.signers.find(el => el?.userId === loggedInUserId)?.status === SIGNER_STATUS.SENT


    const contractSignerStatus = isAllDraft ? [{
        status:"Draft",
        color:"#5243AA"
    }] : contract?.signers.map(el => ({
        status:el?.status === SIGNER_STATUS.DRAFT ? "Draft" : el?.status === SIGNER_STATUS.SENT ? "Sent" : el?.status === SIGNER_STATUS.SIGNED ? "Signed" : el?.status === SIGNER_STATUS.DECLINED ? "Declined" : "Expired",
        color:el?.status === SIGNER_STATUS.DRAFT ? "#5243AA" : el?.status === SIGNER_STATUS.SENT ? "#FFC542" : el?.status === SIGNER_STATUS.SIGNED ? "#00875A" : el?.status === SIGNER_STATUS.DECLINED ? "#D92D20" : "#D92D20",
    }))
    const currentUserStatus = contract?.signers?.find(el => el?.userId === loggedInUserId)?.status

    const [showDropDown,setShowDropDown] = useState(false)
    const wrapperRef = useRef(null)

    const outSideClickCallback = () => {
        setShowDropDown(false)
    }
    useOutsideAlerter(wrapperRef,outSideClickCallback)
    const handleDropDown = (i) => {
        if(i===index){
            setShowDropDown(!showDropDown)
        }
    }

    return(
        <>
            <div className={styles.unit__document__card__wrapper}>
                <div className={styles.card__detail__row}>
                    <div className={`${styles.contract__name} ${styles.detail__list}`}>
                        <h3 >
                            {contract?.name}
                        </h3>
                        {contract?.isLeaseContract && <p><i>Lease Contract</i></p>}
                    </div>
                    <div className={styles.detail__list}>
                        <h3>Signers</h3>
                        <ul>
                            {contract?.signers?.map(el => {
                                return(
                                    <Fragment key={el?._id}>
                                        <li>{receiversRole(el?.isApplicant,el?.isOwner)}: {el?.name}</li>
                                    </Fragment>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={styles.detail__list}>
                        <h3>Status</h3>
                        <ul>
                            {contractSignerStatus?.length > 0 && contractSignerStatus?.map(signerStatus => {
                                return(
                                    <Fragment key={nanoid()}>
                                        <li style={{color:signerStatus?.color}}>{signerStatus?.status}</li>
                                    </Fragment>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={styles.action__wrapper}>
                        <div className={styles.button__wrapper}>
                            {isAllDraft && (
                                <button className={styles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.CONTINUE, {id:contract?._id})}>
                                    Continue
                                </button>
                            )}
                            {isAllSigned && (
                                <button className={styles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.DOWNLOAD, {media:contract?.media})}>
                                    Download
                                </button>
                            )}
                            {isLoggedInUserSent && (
                                <button className={styles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.SIGN, {id:contract?._id,signerId:loggedInUserSignerId})}>
                                    <BiEditAlt/> Sign
                                </button>
                            )}
                        </div>
                        {((!isRenter || (isRenter && currentUserStatus === SIGNER_STATUS.SENT)) && !isAllSigned) ? <div className={styles.menu__dropdown}>
                            <div className={styles.menu__dropdown__inner} ref={wrapperRef}>
                                <button className={styles.drop__down__button} onClick={() => handleDropDown(index)}
                                        title={"More Actions"}>
                                    <BsThreeDotsVertical/>
                                </button>
                                {(showDropDown) && <div className={styles.drop__down}>
                                    <ul>
                                        {/*{(!isRenter && isAllSigned) && <li onClick={()=>onViewDocument(contract?._id)}>*/}
                                        {/*    View*/}
                                        {/*</li>}*/}
                                        {!isRenter && <li onClick={() => onDeleteDocument(contract?._id)}>
                                            Delete
                                        </li>}
                                        {(isRenter && currentUserStatus === SIGNER_STATUS.SENT) &&
                                            <li onClick={() => onDeclineDocument(true, contract?._id, loggedInUserSignerId)}>
                                                Decline
                                            </li>}
                                    </ul>
                                </div>}
                            </div>
                        </div> : <div></div>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default UnitDocumentCard
