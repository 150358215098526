import React from "react"
import styles from "../../../../../styles/rent-collection.module.css";
import {Col, Row} from "react-bootstrap";
import TransactionCard from "../../../../common/cards/transaction-card";
import BaseTable from "../../../../common/tables/base-table";
import {PAYMENT_TRANSACTION_STATUS} from "../../../../../utils/constants/listing";
import TokenService from "../../../../../services/token.service";
import UserProfilePicture from "../../../../common/user-profile-picture";
import UnitRentCollectionService from "../../../../../services/unit-rent-collection.service";
import useWindowSize from "../../../../../hooks/use-window-size";
import TransactionMobileTable from "./transaction-mobile-table";

const headerTitles = [
    'Name',
    'Payment Date',
    'Due Date',
    'Amount',
    'Status',
    ""
]
const PaymentTransactions = ({unitPaymentTransaction,isRenter}) => {
    const userData = TokenService.getUser()
    const {width} = useWindowSize()
    const {useHandleMarkAsPaidTransaction} = UnitRentCollectionService()

    const {isLoading: isMarkAsPaidLoading,mutate: markAsPaid} = useHandleMarkAsPaidTransaction()

    function handleComputeTransactionCard(status) {
        let transactionData = unitPaymentTransaction?.find(el => el?._id === status)?.payments || []
        let statusSpecificTransactions = transactionData?.filter(el => el?.status === status)
        return {
            filteredArray:statusSpecificTransactions,
            amount:statusSpecificTransactions?.map(el => el?.amount)?.reduce((acc, curr) => acc + curr, 0)?.toFixed(0),
            numberOfTransaction:statusSpecificTransactions?.length
        }
    }
    let receivedTransactionData = handleComputeTransactionCard(PAYMENT_TRANSACTION_STATUS.RECEIVED)
    let upcomingTransactionData = handleComputeTransactionCard(PAYMENT_TRANSACTION_STATUS.UPCOMING)
    let overdueTransactionData = handleComputeTransactionCard(PAYMENT_TRANSACTION_STATUS.OVERDUE)

    function onMarkAsPaid(schedulePaymentId) {
        markAsPaid({schedulePaymentId})
    }
  return(
      <>
        <div className={styles.payment__transaction__wrapper}>
            <div className={styles.transaction__cards__wrapper}>
                <Row>
                    <Col md={4} className={styles.col}>
                        <TransactionCard
                            colorCode={"#00875A"}
                            amount={receivedTransactionData.amount}
                            cardTitle={"Received"}
                            numberOfTransactions={receivedTransactionData.numberOfTransaction}
                        />
                    </Col>
                    <Col md={4} className={styles.col}>
                        <TransactionCard
                            colorCode={"#FFC542"}
                            amount={upcomingTransactionData.amount}
                            cardTitle={"Upcoming"}
                            numberOfTransactions={upcomingTransactionData.numberOfTransaction}
                        />
                    </Col>
                    <Col md={4} className={styles.col}>
                        <TransactionCard
                            colorCode={"#EF2F6E"}
                            amount={overdueTransactionData.amount}
                            cardTitle={"Overdue"}
                            numberOfTransactions={overdueTransactionData.numberOfTransaction}
                        />
                    </Col>
                </Row>
            </div>
            <div className={`${styles.table__wrapper} `}>
                <h3 className={styles.title}>Received</h3>
                {width > 767 && <BaseTable headerList={headerTitles.filter(el => !!el)}>
                    {receivedTransactionData?.filteredArray?.length > 0 && receivedTransactionData?.filteredArray?.map(el =>
                        <tbody key={el?._id}>
                        <tr>
                            <td>
                                <div className={styles.user__info}>
                                    <UserProfilePicture
                                        profilePic={isRenter ? userData?.profilePicture : el?.renter?.profilePicture}/>
                                    <p>{isRenter ? `${userData?.firstName} ${userData?.lastName}` : `${el?.renter?.firstName} ${el?.renter?.lastName}`}</p>
                                </div>
                            </td>
                            <td>
                                <p className={styles.row__text}>{new Date(el?.paymentDate).toDateString()}</p>
                            </td>
                            <td>
                                <p className={styles.row__text}>{new Date(el?.scheduleDate).toDateString()}</p>
                            </td>
                            <td>
                                <p className={styles.row__text}>${el?.amount}</p>
                            </td>
                            <td>
                                <div className={styles.decision__button__wrapper}>
                                    <p className={styles.received}>Received</p>
                                </div>
                            </td>
                        </tr>
                        </tbody>)}
                </BaseTable>}
                {(width <= 767 && receivedTransactionData?.filteredArray?.length > 0) &&
                    <TransactionMobileTable
                        transactionData={ receivedTransactionData?.filteredArray}
                        isRenter={isRenter}
                        status={"Received"}
                    />}
                {/*<--TABLE FOOTER-->*/}
                {/*<LoadMoreButton*/}
                {/*    clickCallback={loadMoreCallback}*/}
                {/*/>*/}
                {/*<--EMPTY TABLE CONTAINER-->*/}
                {!receivedTransactionData?.filteredArray?.length > 0 && <div className={styles.empty__table}>
                    <h3>No Transactions Yet</h3>
                </div>}
            </div>
            <div className={`${styles.table__wrapper}`}>
                <h3 className={styles.title}>Upcoming</h3>
                {width > 767 && <BaseTable headerList={isRenter ? headerTitles.filter(el => (!!el && el !==  'Payment Date')) : headerTitles.filter(el => el !== 'Payment Date')}>
                    {upcomingTransactionData?.filteredArray?.length > 0 && upcomingTransactionData?.filteredArray?.map(el =>
                        <tbody key={el?._id}>
                        <tr>
                            <td>
                                <div className={styles.user__info}>
                                    <UserProfilePicture
                                        profilePic={isRenter ? userData?.profilePicture : el?.renter?.profilePicture}/>
                                    <p>{isRenter ? `${userData?.firstName} ${userData?.lastName}` : `${el?.renter?.firstName} ${el?.renter?.lastName}`}</p>
                                </div>
                            </td>
                            <td>
                                <p className={styles.row__text}>{new Date(el?.scheduleDate).toDateString()}</p>
                            </td>
                            <td>
                                <p className={styles.row__text}>${el?.amount}</p>
                            </td>
                            <td>
                                <div className={styles.decision__button__wrapper}>
                                    <p className={styles.upcoming}>Upcoming</p>
                                </div>
                            </td>
                            {!isRenter && <td>
                                <div className={styles.mark__as__paid__button__wrapper}>
                                    <button type={"button"} onClick={() => onMarkAsPaid(el?._id)}
                                            disabled={isMarkAsPaidLoading}>Mark As Paid
                                    </button>
                                </div>
                            </td>}
                        </tr>
                        </tbody>)}
                </BaseTable>}
                {(width <= 767 && upcomingTransactionData?.filteredArray?.length > 0) &&
                    <TransactionMobileTable
                        transactionData={upcomingTransactionData?.filteredArray}
                        isRenter={isRenter}
                        status={"Upcoming"}
                        handleMarkAsPaid={onMarkAsPaid}
                    />}
                {/*<--TABLE FOOTER-->*/}
                {/*<LoadMoreButton*/}
                {/*    clickCallback={loadMoreCallback}*/}
                {/*/>*/}
                {/*<--EMPTY TABLE CONTAINER-->*/}
                {!upcomingTransactionData?.filteredArray?.length > 0 && <div className={styles.empty__table}>
                    <h3>No Transactions Yet</h3>
                </div>}
            </div>
            <div className={`${styles.table__wrapper} `}>
                <h3 className={styles.title}>Overdue</h3>
                {width > 767 && <BaseTable headerList={headerTitles.filter(el => (!!el && el !==  'Payment Date'))}>
                    {overdueTransactionData?.filteredArray?.length > 0 && overdueTransactionData?.filteredArray?.map(el =>
                        <tbody key={el?._id}>
                        <tr>
                            <td>
                                <div className={styles.user__info}>
                                    <UserProfilePicture
                                        profilePic={isRenter ? userData?.profilePicture : el?.renter?.profilePicture}/>
                                    <p>{isRenter ? `${userData?.firstName} ${userData?.lastName}` : `${el?.renter?.firstName} ${el?.renter?.lastName}`}</p>
                                </div>
                            </td>
                            <td>
                                <p className={styles.row__text}>{new Date(el?.scheduleDate).toDateString()}</p>
                            </td>
                            <td>
                                <p className={styles.row__text}>${el?.amount}</p>
                            </td>
                            <td>
                                <div className={styles.decision__button__wrapper}>
                                    <p className={styles.overdue}>Overdue</p>
                                </div>
                            </td>
                        </tr>
                        </tbody>)}
                </BaseTable>}
                {(width <= 767 && overdueTransactionData?.filteredArray?.length > 0) &&
                    <TransactionMobileTable
                        transactionData={overdueTransactionData?.filteredArray}
                        isRenter={isRenter}
                        status={"Overdue"}
                    />}
                {/*<--TABLE FOOTER-->*/}
                {/*<LoadMoreButton*/}
                {/*    clickCallback={loadMoreCallback}*/}
                {/*/>*/}
                {/*<--EMPTY TABLE CONTAINER-->*/}
                {!overdueTransactionData?.filteredArray?.length > 0 && <div className={styles.empty__table}>
                    <h3>No Transactions Yet</h3>
                </div>}
            </div>
        </div>
      </>
  )
}
export default PaymentTransactions
