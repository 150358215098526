import React, {Fragment, useEffect, useRef} from "react";
import styles from "../../../styles/messages.module.css"
import {nanoid} from "nanoid";
import UserProfilePicture from "../../common/user-profile-picture";
import useApiPager from "../../../hooks/use-api-pager";
import TokenService from "../../../services/token.service";
import {MEDIA_BASEURL} from "../../../utils/constants";
import useWindowSize from "../../../hooks/use-window-size";
import DefaultLoader from "../../common/loaders/default-loader";

const MessagesList = ({isLoading,messageThread,onApiPagination}) => {
    const threadRef = useRef(null)

    const userId = TokenService.getUser()?._id
    const {width} = useWindowSize()
    let messageTopBoundaryRef = useRef(null);
    const [pagination] = useApiPager(messageTopBoundaryRef)
    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        threadRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messageThread]);
    useEffect(()=>{
        if (!isLoading){
            onApiPagination(pagination)
        }
    },[pagination,isLoading])

  return(
      <>
              <div className={styles.messages__list__wrapper}>
                  <div ref={messageTopBoundaryRef}/>
                  {isLoading && <DefaultLoader/>}
                  {(!isLoading && messageThread?.data?.messageThread?.length>0) && messageThread?.data?.messageThread
                      .sort((a,b)=>new Date(a.date) - new Date(b.date))
                      .map(message => (
                          <Fragment key={nanoid()}>
                              <div className={styles.message__thread__outer} >
                                  <p className={styles.date__heading}>{new Date(message?.date).toDateString()}</p>
                                  {message?.messages
                                      .sort((a,b)=> new Date(a.createdAt) - new Date(b.createdAt))
                                      .map(innerEl => (
                                      <Fragment key={nanoid()}>
                                          <div className={`${styles.message__box__inner} ${userId === innerEl?.senderId && styles.message__right}`}>
                                              {(userId !== innerEl?.senderId && width > 991) &&
                                                  <UserProfilePicture profilePic={messageThread?.data?.senderData?.profilePicture}/>}
                                              <div className={styles.message}>
                                                  {!innerEl?.isMedia && <p className={styles.message__para}>{innerEl.content}</p>}
                                                  {(innerEl?.isMedia && !innerEl?.isVideo) && (
                                                      <img src={`${MEDIA_BASEURL}${innerEl.content}`} alt={"Message"}/>
                                                  )}
                                                  {(innerEl?.isMedia && innerEl?.isVideo) && (
                                                      <video width="750" height="500" controls >
                                                          <source src={`${MEDIA_BASEURL}${innerEl.content}`} type="video/mp4"/>
                                                      </video>
                                                  )}
                                                  <p className={styles.sent__time}>{new Date(innerEl.createdAt).toLocaleTimeString()}</p>
                                              </div>
                                          </div>
                                      </Fragment>
                                  ))}
                              </div>

                          </Fragment>
                      ))}

                  <div ref={threadRef} />
              </div>

      </>
  )
}
export default MessagesList
