import React, {useEffect, useState} from "react"
import styles from "../../../../../styles/listing.module.css";
import pdfIcon from "../../../../../assets/images/pdf-icon.png";
import mediaIcon from "../../../../../assets/images/media-icon.png";
import {formatBytes, stringTruncate} from "../../../../../utils/helpers";
import {RiDeleteBin5Line} from "react-icons/ri";
import {ProgressBar} from "react-bootstrap";
import ApplicationListingService from "../../../../../services/application-listing.service";
import {MEDIA_BASEURL} from "../../../../../utils/constants";
import useWindowSize from "../../../../../hooks/use-window-size";

const ViewMedia = ({media,isBinaryString,isNotDelete,isReset=false,resetMedia}) => {
    const {useHandleDeleteApplicationMediaService} = ApplicationListingService()

    const [mediaName,setMediaName] = useState("")
    const {width} = useWindowSize()
    const {mutate : deleteMedia, isLoading : isDeleteMediaLoading,isSuccess,reset} = useHandleDeleteApplicationMediaService(mediaName)
    const deleteFile = (name) => {
        setMediaName(name)
    }

    useEffect(()=>{
        if(mediaName){
            deleteMedia()
        }

    },[mediaName])

    useEffect(()=>{
        if(isSuccess){
            setMediaName("")
            reset()
        }
    },[isSuccess])
    return(
        <>
            <div className={styles.uploaded__file__list} title={"Click title to view file"}>
                <div className={styles.uploaded__file}>
                    <img src={media?.name?.split(".")[1] === "pdf" ? pdfIcon : mediaIcon} alt={media?.name?.split(".")[1] === "pdf" ? "Pdf" : "Media"}/>
                    <div className={styles.file__info}>
                        <div className={styles.media__detail}>
                            <div>
                                <a href={isBinaryString ? media?.file : `${MEDIA_BASEURL}${media?.name}`} download={isBinaryString} target={"_blank"}>{width > 767 ? media?.originalName : stringTruncate(media?.originalName,10)}</a>
                                <p>{`${formatBytes(media?.size)}`}</p>
                            </div>
                            {!isNotDelete && <button onClick={() => deleteFile(media?.name)} disabled={isDeleteMediaLoading}>
                                <RiDeleteBin5Line/></button>}
                            {isReset && <button onClick={() => resetMedia()}>
                                <RiDeleteBin5Line/></button>}
                        </div>
                        <div className={styles.progress__div}>
                            <div>
                                <ProgressBar now={100} animated/>
                            </div>
                            <span>100%</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewMedia
