import React, {useRef, useState} from "react"
import styles from "../../../../../styles/listing.module.css";
import UserProfilePicture from "../../../../common/user-profile-picture";
import {AiOutlineCheck,AiOutlineClose} from "react-icons/ai"
import {BsThreeDotsVertical} from "react-icons/bs";
import {APPLICATION_STATUS} from "../../../../../utils/constants/application-listing";
import TokenService from "../../../../../services/token.service";
import {IoMailUnreadOutline} from "react-icons/io5";
import CreditScoreContainer from "../../../../common/container/credit-score-container";
import {APPLICANT_DECISION} from "../../../../../utils/constants/listing";
import ProgressBar from "react-bootstrap/ProgressBar";
import {commaToPrice} from "../../../../../utils/helpers";
import useOutsideAlerter from "../../../../../hooks/use-outside-alerter";
import {useNavigate} from "react-router";

const ApplicantsRow = ({applicants,index,applicantDecisionConfirmation,viewApplication,unAcceptApplication,unDenyApplication,isOneApplicantAccepted,isBlur}) => {
    const [showDropDown,setShowDropDown] = useState(false)
    const isVerified = TokenService.getUser()?.isVerified
    const wrapperRef = useRef(null)
    const navigate = useNavigate()
    const outSideClickCallback = () => {
        setShowDropDown(false)
    }
    useOutsideAlerter(wrapperRef,outSideClickCallback)

    const handleDropDown = (i) => {
        if(i===index){
            setShowDropDown(!showDropDown)
        }
    }

    const isThisApplicantNotAccepted = (applicants?.status !== APPLICATION_STATUS.ACCEPTED && isOneApplicantAccepted) || applicants?.status === APPLICATION_STATUS.REJECTED

    return(
        <>
            <tr className={isBlur ? styles.blur__table : ''}>
                <td className={isThisApplicantNotAccepted ? styles.blur__box : ''}>
                    <div className={styles.user__info}>
                        <UserProfilePicture profilePic={applicants?.renter?.profilePicture}/>
                        <p>{`${applicants?.renter?.firstName} ${applicants?.renter?.lastName}`}</p>
                    </div>
                </td>
                <td className={isThisApplicantNotAccepted ? styles.blur__box : ''}>
                    {applicants?.status !== APPLICATION_STATUS.INVITED && <CreditScoreContainer
                        score={applicants?.creditScore}
                        scoreType={applicants?.scoreType}
                    />}
                </td>
                <td className={isThisApplicantNotAccepted ? styles.blur__box : ''}>
                    {applicants?.status !== APPLICATION_STATUS.INVITED && <div className={`${styles.rti__progress} ${applicants?.rti > 100 ? styles.overloaded__rti__progress : ''}`}>
                        <ProgressBar className={styles.rti__progress__bar} now={applicants?.rti > 100 ? '100' : applicants?.rti}/>
                        <p>{applicants?.rti > 100 ? '100%' : `${parseFloat(applicants?.rti).toFixed(2)}%`}</p>
                    </div>}
                </td>
                <td className={isThisApplicantNotAccepted ? styles.blur__box : ''}>
                    {applicants?.status !== APPLICATION_STATUS.INVITED &&
                        <p className={styles.row__text}>{`$${commaToPrice(applicants?.annualIncome)}`}
                        </p>}
                </td>
                <td className={isThisApplicantNotAccepted ? styles.blur__box : ''}>
                    {applicants?.status !== APPLICATION_STATUS.INVITED &&
                        <p className={styles.row__text}>{`${applicants?.adults?.length+1} Adults${applicants?.dependents ? `, ${applicants?.dependents} Children` : ''}`}</p>
                    }
                </td>
                <td>
                    <div className={styles.decision__button__wrapper}>

                        {applicants?.status === APPLICATION_STATUS.SUBMITTED && (
                            <>
                                <button
                                    type={"button"}
                                    className={styles.decision__buttons}
                                    onClick={isVerified ? () => applicantDecisionConfirmation({
                                        status:APPLICANT_DECISION.ACCEPTED,
                                        applicantId : applicants?._id
                                    },true) : () => {}}>
                                    <AiOutlineCheck/>
                                </button>
                                <button
                                    type={"button"}
                                    className={styles.decision__buttons}
                                    onClick={isVerified ? () => applicantDecisionConfirmation({
                                        status:APPLICANT_DECISION.REJECTED,
                                        applicantId:applicants?._id
                                    },true) : () => {}}>
                                    <AiOutlineClose/>
                                </button>
                            </>
                        )}
                        {applicants?.status === APPLICATION_STATUS.ACCEPTED && (
                            <>
                                <button type={"button"} className={styles.accepted__button}>Accepted</button>
                            </>
                        )}
                        {applicants?.status === APPLICATION_STATUS.REJECTED && (
                            <>
                                <button type={"button"} className={styles.denied__button}>Denied</button>
                            </>
                        )}
                        {applicants?.status === APPLICATION_STATUS.INVITED && (
                            <>
                                <div className={`${styles.status} ${styles.invited}`}>
                                    <IoMailUnreadOutline/>
                                </div>
                                <div className={`${styles.status__text}`}>
                                    <p>Invited</p>
                                </div>
                            </>
                        )}
                    </div>
                </td>
                <td>
                    <div className={styles.menu__dropdown}>
                        <div className={styles.menu__dropdown__inner} ref={wrapperRef}>
                            <button className={styles.drop__down__button} onClick={() => handleDropDown(index)} title={"More Actions"}>
                                <BsThreeDotsVertical/>
                            </button>
                            {(showDropDown && isVerified) && <div className={styles.drop__down}>
                                <ul>
                                    <li onClick={() => navigate("/message")}>
                                        Message
                                    </li>
                                    {applicants?.status !== APPLICATION_STATUS.INVITED && <li onClick={isVerified ? () => viewApplication(applicants?._id, true) : () => {}}>
                                        View Application
                                    </li>}
                                    {(applicants?.status === APPLICATION_STATUS.ACCEPTED) && <li onClick={() => unAcceptApplication(true)}>
                                        Un-Accept
                                    </li>}
                                    {(applicants?.status === APPLICATION_STATUS.REJECTED) && <li onClick={() => unDenyApplication(true,applicants?._id)}>
                                        Un-Deny
                                    </li>}
                                </ul>
                            </div>}
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}
export default ApplicantsRow
