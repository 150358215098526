import React, {Fragment} from "react"
import {Table} from "react-bootstrap";
import styles from "../../../styles/common/tables.module.css"
import propTypes from "prop-types";

const BaseTable = ({headerList,children}) =>{
    // const [bodyKeys,setBodyKeys] = useState([])
    //
    // useEffect(()=>{
    //     //Fetching keys from objects inside array
    //         //and making a new array of arrays with keys as strings ([ [key0,key1],[key0,key1] ])
    //     setBodyKeys(bodyData.map((data)=>Object.keys(data)))
    // },[])
    return(
        <>
            <div className={styles.base__tables__wrapper}>
                {/*&& !bodyKeys.length > 0*/}

                <Table responsive>
                    <thead>
                        <tr>
                            {headerList.map((header,index)=>{
                                return(
                                    <Fragment key={`${header}_${index}`}>
                                        <th>{header}</th>
                                    </Fragment>
                                )
                            })}
                        </tr>
                    </thead>
                    {/*<tbody>*/}
                    {/*    {(!isLoading && bodyData?.length > 0 && bodyKeys?.length > 0) && bodyData.map((body,i)=>{*/}
                    {/*      return(*/}
                    {/*          <Fragment key={body._id}>*/}
                    {/*              <tr>*/}
                    {/*                  /!*Accessing the sub-array inside the main key array*!/*/}
                    {/*                  /!*The bodyData length will*/}
                    {/*                    always be same as the bodyKeys array so the same index can be worked with*!/*/}
                    {/*                  {bodyKeys[i].map((key)=>{*/}
                    {/*                      return(*/}
                    {/*                          <Fragment key={`${body._id}_${key}`}>*/}
                    {/*                              /!*We now have the key in each iteration*!/*/}
                    {/*                              <td>{body[key]}</td>*/}
                    {/*                          </Fragment>*/}
                    {/*                      )*/}
                    {/*                  })}*/}
                    {/*              </tr>*/}
                    {/*          </Fragment>*/}
                    {/*      )*/}
                    {/*    })}*/}
                    {/*</tbody>*/}
                    {children}
                </Table>
            </div>
        </>
    )
}
export default BaseTable

BaseTable.propType = {
    headerList : propTypes.array.isRequired,
    bodyData : propTypes.array.isRequired,
    isLoading : propTypes.bool.isRequired
}
