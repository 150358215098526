import React from "react"
import styles from "../../../../../styles/contract.module.css";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";
import DefaultLoader from "../../../../common/loaders/default-loader";

const EditContractApplicants = ({onEditApplicantCard,fields,onSaveEditData,isLoading}) => {
    const {details,inputEvent} = useForm(fields)

    return(
        <>
            <Form className={styles.form__group__wrapper} onSubmit={(e)=>onSaveEditData(e,details.email)}>
                <Form.Group className={styles.form__group__wrapper}>
                    <label className={styles.label}>Edit {fields.name}'s Email</label>
                    <input type={"email"} required name={"email"} value={details.email} onChange={inputEvent} placeholder={"Edit Email"}/>
                </Form.Group>
                <div className={styles.contract__button__wrapper}>
                    {!isLoading && (
                        <>
                            <button type={"button"} onClick={() => onEditApplicantCard({}, false)} className={styles.prev__btn}>
                                Cancel
                            </button>
                            <button type={"submit"} className={styles.next__btn}>
                                Save
                            </button>
                        </>
                    )}
                    {isLoading && <DefaultLoader/>}
                </div>
            </Form>
        </>
    )
}
export default EditContractApplicants
