import React from "react";
import propTypes from "prop-types";
import styles from "../../../styles/common/text.module.css"

const Title = ({title}) => {
    return(
        <>
            <div className={styles.title}>
                <h2>{title}</h2>
            </div>
        </>
    )
}
export default Title
Title.propType = {
    title : propTypes.string.isRequired
}