import React, {useCallback, useEffect, useRef, useState} from "react"
import {useLocation} from "react-router";

const useApiPager = (bottomBoundaryRef) => {
    const [pagination,setPagination] = useState(1)
    const [isReset,setIsReset] = useState(false)
    const location = useLocation()
    const initialRender = useRef(false)
    // implement infinite scrolling with intersection observer
    const resetPagination = (resetPage = 0) => {
        console.log("reseting")
        setPagination(resetPage)
        setIsReset(true)
    }
    useEffect(()=>{
        //Reset the pagination on page change
        setPagination(1)
    },[location.pathname])

    console.log(pagination,"pagination 2")
    const scrollObserver = useCallback(
        node => {

            new IntersectionObserver(entries => {

                entries.forEach(en => {
                    if (en.isIntersecting && !isReset) {
                        setPagination((no) => no + 1);
                    }

                });
            }).observe(node);
        },
        []
    );
    useEffect(() => {
        if ((bottomBoundaryRef.current && !isReset)) {
            scrollObserver(bottomBoundaryRef.current);
        }else{
            setIsReset(false)
        }
    }, [scrollObserver, bottomBoundaryRef]);

    return [pagination,resetPagination]
}
export default useApiPager
