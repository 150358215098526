import React, {useState, useEffect, useRef, useContext} from "react"
import {Container, Navbar, Nav, DropdownButton, Dropdown} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import styles from "../../styles/dashboard.module.css"
import WebsiteLogo from "./website-logo";
import {IoMdNotificationsOutline} from "react-icons/io"
import UserProfilePicture from "../common/user-profile-picture";
import TokenService from "../../services/token.service";
import {ROLES} from "../../utils/constants";
import AuthService from "../../services/auth.service";
import MenuList from "./menu-list";
import {useLocation} from "react-router";
import {SocketInstanceContext} from "../../../App";
import {FcMenu} from "react-icons/fc"
import {BiLogOut} from "react-icons/bi"
import {AiFillSetting,AiOutlineClose} from "react-icons/ai"
import useWindowSize from "../../hooks/use-window-size";

const DashboardNavbar = () => {
    const userData = TokenService.getUser()
    const {useHandleLogOutService} = AuthService()
    const socketInstance = useContext(SocketInstanceContext)
    const {isLoading,mutate} = useHandleLogOutService(socketInstance)
    const location = useLocation()
    const {width} = useWindowSize()
    const [isMenuOpen,setIsMenuOpen] = useState(false)

    useEffect(()=>{
        setIsMenuOpen(false)
    },[location?.key])


    return(
        <>
            <section className={styles.home__navbar__wrapper}>
                <Navbar collapseOnSelect expand="lg" className={styles.dashboard__navbar}>
                    <Container>
                        <NavLink to={`${userData?.role === ROLES.OWNER ? "/home" : userData?.role === ROLES.RENTER ? "/application" : "/login"}`} className={styles.nav__logo}><WebsiteLogo/></NavLink>
                        {width > 991 && (
                            <>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse className={styles.navbar__content} id="responsive-navbar-nav">
                                    <Nav className={`m-auto ${styles.pages}`}>
                                        <MenuList/>
                                    </Nav>
                                    {userData?._id && <Nav className={styles.user__bar}>
                                        {/*<button className={styles.notification}><IoMdNotificationsOutline/></button>*/}
                                        <div className={styles.user__information__wrapper}>
                                            <div className={styles.user__info}>
                                                <UserProfilePicture profilePic={userData?.profilePicture}/>
                                                <p>{userData?.firstName} {userData?.lastName}</p>
                                            </div>
                                            <DropdownButton className={`${styles.drop__down} dashboard__navbar__menu__dropdown`}
                                                            align="end" title={""}>
                                                <Dropdown.Item as="button" className={styles.menu}>
                                                    <NavLink
                                                        to={`/${userData?.role.toLowerCase()}-account-settings/account-details`}
                                                        className={({isActive}) => isActive ? styles.active : ""}>Account
                                                        Settings</NavLink>
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" disabled={isLoading} onClick={mutate}
                                                               className={styles.logoutButton}>Logout</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </Nav>}
                                </Navbar.Collapse>
                            </>
                        )}
                        {width <= 991 && (
                            <>
                                <button className={styles.hamburger__menu__button} onClick={()=>setIsMenuOpen(prev => !prev)}>
                                    <FcMenu/>
                                </button>
                                {isMenuOpen && <div className={styles.mobile__menu__wrapper}>
                                    <button type={"button"} className={styles.close__button}  onClick={()=>setIsMenuOpen(prev => !prev)}>
                                        <AiOutlineClose/>
                                    </button>
                                    <div className={styles.mobile__menu__inner__wrapper}>
                                        <div className={styles.mobile__menu}>
                                            <div className={styles.menu__top}>
                                                <NavLink
                                                    to={`${userData?.role === ROLES.OWNER ? "/home" : userData?.role === ROLES.RENTER ? "/application" : "/login"}`}
                                                    className={styles.nav__logo}><WebsiteLogo/></NavLink>
                                                <div className={styles.mobile__profile}>
                                                    {/*<button className={styles.notification}><IoMdNotificationsOutline/></button>*/}
                                                    <div className={styles.user__info}>
                                                        <UserProfilePicture profilePic={userData?.profilePicture}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.mobile__menu__list}>
                                                <MenuList isMobile/>
                                            </div>
                                        </div>

                                        <div className={styles.mobile__bottom__menu}>
                                            <NavLink
                                                to={`/${userData?.role.toLowerCase()}-account-settings/account-details`}
                                                className={({isActive}) => isActive ? styles.active : ""}>
                                                <AiFillSetting/>
                                                Account Settings
                                            </NavLink>
                                            <button type={"button"} disabled={isLoading} onClick={mutate} className={styles.logoutButton}>
                                                <BiLogOut/>
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </>
                        )}

                    </Container>
                </Navbar>
            </section>
        </>
    )
}
export default DashboardNavbar;
