import React from "react"
import styles from "../../../styles/account-settings.module.css"
import {FORM_TYPE} from "../../../utils/constants";

const NotificationsSettings = ({inputEvent,details}) => {
    return(
        <>
            <div className={`${styles.form__check__wrapper}`}>
                <div className={`form-check`}>
                    <label htmlFor="notification">
                        <input
                            type="checkbox"
                            onChange={(e)=>inputEvent(e,FORM_TYPE.CHECKBOX)}
                            name="isSubscribedForMarketing"
                            checked={details.isSubscribedForMarketing}
                            className="form-check-input"
                            id="notification"
                        />
                        {/*Send me genuinely useful emails every now and then to help me get the most out of renloc*/}
                        I agree to also receive marketing communications from Renloc
                    </label>
                </div>
            </div>
        </>
    )
}
export default NotificationsSettings;
