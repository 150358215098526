import React, {Fragment, useEffect, useState} from "react"
import styles from "../../../../../styles/listing.module.css";
import {useNavigate, useParams} from "react-router";
import BaseTable from "../../../../common/tables/base-table";
import BaseSearch from "../../../../common/form/base-search";
import useForm from "../../../../../hooks/use-form";
import EmptyContainer from "../../../../common/container/empty-container";
import VerificationWarning from "../../../../common/container/verification-warning";
import UnitListingService from "../../../../../services/unit-listing.service";
import TokenService from "../../../../../services/token.service";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import ApplicantsRow from "../../../../presentational/listing/single-unit/applicants/applicants-row";
import {APPLICATION_STATUS} from "../../../../../utils/constants/application-listing";
import PrimaryModal from "../../../../common/modals/primary-modal";
import InfoModal from "../../../../common/modals/info-modal";
import {APPLICANT_DECISION} from "../../../../../utils/constants/listing";
import {debounce} from "../../../../../utils/helpers";
import DefaultLoader from "../../../../common/loaders/default-loader";
import ContentModal from "../../../../common/modals/content-modal";
import ApplicationListingService from "../../../../../services/application-listing.service";

import ApplicationReportBody from "../../../../presentational/listing/single-unit/applicants/application-report-body";
import UserService from "../../../../../services/user.service";
import UnitTitle from "../../../../common/text/unit-title";
import ApplicantsMobileTable from "../../../../presentational/listing/single-unit/applicants/applicants-mobile-table";
import useWindowSize from "../../../../../hooks/use-window-size";

const ApplicationReportHeader = React.lazy(()=>import('../../../../presentational/listing/single-unit/applicants/application-report-header'))

const headerTitles = [
    'Applicant',
    'Credit Score',
    'RTI Ratio',
    'Household Income',
    'Occupants',
    'Decision',
    'Report'
]
const fields = {
    search : ""
}
const SingleUnitApplicants = ({unitData}) => {
    const navigate = useNavigate()
    const {details,inputEvent} = useForm(fields)
    const {unitId} = useParams()
    const {width} = useWindowSize()
    const isUserVerified = TokenService.getUser()?.isVerified

    const {useFetchListingApplicants,useHandleApplicantsDecision,useHandleUnAcceptApplicant,useHandleUnDenyApplicant} = UnitListingService()
    const {useFetchApplicationByApplicantId} = ApplicationListingService()
    const {useFetchUserByIdService} = UserService()

    const [page,setPage] = useState(1)
    const [search,setSearch] = useState('')
    const [isOneApplicantAccepted,setIsOneApplicantAccepted] = useState(false)
    const [isDecisionConfirmationOpen,setIsDecisionConfirmationOpen] = useState({info:false,warning:false})
    const [applicantDecision,setApplicantDecision] = useState({status:0,applicantId:''})

    const [isUnAcceptModalOpen,setIsUnAcceptModalOpen] = useState(false)
    const [isUnDenyModalOpen,setIsUnDenyModalOpen] = useState({isModal:false,applicantId:""})

    const [isViewAppModalOpen,setIsViewAppModalOpen] = useState(false)
    const [viewApplicantId,setViewApplicantId] = useState('')

    //Fetch Applicants
    const {data : listingApplicants, isLoading,isPreviousData,isSuccess : fetchListingSuccess} = useFetchListingApplicants(search,page,5,unitId)
    //Handle Applicants Decision Yes/No
    const {isLoading : isApplicantDecisionLoading,mutate : handleApplicantsDecision,isSuccess : applicantsDecisionSuccess, reset : applicantsDecisionReset} = useHandleApplicantsDecision(applicantDecision,page,search)
    //Un-Accept Applicant
    const {isLoading : isUnAcceptApplicantLoading,mutate : handleUnAcceptApplicant,isSuccess : unAcceptApplicantSuccess,reset : unAcceptReset} = useHandleUnAcceptApplicant(page,search)
    //Un-Deny Applicant
    const {isLoading : isUnDenyApplicantLoading,mutate : handleUnDenyApplicant,isSuccess : unDenyApplicantSuccess,reset : unDenyReset} = useHandleUnDenyApplicant(page,search,isUnDenyModalOpen.applicantId)
    //Fetch Single Application
    const {isLoading : isViewApplicationLoading,data : applicationData} = useFetchApplicationByApplicantId(viewApplicantId)
    //Fetch User By ID (To Check If User Is Still Verified Or Not)
    const {isLoading : isGetUserByIdLoading} = useFetchUserByIdService(!isUserVerified)

    //Searching Applicants
    const searchApplicants = (e) => {
        e.preventDefault()
    }
    const handleChange = debounce(() => {
        setSearch(details.search)
    }, 1000)
    useEffect(()=>{
        handleChange()
    },[details.search])


    //Applicant Decision (Accept / Reject Applicant)
    const applicantDecisionConfirmation = (decision,isModal) => {
        setIsDecisionConfirmationOpen({
            warning : isModal && (decision?.status === APPLICANT_DECISION.REJECTED),
            info : isModal && (decision?.status === APPLICANT_DECISION.ACCEPTED),
        })
        setApplicantDecision(decision)
    }

    // View Applicant's Application
    const viewApplication = (applicantId,isModalOpen) => {
        setIsViewAppModalOpen(isModalOpen)
        setViewApplicantId(applicantId)
    }

    //Un-Accept Accepted Applicant
    const unAcceptApplicationConfirmation = (isModal) => {
        setIsUnAcceptModalOpen(isModal)
    }

    //Un-Deny Denied Applicant
    const unDenyApplicationConfirmation = (isModal,applicantId) => {
        setIsUnDenyModalOpen({isModal,applicantId})
    }

    useEffect(()=>{
        if(fetchListingSuccess){
            if(listingApplicants?.total > 0){
                //Checking if one of the applicants is 'Accepted' or not (to blur the rest of the applicants row)
                setIsOneApplicantAccepted(listingApplicants?.data?.filter((applicant)=>applicant?.status === APPLICATION_STATUS.ACCEPTED)?.length > 0)
            }
        }
    },[fetchListingSuccess])
    return(
        <>
            <div className={styles.single__unit__applicants__wrapper}>
                {isUserVerified && <BaseSearch
                    inputEvent={inputEvent}
                    submitCallback={searchApplicants}
                    extraClass={"base__search__wrapper__alternative__bg"}
                />}
                {!isUserVerified && <VerificationWarning
                    tooltipDescription='To abide by credit compliance and government regulations,
                            we use Persona Identities Inc. to verify your identity.
                            We do this for security and anti-fraud purposes. '
                    text='To gain access to tenants’ reports, we need to verify your identity, please click here.'
                    clickHandlerCallback={() => navigate('/owner-account-settings/account-details')}
                />}
                {(isLoading || isGetUserByIdLoading) && <div style={{paddingBottom:"10px"}}><DefaultLoader/></div>}
                {!(isLoading || isGetUserByIdLoading) && (
                    <>
                        {/*${(!isUserVerified && listingApplicants?.total > 0) ? styles.blur__table : ''}*/}
                        <div className={`${styles.table__wrapper}`}>
                            {width > 991 && <BaseTable headerList={headerTitles}>
                                {listingApplicants?.total > 0 && <tbody>

                                {listingApplicants?.data?.map((applicants,index) => {
                                    return (
                                        <Fragment key={applicants?._id}>
                                            <ApplicantsRow
                                                applicants={applicants}
                                                index={index}
                                                applicantDecisionConfirmation={applicantDecisionConfirmation}
                                                viewApplication={viewApplication}
                                                unAcceptApplication={unAcceptApplicationConfirmation}
                                                unDenyApplication={unDenyApplicationConfirmation}
                                                isOneApplicantAccepted={isOneApplicantAccepted}
                                                isBlur={!isUserVerified}
                                            />
                                        </Fragment>
                                    )
                                })}

                                </tbody>}
                            </BaseTable>}
                            {(width <= 991 && listingApplicants?.total > 0) && <ApplicantsMobileTable
                                listingApplicants={listingApplicants}
                                applicantDecisionConfirmation={applicantDecisionConfirmation}
                                viewApplication={viewApplication}
                                unAcceptApplication={unAcceptApplicationConfirmation}
                                unDenyApplication={unDenyApplicationConfirmation}
                                isOneApplicantAccepted={isOneApplicantAccepted}
                                isBlur={!isUserVerified}
                            />}
                            {/*<--TABLE FOOTER-->*/}
                            {(listingApplicants?.data?.length > 0 && isUserVerified) && <section className={styles.unit__listing__footer}>
                                <button
                                    disabled={page === 1}
                                    onClick={() => setPage(prevValue => prevValue-1)}>

                                    <IoIosArrowBack/>
                                </button>
                                <button
                                    disabled={isPreviousData || (listingApplicants?.page === listingApplicants?.lastPage)}
                                    onClick={() => setPage(prevValue => prevValue+1)}>
                                    <IoIosArrowForward/>
                                </button>
                            </section>}
                            {/*<--EMPTY TABLE CONTAINER-->*/}
                            {listingApplicants?.total <= 0 &&
                                <EmptyContainer
                                    title={'No Applicants Yet'}
                                    description={'Choose your perfect tenant by screening applicants and making an informed decision!'}
                                />}
                        </div>
                        {(isDecisionConfirmationOpen.warning) &&
                            <PrimaryModal
                                size={"lg"}
                                customClass={"half__modal"}
                                isOpen={isDecisionConfirmationOpen.warning}
                                modalContent={
                                    <InfoModal
                                        handleModalClose={()=>applicantDecisionConfirmation({status:0,applicant:''},false)}
                                        isLoading={isApplicantDecisionLoading}
                                        mutate={handleApplicantsDecision}
                                        isSuccess={applicantsDecisionSuccess}
                                        reset={applicantsDecisionReset}
                                        title={"Deny Applicant"}
                                        description={`Are you sure you want to reject this applicant?`}
                                        confirmButtonText={"Deny"}
                                    />}
                            />
                        }
                        {(isDecisionConfirmationOpen.info) &&
                            <PrimaryModal
                                size={"lg"}
                                customClass={"half__modal"}
                                isOpen={isDecisionConfirmationOpen.info}
                                modalContent={
                                    <InfoModal
                                        handleModalClose={()=>applicantDecisionConfirmation({status:0,applicant:''},false)}
                                        isLoading={isApplicantDecisionLoading}
                                        mutate={handleApplicantsDecision}
                                        isSuccess={applicantsDecisionSuccess}
                                        title={"Accept Applicant"}
                                        description={`Are you sure you want to accept this applicant?`}
                                        confirmButtonText={"Confirm"}
                                        reset={applicantsDecisionReset}
                                        isConfirmationModal
                                    />}
                            />
                        }
                        {isUnAcceptModalOpen &&
                            <PrimaryModal
                                size={"lg"}
                                customClass={"half__modal"}
                                isOpen={isUnAcceptModalOpen}
                                modalContent={
                                    <InfoModal
                                        handleModalClose={()=>unAcceptApplicationConfirmation(false)}
                                        isLoading={isUnAcceptApplicantLoading}
                                        mutate={handleUnAcceptApplicant}
                                        isSuccess={unAcceptApplicantSuccess}
                                        title={"Un-Accept Applicant"}
                                        description={`Are you sure you want to un-accept this applicant?`}
                                        confirmButtonText={"Confirm"}
                                        reset={unAcceptReset}
                                    />}
                            />
                        }
                        {isUnDenyModalOpen.isModal &&
                            <PrimaryModal
                                size={"lg"}
                                customClass={"half__modal"}
                                isOpen={isUnDenyModalOpen.isModal}
                                modalContent={
                                    <InfoModal
                                        handleModalClose={()=>unDenyApplicationConfirmation(false,'')}
                                        isLoading={isUnDenyApplicantLoading}
                                        mutate={handleUnDenyApplicant}
                                        isSuccess={unDenyApplicantSuccess}
                                        title={"Un-Deny Applicant"}
                                        description={`Are you sure you want to un-deny this applicant?`}
                                        confirmButtonText={"Confirm"}
                                        reset={unDenyReset}
                                    />}
                            />
                        }

                        {isViewAppModalOpen && (
                            <>
                                <ContentModal
                                    size={"lg"}
                                    isOpen={isViewAppModalOpen}
                                >
                                    <ApplicationReportHeader applicationData={applicationData} handleClose={()=>viewApplication('',false)}/>

                                    <ApplicationReportBody isLoading={isViewApplicationLoading} applicationData={applicationData}/>
                                </ContentModal>
                            </>
                        )}
                    </>
                )}

            </div>
        </>
    )
}
export default SingleUnitApplicants
