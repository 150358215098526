import React, {Fragment} from "react"
import DefaultLoader from "../../common/loaders/default-loader";
import styles from "../../../styles/viewing.module.css";
import ViewingCard from "../../common/cards/viewing-card";

const ViewingsList = ({isLoading,viewingsData,handleConfirmDeleteViewing}) => {
  return(
      <>
          {isLoading && <DefaultLoader/>}
          {!isLoading && (
              <>
                  {viewingsData?.map(el => (
                      <Fragment key={el?._id}>
                          <div className={styles.viewings__list}>
                              <p className={styles.date}>
                                  {new Date(el?._id).getDate() === new Date().getDate() && <span>{`Today - `}</span>}
                                  {new Date(el?._id).toDateString()}
                              </p>
                              <div className={styles.viewings}>
                                  {el?.events.map(viewing => (
                                      <Fragment key={viewing?._id}>
                                          <ViewingCard
                                              startTime={viewing?.startDateTime}
                                              endTime={viewing?.endDateTime}
                                              applicantName={`${viewing?.renter?.firstName} ${viewing?.renter?.lastName}`}
                                              deleteHandler={()=>handleConfirmDeleteViewing(true,viewing?._id)}
                                              unitName={viewing?.listing?.name}
                                          />
                                      </Fragment>
                                  ))}
                              </div>
                          </div>
                      </Fragment>
                  ))
                  }
              </>
          )}
      </>
  )
}
export default ViewingsList
