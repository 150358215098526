import React from 'react'
import styles from "../../../styles/common/container.module.css"
import propTypes from "prop-types";
import ButtonLoader from "../loaders/button-loader";

const SaveCardContainer = ({text,buttonText,isLoading}) => {
    return(
        <>
            <section className={styles.save__card__box__wrapper}>
                <p>{text}</p>
                {isLoading ? <ButtonLoader/> :  <button type={"submit"}>{buttonText}</button>}
            </section>
        </>
    )
}
export default SaveCardContainer;

SaveCardContainer.propType = {
    text : propTypes.string.isRequired,
    buttonText : propTypes.string.isRequired,
    isLoading : propTypes.bool.isRequired
}
