import React from 'react';
import {TextMode} from "../../../../utils/constants/contract";
import styles from "../../../../styles/contract.module.css"
import {AiOutlineClose} from "react-icons/ai"


export const TextInput = ({
  text,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
    colorTheme,
  positionTop,
  positionLeft,
  onChangeText,
  toggleEditMode,
  handleMouseDown,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  lineHeight,
  removeAttachment,
  isEditable,
    id
}) => {
  return (
      <>
          {!!isEditable && (
          <div
              style={{
                  width,
                  height,
                  lineHeight,

                  top: positionTop,
                  left: positionLeft,
                  wordWrap: 'break-word',
                  padding: 0,
                  position: 'absolute',
              }}
          >
              <input
                  type="text"
                  ref={inputRef}
                  className={`${styles.disabled__pdf__input} ${styles.disabled__pdf__input__color}`}
                  onChange={onChangeText}
                  style={{
                      height,
                      fontSize:size,
                  }}
                  placeholder={"Enter Text..."}
                  value={text}
              />
          </div>)}
          {!isEditable && (<div
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseOut={handleMouseOut}
              onDoubleClick={toggleEditMode}
              id={id}
              style={{
                  width,
                  cursor: mode === TextMode.COMMAND ? 'move' : 'default',
                  top: positionTop,
                  left: positionLeft,
                  padding: 0,
                  position: 'absolute',
              }}
          >
              <input
                  type="text"
                  ref={inputRef}
                  disabled
                  onChange={onChangeText}
                  readOnly
                  style={{
                    cursor: mode === TextMode.COMMAND ? 'move' : 'text',
                    border:`1px solid ${colorTheme}`,
                    color:colorTheme,
                    fontSize:size,
                  }}
                  className={styles.disabled__pdf__input}
                  value={text}
              />
              <button type={"button"} onClick={removeAttachment} className={styles.remove__pdf__field}>
                <AiOutlineClose/>
              </button>
          </div>)}
      </>

  );
};
