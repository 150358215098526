import React, {Fragment, useRef, useState} from "react"
import MobileTable from "../../../../common/tables/mobile-table";
import {nanoid} from "nanoid";
import tableStyles from "../../../../../styles/common/tables.module.css"
import styles from "../../../../../styles/listing.module.css";
import {BsThreeDotsVertical} from "react-icons/bs";
import useOutsideAlerter from "../../../../../hooks/use-outside-alerter";
import {useNavigate} from "react-router";
import TokenService from "../../../../../services/token.service";
import {APPLICATION_STATUS} from "../../../../../utils/constants/application-listing";
import CreditScoreContainer from "../../../../common/container/credit-score-container";
import ProgressBar from "react-bootstrap/ProgressBar";
import {commaToPrice} from "../../../../../utils/helpers";
import {APPLICANT_DECISION} from "../../../../../utils/constants/listing";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {IoMailUnreadOutline} from "react-icons/io5";

const ApplicantsMobileTable = ({listingApplicants,applicantDecisionConfirmation,isOneApplicantAccepted,unDenyApplication,viewApplication,unAcceptApplication,isBlur}) => {
    const [showDropDown,setShowDropDown] = useState(false)
    const wrapperRef = useRef(null)
    const isVerified = TokenService.getUser()?.isVerified
    const navigate = useNavigate()

    const outSideClickCallback = () => {
        setShowDropDown(-1)
    }
    useOutsideAlerter(wrapperRef,outSideClickCallback)

    const handleDropDown = (index) => {
        setShowDropDown(prev => prev !== index ? index : -1)
    }

    const mutatedListingApplicantsData = listingApplicants?.data?.map(el => ({
        applicant:{
            title:"Applicant",
            name:`${el?.renter?.firstName} ${el?.renter?.lastName}`
        },
        creditScore:{
            title:"Credit Score",
            creditScore:el?.creditScore,
            scoreType:el?.scoreType
        },
        rtiScore:{
            title:"RTI Ratio",
            rti:el?.rti
        },
        income:{
            title:"Household Income",
            annualIncome:el?.annualIncome
        },
        occupants:{
            title:"Occupants",
            dependents:el?.dependents,
            adults:el?.adults

        },
        status:el?.status,
        listingId:el?._id
    }))

    const isThisApplicantNotAccepted = (status) => (status !== APPLICATION_STATUS.ACCEPTED && isOneApplicantAccepted) || status === APPLICATION_STATUS.REJECTED


    return(
        <>
            <div className={styles.applicants__mobile__table__wrapper}>
                <MobileTable>
                    <div className={tableStyles.mobile__table__card__wrapper}>
                        {mutatedListingApplicantsData?.map((el,index)=>(
                            <Fragment key={nanoid()}>
                                <div className={`${!isVerified ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card}`}>
                                    <div className={tableStyles.menu__dropdown}>
                                        <div className={tableStyles.menu__dropdown__inner} >
                                            <button className={tableStyles.drop__down__button} onClick={() => handleDropDown(index)} title={"More Actions"}>
                                                <BsThreeDotsVertical/>
                                            </button>
                                            {(showDropDown === index && isVerified) && <div className={tableStyles.drop__down} ref={wrapperRef}>
                                                <ul>
                                                    <li onClick={() => navigate("/message")}>
                                                        Message
                                                    </li>
                                                    {el?.status !== APPLICATION_STATUS.INVITED && <li onClick={isVerified ? () => viewApplication(el?.listingId, true) : () => {}}>
                                                        View Application
                                                    </li>}
                                                    {(el?.status === APPLICATION_STATUS.ACCEPTED) && <li onClick={() => unAcceptApplication(true)}>
                                                        Un-Accept
                                                    </li>}
                                                    {(el?.status === APPLICATION_STATUS.REJECTED) && <li onClick={() => unDenyApplication(true,el?.listingId)}>
                                                        Un-Deny
                                                    </li>}
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className={`${isThisApplicantNotAccepted(el?.status) ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card__content}`}>
                                        <h5>{el?.applicant?.title}</h5>
                                        <p>{el?.applicant?.name}</p>
                                    </div>
                                    {el?.status !== APPLICATION_STATUS.INVITED && <div className={`${isThisApplicantNotAccepted(el?.status) ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card__content}`}>
                                        <h5>{el?.creditScore?.title}</h5>
                                        <CreditScoreContainer
                                            score={el?.creditScore?.creditScore}
                                            scoreType={el?.creditScore?.scoreType}
                                        />
                                    </div>}
                                    {el?.status !== APPLICATION_STATUS.INVITED && <div className={`${isThisApplicantNotAccepted(el?.status) ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card__content}`}>
                                        <h5>{el?.rtiScore?.title}</h5>
                                        <div className={`${tableStyles.rti__progress} ${el?.rtiScore?.rti > 100 ? tableStyles.overloaded__rti__progress : ''}`}>
                                            <ProgressBar className={tableStyles.rti__progress__bar} now={el?.rtiScore?.rti > 100 ? '100' : el?.rtiScore?.rti}/>
                                            <p>{el?.rtiScore?.rti > 100 ? '100%' : `${parseFloat(el?.rtiScore?.rti).toFixed(2)}%`}</p>
                                        </div>
                                    </div>}
                                    {el?.status !== APPLICATION_STATUS.INVITED && <div className={`${isThisApplicantNotAccepted(el?.status) ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card__content}`}>
                                        <h5>{el?.income?.title}</h5>
                                        <p>{`$${commaToPrice(el?.income?.annualIncome)}`}</p>
                                    </div>}
                                    {el?.status !== APPLICATION_STATUS.INVITED && <div className={`${isThisApplicantNotAccepted(el?.status) ? tableStyles.blur__table : ""} ${tableStyles.mobile__table__card__content}`}>
                                        <h5>{el?.occupants?.title}</h5>
                                        <p>{`${el?.occupants?.adults?.length+1} Adults${el?.occupants?.dependents ? `, ${el?.occupants?.dependents} Children` : ''}`}</p>
                                    </div>}
                                    <div className={tableStyles.decision__button__wrapper}>
                                        {el?.status === APPLICATION_STATUS.SUBMITTED && (
                                            <>
                                                <button
                                                    type={"button"}
                                                    className={tableStyles.decision__buttons}
                                                    onClick={isVerified ? () => applicantDecisionConfirmation({
                                                        status:APPLICANT_DECISION.ACCEPTED,
                                                        applicantId : el?.listingId
                                                    },true) : () => {}}>
                                                    <AiOutlineCheck/>
                                                </button>
                                                <button
                                                    type={"button"}
                                                    className={tableStyles.decision__buttons}
                                                    onClick={isVerified ? () => applicantDecisionConfirmation({
                                                        status:APPLICANT_DECISION.REJECTED,
                                                        applicantId:el?.listingId
                                                    },true) : () => {}}>
                                                    <AiOutlineClose/>
                                                </button>
                                            </>
                                        )}
                                        {el?.status === APPLICATION_STATUS.ACCEPTED && (
                                            <>
                                                <button type={"button"} className={tableStyles.accepted__button}>Accepted</button>
                                            </>
                                        )}
                                        {el?.status === APPLICATION_STATUS.REJECTED && (
                                            <>
                                                <button type={"button"} className={tableStyles.denied__button}>Denied</button>
                                            </>
                                        )}
                                        {el?.status === APPLICATION_STATUS.INVITED && (
                                            <>
                                                <div className={`${tableStyles.status} ${tableStyles.invited}`}>
                                                    <IoMailUnreadOutline/>
                                                </div>
                                                <div className={`${tableStyles.status__text}`}>
                                                    <p>Invited</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </MobileTable>
            </div>
        </>
    )
}
export default ApplicantsMobileTable
