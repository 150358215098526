import React,{Fragment} from "react"
import {ROLES} from "../../utils/constants";
import {NavLink} from "react-router-dom";
import TokenService from "../../services/token.service";
import styles from "../../styles/dashboard.module.css"
import {AiFillHome,AiFillFileText,AiFillCreditCard} from "react-icons/ai"
import {BsFillCalendarEventFill} from "react-icons/bs"
import {FaEnvelope} from "react-icons/fa"
import {IoDocumentTextSharp} from "react-icons/io5"

const renterMenuList = [
    {
        text:"Application",
        link:"/application",
        icon: <IoDocumentTextSharp/>
    },
    {
        text:"Payments",
        link:"/payments",
        icon:<AiFillCreditCard/>
    },
    {
        text:"Message",
        link:"/message",
        icon:<FaEnvelope/>
    },
    {
        text:"Contract",
        link:"/contract",
        icon: <AiFillFileText/>
    },
]
const ownerMenuList = [
    {
        text:"Home",
        link:"/home",
        icon:<AiFillHome/>,
    },
    {
        text:"Calendar",
        link:"/calendar",
        icon:<BsFillCalendarEventFill/>
    },
    {
        text:"Message",
        link:"/message",
        icon:<FaEnvelope/>
    },
]

const MenuList = ({isMobile}) => {
    const role = TokenService.getUser()?.role
    let menuNode = ""
    // console.log(role,"role")
    if(role === ROLES.OWNER){
        menuNode = ownerMenuList.map((owner,i)=>{
            return(
                <Fragment key={`${owner.link}_${i}`}>
                    <NavLink to={owner.link} className={({isActive})=>`${isActive ? styles.active__link : ""}`}>
                        {isMobile && owner.icon}
                        {owner.text}
                    </NavLink>
                </Fragment>
            )
        })
    }else if(role === ROLES.RENTER){
        menuNode = renterMenuList.map((renter,i)=>{
            return(
                <Fragment key={`${renter.link}_${i}`}>
                    <NavLink to={renter.link} className={({isActive})=>`${isActive ? styles.active__link : ""}`}>
                        {isMobile && renter.icon}
                        {renter.text}
                    </NavLink>
                </Fragment>
            )
        })
    }
    return menuNode
}
export default MenuList
