import React from 'react';
import { TextInput } from '../../../presentational/contract/signing/text-input';
import { DateInput } from '../../../presentational/contract/signing/date-input';
import {AttachmentTypes} from "../../../../utils/constants/contract";
import {SignatureInput} from "../../../presentational/contract/signing/signature-input";
import {InitialsInput} from "../../../presentational/contract/signing/initials-input";
import {CheckboxInput} from "../../../presentational/contract/signing/checkbox-input";

export const PdfAttachments = ({
  attachments,
  pdfName,
  pageDimensions,
  removeAttachment,
  updateAttachment,
  onHandleSignatureModal,
                                 allPageAttachments,
                                 pageIndex
}) => {
  const handleAttachmentUpdate = (index) => (attachment) => updateAttachment(index, attachment)

  console.log(attachments,"attachments")
  return attachments ? (
    <>
      {attachments.length
        ? attachments.map((attachment, index) => {
            const key = `${pdfName}-${index}`;

            if (attachment.type === AttachmentTypes.DATE) {
              return (
                <DateInput
                  key={key}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  removeAttachment={()=>removeAttachment(index)}
                  updateTextAttachment={handleAttachmentUpdate(index)}
                  isEditable={attachment.isEditable}
                  allPageAttachments={allPageAttachments}
                  pageIndex={pageIndex}
                  {...(attachment)}
                />
              );
            }

            if (attachment.type === AttachmentTypes.TEXT) {
              return (
                  <TextInput
                      key={key}
                      isEditable={attachment.isEditable}
                      pageWidth={pageDimensions.width}
                      pageHeight={pageDimensions.height}
                      removeAttachment={()=>removeAttachment(index)}
                      updateTextAttachment={handleAttachmentUpdate(index)}
                      allPageAttachments={allPageAttachments}
                      pageIndex={pageIndex}
                      {...(attachment)}
                  />
              );
            }

            if (attachment.type === AttachmentTypes.CHECKBOX) {
              return (
                  <CheckboxInput
                      key={key}
                      isEditable={attachment.isEditable}
                      pageWidth={pageDimensions.width}
                      pageHeight={pageDimensions.height}
                      removeAttachment={()=>removeAttachment(index)}
                      updateTextAttachment={handleAttachmentUpdate(index)}
                      allPageAttachments={allPageAttachments}
                      pageIndex={pageIndex}
                      {...(attachment)}
                  />
              );
            }

            if (attachment.type === AttachmentTypes.SIGNATURE) {
              return (
                  <SignatureInput
                      key={key}
                      isEditable={attachment.isEditable}
                      pageWidth={pageDimensions.width}
                      pageHeight={pageDimensions.height}
                      removeAttachment={()=>removeAttachment(index)}
                      updateTextAttachment={handleAttachmentUpdate(index)}
                      onHandleSignatureModal={()=>onHandleSignatureModal(attachment?.editId,attachment?.signerId,index,true)}
                      allPageAttachments={allPageAttachments}
                      pageIndex={pageIndex}
                      {...(attachment)}
                  />
              );
            }

            if (attachment.type === AttachmentTypes.INITIALS) {
              return (
                  <InitialsInput
                      key={key}
                      isEditable={attachment.isEditable}
                      pageWidth={pageDimensions.width}
                      pageHeight={pageDimensions.height}
                      removeAttachment={()=>removeAttachment(index)}
                      updateTextAttachment={handleAttachmentUpdate(index)}
                      onHandleSignatureModal={()=>onHandleSignatureModal(attachment?.editId,attachment?.signerId,index,false)}
                      allPageAttachments={allPageAttachments}
                      pageIndex={pageIndex}
                      {...(attachment)}
                  />
              );
            }
            return null;
          })
        : null}
    </>
  ) : null;
};
