import React, {useEffect, useState} from "react"
import {useOutletContext, useParams} from "react-router";
import styles from "../../styles/viewing.module.css";
import UnitTitle from "../../components/common/text/unit-title";
import ScheduledViewing from "../../components/presentational/listing/single-unit/viewing/scheduled-viewing";
import SetupAvailability from "../../components/presentational/listing/single-unit/viewing/setup-availability";
import ViewingDuration from "../../components/presentational/listing/single-unit/viewing/viewing-duration";
import UnitViewingService from "../../services/unit-viewing.service";
import DefaultLoader from "../../components/common/loaders/default-loader";
import {appendingZeroToTime, formatDateToISO} from "../../utils/helpers";
import ViewingDateRange from "../../components/presentational/listing/single-unit/viewing/viewing-date-range";
import {VIEWING_DATE_RANGE, VIEWING_SCHEDULE_TYPE} from "../../utils/constants/listing";

const Viewing = () => {
    const {useFetchUnitAvailabilities,useHandleSaveUnitAvailabilities} = UnitViewingService()
    //Api is called from the parent component i.e. index.js
    const {data:unitData} = useOutletContext()
    const {unitId} = useParams()
    const {data:availabilityData,isLoading: isAvailabilityLoading,isSuccess: isAvailabilitySuccess} = useFetchUnitAvailabilities(unitId)
    const {isLoading: isSaveAvailabilityLoading,mutate: handleSaveAvailabilityMutation} = useHandleSaveUnitAvailabilities()
    const [fields,setFields] = useState({
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false
    })
    const fieldKeys = Object.keys(fields)

    const [duration,setDuration] = useState(15)
    const [dateRangeFields,setDateRangeFields] = useState({
        scheduleType:VIEWING_SCHEDULE_TYPE.INFINITE_DURATION,
        type:VIEWING_DATE_RANGE.CALENDAR,
        days:1,
        startDate:new Date(),
        endDate:null
    })
    const [availability,setAvailability] = useState({
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: []
    })
    const [durationFields,setDurationFields] = useState({
        duration:15
    })
    useEffect(()=>{
        if(isAvailabilitySuccess){
            let tempAvailabilityData = {}
            if (availabilityData?.invitees){
                setDateRangeFields({
                    scheduleType:availabilityData.invitees.scheduleType,
                    type:availabilityData.invitees.daysDuration.type === 0
                        ? VIEWING_DATE_RANGE.CALENDAR : availabilityData.invitees.daysDuration.type,
                    days:availabilityData.invitees.daysDuration.days === 0
                        ? 1 : availabilityData.invitees.daysDuration.days,
                    startDate:!!availabilityData.invitees.dateDuration.startDate ?
                        new Date(availabilityData.invitees.dateDuration.startDate) : new Date(),
                    endDate:!!availabilityData.invitees.dateDuration.endDate ?
                        new Date(availabilityData.invitees.dateDuration.endDate) : null
                })
            }
            setDurationFields({
                duration: availabilityData?.duration
            })
            setDuration(availabilityData?.duration)

            let filteredApiData = availabilityData
            let {updatedAt,__v,_id,listingId,createdAt,duration,...rest} = filteredApiData
            filteredApiData = rest

            //Mapping Each Key From Api To The State
            fieldKeys.forEach(key => {
                if(filteredApiData[key]?.length > 0){
                    tempAvailabilityData[key] = filteredApiData[key].map(el => {
                        let currDate = new Date().toDateString()
                        return {
                            startTime:new Date(`${currDate} ${el?.startTime}`),
                            endTime:new Date(`${currDate} ${el?.endTime}`),
                            _id:el?._id
                        }
                    })
                    //Checking the boxes which are present in the API
                    setFields(prev => ({
                            ...prev,
                            [key]:true
                        }))
                }else{
                    tempAvailabilityData[key] = []
                }
            })
            setAvailability(tempAvailabilityData)
        }
    },[isAvailabilitySuccess])


    //Saving Availability's Current State
    const handleSaveAvailability = (availability) => {
        const dateRange = {
            scheduleType: parseInt(dateRangeFields.scheduleType),
            daysDuration: {
                days: parseInt(dateRangeFields.days) || 1,
                type: parseInt(dateRangeFields.type)
            },
            dateDuration: {
                startDate: !!dateRangeFields.startDate ? formatDateToISO(dateRangeFields.startDate) : "",
                endDate: !!dateRangeFields.endDate ? formatDateToISO(dateRangeFields.endDate) : ""
            }
        }
        let availabilityData = {duration}
        fieldKeys.forEach(key => {
            if(availability[key]?.length > 0){
                console.log( availabilityData," availabilityData[key]")
                availabilityData[key] = availability[key].map(el => {
                    return {
                        startTime:`${appendingZeroToTime(el?.startTime?.getHours())}:${appendingZeroToTime(el?.startTime?.getMinutes())}`,
                        endTime:`${appendingZeroToTime(el?.endTime?.getHours())}:${appendingZeroToTime(el?.endTime?.getMinutes())}`
                    }
                })
            }else{
                availabilityData[key] = []
            }
        })
        handleSaveAvailabilityMutation({...availabilityData,invitees:dateRange})
    }


    return(
        <>
            <section className={styles.single__unit__viewing__wrapper}>
                <div className={styles.viewing__availability__wrapper}>
                    <div className={styles.viewing__availability__title}>
                        <UnitTitle title={unitData?.name}/>
                        {!isAvailabilityLoading && <ViewingDuration setDuration={setDuration} fields={durationFields}/>}
                    </div>
                    <div className={styles.setup__availability__section}>
                        <h2>Date Range</h2>
                        {!isAvailabilityLoading &&
                            <ViewingDateRange dateRangeFields={dateRangeFields} setDateRangeFields={setDateRangeFields}/>}
                    </div>
                    <div className={styles.setup__availability__section}>
                        <h2>Set your availability</h2>
                        {isAvailabilityLoading && <DefaultLoader/>}
                        {!isAvailabilityLoading &&
                            <SetupAvailability
                                availability={availability}
                                fields={fields}
                                fieldKeys={fieldKeys}
                                setAvailability={setAvailability}
                                saveAvailability={handleSaveAvailability}
                                durationInterval={duration}
                                isSaveAvailabilityLoading={isSaveAvailabilityLoading}
                            />}
                    </div>
                </div>
                <div className={styles.scheduled__viewing__wrapper}>
                    <ScheduledViewing/>
                </div>
            </section>
        </>
    )
}
export default Viewing
