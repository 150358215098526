import React, {Fragment} from "react"
import MobileTable from "../../../../common/tables/mobile-table";
import {nanoid} from "nanoid";
import tableStyles from "../../../../../styles/common/tables.module.css"
import styles from "../../../../../styles/listing.module.css";
import {APPLICATION_STATUS} from "../../../../../utils/constants/application-listing";
import CreditScoreContainer from "../../../../common/container/credit-score-container";
import ProgressBar from "react-bootstrap/ProgressBar";
import {commaToPrice} from "../../../../../utils/helpers";
import TokenService from "../../../../../services/token.service";

const TransactionMobileTable = ({transactionData,handleMarkAsPaid,isRenter,status}) => {
    const userData = TokenService.getUser()
    const mutatedListingApplicantsData = transactionData?.map(el => ({
        name:{
            title:"Name",
            name:isRenter ? `${userData?.firstName} ${userData?.lastName}` : `${el?.renter?.firstName} ${el?.renter?.lastName}`
        },
        date:{
            title:"Date",
            date:new Date(el?.scheduleDate).toDateString(),
        },
        amount:{
            title:"Amount",
            amount:el?.amount
        },
        _id:el?._id
    }))

    return(
        <>
            <div className={styles.applicants__mobile__table__wrapper}>
                <MobileTable>
                    <div className={tableStyles.mobile__table__card__wrapper}>
                        {mutatedListingApplicantsData?.map((el,index)=>(
                            <Fragment key={nanoid()}>
                                <div className={tableStyles.mobile__table__card}>
                                    <div className={tableStyles.mobile__table__card__content}>
                                        <h5>{el?.name?.title}</h5>
                                        <p>{el?.name?.name}</p>
                                    </div>
                                    <div className={tableStyles.mobile__table__card__content}>
                                        <h5>{el?.date?.title}</h5>
                                        <p>{el?.date?.date}</p>
                                    </div>
                                    <div className={tableStyles.mobile__table__card__content}>
                                        <h5>{el?.amount?.title}</h5>
                                        <p>{el?.amount?.amount}</p>
                                    </div>
                                    {(handleMarkAsPaid && !isRenter) && <div className={tableStyles.decision__button__wrapper}>
                                        <button className={tableStyles.action__button} type={"button"}
                                                onClick={() => handleMarkAsPaid(el?._id)}>
                                            Mark As Paid
                                        </button>
                                    </div>}
                                    <div className={tableStyles.decision__button__wrapper}>
                                        <button type={"button"} className={tableStyles[status.toLowerCase()]}>{status}</button>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </MobileTable>
            </div>
        </>
    )
}
export default TransactionMobileTable
