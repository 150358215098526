import React from "react"
import {Image} from "react-bootstrap";
import logo from "../../assets/images/logo.png"
import logoWhite from "../../assets/images/logo-white.png"

const WebsiteLogo = ({isWhite}) => {
    return(
        <>
            <Image src={isWhite ? logoWhite : logo} alt={"Renloc"}/>
        </>
    )
}
export default WebsiteLogo;
