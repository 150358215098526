import React from "react"
import propTypes from "prop-types"
import styles from "../../../../../styles/listing.module.css";
import {Col, Row} from "react-bootstrap";
import {BiBuilding} from "react-icons/bi";
import {AGREEMENT_TYPE, UNIT_TYPE} from "../../../../../utils/constants/listing";
import {AiFillClockCircle} from "react-icons/ai";
import {BsFillCalendar2WeekFill} from "react-icons/bs";
import {HiLocationMarker} from "react-icons/hi";

const SingleUnitMetaInformation = ({unitData,address}) => {
    return(
        <>
            <div className={styles.preview__header}>
                <Row>
                    <Col lg={3} xs={12}>
                        <h2>{unitData?.name}</h2>
                    </Col>
                    <Col lg={2} xs={12}>
                        <h5>${unitData?.rentPrice} / month</h5>
                    </Col>
                    <Col lg={7} xs={12}>
                        <div className={styles.unit__highlighted__details}>
                            <div className={styles.unit__detail}>
                                <h6>Unit type</h6>
                                <p>
                                    <span><BiBuilding/></span>
                                    {unitData?.unitType === UNIT_TYPE.ROOM ? "Room"
                                        : unitData?.unitType === UNIT_TYPE.STUDIO ? "Studio"
                                            : unitData?.unitType === UNIT_TYPE.HOUSE ? "House"
                                                : unitData?.unitType === UNIT_TYPE.CONDO ? "Condo"
                                                    : unitData?.unitType === UNIT_TYPE.TOWNHOUSE ? "Town-house"
                                                        : unitData?.unitType === UNIT_TYPE.DUPLEX_OR_TRIPLEX ? "Duplex Or Triplex"
                                                            : unitData?.unitType === UNIT_TYPE.BASEMENT ? "Basement"
                                                                : unitData?.unitType === UNIT_TYPE.APARTMENT ? "Apartment" : null}
                                </p>
                            </div>
                            <div className={styles.unit__detail}>
                                <h6>Agreement type</h6>
                                <p>
                                    <span><AiFillClockCircle/></span>
                                    {unitData?.agreementType === AGREEMENT_TYPE.ROOM_RENTAL ? "Room Rental"
                                        : unitData?.agreementType === AGREEMENT_TYPE.TWELVE_MONTHS_AGREEMENT ? "12-Months Agreement"
                                            : unitData?.agreementType === AGREEMENT_TYPE.MONTH_TO_MONTH_RENTAL ? "Month-To-Month Rental" : null}
                                </p>
                            </div>
                            <div className={styles.unit__detail}>
                                <h6>Move in date</h6>
                                <p>
                                    <span><BsFillCalendar2WeekFill/></span>
                                    {unitData?.moveInDate}
                                </p>
                            </div>
                            <div className={styles.unit__detail}>
                                <h6>Location</h6>
                                <p>
                                    <span><HiLocationMarker/></span>
                                    {typeof address === 'string' ? address : ""}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default SingleUnitMetaInformation

SingleUnitMetaInformation.propType = {
    unitData : propTypes.object.isRequired
}
