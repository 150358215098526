import React, {Fragment, useEffect, useState} from "react"
import styles from "../../../styles/viewing.module.css";
import DatePicker from "react-datepicker";
import {formatDateToISO, viewingSlots} from "../../../utils/helpers";
import {Form} from "react-bootstrap";
import getDay from "date-fns/getDay";
import formatISO from "date-fns/formatISO"
import isWeekend from "date-fns/isWeekend";
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import UnitViewingService from "../../../services/unit-viewing.service";
import UnitListingService from "../../../services/unit-listing.service";
import useForm from "../../../hooks/use-form";
import {
    UNIT_STATUS,
    USER_UNIT_FILTER,
    VIEWING_DATE_RANGE,
    VIEWING_SCHEDULE_TYPE
} from "../../../utils/constants/listing";
import DefaultLoader from "../../common/loaders/default-loader";
import {toast} from "react-toastify";
import ButtonLoader from "../../common/loaders/button-loader";

const fields = {
    unit:"",
    applicant:"",
}
let days = {mon:1,tue:2,wed:3,thu:4,fri:5,sat:6,sun:0}

const ScheduleViewing = ({onCreateSchedule,isAddViewingLoading,unitId,renterId}) => {
    const {useFetchUnitAvailabilities} = UnitViewingService()
    const {useFetchUserListing,useFetchListingApplicants} = UnitListingService()
    const {inputEvent,details} = useForm(fields)

    const {data: unitData,isLoading: isUnitLoading} = useFetchUserListing(unitId ? '' : USER_UNIT_FILTER.NEWEST,1, "",99,UNIT_STATUS.ALL)

    let dayKeys = Object.keys(days)
    const [startDate,setStartDate] = useState(null)
    const [filteredDays,setFilteredDays] = useState([])
    const [slot,setSelectedSlot] = useState(null)
    const [slotList,setSlotList] = useState([])
    const [filteredAvailability,setFilteredAvailability] = useState({})
    const [filteredInvitees,setFilteredInvitees] = useState([])
    const {data:availabilityData,isFetching: isAvailabilityLoading,isSuccess: isAvailabilitySuccess} = useFetchUnitAvailabilities(unitId ? unitId : details?.unit)
    const {data: applicantsData, isFetching: isApplicantsDataLoading} = useFetchListingApplicants('',1,99,!renterId ? unitId ? unitId : details?.unit : '')

    function addOneDay(date) {
        date.setDate(date.getDate() + 1);
        return formatISO(date, { representation: 'date' });
    }

    useEffect(()=>{
        if(isAvailabilitySuccess){
            let filteredApiData = availabilityData

            let {mon,tue,wed,thu,fri,sat,sun} = filteredApiData
            filteredApiData = {mon,tue,wed,thu,fri,sat,sun}

            dayKeys.forEach(key => {
                if(!filteredApiData[key]?.length > 0){
                    setFilteredDays(prev => [...prev,days[key]])
                }else{
                    setFilteredAvailability(prev => ({
                        ...prev,
                        [key]:filteredApiData[key]
                    }))
                }
            })
            if (!renterId){
                if (availabilityData?.invitees?.scheduleType === VIEWING_SCHEDULE_TYPE.DATE_DURATION){
                    const dates = eachDayOfInterval({
                        start: new Date(availabilityData?.invitees?.dateDuration?.startDate),
                        end: new Date(availabilityData?.invitees?.dateDuration?.endDate)
                    })
                    setFilteredInvitees(dates.map(el =>  formatISO(el, { representation: 'date' })))
                }else if (availabilityData?.invitees?.scheduleType === VIEWING_SCHEDULE_TYPE.DAYS_DURATION){
                    const daysDurationObject = availabilityData.invitees?.daysDuration
                    const startDate = new Date(daysDurationObject?.startDate)

                    for (let i = 0; i < daysDurationObject.days; i++) {
                        let date = addOneDay(startDate)
                        if (daysDurationObject.type === VIEWING_DATE_RANGE.CALENDAR){
                            setFilteredInvitees(prev=>[...prev,date])
                        }else{
                            if (!isWeekend(new Date(date))){
                                setFilteredInvitees(prev=>[...prev,date])
                            }
                        }
                    }
                }
            }
        }
    },[isAvailabilitySuccess])

    //When date is selected on the datepicker, populate the slots array
    useEffect(()=>{
        if(!!startDate){
            const daysByNumber = [1,2,3,4,5,6,0]
            const activeDays = daysByNumber.filter(el => !filteredDays.includes(el))
            let slots = []
            dayKeys.forEach(key => {
                if(activeDays.includes(days[key])){
                    if(new Date(startDate).getDay() === days[key]){
                        for (let i = 0; i < filteredAvailability[key]?.length; i++) {
                            slots.push(...viewingSlots(filteredAvailability[key][i]?.startTime,filteredAvailability[key][i]?.endTime,availabilityData?.duration,startDate))
                        }

                        setSlotList(slots)
                    }
                }
            })
        }
    },[startDate])

    const isActiveDay = (date) => {
        const day = getDay(date);

        if (availabilityData?.invitees?.scheduleType === VIEWING_SCHEDULE_TYPE.INFINITE_DURATION){
            return !filteredDays.includes(day)
        }else{
            return !filteredDays.includes(day) && filteredInvitees.some(el => el ===  formatISO(date, { representation: 'date' }))
        }
    };
    const onUnitChange = (e) =>{
        setStartDate(null)
        setSlotList([])
        setSelectedSlot(null)
        inputEvent(e)
    }

    function handleCreateViewing() {
        const data = {
            startDateTime:`${formatDateToISO(startDate ? new Date(startDate) : new Date())}T${slot?.startTime}:00.000Z`,
            endDateTime:`${formatDateToISO(startDate ? new Date(startDate) : new Date())}T${slot?.endTime}:00.000Z`,
        }
        const submitRenterId = renterId ? renterId : details?.applicant
        const submitUnitId = unitId ? unitId : details?.unit
        if(!submitUnitId){
            toast.error('Select An Applicant')
            return
        }
        if(!data?.startDateTime || !data?.endDateTime){
            toast.error('An Available Date Needs To Be Selected')
            return
        }
        if(!submitRenterId){
            toast.error('Select An Applicant')
            return
        }
        onCreateSchedule({data, renterId:submitRenterId, unitId:submitUnitId})
    }

    if (isApplicantsDataLoading || isAvailabilityLoading || isUnitLoading) return <DefaultLoader/>
    return(
        <>
            <div className={styles.availability__selection__wrapper}>
                {availabilityData && <div className={'scheduler__datepicker'}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        filterDate={isActiveDay}
                        inline
                    />
                </div>}
                {(startDate && availabilityData) && <div className={styles.availability__selection}>
                    <p>{new Date(startDate).toDateString()}</p>
                    <div className={styles.btn__wrapper}>
                        {slotList?.map(el => (
                            <Fragment key={el?.startTime}>
                                <div className={styles.slot__button}>
                                    <button onClick={() => setSelectedSlot(el)} className={slot?.startTime === el?.startTime ? styles.active : ''}>
                                        {el?.startTime}
                                    </button>
                                    {slot?.startTime === el?.startTime && (
                                        <>
                                            {isAddViewingLoading && <ButtonLoader/>}
                                            {!isAddViewingLoading && <button onClick={handleCreateViewing} className={styles.next__button}>
                                                Schedule
                                            </button>}
                                        </>
                                        )}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>}
            </div>
            <div>
                <Form.Group className={styles.form__group__wrapper}>
                    {!unitId && <Form.Group>
                        <label className={`${styles.label} mb-2`}>Units</label>
                        <Form.Select
                            name={"unit"}
                            onChange={onUnitChange}
                            value={details.unit}
                        >
                            <option value={''} disabled>Select A Unit</option>
                            {unitData?.data?.data?.length > 0 && unitData?.data?.data?.map((unit) => {
                                return (
                                    <Fragment key={unit?._id}>
                                        <option value={unit?._id}>{unit?.name}</option>
                                    </Fragment>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>}
                    {((details?.unit || unitId) && !renterId) && <Form.Group>
                        <label className={`${styles.label} mb-2`}>Applicants</label>
                        <Form.Select
                            name={"applicant"}
                            onChange={inputEvent}
                            value={details.applicant}
                        >
                            <option value={''} disabled>Select An Applicant</option>
                            {applicantsData?.data?.length > 0 && applicantsData?.data?.map((applicant)=>{
                                return(
                                    <Fragment key={applicant?._id}>
                                        <option value={applicant?.renter?._id}>{`${applicant?.renter?.firstName} ${applicant?.renter?.lastName}`}</option>
                                    </Fragment>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>}

                </Form.Group>
            </div>
        </>
    )
}
export default ScheduleViewing
