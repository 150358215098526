import React from "react"
import axios from "../config/axios-instance";
import TokenService from "./token.service";
import {useQuery} from "react-query";

const UserService = () => {

    //Get User By Id
    const useFetchUserByIdService = (isUserNotVerified) => {
        const userId = TokenService.getUser()?._id


        const onSuccess = (response) => {
            TokenService.updateUser('isVerified',response?.isVerified)
        }

        const handleGetUserByIdRequest = () => {
            return axios.get(`/user/?userId=${userId}`)
        }

        return useQuery(
            ["user-by-id",userId],
            () => handleGetUserByIdRequest(),
            {
                onSuccess,
                retry:1,
                refetchOnWindowFocus:false,
                select:(response)=>response?.data?.data,
                enabled:isUserNotVerified,
            }
        )
    }



    return {useFetchUserByIdService}
};

export default UserService;
