import React, {useLayoutEffect, useEffect, useState, useRef, createRef} from "react"
import styles from "../../styles/contract.module.css"
import {useOutletContext} from "react-router";
import {PdfPage} from "../../components/stateful/contract/signing/pdf-page";
import {PdfAttachments} from "../../components/stateful/contract/signing/pdf-attachments";
import DefaultLoader from "../../components/common/loaders/default-loader";
import {Col, Container, Row} from "react-bootstrap";
import UnitContractService from "../../services/unit-contract.service";
import ButtonLoader from "../../components/common/loaders/button-loader";
import PrimaryModal from "../../components/common/modals/primary-modal";
import SchedulerModal from "../../components/common/modals/schedular-modal";
import SignatureOnContract from "../../components/presentational/contract/signing/signature-on-contract";
import {AttachmentTypes} from "../../utils/constants/contract";
import {useScreenshot} from "use-react-screenshot";
import {nanoid} from "nanoid";
import useWindowSize from "../../hooks/use-window-size";
import useInitializeContractAttachments from "../../hooks/use-initialize-contract-attachments";
import useIsInViewport from "../../hooks/use-is-in-viewport";

const SignContract = () => {
    const {documentFile,usePdfFromParent,useAttachmentsFromParent,contractData,contractId,signerId} = useOutletContext()
    const { file, initialize, setIsLastPage, isLastPage, currentPage, setDimensions, name, dimensions,returnPdfWithAttachments,pages } = usePdfFromParent
    const { allPageAttachments,pageAttachments, reset: resetAttachments, update, remove,pageIndex, setPageIndex,addAtSpecificIndex } = useAttachmentsFromParent
    const {width} = useWindowSize()

    const [isReturnPdfLoading,setIsReturnPdfLoading] = useState(false)
    const {useHandleSignContract,useHandleSignatureUploadForContract} = UnitContractService()
    const {mutate:handleSignContract,isLoading:isSignContractLoading} = useHandleSignContract()

    const initializeContract = useInitializeContractAttachments({
        addAtSpecificIndex,
        initializePdf:initialize,
        resetAttachments,
        isContractSigned:true,
        signerId,
    })

    const handleSavePdf = async () => await returnPdfWithAttachments(allPageAttachments);


    useEffect(()=>{
        (async ()=>{
            await initializeContract(documentFile,contractData)
        })()
    },[])

    // useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);

    const [elRefs, setElRefs] = useState([]);

    useEffect(()=>{
        setElRefs((elRefs) =>
            Array(allPageAttachments.length)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
        );
    },[allPageAttachments.length])
    const intersectingElement = useIsInViewport(elRefs)

    useEffect(()=>{
        setPageIndex(intersectingElement)
        if (intersectingElement === allPageAttachments.length-1){
            setIsLastPage(true)
        }else{
            setIsLastPage(false)
        }
    },[intersectingElement])

    const finishSigning = async () => {
        let pageData = new FormData();
        let attachmentIndex = 0
        for (let i = 0; i < allPageAttachments.length; i++) {
            let loopData = allPageAttachments[i]
            for (let j = 0; j < loopData.length; j++) {
                if (loopData[j]?.type === AttachmentTypes.TEXT || loopData[j]?.type === AttachmentTypes.DATE  || loopData[j]?.type === AttachmentTypes.CHECKBOX){
                    pageData.append(
                        `edits[${attachmentIndex}][editId]`,
                        `${loopData[j]?.editId}`
                    );
                    pageData.append(
                        `edits[${attachmentIndex}][asset]`,
                        `${loopData[j]?.text}`
                    );
                    attachmentIndex = attachmentIndex+1
                //     pageData.push({
                //         editId:loopData[j]?.editId,
                //         asset: loopData[j]?.text,
                //     })
                }

            }
        }
        try {
            setIsReturnPdfLoading(true)
            let updatedFile = await handleSavePdf()
            pageData.append("file",updatedFile)
            handleSignContract({pageData,contractId,signerId})
        }catch (e) {
            console.log("Failed to return file")
        }finally {
            setIsReturnPdfLoading(false)
        }
    }

    //Signature Upload Functionality
    const [isSignModalOpen,setIsSignModalOpen] = useState({
        isOpen:false,
        editId:"",
        signerId:"",
        index:-1,
        isSignature:false
    })

    const {data:signatureUploadData,mutate:handleSignatureUpload,isLoading:isSignatureUploadLoading,isSuccess:isSignatureUploadSuccess,reset} = useHandleSignatureUploadForContract()
    const [signatureFile,setSignatureFile] = useState("")

    const textSignatureRef = useRef(null)
    const [_, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const [isTextSignatureImmutable,setIsTextSignatureImmutable] = useState(false)
    const download = async (image) => {
        const blob = await fetch(image).then((res) => res.blob());
        let metadata = {
            type: 'image/jpeg'
        };
        return new File([blob], `${nanoid()}.jpeg`, metadata)
    };

    const handleContractSignatureFile = (passedFile) => {
        setSignatureFile(passedFile)
        return () => {
            //If it's text convert it into file then send it
            if (!!textSignatureRef.current){
                setIsTextSignatureImmutable(true)
                textSignatureRef.current.style.color = "#192252"

                takeScreenShot(textSignatureRef.current)
                    .then(async response => {
                        const file = await download(response)
                        const formData = new FormData()

                        formData.append("asset",file)
                        formData.append("editId",isSignModalOpen.editId)

                        handleSignatureUpload({fileData:formData,contractId,signerId:isSignModalOpen.signerId})
                    })
                    .finally(()=>{
                        setIsTextSignatureImmutable(false)
                        textSignatureRef.current.style.color = "transparent"
                    })
                //If it's directly the file just send it!
            }else{
                const formData = new FormData()

                formData.append("asset",passedFile)
                formData.append("editId",isSignModalOpen.editId)

                handleSignatureUpload({fileData:formData,contractId,signerId:isSignModalOpen.signerId})
            }
        }
    }

    //Handle Upload Signature
    const onHandleSignatureModal = (editId,signerId,index,isSignature) => {
        setIsSignModalOpen(prev => ({
            isOpen:!prev.isOpen,
            editId:editId,
            signerId:signerId,
            index:index,
            isSignature
        }))
    }
    //Show signature on ui after uploading from here
    useEffect(()=>{
        if(isSignatureUploadSuccess){
            update(isSignModalOpen.index,{media:signatureUploadData.data?.data?.asset})
        }
    },[isSignatureUploadSuccess])

    return (
        <>
            <section className={styles.sign__contract__wrapper}>
                <p className={styles.current__page__highlighter}>Current Page: {pageIndex+1}/{allPageAttachments.length}</p>
                <Container>
                    <Row>
                        {width <= 991 && <Col md={12}>
                            {!!file && <div className={`${styles.button__wrapper} ${styles.right__button__wrapper}`}>
                                {/*{isMultiPage && !isLastPage &&*/}
                                {/*    <button type={"button"} onClick={nextPage}>Next</button>}*/}
                                {(isLastPage && (!isSignContractLoading || !isReturnPdfLoading)) &&
                                    <button type={"button"} onClick={finishSigning}>Finish Signing</button>}
                                {(isSignContractLoading && isReturnPdfLoading) && <ButtonLoader/>}
                            </div>}
                        </Col>}
                        <Col lg={2} xs={12}>
                            {/*{!!file && <div className={`${styles.button__wrapper} ${styles.left__button__wrapper}`}>*/}
                            {/*    {isMultiPage && !isFirstPage && (!isSignContractLoading || !isReturnPdfLoading) &&*/}
                            {/*        <button type={"button"} onClick={previousPage}>Back</button>}*/}
                            {/*</div>}*/}
                        </Col>
                        <Col lg={8} xs={12}>
                            { !file ? (
                                <DefaultLoader/>
                            ) : (
                                    <div className={styles.pdf__wrapper}>
                                        {allPageAttachments.map((el,index) => (
                                            <>
                                                { currentPage && (
                                                    <div ref={elRefs[index]} id={`page${index}`} className={"relative"}>
                                                        <PdfPage
                                                            dimensions={dimensions}
                                                            updateDimensions={setDimensions}
                                                            page={pages[index]}
                                                        />
                                                        { dimensions && (
                                                            <PdfAttachments
                                                                pdfName={name}
                                                                removeAttachment={remove}
                                                                updateAttachment={update}
                                                                pageDimensions={dimensions}
                                                                attachments={pageAttachments}
                                                                onHandleSignatureModal={onHandleSignatureModal}
                                                                pageIndex={index}
                                                                allPageAttachments={allPageAttachments}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                            )}
                        </Col>
                        {width > 991 && <Col md={2}>
                            {!!file && <div className={`${styles.button__wrapper} ${styles.right__button__wrapper}`}>
                                {/*{isMultiPage && !isLastPage &&*/}
                                {/*    <button type={"button"} onClick={nextPage}>Next</button>}*/}
                                {isLastPage && !isSignContractLoading &&
                                    <div>
                                        <button type={"button"} onClick={finishSigning}>Finish Signing</button>
                                    </div>}
                                {isSignContractLoading && <ButtonLoader/>}
                            </div>}
                        </Col>}
                    </Row>
                </Container>
            </section>
            {isSignModalOpen.isOpen && <PrimaryModal
                size={"lg"}
                isOpen={isSignModalOpen.isOpen}

                modalContent={
                    <SchedulerModal
                        handleModalClose={()=>onHandleSignatureModal("","",-1,false)}
                        isLoading={isSignatureUploadLoading}
                        reset={reset}
                        mutate={()=>handleContractSignatureFile(signatureFile)()}
                        Content={<SignatureOnContract isTextSignatureImmutable={isTextSignatureImmutable} isSignature={isSignModalOpen.isSignature} setFile={handleContractSignatureFile} textRefCallback={textSignatureRef}/>}
                        isSuccess={isSignatureUploadSuccess}
                        title={"Signature"}
                        confirmButtonText={"Sign"}
                    />
                }
            />}
        </>
    )
}
export default SignContract
