import React from "react"
import SuccessContainer from "../components/common/container/success-container";
import {useNavigate} from "react-router";
import TokenService from "../services/token.service";
import {ROLES} from "../utils/constants";

const Success = () => {
    const navigate = useNavigate()
    const userRole = TokenService.getUser()?.role
    const redirectToHome = () => {
        navigate(userRole === ROLES.OWNER ? "/home" : "/application")
    }
    return(
        <>
            <SuccessContainer
                title={"Submitted"}
                description={"Your application has been received and will be reviewed by the owner. \n" +
                    "We will send you an email once a decision is made."}
                buttonText={"Home"}
                clickHandler={redirectToHome}
                isLoading={false}
            />
        </>
    )
}
export default Success