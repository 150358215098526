import React from "react"
const RootFallback = () => {
    return(
        <>
            <div className={""}>
                <div className={""}>
                    <div className={""}>
                        <h1>Something Went Wrong!</h1>
                        <small>We are looking in to the issues, please be patient with it.</small>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RootFallback;