import React from "react"
import styles from "../../../styles/common/card.module.css"
import {FaTrashAlt} from "react-icons/fa";
import {BiEditAlt} from "react-icons/bi"
import useWindowSize from "../../../hooks/use-window-size";
import {stringTruncate} from "../../../utils/helpers";
const ApplicantCard = ({applicantData,currentLabel,onDeleteApplicantCard,onEditApplicantCard,isLoading}) => {
    const {width} = useWindowSize()
    const colorCode = currentLabel === "Owner" ? "#58606C" : currentLabel === "Applicant" ? "#A296ED" : "#4EC79E"
    return(
        <>
            <div className={`${styles.applicant__card__wrapper}`} style={{borderLeft:`10px solid ${colorCode}`}}>
                <div className={styles.top}>
                    <h2>{width > 767 ? applicantData?.name : stringTruncate(applicantData?.name,12)}</h2>
                    <div className={styles.button__wrapper}>
                        <button className={styles.edit__button} type={"button"} onClick={() => onEditApplicantCard(applicantData, true)}>
                            <BiEditAlt/> Edit
                        </button>
                        {currentLabel === "Co-Applicant" && <button disabled={isLoading} className={styles.delete__button} type={"button"}
                                 onClick={() => onDeleteApplicantCard(applicantData?._id)}>
                            <FaTrashAlt/>
                        </button>}
                    </div>
                </div>
                <p>{width > 767 ? applicantData?.email : stringTruncate(applicantData?.email,20)}</p>
            </div>
        </>
    )
}
export default ApplicantCard
