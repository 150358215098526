import React from "react"
import axios from "../config/axios-instance";
import TokenService from "./token.service";
import {useMutation, useQuery} from "react-query";
import {ROLES} from "../utils/constants";
import {toast} from "react-toastify";
import {viewError} from "../utils/helpers";
import {useNavigate} from "react-router";
import useUrlQuery from "../hooks/use-url-query";

const AuthService = () => {
    const navigate = useNavigate()
    let query = useUrlQuery()

    //User Log In
    const useHandleLoginInService = (socketInstance) => {

        const handleLogInRequest = (data) => {
            return axios.post(`/user-auth/login`,data)
        }

        const onSuccess = (response) => {
            const unitIdResponse = query.get("unitId")
            const redirectResponse = query.get("redirect")
            if(response.data?.data?.user?.isApproved){
                TokenService.setUser(response.data.data?.user)
                TokenService.saveLocalRefreshToken(response.data.data?.token?.refreshToken)
                TokenService.saveLocalAccessToken(response.data.data?.token?.accessToken)
                TokenService.setTokenRetries(5)
                socketInstance.connectToSocket()
                console.log(unitIdResponse,"unitIdResponse")
                console.log(redirectResponse,"redirectResponse")
                if (unitIdResponse && redirectResponse){
                    navigate(`/unit/${unitIdResponse}/preview?redirect=${redirectResponse}`)
                }else{
                    if(response.data.data?.user?.role === ROLES.RENTER){
                        navigate("/application")

                    }else if(response.data.data.user?.role === ROLES.OWNER){
                        navigate("/home")
                    }
                }
            }
            else{
                navigate(`/email-verification?user=${response.data.data?.user?._id}`)
            }
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            handleLogInRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //User Log Out
    const useHandleLogOutService = (socketInstance) => {
        const userId = TokenService.getUser()?._id

        const onSuccess = (response) => {
            socketInstance.closeSocketConnection()
            TokenService.clearStorage()
            toast.success(response.data.message)
            navigate("/login")
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        const handleLogoutRequest = () => {
            return axios.post(`/user-auth/logout?userId=${userId}`)
        }

        return useMutation(
            handleLogoutRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Forgot Password
    const useHandleForgotPasswordService = (forgotPasswordData) => {

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            navigate("/forget-password-confirmation",{state:true})
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        const handleForgotPasswordRequest = (data) => {
            return axios.post(`/user-auth/forgot-password`,data)
        }

        return useMutation(
            () => handleForgotPasswordRequest(forgotPasswordData),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Change Password
    const useHandleChangePasswordService = (changePasswordData,email) => {

        const onSuccess = (response) => {
            toast.success(response.data.message)
            navigate("/login")
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        const handleChangePasswordRequest = (data,email) => {
            return axios.post(`/user-auth/forgot-password/change-password?email=${email}`,data)
        }


        return useMutation(
            () => handleChangePasswordRequest(changePasswordData,email),
            {
                onError,
                onSuccess,
                retry:0,
            }
        )
    }

    //Verify Verification Code
    const useFetchVerifyCodeStatusService = (verifyCodeData) => {

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
            navigate("/login")
        }

        const handleVerifyCodeRequest = (data) => {
            return axios.post(`/user-auth/user-validation`,data)
        }

        return useQuery(
            "auth-verify-code",
            () => handleVerifyCodeRequest(verifyCodeData),
            {
                onError,
                retry:1,
                refetchOnWindowFocus:false
            }
        )
    }

    //User Email Verification
    const useHandleEmailVerificationService = (emailData,userId) => {

        const onSuccess = (response) => {
            toast.success(response.data.message)
            navigate("/login")
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        const handleEmailVerificationRequest = (data,id) => {
            return axios.post(`/user-auth/email-verification?userId=${id}`,data)
        }

        return useMutation(
            () => handleEmailVerificationRequest(emailData,userId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    // User Sign Up
    const useHandleSignupService = (signUpData) => {

        const onSuccess = (response) => {
            toast.success(response.data.message)
            navigate(`/email-verification?user=${response.data.data?._id}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        const handleSignupRequest = (data) => {
            return axios.post(`/user-auth/signup`,data)
        }

        return useMutation(
            () => handleSignupRequest(signUpData),
            {
                onError,
                onSuccess,
                retry:0,
            }
        )

    }


    return {useHandleLoginInService,useHandleSignupService,useHandleEmailVerificationService,
        useHandleForgotPasswordService, useHandleChangePasswordService,useFetchVerifyCodeStatusService,useHandleLogOutService}
};

export default AuthService;
