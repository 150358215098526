import { useState, useCallback } from 'react';
import {returnFileWithAttachments, save} from "../utils/contract-signing/pdf";
import {toast} from "react-toastify";

// export interface Pdf {
//   name: string;
//   file: File;
//   pages: Promise<any>[];
// }

export const usePdf = () => {
    const [name, setName] = useState('');
    const [pageIndex, setPageIndex] = useState(-1);
    const [dimensions, setDimensions] = useState();
    const [file, setFile] = useState();
    const [pages, setPages] = useState([]);
    const [isMultiPage, setIsMultiPage] = useState(false);
    const [isFirstPage, setIsFirstPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const currentPage = pages[pageIndex];

    const setDimensionsHandler = useCallback(setDimensions, [setDimensions]);

    const nextPage = () => {
        const newPageIndex = pageIndex + 1;
        setPageIndex(pageIndex + 1);
        setIsFirstPage(newPageIndex === 0);
        setIsLastPage(newPageIndex === pages.length - 1);
    };

    const previousPage = () => {
        const newPageIndex = pageIndex - 1;
        setPageIndex(newPageIndex);
        setIsFirstPage(newPageIndex === 0);
        setIsLastPage(newPageIndex === pages.length - 1);
    };

    const initialize = ({ name, file, pages: _pages }) => {
        const multi = _pages.length > 1;
        setName(name);
        setFile(file);
        setPages(_pages);
        setPageIndex(0);
        setIsMultiPage(multi);
        setIsFirstPage(true);
        setIsLastPage(_pages.length === 1);
    };

    const savePdf = async (attachments) => {
        if (isSaving || !file) return;

        setIsSaving(true);

        try {
            await save(file, attachments, name);
        } catch (e) {
            console.log(e,"e")
            toast.error("PDF is unable to download");
        } finally {
            setIsSaving(false);
        }
    };

    const returnPdfWithAttachments = async (attachments) => {
        if (isSaving || !file) return;

        setIsSaving(true);

        try {
            return await returnFileWithAttachments(file, attachments, name);
        } catch (e) {
            console.log(e,"e")
            toast.error("PDF is unable to download");
        } finally {
            setIsSaving(false);
        }
    };


    return {
        currentPage,
        dimensions,
        setDimensions: setDimensionsHandler,
        name,
        setName,
        pageIndex,
        setPageIndex,
        file,
        setFile,
        nextPage,
        pages,
        savePdf,
        returnPdfWithAttachments,
        initialize,
        isMultiPage,
        previousPage,
        isFirstPage,
        isLastPage,
        setIsLastPage,
        isSaving,
    };
};
