import React, {Fragment, useState} from "react";
import styles from "../../styles/contract.module.css"
import {useNavigate} from "react-router";
import {AiOutlineClose} from "react-icons/ai"
import {Form} from "react-bootstrap";
import {BiFontFamily} from "react-icons/bi"
import {IoIosCheckboxOutline} from "react-icons/io"
import {BsPencil,BsCalendarDate} from "react-icons/bs"
import {FaSignature} from "react-icons/fa"
import {AttachmentTypes} from "../../utils/constants/contract";
import returnCurrentContractColor from "../presentational/contract/return-current-contract-color";
import {nanoid} from "nanoid";
import {toast} from "react-toastify";


const EditContractSidebar = ({contractData,useAttachmentsFromParent,usePdfFromParent,disableSignatureForSigner}) => {
    const navigate = useNavigate()
    const [currentColor,setCurrentColor] = useState(returnCurrentContractColor(contractData[0]?.label))
    const [currentSigner,setCurrentSigner] = useState(contractData[0]?.data[0]?._id)

    const {dimensions} = usePdfFromParent
    const { add: addAttachment, pageAttachments} = useAttachmentsFromParent

    const onAssignToChange = (e) => {
        const {id,label} = JSON.parse(e.target.value)

        setCurrentSigner(id)
        setCurrentColor(returnCurrentContractColor(label))
    }

    const addInitialAttachment = (type,text,extraProperties) => {
        const pageHeight = 750
        const pageWidth = dimensions.width
        // const y = pageAttachments.length > 0
        //     ? (pageAttachments[pageAttachments.length - 1].y + )
        let x = 20;
        let y = 20;

        if (pageAttachments.length > 0){
            x = (pageAttachments[pageAttachments.length - 1].x + pageAttachments[pageAttachments.length - 1].width + 20)
            if (x > (pageWidth - pageAttachments[pageAttachments.length - 1].width)){
                x = 20
                y = pageAttachments[pageAttachments.length - 1].y + pageAttachments[pageAttachments.length - 1].height + 20
            }else{
                y = pageAttachments[pageAttachments.length - 1].y
            }
        }

        if (y > pageHeight){
            toast.info("No space to enter more fields")
            return;
        }


        const newTextAttachment = {
            id: nanoid(),
            type: type,
            x: x,
            y: y,
            width: 100,
            editId:"",
            signerId:currentSigner,
            colorTheme:currentColor,
            isEditable:false,
            height: 40,
            size: 14,
            lineHeight: 1.4,
            fontFamily: 'Times-Roman',
            text: text,
            ...extraProperties
        };
        addAttachment(newTextAttachment);
    }
    return(
      <>
        <aside className={styles.sidebar__wrapper}>
            <div className={styles.close__button}>
                <button type={"button"} onClick={()=>navigate("/home")}><AiOutlineClose/></button>
            </div>
            <div className={styles.content__wrapper}>
                <div className={styles.content}>
                    <h6>Assign To</h6>
                    <Form.Select
                        name={"assignTo"}
                        onChange={onAssignToChange}
                        style={{color:currentColor}}
                    >
                        {contractData?.map((el,index) => (
                            <Fragment key={`${el?.label}_${index}`}>
                                {el?.data?.map(innerEl => (
                                    <Fragment key={innerEl?._id}>
                                        <option value={JSON.stringify({id:innerEl?._id,label:el?.label})}>{`${el?.label}: ${innerEl?.name}`}</option>
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}
                    </Form.Select>
                </div>
                <div className={styles.content}>
                    <h6>Fields</h6>
                    <div className={styles.content__button__wrapper}>
                        <button type={"button"} style={{color:currentColor}} onClick={() => addInitialAttachment(AttachmentTypes.SIGNATURE,"Sign",{width:200})} disabled={disableSignatureForSigner.includes(currentSigner)}>
                            <FaSignature/>
                            Sign
                        </button>
                        <button type={"button"} style={{color:currentColor}} onClick={() => addInitialAttachment(AttachmentTypes.INITIALS,"Initials",{width:200})}>
                            <BsPencil/>
                            Initials
                        </button>
                        <button type={"button"} style={{color:currentColor}} onClick={() => addInitialAttachment(AttachmentTypes.TEXT,"Text")}>
                            <BiFontFamily/>
                            Text
                        </button>
                        <button type={"button"} style={{color:currentColor}} onClick={() => addInitialAttachment(AttachmentTypes.DATE,new Date())}>
                            <BsCalendarDate/>
                            Date
                        </button>
                        <button type={"button"} style={{color:currentColor}} onClick={() => addInitialAttachment(AttachmentTypes.CHECKBOX,false)}>
                            <IoIosCheckboxOutline/>
                            Checkbox
                        </button>
                    </div>
                </div>
            </div>
        </aside>
      </>
  )
}
export default EditContractSidebar
