import React, {useEffect, useState} from "react"
import styles from "../../../../../styles/rent-collection.module.css";
import Title from "../../../../common/text/title";
import {Form} from "react-bootstrap";
import {IoAddCircleOutline} from "react-icons/io5"
import ContentModal from "../../../../common/modals/content-modal";
import plaidLogo from "../../../../../assets/images/plaid.jpg"
import renlocLogo from "../../../../../assets/images/logo-2.png"
import {AiOutlineClose} from "react-icons/ai"
import ButtonLoader from "../../../../common/loaders/button-loader";

const AddRentReceivingAccount = ({title,handleAddReceivingAccountCallback,handleBackAddReceivingAccountCallback,isHalf,fetchVoPayUrl,isFetchVoPayUrlLoading,isFetchVoPayUrlSuccess,voPayUrlData}) =>{
    const [isAddAccountModalOpen,setIsAddAccountModalOpen] = useState(false)


    const handleAddAccountModal = () => setIsAddAccountModalOpen(prev => !prev)
    function onAddAccount() {
        fetchVoPayUrl()
    }

    useEffect(()=>{
        if(isFetchVoPayUrlSuccess){
            handleAddAccountModal()
            window.location.href = voPayUrlData?.data?.data
        }
    },[isFetchVoPayUrlSuccess])
    return(
        <>
            <div className={`${styles.add__rent__receiving__account__wrapper} ${isHalf ? styles.half__width : ""}`}>
                {title && <Title title={title}/>}
                <Form className={styles.add__rent__receiving__account__form} onSubmit={handleAddReceivingAccountCallback}>
                    <div className={styles.no__receiving__account__wrapper}>
                        <div className={styles.no__receiving__account}>
                            <h6>You haven’t added any receiving account yet</h6>
                            <button type={"button"} onClick={handleAddAccountModal} className={styles.add__rent__receiving__account__button}>
                                <IoAddCircleOutline/>
                                <span>Add New Account</span>
                            </button>
                        </div>
                    </div>

                    {handleBackAddReceivingAccountCallback && <div className={styles.rent__button__wrapper}>
                        <button type={"button"} onClick={handleBackAddReceivingAccountCallback}
                                className={styles.prev__btn}>
                            Back
                        </button>
                        {/*<button type={"submit"} className={styles.next__btn} disabled={true}>*/}
                        {/*    Confirm*/}
                        {/*</button>*/}
                    </div>}

                </Form>
            </div>
            {isAddAccountModalOpen && (
                <>
                    <ContentModal
                        size={"lg"}
                        isNotLarge
                        isOpen={isAddAccountModalOpen}
                        customClass={"add__rent__receiving__account"}
                    >
                        <div className={styles.bank__modal__content__wrapper}>
                            <span onClick={handleAddAccountModal} className={styles.close__modal}><AiOutlineClose/></span>
                            <div className={styles.bank__modal__content__inner__wrapper}>
                                {/*<img src={bankImage} alt={"Add Bank"}/>*/}
                                <div className={styles.bank__modal__content__image__wrapper}>
                                    <img src={plaidLogo} alt={"Plaid"}/>
                                    <img src={renlocLogo} alt={"Renloc"}/>
                                </div>
                                <h2>
                                    Renloc uses <span>VoPay</span> to connect your account
                                </h2>
                                <div className={styles.bank__modal__content}>
                                    <h3>Connect effortlessly</h3>
                                    <p>VoPay lets you securely connect your financial accounts in seconds</p>
                                </div>
                                <div className={styles.bank__modal__content}>
                                    <h3>Your data belongs to you</h3>
                                    <p>VoPay does not sell personal info, and will only use it with your permission</p>
                                </div>
                            </div>
                            <div className={styles.bank__modal__content__bottom__wrapper}>
                                <div className={styles.disclaimer}>
                                    <span>By selecting “Continue” you agree to the</span>
                                    <a href={"https://vopay.com/ca/legal/tos/"} target={"_blank"} rel={"noreferrer"}>VoPay Terms Of Service</a>
                                </div>
                                {!isFetchVoPayUrlLoading && <button type={"button"} onClick={onAddAccount}>Continue</button>}
                                {isFetchVoPayUrlLoading && <ButtonLoader/>}
                            </div>
                        </div>
                    </ContentModal>
                </>
            )}
        </>
    )
}
export default AddRentReceivingAccount
