import React, {useEffect} from "react"
import styles from "../../../../../styles/rent-collection.module.css";
import Title from "../../../../common/text/title";
import {Form} from "react-bootstrap";
import ButtonLoader from "../../../../common/loaders/button-loader";
import {AiFillEdit} from "react-icons/ai";
import DefaultLoader from "../../../../common/loaders/default-loader";

const RentPaymentAccount = ({title,description,handleConfirmPaymentAccountCallback,isHalf,bankData,isLoading,editBankAccount,isEditBankAccountLoading,editBankAccountData,isEditBankAccountSuccess}) =>{
    useEffect(()=>{
        if(isEditBankAccountSuccess){
            window.location.href = editBankAccountData?.data?.data
        }
    },[isEditBankAccountSuccess])
    return(
        <>
            <div className={`${styles.rent__payment__account__wrapper} ${isHalf ? styles.half__width : ""}`}>
                {title && <Title title={title}/>}
                {description && <p className={styles.description}>{description}</p>}
                {isEditBankAccountLoading && <DefaultLoader/>}
                {!isEditBankAccountLoading && <Form className={styles.rent__payment__account__form} onSubmit={handleConfirmPaymentAccountCallback}>

                    <div className={styles.bank__detail__wrapper}>
                        <div className={styles.bank__detail__header}>
                            <h6>{bankData?.BankName}</h6>
                            <button className={styles.edit__bank__detail} onClick={editBankAccount}>
                                <AiFillEdit/>
                                <span>Edit</span>
                            </button>
                        </div>
                        <div className={styles.bank__detail__content}>
                            <h5>Chequing</h5>
                            {bankData?.MaskedAccount && <p>Account: <span>{bankData?.MaskedAccount}</span></p>}
                            {bankData?.TransitNumber && <p>Transit: <span>{bankData?.TransitNumber}</span></p>}
                            {bankData?.ABARoutingNumber &&
                                <p>ABARoutingNumber: <span>{bankData?.ABARoutingNumber}</span></p>}
                        </div>
                    </div>
                    {handleConfirmPaymentAccountCallback && <div className={styles.rent__button__wrapper}>
                        {!isLoading && <button type={"submit"} className={styles.next__btn}>
                            Confirm
                        </button>}
                        {isLoading && <ButtonLoader/>}
                    </div>}

                </Form>}
            </div>

        </>
    )
}
export default RentPaymentAccount
