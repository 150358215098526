import React, {useLayoutEffect, useEffect, useState, useRef, createRef} from "react"
import styles from "../../styles/contract.module.css"
import {useOutletContext, useParams} from "react-router";
import {PdfPage} from "../../components/stateful/contract/signing/pdf-page";
import {PdfAttachments} from "../../components/stateful/contract/signing/pdf-attachments";
import DefaultLoader from "../../components/common/loaders/default-loader";
import {Container} from "react-bootstrap";
import UnitContractService from "../../services/unit-contract.service";
import ButtonLoader from "../../components/common/loaders/button-loader";
import {AttachmentTypes, EDIT_BUTTON_CLICKED} from "../../utils/constants/contract";
import useInitializeContractAttachments from "../../hooks/use-initialize-contract-attachments";
import {readAsArrayBuffer} from "../../utils/contract-signing/async-reader";
import useIsInViewport from "../../hooks/use-is-in-viewport";

const EditContract = () => {
    const {documentFile,usePdfFromParent,useAttachmentsFromParent,contractData,handleSignerListForSignature,signerData} = useOutletContext()
    const { file, initialize, setIsLastPage,isLastPage, setDimensions, name, dimensions,pages } = usePdfFromParent
    const { allPageAttachments,pageAttachments, reset: resetAttachments, update, remove, pageIndex,setPageIndex,addAtSpecificIndex } = useAttachmentsFromParent
    const {contractId} = useParams()

    const {useHandleUpdateContractEdits} = UnitContractService()
    const {mutate:handleUpdateContractEdits,isLoading:isUpdateContractEditsLoading} = useHandleUpdateContractEdits()

    const [elRefs, setElRefs] = useState([]);

    useEffect(()=>{
        setElRefs((elRefs) =>
            Array(allPageAttachments.length)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
        );
    },[allPageAttachments.length])
    const intersectingElement = useIsInViewport(elRefs)

    useEffect(()=>{
        setPageIndex(intersectingElement)
        if (intersectingElement === allPageAttachments.length-1){
            setIsLastPage(true)
        }else{
            setIsLastPage(false)
        }
    },[intersectingElement])

    const [isConfirmEdit,setIsConfirmEdit] = useState(false)
    const [currentSignerIdList,setCurrentSignerIdList] = useState([])
    const initializeContract = useInitializeContractAttachments({
        addAtSpecificIndex,
        initializePdf:initialize,
        resetAttachments,
        isContractSigned:false
    })

    useEffect(()=>{
        if (!!allPageAttachments.find(el => el?.length > 0)){
            let signatureSignerIds = [] //To disable signer input field on the sidebar
            let signerIds = [] //To disable confirm edit button
            for (let i = 0; i < allPageAttachments.length; i++) {
                let loopData = allPageAttachments[i]
                for (let j = 0; j < loopData.length; j++) {
                    if (loopData[j]?.type === AttachmentTypes.SIGNATURE){
                        signatureSignerIds.push(loopData[j].signerId)
                    }
                    if (!signerIds.some(el => el === loopData[j].signerId)){
                        signerIds.push(loopData[j].signerId)
                    }
                }
            }
            setCurrentSignerIdList(signerIds)
            handleSignerListForSignature(signatureSignerIds)
        }else{
            setCurrentSignerIdList([])
            handleSignerListForSignature([])
        }
    },[allPageAttachments])
    useEffect(()=>{
        let hasDifferentSignersSigned = signerData
            .map(el => el.data.map(innerEl => currentSignerIdList.some(signerIdEl => signerIdEl === innerEl?._id)))
            .flat(1)
            .every(Boolean)
        setIsConfirmEdit(hasDifferentSignersSigned)
    },[currentSignerIdList.length])

    useEffect(()=>{
        (async ()=>{
            await initializeContract(documentFile,contractData)
        })()
    },[])

    // useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);

    function handleSaveCurrentAttachmentsData() {
        let pageData = [];
        for (let i = 0; i < allPageAttachments.length; i++) {
            let loopData = allPageAttachments[i]
            for (let j = 0; j < loopData.length; j++) {
                pageData.push({
                    signerId: loopData[j].signerId,
                    type: loopData[j]?.type,
                    xPosition: loopData[j]?.x,
                    yPosition: loopData[j]?.y,
                    pageNumber: i,
                })
            }
        }

        return pageData
    }
    const handleSaveAttachmentData = (buttonClickedType) => {
        const pageData = handleSaveCurrentAttachmentsData()
        handleUpdateContractEdits({pageData,contractId,buttonClicked:buttonClickedType})
    }

    return (
        <>
            <section className={styles.edit__contract__wrapper}>
                <p className={styles.current__page__highlighter}>Current Page: {pageIndex+1}/{allPageAttachments.length}</p>
                <Container>
                    { !file ? (
                        <DefaultLoader/>
                    ) : (
                        <>
                            <div className={styles.pdf__wrapper}>
                                {allPageAttachments.map((el,index) => (
                                    <>
                                        <div ref={elRefs[index]} id={`page${index}`} className={"relative"}>
                                            <PdfPage
                                                dimensions={dimensions}
                                                updateDimensions={setDimensions}
                                                page={pages[index]}
                                            />
                                            { dimensions && (
                                                <PdfAttachments
                                                    pdfName={name}
                                                    removeAttachment={remove}
                                                    updateAttachment={update}
                                                    pageDimensions={dimensions}
                                                    attachments={pageAttachments}
                                                    pageIndex={index}
                                                    allPageAttachments={allPageAttachments}
                                                />
                                            )}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    )}
                </Container>
                {!!file && <div className={styles.bottom__action__buttons}>
                    {!isUpdateContractEditsLoading && (
                        <>
                            {/*{(isMultiPage && !isFirstPage) &&*/}
                            {/*    <button type={"button"} onClick={previousPage} className={styles.back}>Back</button>}*/}
                            {/*{(isMultiPage && !isLastPage) &&*/}
                            {/*    <button type={"button"} onClick={nextPage} className={styles.next}>Next</button>}*/}
                            {isLastPage &&
                                <button type={"button"} disabled={!isConfirmEdit} className={styles.next} onClick={()=>handleSaveAttachmentData(EDIT_BUTTON_CLICKED.CONFIRM_EDIT)}>Confirm Edit</button>}
                            <button type={"button"} className={styles.next} onClick={()=>handleSaveAttachmentData(EDIT_BUTTON_CLICKED.SAVE_AS_DRAFT)}>Save As Draft</button>
                        </>
                    )}
                    {isUpdateContractEditsLoading && <ButtonLoader/>}
                </div>}
            </section>
        </>
    )
}
export default EditContract
