import React, {useState} from "react";
import styles from "../../../styles/common/container.module.css"
import propTypes from "prop-types";
import CustomInfo from "../icons/custom-info";
import CustomTooltip from "../tooltips/custom-tooltip";

const VerificationWarning = ({tooltipDescription,text,clickHandlerCallback,customClass}) => {
    const [isShowToolTip,setIsShowToolTip] = useState(false)
    const mouseEnter = () => {
        setIsShowToolTip(true)
    }
    const mouseLeave = () => {
        setIsShowToolTip(false)
    }
    return(
        <>
            <div className={styles.verification__warning__wrapper} onClick={clickHandlerCallback}>
                <div className={styles.text}>
                    {text}
                    <div className={styles.tooltip__content}>
                        {tooltipDescription && <span>
                            <CustomInfo onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}/>
                        </span>}
                        {isShowToolTip && <CustomTooltip extraClass={'tooltip__wrapper__verification__warning'}>
                            <p>{tooltipDescription}</p>
                        </CustomTooltip>}
                    </div>

                </div>

            </div>
        </>
    )
}

export default VerificationWarning

VerificationWarning.propType = {
    tooltipDescription : propTypes.string.isRequired,
    text : propTypes.string.isRequired,
    customClass : propTypes.string,

    clickHandlerCallback : propTypes.func.isRequired
}
