export const APPLICATION_STATUS = Object.freeze({
    INVITED : 1,
    DRAFT : 2,
    SUBMITTED : 3,
    ACCEPTED : 4,
    REJECTED : 5
})

export const STATE_PROVINCE = Object.freeze({
    ALBERTA:"Alberta",
    BRITISH_COLUMBIA:"British Columbia",
    MANITOBA:"Manitoba",
    NEW_BRUNSWICK:"New Brunswick",
    NEWFOUND_LAND_AND_LABRADOR:"Newfound land And Labrador",
    NOVA_SCOTIA:"Nova Scotia",
    ONTARIO:"Ontario",
    PRINCE_EDWARD_ISLAND:"Prince Edward Island",
    QUEBEC:"Quebec",
    SASKATCHEWAN:"Saskatchewan",
});

export const CREDIT_SCORE = Object.freeze({
    EQUIFAX:1,
    TRANSUNION:2,
});

export const PERMISSION_TO_CONTACT = Object.freeze({
    YES:true,
    NO:false,
});

export const ANIMAL_TYPE = Object.freeze({
    DOG:1,
    CAT:2,
    OTHER:3,
});

export const EMPLOYMENT_TYPE = Object.freeze({
    SELF_EMPLOYED:"Self Employed",
    FREELANCE:"Freelance",
    FULL_TIME:"Full Time",
    PART_TIME:"Part Time",
    CONTRACT:"Contract",
    ON_CALL:"On Call",
    SEASONAL:"Seasonal",
    OTHER:"Other",
});

export const RELATIONSHIP = Object.freeze({
    SPOUSE:"Spouse",
    PARTNER:"Partner",
    FRIEND:"Friend",
    SIBLING:"Sibling",
});