import React from 'react';
import {TextMode} from "../../../../utils/constants/contract";
import styles from "../../../../styles/contract.module.css"
import {AiOutlineClose,AiOutlineEdit} from "react-icons/ai"
import {MEDIA_BASEURL} from "../../../../utils/constants";
import {BsPencil} from "react-icons/bs";

export const SignatureInput = ({
                              width,
                              height,
                              mode,
                              size,
                              positionTop,
                              positionLeft,
                              onHandleSignatureModal,
                              toggleEditMode,
                              handleMouseDown,
                              handleMouseMove,
                              handleMouseOut,
                              handleMouseUp,
                              lineHeight,
                              removeAttachment,
                              isEditable,
                                   colorTheme,
                              signatureMedia,
    id
                          }) => {
    return (
        <>
            {!!isEditable && (
                <div
                    style={{
                        width,
                        height,
                        lineHeight,
                        top: positionTop,
                        left: positionLeft,
                        wordWrap: 'break-word',
                        padding: 0,
                        position: 'absolute',
                    }}
                >
                    {!signatureMedia && <button
                        className={`${styles.disabled__pdf__input} ${styles.disabled__pdf__input__color}`}
                        type={"button"}
                        onClick={onHandleSignatureModal}
                        style={{
                            fontSize: size,
                        }}
                    >
                        <BsPencil/>
                        Sign
                    </button>}
                    {!!signatureMedia && <div style={{
                        position: 'absolute',
                    }}>
                        <img className={styles.signature__media} src={`${MEDIA_BASEURL}${signatureMedia}`} alt={"Signature"}/>
                        <button type={"button"} onClick={onHandleSignatureModal} className={styles.edit__pdf__field}>
                            <AiOutlineEdit/>
                        </button>
                    </div>}
                </div>)}
            {!isEditable && (<div
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onDoubleClick={toggleEditMode}
                id={id}
                style={{
                    width,
                    cursor: mode === TextMode.COMMAND ? 'move' : 'default',
                    top: positionTop,
                    left: positionLeft,
                    padding: 0,
                    position: 'absolute',
                }}
            >
                <button
                    className={styles.disabled__pdf__input}
                    type={"button"}
                    style={{
                        fontSize:size,
                        border:`1px solid ${colorTheme}`,
                        color:colorTheme
                    }}
                >
                    <BsPencil/>
                    Sign
                </button>
                <button type={"button"} onClick={removeAttachment} className={styles.remove__pdf__field}>
                    <AiOutlineClose/>
                </button>
            </div>)}
        </>

    );
};
