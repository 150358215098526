import React from "react"
import {handlers, UploadTypes} from "./use-uploader";
import returnCurrentContractColor from "../components/presentational/contract/return-current-contract-color";
import {AttachmentTypes} from "../utils/constants/contract";

const useInitializeContractAttachments = ({
                                              addAtSpecificIndex,
                                              initializePdf,
                                              resetAttachments,
                                              isContractSigned,
                                              signerId
                                          }) => {

    const initializePageAndAttachments = (pdfDetails) => {
        initializePdf(pdfDetails);
        const numberOfPages = pdfDetails.pages.length;
        resetAttachments(numberOfPages);
    };

    const addDataAtSpecificIndex = (editData,currentSigner) => {
        addAtSpecificIndex(editData?.pageNumber,{
            id: editData?._id,
            type:editData?.type,
            x: editData?.xPosition,
            y: editData?.yPosition,
            width: editData?.type === AttachmentTypes.SIGNATURE || editData?.type === AttachmentTypes.INITIALS ? 200 : 100,
            editId:editData?._id,
            signerId:editData?.signerId,
            colorTheme:!isContractSigned && returnCurrentContractColor(currentSigner?.isApplicant ? "Applicant" : currentSigner?.isOwner ? "Owner" : "Co-Applicant"),
            isEditable:isContractSigned,
            height: 40,
            size: 14,
            lineHeight: 1.4,
            fontFamily: 'Times-Roman',
            text: isContractSigned ? '' : editData?.type === AttachmentTypes.INITIALS ? 'Initials' : editData?.type === AttachmentTypes.TEXT ? "Text" : editData?.type === AttachmentTypes.DATE ? "Date" : editData?.type === AttachmentTypes.CHECKBOX ? "Checkbox" : '',
            media:editData?.asset
        })
    }

    async function initializeContract (documentFile,contractData) {
        initializePageAndAttachments(await handlers[UploadTypes.PDF](documentFile,true))
        if (contractData?.edits?.length > 0){
            for (let i = 0; i < contractData?.edits?.length; i++) {
                let editData = contractData?.edits[i]
                let currentSigner = contractData?.signers?.find(el=>el?.data?._id === editData?.signerId)
                // if (editData?.type === AttachmentTypes.SIGNATURE){
                //     console.log("here")
                //     handleSignerListForSignature(editData.signerId)
                //     // setSignsPerSignerId(prev => [...prev,editData?.signerId])
                // }

                //If the contract is in signature phased, only show the text/ input fields for that specific signer id
                if (isContractSigned){
                    if (editData?.signerId === signerId){
                        addDataAtSpecificIndex(editData,currentSigner?.data)
                    }
                }else{
                    addDataAtSpecificIndex(editData,currentSigner?.data)
                }


            }

        }
    }

    return initializeContract
}
export default useInitializeContractAttachments
