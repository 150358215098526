import React, {Fragment, useContext, useEffect, useState} from "react";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import ApplicationListingService from "../../../../../services/application-listing.service";
import DatePicker from "react-datepicker";
import {AiOutlineClose, AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import useForm from "../../../../../hooks/use-form";
import TokenService from "../../../../../services/token.service";
import {EMPLOYMENT_TYPE} from "../../../../../utils/constants/application-listing";
import {formatDateToISO} from "../../../../../utils/helpers";

const employmentList = [EMPLOYMENT_TYPE.SELF_EMPLOYED,EMPLOYMENT_TYPE.PART_TIME,EMPLOYMENT_TYPE.ON_CALL,EMPLOYMENT_TYPE.FULL_TIME,EMPLOYMENT_TYPE.OTHER,EMPLOYMENT_TYPE.FREELANCE,EMPLOYMENT_TYPE.CONTRACT]
const FinancialInformationForm = ({applicationData}) => {
    const {previousStep, nextStep} = useWizard();
    const userData = TokenService.getUser()
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)

    const {useHandleUpdateFinancialInformationService} = ApplicationListingService()


    const [incrementerFields,setIncrementerFields] = useState({
        "applicants" : 0,
    })
    const [incomeSourceFieldsList,setIncomeSourceFieldsList] = useState({
        "applicants" : []
    })
    // const [fields,setFields] = useState({
    //     name: "",
    //     isApplicant: true,
    //     employmentType: "",
    //     employerName: "",
    //     employerAddress: "",
    //     position: "",
    //     annualIncome: ""
    // })
    const [occupantKey,setOccupantKey] = useState([])
    const addInitialIncrementerFields = (name,isApi) => {
        if(isApi){
            console.log(name,"name")
            setIncrementerFields((prevState)=>{
                return {
                    ...prevState,
                    [`occupants_${name}`]: applicationData?.financialInformation?.incomeSource?.filter((el)=>el.name === name).length
                }
            });
            setIncomeSourceFieldsList((prevState)=>{
                return {
                    ...prevState,
                    [`occupants_${name}`]:applicationData?.financialInformation?.incomeSource?.filter((el)=>el.name === name).map((el)=>{
                        return{
                            ...el,
                            startedWorking : new Date(el.startedWorking)
                        }
                    })
                }
            });

        }else{
            setIncrementerFields((prevState)=>{
                return {
                    ...prevState,
                    [`occupants_${name}`]: 0
                }
            });
            setIncomeSourceFieldsList((prevState)=>{
                return {
                    ...prevState,
                    [`occupants_${name}`]:[]
                }
            });
        }

    }
    useEffect(()=>{
        setOccupantKey(Object.keys(incrementerFields).filter((el)=>el !== "applicants"))
    },[incrementerFields])

    useEffect(()=>{
        if(applicationData?.financialInformation?.incomeSource?.length > 0){
            for(let i=0;i<applicationData?.occupants?.adults?.length;i++){
                    addInitialIncrementerFields(applicationData?.occupants?.adults[i]?.name,true)
            }
            setIncrementerFields((prevState)=>{
                return {
                    ...prevState,
                    [`applicants`]: applicationData?.financialInformation?.incomeSource?.filter((el)=>el.name === `${userData?.firstName} ${userData?.lastName}`).length
                }
            });
            setIncomeSourceFieldsList((prevState)=>{
                return {
                    ...prevState,
                    [`applicants`]:applicationData?.financialInformation?.incomeSource?.filter((el)=>el.name === `${userData?.firstName} ${userData?.lastName}`).map((el)=>{
                        return{
                            ...el,
                            startedWorking : new Date(el.startedWorking)
                        }
                    })
                }
            });
        }else{
            for(let i=0;i<applicationData?.occupants?.adults.length;i++){
                addInitialIncrementerFields(applicationData?.occupants?.adults[i]?.name,false)
            }
        }

    },[applicationData?.occupants,applicationData?.financialInformation?.incomeSource])

    const {incrementerEvent,incrementer} = useForm(null,incrementerFields)

    const [finalData,setFinalData] = useState([])
    const {isLoading,isSuccess,mutate} = useHandleUpdateFinancialInformationService({
        incomeSource: finalData
    })

    const removeApplicantField = (i,key) => {
        let newFormValues = [...incomeSourceFieldsList[key]];
        newFormValues.splice(i, 1);
        setIncomeSourceFieldsList({...incomeSourceFieldsList,[key]:newFormValues});
        incrementerEvent("decrement", key)
    }
    const addAddressFormFields = (key) => {
        const id = Math.floor(Math.random() * 100)
        setIncomeSourceFieldsList({
            ...incomeSourceFieldsList,
            [key]:[
                ...incomeSourceFieldsList[key],
                // generating unique keys for each object
                {
                    name: key === "applicants" ? `${userData?.firstName} ${userData?.lastName}` : key?.split("_")[1],
                    isApplicant: key === "applicants",
                    employmentType: EMPLOYMENT_TYPE.SELF_EMPLOYED,
                    employerName: "",
                    employerAddress: "",
                    position: "",
                    startedWorking: new Date(),
                    annualIncome: "",
                    id
                },
            ]
        });
    };
    // console.log(incomeSourceFieldsList,"hehe")
    const handleFinancialInformationChange = (index, clickedInput,key) => (e) => {
        incomeSourceFieldsList[key][index] = {
            ...incomeSourceFieldsList[key][index],
            [`${clickedInput}`]: e.target.value,
        };
        setIncomeSourceFieldsList(()=>{
            return{
                ...incomeSourceFieldsList,
                [key] : [...incomeSourceFieldsList[key]]
            }
        });
    };
    const handleFinancialDateChange = (index, date,key) => {
        incomeSourceFieldsList[key][index] = {
            ...incomeSourceFieldsList[key][index],
            [`startedWorking`]: date,
        };
        setIncomeSourceFieldsList(()=>{
            return{
                ...incomeSourceFieldsList,
                [key] : [...incomeSourceFieldsList[key]]
            }
        });
    };
    //Increment Applicant
    const incrementApplicant = () => {
        incrementerEvent("increment","applicants")
        addAddressFormFields("applicants")
    }


    //Increment Occupant
    const incrementOccupant = (key) => {
        incrementerEvent("increment",key)
        addAddressFormFields(key)
    }

    const goToPrevStep = () => {
        previousStep()
    }
    // const keysArray = Object.keys(incomeSourceFieldsList)
    // for(let key of keysArray){
    //     console.log(incomeSourceFieldsList[key],"1")
    // }
    const goToNextStep =  (e) => {
        e.preventDefault()
        const keysArray = Object.keys(incomeSourceFieldsList)
        for(let key of keysArray){
            setFinalData((prevState)=>{
                return[
                    ...prevState,
                    ...incomeSourceFieldsList[key].map((el)=>{
                        return{
                            ...el,
                            startedWorking : formatDateToISO(el?.startedWorking)
                        }
                    }),
                ]
            })
        }
    }

    useEffect(()=>{
        if(finalData?.length > 0){
            mutate()
        }
    },[finalData])

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    fourthStepperCompleted:true
                }
            })
            nextStep().then()
        }
    },[isSuccess])


    return(
        <>
            <Form className={styles.create__listing__form__alternate} onSubmit={goToNextStep}>

                {/*Applicant Income Source*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Income Source</Form.Label>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Group className={styles.form__check__group}>
                        <Form.Label id={styles.main__label}>{`${userData?.firstName} ${userData?.lastName}`}</Form.Label>
                        <div className={styles.incrementer__wrapper}>
                            <button disabled={incrementer.applicants <= 0} type={"button"} onClick={() => incrementerEvent("decrement","applicants")}><AiOutlineMinus/></button>
                            <span>{incrementer.applicants}</span>
                            <button type={"button"} onClick={() => incrementApplicant()}><AiOutlinePlus/></button>
                        </div>
                    </Form.Group>
                </Form.Group>
                {incrementer?.applicants > 0 && incomeSourceFieldsList?.applicants?.map((applicant,index)=>{
                    return(
                        <Fragment key={applicant?.id}>
                            <div style={{marginBottom: "20px"}}>
                                <button type={"button"} onClick={() => removeApplicantField(index,"applicants")}
                                        className={styles.close__field}><AiOutlineClose/></button>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Type Of Employment</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Select
                                                name={"employmentType"}
                                                onChange={handleFinancialInformationChange(index,"employmentType","applicants")}
                                                value={applicant.employmentType}
                                                required
                                            >
                                                {employmentList?.map((employment) => {
                                                    return (
                                                        <Fragment key={employment}>
                                                            <option value={employment}>{employment}</option>
                                                        </Fragment>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Employer's Name</Form.Label>
                                            <Form.Control type={"text"} name={"employerName"} value={applicant?.employerName}
                                                          onChange={handleFinancialInformationChange(index,"employerName","applicants")} placeholder={"Tesla"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>Employer's Address</Form.Label>
                                            <Form.Control type={"text"} name={"employerAddress"} value={applicant?.employerAddress}
                                                          onChange={handleFinancialInformationChange(index,"employerAddress","applicants")} placeholder={"123 Name Street, Toronto, ON A1A B2B"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>What's Your Job Position</Form.Label>
                                            <Form.Control type={"text"} name={"position"} value={applicant?.position}
                                                          onChange={handleFinancialInformationChange(index,"position","applicants")} placeholder={"Sales Manager"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <div className={styles.form__group__outer__wrapper}>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>When did you start working here?</Form.Label>
                                            <DatePicker
                                                onChange={(date) => handleFinancialDateChange(index,date,"applicants")}
                                                selected={applicant.startedWorking}
                                                dateFormat={"yyyy-MM-dd"}
                                                // minDate={new Date()}
                                            />
                                        </Form.Group>
                                        <Form.Group className={styles.form__group}>
                                            <Form.Label>What is your annual income?</Form.Label>
                                            {/*className={error["unitSize"] ? "error" : ""}*/}
                                            <Form.Control type={"number"} name={"annualIncome"} value={applicant?.annualIncome}
                                                          onChange={handleFinancialInformationChange(index,"annualIncome","applicants")} placeholder={"$"}
                                                          required/>
                                        </Form.Group>
                                    </div>
                                </Form.Group>
                            </div>
                        </Fragment>
                    )
                })}


                {/*Occupant Income Source*/}
                {occupantKey.length > 0 && <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Income Source</Form.Label>
                </Form.Group>}
                {occupantKey.map((key,i)=>{
                    return(
                        <Fragment key={key}>
                            <Form.Group className={styles.form__group__wrapper}>
                                <Form.Group className={styles.form__check__group}>
                                    <Form.Label id={styles.main__label}>{applicationData?.occupants?.adults[i]?.name}</Form.Label>
                                    <div className={styles.incrementer__wrapper}>
                                        <button disabled={incrementer[key] <= 0} type={"button"}
                                                onClick={() => incrementerEvent("decrement", key)}><AiOutlineMinus/>
                                        </button>
                                        <span>{incrementer[key]}</span>
                                        <button type={"button"} onClick={() => incrementOccupant(key)}><AiOutlinePlus/></button>
                                    </div>
                                </Form.Group>
                            </Form.Group>
                            {incrementer[key] > 0 && incomeSourceFieldsList?.[key]?.map((occupant,index)=>{
                                return(
                                    <Fragment key={occupant?.id}>
                                        <div style={{marginBottom: "20px"}}>
                                            <button type={"button"} onClick={() => removeApplicantField(index,key)}
                                                    className={styles.close__field}><AiOutlineClose/></button>
                                            <Form.Group className={styles.form__group__wrapper}>
                                                <div className={styles.form__group__outer__wrapper}>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>Type Of Employment</Form.Label>
                                                        {/*className={error["unitSize"] ? "error" : ""}*/}
                                                        <Form.Select
                                                            name={"employmentType"}
                                                            onChange={handleFinancialInformationChange(index,"employmentType",key)}
                                                            value={occupant.employmentType}
                                                            required
                                                        >
                                                            {employmentList?.map((employment) => {
                                                                return (
                                                                    <Fragment key={employment}>
                                                                        <option value={employment}>{employment}</option>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </Form.Select>

                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group__wrapper}>
                                                <div className={styles.form__group__outer__wrapper}>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>Employer's Name</Form.Label>
                                                        <Form.Control type={"text"} name={"employerName"} value={occupant?.employerName}
                                                                      onChange={handleFinancialInformationChange(index,"employerName",key)} placeholder={"Tesla"}
                                                                      required/>
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group__wrapper}>
                                                <div className={styles.form__group__outer__wrapper}>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>Employer's Address</Form.Label>
                                                        <Form.Control type={"text"} name={"employerAddress"} value={occupant?.employerAddress}
                                                                      onChange={handleFinancialInformationChange(index,"employerAddress",key)} placeholder={"123 Name Street, Toronto, ON A1A B2B"}
                                                                      required/>
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group__wrapper}>
                                                <div className={styles.form__group__outer__wrapper}>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>What's Your Job Position</Form.Label>
                                                        <Form.Control type={"text"} name={"position"} value={occupant?.position}
                                                                      onChange={handleFinancialInformationChange(index,"position",key)} placeholder={"Sales Manager"}
                                                                      required/>
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group__wrapper}>
                                                <div className={styles.form__group__outer__wrapper}>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>When did you start working here?</Form.Label>
                                                        <DatePicker
                                                            onChange={(date) => handleFinancialDateChange(index,date,key)}
                                                            selected={occupant.startedWorking}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            // minDate={new Date()}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className={styles.form__group}>
                                                        <Form.Label>What is your annual income?</Form.Label>
                                                        {/*className={error["unitSize"] ? "error" : ""}*/}
                                                        <Form.Control type={"number"} name={"annualIncome"} value={occupant?.annualIncome}
                                                                      onChange={handleFinancialInformationChange(index,"annualIncome",key)} placeholder={"$"}
                                                                      required/>
                                                    </Form.Group>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </Fragment>
                    )
                })}



                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading}
                />
            </Form>

        </>
    )
}
export default FinancialInformationForm
