
export const UNIT_STATUS = Object.freeze({
    PUBLISHED : 2,
    DRAFT : 1,
    LEASED: 3,
    ALL : 4
})

export const USER_UNIT_FILTER = Object.freeze({
    A_TO_Z : 1,
    Z_TO_A : 2,
    NEWEST : 3,
    OLDEST : 4
})

export const APPLICANT_DECISION = Object.freeze({
    ACCEPTED : 1,
    REJECTED : 2,
})

export const AGREEMENT_TYPE = Object.freeze({
    TWELVE_MONTHS_AGREEMENT:1,
    MONTH_TO_MONTH_RENTAL:2,
    ROOM_RENTAL:3,
});

export const INVITE_TYPE = Object.freeze({
    ANYONE_WITH_THE_LINK:1,
    INVITE_BY_EMAIL:2,
});

export const RENT_TYPE = Object.freeze({
    WEEKLY:2,
    MONTHLY:1,
});

export const SCREENING_PAY = Object.freeze({
    RENTER:1,
    OWNER:2,
});

export const UNIT_TYPE = Object.freeze({
    APARTMENT:1,
    CONDO:2,
    BASEMENT:3,
    HOUSE:4,
    TOWNHOUSE:5,
    DUPLEX_OR_TRIPLEX:6,
    ROOM:7,
    STUDIO:8,
});

export const SCREENING_FEE = 30

export const SIGNER_STATUS = {
    DRAFT:1,
    SENT:2,
    SIGNED:3,
    EXPIRED:4,
    DECLINED:5
}

export const DOCUMENT_ACTIONS = {
    SIGN : "Sign",
    CONTINUE : "Continue",
    DOWNLOAD : "Download"
}

export const RENT_PAYMENT_TYPE = {
    DEPOSIT : 1,
    LAST_MONTH_RENT : 2,
}

export const PAYMENT_TRANSACTION_STATUS = {
    RECEIVED : 1,
    UPCOMING : 2,
    OVERDUE: 3
}

export const VIEWING_SCHEDULE_TYPE = {
    DAYS_DURATION:1,
    DATE_DURATION:2,
    INFINITE_DURATION:3,
}
export const VIEWING_DATE_RANGE = {
    CALENDAR:1,
    WEEK:2,
}
