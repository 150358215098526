import React from "react"
import {Form} from "react-bootstrap";
import styles from "../../../../../styles/viewing.module.css";
import {VIEWING_DATE_RANGE, VIEWING_SCHEDULE_TYPE} from "../../../../../utils/constants/listing";
import useForm from "../../../../../hooks/use-form";
import DatePicker from "react-datepicker";

function ViewingDateRange({dateRangeFields,setDateRangeFields}) {
    const {details,inputEvent} = useForm(dateRangeFields)

    const onDurationChange = (e) => {
        inputEvent(e)
        setDateRangeFields(prev => ({
            ...prev,
            [e.target.name]:e.target.value
        }))
    }
    return(
        <div>
            <Form.Group className={styles.form__group__wrapper}>
                <Form.Label className={styles.label}>Invitees Can Schedule:</Form.Label>
                <div className={styles.date__range__fields__wrapper}>
                    <div className={styles.date__range__fields__inner__wrapper}>
                        <input
                            type="radio"
                            name="scheduleType"
                            value={VIEWING_SCHEDULE_TYPE.DAYS_DURATION}
                            onChange={onDurationChange}
                            className={`form-check-input ${styles.radio__field}`}
                            id="scheduleType_0"
                            checked={parseInt(details.scheduleType) === VIEWING_SCHEDULE_TYPE.DAYS_DURATION}
                        />
                        <Form.Control
                            type="number"
                            name={"days"}
                            onChange={onDurationChange}
                            value={details.days}
                            required={true}
                            className={styles.input}
                            min={1}
                        />
                        <Form.Select
                            name={"type"}
                            onChange={onDurationChange}
                            value={details.type}
                            required={true}
                            className={styles.input}
                        >
                            <option value={VIEWING_DATE_RANGE.CALENDAR}>calendar days</option>
                            <option value={VIEWING_DATE_RANGE.WEEK}>week days</option>
                        </Form.Select>
                        <span>into the future.</span>
                    </div>
                    <div className={styles.date__range__fields__inner__wrapper}>
                        <input
                            type="radio"
                            name="scheduleType"
                            value={VIEWING_SCHEDULE_TYPE.DATE_DURATION}
                            onChange={onDurationChange}
                            className={`form-check-input ${styles.radio__field}`}
                            id="scheduleType_1"
                            checked={parseInt(details.scheduleType) === VIEWING_SCHEDULE_TYPE.DATE_DURATION}
                        />
                        <span>Within a date range</span>
                        <div className={styles.field__group}>
                            <DatePicker
                                onChange={(date) => setDateRangeFields(prev => ({
                                  ...prev,
                                  startDate:date
                                }))}
                                selected={dateRangeFields.startDate}
                                dateFormat={"yyyy-MM-dd"}
                                maxDate={dateRangeFields.endDate}
                                placeholderText={"Start Date"}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                            <span>-</span>
                            <DatePicker
                                onChange={(date) => setDateRangeFields(prev => ({
                                    ...prev,
                                    endDate:date
                                }))}
                                selected={dateRangeFields.endDate}
                                dateFormat={"yyyy-MM-dd"}
                                minDate={dateRangeFields.startDate}
                                // disabled={}
                                placeholderText={"End Date"}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    <div className={styles.date__range__fields__inner__wrapper}>
                        <input
                            type="radio"
                            name="scheduleType"
                            value={VIEWING_SCHEDULE_TYPE.INFINITE_DURATION}
                            onChange={onDurationChange}
                            className={`form-check-input ${styles.radio__field}`}
                            id="scheduleType_2"
                            checked={parseInt(details.scheduleType) === VIEWING_SCHEDULE_TYPE.INFINITE_DURATION}
                        />
                        <span>Indefinitely into the future.</span>
                    </div>
                </div>
            </Form.Group>
        </div>
    )
}
export default ViewingDateRange
