import React from "react"
import {Spinner} from "react-bootstrap";
import propTypes from "prop-types";
import styles from "../../../styles/common/loader.module.css"

const DefaultLoader = ({size}) =>{
    return(
        <>
            <div className={styles.default__loader__wrapper}>
                <Spinner variant="primary" animation="border" role="status" size={size === "small" ? "sm" : size === "lg" ? "lg" : ""} >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </>
    )
}
export default DefaultLoader

DefaultLoader.propType = {
    size : propTypes.string
}