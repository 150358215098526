import React, {useState, useEffect} from "react"
import styles from "../../../styles/account-settings.module.css"
import {Form, Tab, Tabs} from "react-bootstrap";
import ButtonLoader from "../../common/loaders/button-loader";
import AccountSettingsService from "../../../services/account-settings.service";
import CardDetails from "./card-details";
import PaypalBox from "../../presentational/account-settings/paypal-box";
import useExternalResponse from "../../../hooks/use-external-response";
import SaveCardContainer from "../../common/container/save-card-container";


const PaymentDetailsContainer = () => {
    const {useHandleSaveCardService} = AccountSettingsService()

    const [key,setKey] = useState("creditCard")
    const externalResponseCallback = useExternalResponse("Card saved successfully","Card not saved")
    const {isLoading,mutate} = useHandleSaveCardService()


    const addCard = (e) => {
        e.preventDefault()
        mutate()
    }
    const savePaypal = e => {
        e.preventDefault()
    }

    useEffect(()=>{
        externalResponseCallback()
    },[])
    return(
        <>
            <section className={styles.account__details__wrapper}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="account__settings__tab__wrapper"
                >
                    <Tab eventKey="creditCard" title="Credit Card">
                        <Form onSubmit={addCard}>
                            <SaveCardContainer
                                text={"Click the button below to save your card details to ease your future payments through Stripe"}
                                buttonText={"Save Card Details"}
                                isLoading={isLoading}
                            />
                        </Form>
                        <CardDetails/>
                    </Tab>
                    <Tab eventKey="payPal" title="PayPal">
                        <Form onSubmit={savePaypal}>
                            <SaveCardContainer
                                text={"Click the button below to log into your PayPal account and authorize your payments."}
                                buttonText={"Pay With PayPal"}
                                isLoading={false}
                            />
                        </Form>
                    </Tab>
                </Tabs>
            </section>
        </>
    )
}
export default PaymentDetailsContainer
