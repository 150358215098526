import React from "react"
import axios from "../config/axios-instance";
import axiosDefault from "axios"
import TokenService from "./token.service";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {mergeDuplicateLabels, viewError} from "../utils/helpers";
import {useNavigate, useParams} from "react-router";
import {MEDIA_BASEURL, ROLES} from "../utils/constants";
import {nanoid} from "nanoid";
import {EDIT_BUTTON_CLICKED} from "../utils/constants/contract";

const UnitContractService = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    //Fetch Unit's Contracts
    const useFetchUnitContracts = (unitId) => {
        const fetchUnitContractsRequest = () => {
            return axios.get(`/contract/all?listingId=${unitId}&page=1&limit=99`)
        }

        return useQuery(
            [`unit-contract-documents`, unitId],
            () => fetchUnitContractsRequest(),
            {
                retry: 1,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
                enabled: !!unitId,
                select: (response) => response.data?.data
            }
        )
    }

    //Fetch Contract By ID
    const useFetchContractById = (contractId) => {
        const fetchContractByIdRequest = () => {
            if(!!contractId){
                return axios.get(`/contract?contractId=${contractId}`)
            }
        }

        return useQuery(
            [`unit-contract-document`, contractId],
            fetchContractByIdRequest,
            {
                retry: 1,
                refetchOnWindowFocus: false,
                enabled:!!contractId,
                select: (response) => {
                    const responseData = response.data?.data

                    return {
                        ...responseData,
                        signers:responseData?.signers?.map(el => {
                            return {
                                data: el,
                                label:(el?.isOwner && !el?.isApplicant) ? "Owner" : (!el?.isOwner && el?.isApplicant) ? "Applicant" : "Co-Applicant"
                            }
                        })
                    }
                }
            }
        )
    }

    //Handle Delete Unit Contract
    const useHandleDeleteUnitContract = () => {
        const {unitId} = useParams()
        const handleDeleteUnitContractRequest = (contractId) => {
            return axios.delete(`/contract?contractId=${contractId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-contract-documents`, unitId])
        }

        return useMutation(
            ({contractId}) => handleDeleteUnitContractRequest(contractId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Fetch Contract Document By Document Name
    const useFetchContractDocumentByName = (documentName) => {
        const fetchContractByIdRequest = () => {
            return axiosDefault.get(`${MEDIA_BASEURL}${documentName}`,{responseType:"blob"})
        }

        return useQuery(
            [`unit-contract-pdf`, documentName],
            fetchContractByIdRequest,
            {
                retry: 1,
                enabled:!!documentName,
                select:(response) => {
                    let data = response.data;
                    let metadata = {
                        type: 'application/pdf'
                    };
                    return new File([data], `${nanoid()}.pdf`, metadata)
                }
            }
        )
    }

    //Verify Signer Id
    const useVerifySignerId = (contractId,signerId) => {
        const isUserLoggedIn = !!TokenService.getUser()?._id && !!TokenService.getLocalAccessToken() && !!TokenService.getLocalRefreshToken()
        const loggedInUserRole = TokenService.getUser()?.role
        const verifySignerIdRequest = () => {
            return axios.get(`contract/verify-signer?contractId=${contractId}&signerId=${signerId}`)
        }

        const onError = () => {
            toast.error("Invalid URL")
            navigate(loggedInUserRole === ROLES.RENTER ? "/application" : loggedInUserRole === ROLES.OWNER ? "/home" : "/login",{replace:true})
        }

        return useQuery(
            [`verify-signer-id`, signerId],
            verifySignerIdRequest,
            {
                retry: 0,
                onError,
                enabled:!!signerId && isUserLoggedIn,
            }
        )
    }

    //Fetch Renter's Contracts
    const useFetchRenterContracts = () => {
        const userId = TokenService.getUser()?._id
        const fetchRenterContractsRequest = () => {
            return axios.get(`/contract/renter?renterId=${userId}&page=1&limit=99`)
        }

        return useQuery(
            [`renter-contract-documents`, userId],
            fetchRenterContractsRequest,
            {
                retry: 1,
                refetchOnWindowFocus: false,
                enabled: TokenService.getUser()?.role === ROLES.RENTER,
                select: (response) => {
                    const responseData = response.data?.data
                    return mergeDuplicateLabels(responseData?.map(el => {
                        return {
                            data:el,
                            label:el?.listing?.name
                        }
                    }))

                }
            }
        )
    }

    //Handle Add Unit's Document
    const useHandleAddUnitDocument = () => {
        const {unitId} = useParams()
        const userId = TokenService.getUser()?._id

        const handleAddUnitDocumentRequest = (data) => {
            return axios.post(`/contract?listingId=${unitId}&ownerId=${userId}`,data)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            (data) => handleAddUnitDocumentRequest(data),
            {
                retry:1,
                onError,
            }
        )
    }

    //Handle Add Co-Applicants To Unit
    const useHandleAddCoApplicantsToUnitDocument = (contractId) => {

        const handleAddCoApplicantToUnitRequest = (data,contractId) => {
            return axios.put(`/contract/co-applicant?contractId=${contractId}`,data)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-contract-document`, contractId])
        }

        return useMutation(
            (data) => handleAddCoApplicantToUnitRequest(data,contractId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Edit Applicant's Data
    const useHandleEditApplicantDataDocument = (contractId,signerId) => {

        const handleEditApplicantDataRequest = (data,contractId,signerId) => {
            return axios.put(`contract/email?contractId=${contractId}&signerId=${signerId}`, {email:data})
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            queryClient.invalidateQueries([`unit-contract-document`, contractId])
        }

        return useMutation(
            (data) => handleEditApplicantDataRequest(data,contractId,signerId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Delete Co-Applicant
    const useHandleDeleteCoApplicant = (contractId) => {

        const handleDeleteCoApplicantRequest = (contractId,signerId) => {
            return axios.delete(`/contract/co-applicant?contractId=${contractId}&signerId=${signerId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = (response) => {
            queryClient.setQueryData([`unit-contract-document`, contractId],(oldQueryData)=>{
                return {
                    ...oldQueryData,
                    data:{
                        data:response?.data?.data
                    }
                }
            })
        }

        return useMutation(
            (signerId) => handleDeleteCoApplicantRequest(contractId,signerId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Update Contract Edits
    const useHandleUpdateContractEdits = () => {
        const handleUpdateContractEditsRequest = async (pageData,contractId,buttonClicked) => {
            const apiCall = axios.put(`/contract/edits?contractId=${contractId}`, {edits:pageData})
            return {response:await apiCall,buttonClicked}
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = ({response,buttonClicked}) => {
            const listingId = response.data?.data?.listingId
            if (buttonClicked === EDIT_BUTTON_CLICKED.CONFIRM_EDIT){
                navigate(`/unit/${listingId}/contract`,{state: response.data?.data?._id})
            }else if (buttonClicked === EDIT_BUTTON_CLICKED.SAVE_AS_DRAFT){
                toast.success("Content Saved")
                navigate(`/unit/${listingId}/contract`)
            }

        }

        return useMutation(
            ({pageData,contractId,buttonClicked}) => handleUpdateContractEditsRequest(pageData,contractId,buttonClicked),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Contract Signature Upload
    const useHandleSignatureUploadForContract = () => {
        const handleSignatureUploadForContractRequest = (fileData,contractId,signerId) => {
            return axios.put(`/contract/edits-image?contractId=${contractId}&signerId=${signerId}`,fileData)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = (response) => {

        }

        return useMutation(
            ({fileData,contractId,signerId}) => handleSignatureUploadForContractRequest(fileData,contractId,signerId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Sign Contract
    const useHandleSignContract = () => {
        const userRole = TokenService.getUser()?.role
        const handleSignContractRequest = (pageData,contractId,signerId) => {
            return axios.put(`/contract/sign?contractId=${contractId}&signerId=${signerId}`, pageData)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = (response) => {
            toast.success("Contract Successfully Signed")
            navigate(!!userRole ? userRole === ROLES.RENTER ? "/application" : "/home" : "/login")
        }

        return useMutation(
            ({pageData,contractId,signerId}) => handleSignContractRequest(pageData,contractId,signerId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Decline Contract
    const useHandleDeclineContract = () => {
        const userId = TokenService.getUser()?._id
        const handleDeclineContractRequest = (contractId,signerId) => {
            return axios.put(`/contract/decline?contractId=${contractId}&signerId=${signerId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = () => {
            toast.success("Contract Successfully Declined")
            queryClient.invalidateQueries([`renter-contract-documents`, userId])
        }

        return useMutation(
            ({contractId,signerId}) => handleDeclineContractRequest(contractId,signerId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    //Handle Send Contract For Signature
    const useHandleSendContractForSignature = (contractId) => {
        const handleSendContractForSignatureRequest = (contractId) => {
            return axios.put(`/contract/send?contractId=${contractId}`)
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }
        const onSuccess = (response) => {

            queryClient.invalidateQueries([`unit-contract-documents`, response.data?.data?.listingId])
        }

        return useMutation(
            () => handleSendContractForSignatureRequest(contractId),
            {
                retry:1,
                onSuccess,
                onError,
            }
        )
    }

    return {useFetchUnitContracts,useHandleAddUnitDocument,useFetchContractById,
        useHandleAddCoApplicantsToUnitDocument,useHandleEditApplicantDataDocument,
        useHandleDeleteCoApplicant,useFetchRenterContracts,useFetchContractDocumentByName,
        useHandleSignatureUploadForContract,useHandleSignContract,useHandleUpdateContractEdits,
        useHandleSendContractForSignature,useHandleDeleteUnitContract,useVerifySignerId,
        useHandleDeclineContract}
};

export default UnitContractService;
