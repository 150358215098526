import React from "react"
import propTypes from "prop-types";
import {Modal} from "react-bootstrap"

const ContentModal = (props) => {
    return(
        <>
            <Modal
                size={props.size}
                dialogClassName={`modal-120w ${props.isNotLarge ? "content__modal__wrapper__sm" : 'content__modal__wrapper'}`}
                className={`content__modal ${props.customClass}`}
                fullscreen={props.isFullScreen}
                show={props.isOpen}
                backdrop="static"
                scrollable={true}
                keyboard={false}
                centered
            >
                <Modal.Body className="modal__body">
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ContentModal;

ContentModal.PropType = {
    isOpen : propTypes.bool.isRequired,
    size : propTypes.string.isRequired,
    customClass : propTypes.string
}
