import React, { useState, useRef } from 'react';
import { SignatureInput as Component } from '../../../stateful/contract/signing/signature-input';
import { getMovePosition } from '../../../../utils/contract-signing/helpers';
import {DragActions,TextMode} from "../../../../utils/constants/contract";
import HighlightingLabel from "../highlighting-label";


export const SignatureInput = ({
                              x,
                              y,
                              text,
                              width,
                              height,
                              lineHeight,
                              size,
                              fontFamily,
                              pageHeight,
                              pageWidth,
                              updateTextAttachment,
                              isEditable,
                              removeAttachment,
                              onHandleSignatureModal,
                              media,
                              colorTheme,
    allPageAttachments,
                                   pageIndex,
                                   id
                          }) => {
    const inputRef = useRef(null);
    const [content, setContent] = useState(text || '');
    const [mouseDown, setMouseDown] = useState(false);
    const [positionTop, setPositionTop] = useState(y);
    const [positionLeft, setPositionLeft] = useState(x);
    const [operation, setOperation] = useState(
        DragActions.NO_MOVEMENT
    );
    const [textMode, setTextMode] = useState(TextMode.COMMAND);

    const handleMouseMove = (event) => {
        event.preventDefault();

        if (mouseDown) {
            const { top, left } = getMovePosition(
                positionLeft,
                positionTop,
                event.movementX,
                event.movementY,
                width,
                height,
                pageWidth,
                pageHeight,
                !!allPageAttachments ? allPageAttachments : [],
                pageIndex,
                id
            );

            setPositionTop(top);
            setPositionLeft(left);
        }
    };

    const handleMousedown = (event) => {
        if (textMode !== TextMode.COMMAND) {
            return;
        }

        setMouseDown(true);
        setOperation(DragActions.MOVE);
    };

    const handleMouseUp = (event) => {
        event.preventDefault();

        if (textMode !== TextMode.COMMAND) {
            return;
        }

        setMouseDown(false);

        if (operation === DragActions.MOVE) {
            const { top, left } = getMovePosition(
                positionLeft,
                positionTop,
                event.movementX,
                event.movementY,
                width,
                height,
                pageWidth,
                pageHeight,
                !!allPageAttachments ? allPageAttachments : [],
                pageIndex,
                id
            );

            updateTextAttachment({
                x: left,
                y: top,
            });
        }

        // if (operation === DragActions.SCALE) {
        //     updateTextObject({
        //         x: positionLeft,
        //         y: positionTop,
        //     });

        // }

        setOperation(DragActions.NO_MOVEMENT);
    };

    const handleMouseOut = (event) => {
        if (operation === DragActions.MOVE) {
            handleMouseUp(event);
        }

        if (textMode === TextMode.INSERT) {
            setTextMode(TextMode.COMMAND);
            prepareTextAndUpdate();
        }
    };

    const prepareTextAndUpdate = () => {
        // Deselect any selection when returning to command mode
        // document?.getSelection()?.removeAllRanges();

        const lines = [content];
        updateTextAttachment({
            lines,
            text: content,
        });
    };

    const toggleEditMode = () => {
        const input = inputRef.current;
        const mode =
            textMode === TextMode.COMMAND ? TextMode.INSERT : TextMode.COMMAND;

        setTextMode(mode);

        if (input && mode === TextMode.INSERT) {
            input.focus();
            input.select();
        } else {
            prepareTextAndUpdate();
        }
    };

    return (
        <>
            {isEditable && <HighlightingLabel label={"Sign"} positionLeft={-140} positionTop={positionTop}/>}
            <Component
                text={content}
                width={width}
                height={height}
                mode={textMode}
                size={size}
                isEditable={isEditable}
                lineHeight={lineHeight}
                inputRef={inputRef}
                colorTheme={colorTheme}
                fontFamily={fontFamily}
                positionTop={positionTop}
                signatureMedia={media}
                positionLeft={positionLeft}
                handleMouseUp={handleMouseUp}
                toggleEditMode={toggleEditMode}
                handleMouseOut={handleMouseOut}
                handleMouseDown={handleMousedown}
                handleMouseMove={handleMouseMove}
                removeAttachment={removeAttachment}
                onHandleSignatureModal={onHandleSignatureModal}
                id={id}
            />
        </>

    );
};
