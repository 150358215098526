import React, {useState} from "react"
import styles from "../../../../styles/contract.module.css"
import SignaturePad from "react-signature-canvas";
import {nanoid} from "nanoid";

const DrawSignature = ({onSignatureDraw}) => {
    let sigPad = {}
    const [isEmpty,setIsEmpty] = useState(true)

    const onStrokeEnd = async () => {
        let base64Image = sigPad.getTrimmedCanvas().toDataURL("image/png")
        const blob = await fetch(base64Image).then((res) => res.blob());
        let metadata = {
            type: 'image/png'
        };
        setIsEmpty(false)
        onSignatureDraw(new File([blob], `${nanoid()}.png`, metadata))
    }
    const onClear = () => {
        sigPad.clear()
        setIsEmpty(true)
    }
    return(
        <>
            <div className={styles.draw__signature__wrapper}>
                {!isEmpty && <button className={styles.clear__button} onClick={onClear}>
                    Clear
                </button>}
                <div className={styles.signature__container}>
                    <SignaturePad
                        onEnd={onStrokeEnd}
                        canvasProps={{ className: styles.sigPad }}
                        ref={(ref) => {
                            setIsEmpty(ref?.isEmpty())
                            sigPad = ref;
                        }}
                    />
                </div>
            </div>
        </>
    )
}
export default DrawSignature
