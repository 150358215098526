import React from 'react';
import {TextMode} from "../../../../utils/constants/contract";
import styles from "../../../../styles/contract.module.css"
import {AiOutlineClose} from "react-icons/ai"
import {MEDIA_BASEURL} from "../../../../utils/constants";
import {BsPencil} from "react-icons/bs";
// interface Props {
//   inputRef: RefObject<HTMLInputElement>;
//   text?: string;
//   mode: string;
//   width: number;
//   size?: number;
//   height: number;
//   lineHeight?: number;
//   fontFamily?: string;
//   positionTop: number;
//   positionLeft: number;
//   toggleEditMode: () => void;
//   handleMouseDown: DragEventListener<HTMLDivElement>;
//   handleMouseUp: DragEventListener<HTMLDivElement>;
//   handleMouseMove: DragEventListener<HTMLDivElement>;
//   handleMouseOut: DragEventListener<HTMLDivElement>;
//   onChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }

export const InitialsInput = ({
                                   width,
                                   height,
                                   mode,
                                   size,
                                   positionTop,
                                   positionLeft,
                                   onHandleSignatureModal,
                                   toggleEditMode,
                                   handleMouseDown,
                                   handleMouseMove,
                                   handleMouseOut,
                                   handleMouseUp,
                                   lineHeight,
                                   removeAttachment,
                                   isEditable,
                                   colorTheme,
                                   signatureMedia,
                                  id
                               }) => {
    return (
        <>
            {!!isEditable && (
                <div
                    style={{
                        width:width,
                        height,
                        lineHeight,
                        top: positionTop,
                        left: positionLeft,
                        wordWrap: 'break-word',
                        padding: 0,
                        position: 'absolute',
                    }}
                >
                    {!signatureMedia && <button
                        className={`${styles.disabled__pdf__input} ${styles.disabled__pdf__input__color}`}
                        type={"button"}
                        onClick={onHandleSignatureModal}
                        style={{
                            fontSize: size,
                        }}
                    >
                        <BsPencil/>
                        Initial
                    </button>}
                    {!!signatureMedia && <img className={styles.signature__media}  style={{width:width}} src={`${MEDIA_BASEURL}${signatureMedia}`} alt={"Signature"}/>}
                </div>)}
            {!isEditable && (<div
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onDoubleClick={toggleEditMode}
                id={id}
                style={{
                    width:width,
                    cursor: mode === TextMode.COMMAND ? 'move' : 'default',
                    top: positionTop,
                    left: positionLeft,
                    padding: 0,
                    position: 'absolute',
                }}
            >
                <button
                    className={styles.disabled__pdf__input}
                    type={"button"}
                    style={{
                        fontSize:size,
                        border:`1px solid ${colorTheme}`,
                        color:colorTheme
                    }}
                >
                    <BsPencil/>
                    Initial
                </button>
                <button type={"button"} onClick={removeAttachment} className={styles.remove__pdf__field}>
                    <AiOutlineClose/>
                </button>
            </div>)}
        </>

    );
};
