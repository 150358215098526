import React, {Fragment, useState} from "react"
import styles from "../../../styles/common/tabs.module.css"
import {nanoid} from "nanoid";
import useWindowSize from "../../../hooks/use-window-size";

const CustomTabs = ({tabs,setSelectedTab,selectedTab}) => {
    const {width} = useWindowSize()
    return(
        <>
            <div className={styles.custom__tabs__wrapper}>
                {width > 767 && tabs.map(el => (
                    <Fragment key={nanoid()}>
                        <button onClick={() => setSelectedTab(el)} className={`${selectedTab === el ? styles.active__button : styles.inactive__button}`}>
                            {el}
                        </button>
                    </Fragment>
                ))}
                {width <= 767 && <select name={"selectedTab"} onChange={(e) => setSelectedTab(e.target.value)} value={selectedTab}>
                    {tabs.map((el, i) => {
                        return (
                            <Fragment key={`${el}_${i}`}>
                                <option value={el} selected={selectedTab === el}>
                                    {el}
                                </option>
                            </Fragment>
                        )
                    })}
                </select>}
            </div>
        </>
    )
}
export default CustomTabs
