import React, {useState} from "react"
import {Wizard} from "react-use-wizard";
import {useOutletContext} from "react-router";
import CreateListingFormWrapper from "../components/presentational/listing/create-listing/create-listing-form-wrapper";
import ApplicationInformationForm
    from "../components/stateful/listing/create-listing/create-application/application-information-form";
import OccupantsForm from "../components/stateful/listing/create-listing/create-application/occupants-form";
import AddressHistoryForm from "../components/stateful/listing/create-listing/create-application/address-history-form";
import FinancialInformationForm
    from "../components/stateful/listing/create-listing/create-application/financial-information-form";
import TransportationForm from "../components/stateful/listing/create-listing/create-application/transportation-form";
import DocumentsForm from "../components/stateful/listing/create-listing/create-application/documents-form";
import BackgroundCheckForm
    from "../components/stateful/listing/create-listing/create-application/background-check-form";


const TooltipDescription = () => {
    return(
        <>
            <h6>Recommended Documents:</h6>
            <p>Official ID</p>
            <p>Employment Letter</p>
            <p>Proof of Income (ex. paystub)</p>
            <p>Credit Report</p>
        </>
    )
}
const CreateApplicationListing =() => {
    const {listingData} = useOutletContext()
    const [redirectCount,setRedirectCount] = useState(0)


    return(
        <>
            <Wizard
                startIndex={0}
            >
                <CreateListingFormWrapper
                    form={<ApplicationInformationForm applicationData={listingData} count={redirectCount} setCount={setRedirectCount}/>}
                    title={"Application Information"}
                />
                <CreateListingFormWrapper
                    form={<OccupantsForm applicationData={listingData}/>}
                    title={"Occupants"}
                />
                <CreateListingFormWrapper
                    form={<AddressHistoryForm applicationData={listingData}/>}
                    title={"Address History"}
                />
                <CreateListingFormWrapper
                    form={<FinancialInformationForm applicationData={listingData}/>}
                    title={"Financial Information"}
                />
                <CreateListingFormWrapper
                    form={<TransportationForm applicationData={listingData}/>}
                    title={"Transportation"}
                />
                <CreateListingFormWrapper
                    form={<DocumentsForm applicationData={listingData}/>}
                    title={"Documents"}
                    TooltipDescription={<TooltipDescription/>}
                />
                <CreateListingFormWrapper
                    form={<BackgroundCheckForm applicationData={listingData}/>}
                    title={"Background Check"}
                />
            </Wizard>
        </>
    )
}
export default CreateApplicationListing
