import React from "react"
import {AiOutlineSearch} from "react-icons/ai"
import propTypes from "prop-types"
import style from "../../../styles/common/form.module.css";
import {Form} from "react-bootstrap";

const BaseSearch = ({inputEvent,submitCallback,extraClass,placeholder = "Search"}) => {
    return(
        <>
            <div className={`${style.base__search__wrapper} ${style[extraClass]}`}>
                <Form onSubmit={submitCallback}>
                    <label>
                        <AiOutlineSearch/>
                    </label>
                    <input type="text" name="search" onChange={inputEvent} placeholder={placeholder}/>
                </Form>
            </div>

        </>
    )
}
export default BaseSearch;

BaseSearch.propType = {
    inputEvent : propTypes.func.isRequired,
    submitCallback : propTypes.func.isRequired,
    extraClass : propTypes.string,
    placeholder: propTypes.string
}
