import React from "react"
import propTypes from "prop-types";
import styles from "../../styles/listing.module.css"
import {useNavigate} from "react-router";
import {LISTING} from "../../utils/constants";
import useWindowSize from "../../hooks/use-window-size";

const CreateListingTopBar = ({completionPercentage,listing}) => {
    const navigate = useNavigate()
    const {width} = useWindowSize()

    const redirectUrl = listing === LISTING.APPLICATION_LISTING ? "/application" : listing === LISTING.UNIT_LISTING ? "/home" : "/"
    return(
        <>
            <section className={styles.create__listing__top__bar}>
                <div className={styles.top__bar__buttons}>
                    {width > 767 && <button onClick={() => navigate(redirectUrl)}>
                        Save And Continue Later
                    </button>}
                    <div className={styles.percentage__wrapper}>
                        <div className={styles.percentage__inner__wrapper}>
                            <p>
                                {completionPercentage}%
                            </p>
                            <div className={`${styles.centr} ${completionPercentage > 50 ? styles.over50 : ""} p${completionPercentage}`}>
                                <div className={`${styles.value__bar} value-bar`}/>
                                <div className={styles.left__half}/>
                            </div>
                            <div className={styles.centrV}/>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default CreateListingTopBar

CreateListingTopBar.propType = {
    completionPercentage : propTypes.number.isRequired
}
