import React, {Fragment, useRef, useState} from "react"
import MobileTable from "../../../../common/tables/mobile-table";
import {nanoid} from "nanoid";
import tableStyles from "../../../../../styles/common/tables.module.css"
import styles from "../../../../../styles/listing.module.css";
import {BsThreeDotsVertical} from "react-icons/bs";
import useOutsideAlerter from "../../../../../hooks/use-outside-alerter";
import TokenService from "../../../../../services/token.service";
import { receiversRole} from "../../../../../utils/helpers";
import { DOCUMENT_ACTIONS, SIGNER_STATUS} from "../../../../../utils/constants/listing";
import {BiEditAlt} from "react-icons/bi";

const UnitDocumentCardMobile = ({onDeleteDocument,contractsListData,onAction,isRenter,onDeclineDocument}) => {
    const [showDropDown,setShowDropDown] = useState(false)
    const wrapperRef = useRef(null)
    const loggedInUserId = TokenService.getUser()?._id

    const outSideClickCallback = () => {
        setShowDropDown(-1)
    }
    useOutsideAlerter(wrapperRef,outSideClickCallback)

    const handleDropDown = (index) => {
        setShowDropDown(prev => prev !== index ? index : -1)
    }

    const mutatedDocumentsData = contractsListData?.map(el => ({
        signers:el?.signers,
        contractName:el?.name,
        _id:el?._id
    }))

    const loggedInUserSignerId = (contract) => contract?.signers.find(el => el?.userId === loggedInUserId)?._id
    const currentUserStatus = (contract) => contract?.signers?.find(el => el?.userId === loggedInUserId)?.status
    const isAllDraft = (contract) => contract?.signers?.every(el => el?.status === SIGNER_STATUS.DRAFT)
    const isAllSigned = (contract) => contract?.signers?.every(el => el?.status === SIGNER_STATUS.SIGNED)
    // const isMixed =  !(contract?.signers?.every(el => el?.status === SIGNER_STATUS.DRAFT) || contract?.signers?.every(el => el?.status === SIGNER_STATUS.SIGNED))
    const isLoggedInUserSent = (contract) => contract?.signers.find(el => el?.userId === loggedInUserId)?.status === SIGNER_STATUS.SENT


    const contractSignerStatus = (contract) => isAllDraft(contract) ? [{
        status:"Draft",
        color:"#5243AA"
    }] : contract?.signers.map(el => ({
        status:el?.status === SIGNER_STATUS.DRAFT ? "Draft" : el?.status === SIGNER_STATUS.SENT ? "Sent" : el?.status === SIGNER_STATUS.SIGNED ? "Signed" : el?.status === SIGNER_STATUS.DECLINED ? "Declined" : "Expired",
        color:el?.status === SIGNER_STATUS.DRAFT ? "#5243AA" : el?.status === SIGNER_STATUS.SENT ? "#FFC542" : el?.status === SIGNER_STATUS.SIGNED ? "#00875A" : el?.status === SIGNER_STATUS.DECLINED ? "#D92D20" : "#D92D20",
    }))

    return(
        <>
            <div className={styles.applicants__mobile__table__wrapper}>
                <MobileTable>
                    <div className={tableStyles.mobile__table__card__wrapper}>
                        {mutatedDocumentsData?.map((el,index)=>(
                            <Fragment key={nanoid()}>
                                <div className={tableStyles.mobile__table__card}>
                                    <div className={tableStyles.menu__dropdown}>
                                        <h6 className={tableStyles.menu__title}>{el?.contractName}</h6>
                                        {((!isRenter || (isRenter && currentUserStatus(el) === SIGNER_STATUS.SENT)) && !isAllSigned(el)) ? <div className={tableStyles.menu__dropdown__inner} >
                                            <button className={tableStyles.drop__down__button} onClick={() => handleDropDown(index)} title={"More Actions"}>
                                                <BsThreeDotsVertical/>
                                            </button>
                                            {(showDropDown === index) && <div className={tableStyles.drop__down} ref={wrapperRef}>
                                                <ul>
                                                    {!isRenter && <li onClick={() => onDeleteDocument(el?._id)}>
                                                        Delete
                                                    </li>}
                                                    {(isRenter && currentUserStatus(el) === SIGNER_STATUS.SENT) &&
                                                        <li onClick={() => onDeclineDocument(true, el?._id, loggedInUserSignerId(el))}>
                                                            Decline
                                                        </li>}
                                                </ul>
                                            </div>}
                                        </div>: <div></div>}
                                    </div>
                                    <div className={tableStyles.mobile__document__status__card}>
                                        <div className={tableStyles.mobile__document__status__card__list}>
                                            <h3>Signers</h3>
                                            <ul>
                                                {el?.signers?.map(el => {
                                                    return(
                                                        <Fragment key={el?._id}>
                                                            <li>{receiversRole(el?.isApplicant,el?.isOwner)}:<br/> {el?.name}</li>
                                                        </Fragment>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className={tableStyles.mobile__document__status__card__list}>
                                            <h3>Status</h3>
                                            <ul className={tableStyles.status}>
                                                {contractSignerStatus(el)?.length > 0 && contractSignerStatus(el)?.map(signerStatus => {
                                                    return(
                                                        <Fragment key={nanoid()}>
                                                            <li style={{color:signerStatus?.color}}>{signerStatus?.status}</li>
                                                        </Fragment>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={tableStyles.decision__button__wrapper}>
                                        {isAllDraft(el) && (
                                            <button className={tableStyles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.CONTINUE, {id:el?._id})}>
                                                Continue
                                            </button>
                                        )}
                                        {isLoggedInUserSent(el) && (
                                            <button className={tableStyles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.SIGN, {id:el?._id,signerId:loggedInUserSignerId(el)})}>
                                                <BiEditAlt/> Sign
                                            </button>
                                        )}
                                        {isAllSigned(el) && <button className={tableStyles.action__button} type={"button"} onClick={() => onAction(DOCUMENT_ACTIONS.DOWNLOAD, {media:el?.media})}>
                                            Download
                                        </button>}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </MobileTable>
            </div>
        </>
    )
}
export default UnitDocumentCardMobile
