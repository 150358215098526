import React, {useEffect, useState} from "react"
import styles from "../../styles/rent-collection.module.css";
import UnitTitle from "../../components/common/text/unit-title";
import {useOutletContext} from "react-router";
import EmptyContainer from "../../components/common/container/empty-container";
import { RENT_PAYMENT_TYPE} from "../../utils/constants/listing";
import RentUserInformation
    from "../../components/presentational/listing/single-unit/rent-collection/rent-user-information";
import PaymentDetailForm from "../../components/presentational/listing/single-unit/rent-collection/payment-detail-form";
import AddRentReceivingAccount
    from "../../components/presentational/listing/single-unit/rent-collection/add-rent-receiving-account";
import RentPaymentAccount
    from "../../components/presentational/listing/single-unit/rent-collection/rent-payment-account";
import PaymentTransactions
    from "../../components/presentational/listing/single-unit/rent-collection/payment-transactions";
import CustomTabs from "../../components/common/tabs/custom-tabs";
import UnitListingService from "../../services/unit-listing.service";
import DefaultLoader from "../../components/common/loaders/default-loader";
import UnitRentCollectionService from "../../services/unit-rent-collection.service";
import {dateFromISOString} from "../../utils/helpers";
import useUrlQuery from "../../hooks/use-url-query";
import useWindowSize from "../../hooks/use-window-size";

const tabList = ["Renter's Detail","Transaction"]
const componentStates = {
    IS_APPLICANT_ACCEPTED_STATE:false,
    IS_LISTING_PAYMENT_CREATED:false,
    FIRST_STATE:false,
    SECOND_STATE:false,
    THIRD_STATE:false,
}
const RentCollection = () => {
    //Api is called from the parent component i.e. index.js
    const {data:unitData} = useOutletContext()
    const {width} = useWindowSize()
    const [selectedTab,setSelectedTab] = useState(tabList[0])
    const [currentComponent,setCurrentComponent] = useState(componentStates)
    const [renterDetail,setRenterDetail] = useState({
        _id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    })
    const [paymentDetail,setPaymentDetail] = useState({
        oneTimeType:RENT_PAYMENT_TYPE.DEPOSIT,
        oneTimePrice:"",
        monthlyFirstPayment: dateFromISOString(new Date()),
        monthlyLastPayment: dateFromISOString(new Date()),
        monthlyAmount:"",
        monthlyDueOn:1,
    })
    const query = useUrlQuery()
    const Token = query.get("Token")

    const {useFetchAcceptedListingApplicants} = UnitListingService()
    const {useHandleCreateListingPayment,useFetchBankData,useHandleConfirmBankingAccount,useFetchVoPayUrl,useFetchListingPaymentData,useHandleConfirmRenterDetail,useHandleConfirmPaymentDetail,useFetchPaymentTransaction,useHandleAddBankAccount} = UnitRentCollectionService()

    //Fetch All Accepted Applicants In A Unit
    const {data:acceptedClientData,isLoading:isAcceptedClientLoading,isSuccess:isAcceptedClientSuccess} = useFetchAcceptedListingApplicants()
    //Create Listing Payment
    const {isLoading: isCreateListingPaymentLoading, mutate: handleCreateListingPayment} = useHandleCreateListingPayment()
    //Fetch Listing Payment Data
    const {data: listingPaymentData,isLoading: isListingPaymentDataLoading} = useFetchListingPaymentData(unitData?._id)
    //Confirm Renter Details
    const {isLoading: isConfirmRenterDetailLoading, mutate: handleConfirmRenterDetailMutation, isSuccess: isConfirmRenterSuccess} = useHandleConfirmRenterDetail()
    //Confirm Payment Details
    const {isLoading: isConfirmPaymentDetailLoading, mutate: handleConfirmPaymentDetailMutation, isSuccess: isConfirmPaymentSuccess} = useHandleConfirmPaymentDetail()
    //Fetch Unit Transactions
    const {data:unitPaymentTransaction,isLoading:isPaymentTransactionLoading} = useFetchPaymentTransaction()
    //
    const {data:voPayUrlData,isLoading: isFetchVoPayUrlLoading,mutate: fetchVoPayUrl,isSuccess:isFetchVoPayUrlSuccess} = useFetchVoPayUrl(unitData?._id)
    //Add Bank Account --> After redirected from voPay, call this api
    const {isLoading: isAddBankAccountLoading,isSuccess:isAddBankAccountSuccess} = useHandleAddBankAccount(Token,listingPaymentData?._id)
    //Fetch Bank Data
    const {data: bankData,isLoading: isBankDataLoading} = useFetchBankData(Token,isAddBankAccountSuccess)
    //Confirm Bank Account
    const {isLoading: isConfirmBankAccountLoading,mutate:handleConfirmBankingAccount} = useHandleConfirmBankingAccount()


    useEffect(()=>{
        if(isAcceptedClientSuccess && acceptedClientData?.data?.length > 0){
            if (!!listingPaymentData){
                setCurrentComponent({
                    FIRST_STATE:listingPaymentData?.firstStepperCompleted, //Is Renter Confirmed
                    SECOND_STATE: listingPaymentData?.secondStepperCompleted, //Is Payment Confirmed,
                    THIRD_STATE: listingPaymentData?.thirdStepperCompleted, //Is Bank Account Confirmed
                    IS_LISTING_PAYMENT_CREATED:true, //Is Listing Payment Created
                    IS_APPLICANT_ACCEPTED_STATE:true, //Is There Any Accepted Clients?
                })
                setRenterDetail(listingPaymentData?.renter)
                setPaymentDetail({
                    oneTimeType:listingPaymentData?.details?.oneTime?.paymentType,
                    oneTimePrice:listingPaymentData?.details?.oneTime?.price > 0 ? listingPaymentData?.details?.oneTime?.price : "",
                    monthlyFirstPayment:listingPaymentData?.details?.monthly?.firstPayment ? dateFromISOString(listingPaymentData?.details?.monthly?.firstPayment) : dateFromISOString(new Date()),
                    monthlyLastPayment: listingPaymentData?.details?.monthly?.lastPayment ? dateFromISOString(listingPaymentData?.details?.monthly?.lastPayment) : dateFromISOString(new Date()),
                    monthlyAmount:listingPaymentData?.details?.monthly?.amount > 0 ? listingPaymentData?.details?.monthly?.amount : "",
                    monthlyDueOn:listingPaymentData?.details?.monthly?.dueOn,
                })
            }
            else{
                setCurrentComponent({
                    ...currentComponent,
                    IS_APPLICANT_ACCEPTED_STATE:true, //Is There Any Accepted Clients?
                })
            }
        }
    },[isAcceptedClientSuccess,listingPaymentData,isConfirmRenterSuccess,isConfirmPaymentSuccess])

    console.log(listingPaymentData,"listingPaymentData")
    console.log(bankData,"bankData")

    //Confirm Renter Details
    function handleConfirmRenterDetail(e) {
        e.preventDefault()
        handleConfirmRenterDetailMutation({paymentId:listingPaymentData?._id})
    }

    //Back Payment Detail
    function handleBackPaymentDetail() {
        setCurrentComponent({
            FIRST_STATE:false, //Is Renter Confirmed
            SECOND_STATE:false,
            THIRD_STATE: false,
            IS_LISTING_PAYMENT_CREATED:true, //Is Listing Payment Created
            IS_APPLICANT_ACCEPTED_STATE:true, //Is There Any Accepted Clients?
        })
    }
    //Confirm Payment Detail
    function handleConfirmPaymentDetail(e) {
        e.preventDefault()
        console.log(paymentDetail,"paymentDetail")
        handleConfirmPaymentDetailMutation(
            {
                paymentId:listingPaymentData?._id,
                paymentDetail:{
                    ...paymentDetail,
                    monthlyAmount: parseInt(paymentDetail?.monthlyAmount),
                    monthlyDueOn: parseInt(paymentDetail?.monthlyDueOn),
                    oneTimePrice: parseInt(paymentDetail?.oneTimePrice),
                    oneTimeType: parseInt(paymentDetail?.oneTimeType)
                }
        })
    }
    //Back Add Receiving/Bank Account
    function handleBackAddReceivingAccount() {
        setCurrentComponent({
            FIRST_STATE:true, //Is Renter Confirmed
            SECOND_STATE:false,
            THIRD_STATE: false,
            IS_LISTING_PAYMENT_CREATED:true, //Is Listing Payment Created
            IS_APPLICANT_ACCEPTED_STATE:true, //Is There Any Accepted Clients?
        })
    }
    //Confirm Adding Bank Account
    function handleConfirmBankAccount(e) {
        e.preventDefault()
        handleConfirmBankingAccount({paymentId:listingPaymentData?._id})
    }
    return(
        <>
            <section className={styles.single__unit__rent__collection__wrapper}>
                <div className={styles.single__unit__rent__collection__header}>
                    <UnitTitle title={unitData?.name}/>
                </div>
                {(isAcceptedClientLoading || isListingPaymentDataLoading || isPaymentTransactionLoading || isAddBankAccountLoading || isBankDataLoading) && <DefaultLoader/>}
                {!(isAcceptedClientLoading || isListingPaymentDataLoading || isPaymentTransactionLoading || isAddBankAccountLoading || isBankDataLoading) && <div>
                    {!currentComponent.IS_APPLICANT_ACCEPTED_STATE && <div className={styles.empty__rent__wrapper}>
                        <div className={styles.empty__rent}>
                            <h6>You must accept an applicant first</h6>
                        </div>
                    </div>}
                    {currentComponent.IS_APPLICANT_ACCEPTED_STATE && (
                        <>
                            {!currentComponent.IS_LISTING_PAYMENT_CREATED && <EmptyContainer
                                title={"Rent Payment"}
                                description={"Ready to start collecting rent online? Get set up in minutes at zero cost to you"}
                                buttonText={"Start Collecting Rent"}
                                clickHandler={handleCreateListingPayment}
                                isLoading={isCreateListingPaymentLoading}
                            />}
                            {currentComponent.IS_LISTING_PAYMENT_CREATED && (
                                <>
                                    {(!currentComponent.FIRST_STATE && !currentComponent.SECOND_STATE && !currentComponent.THIRD_STATE) && <RentUserInformation
                                        title={"Your Renter"}
                                        isHalf={width > 767}
                                        fields={renterDetail}
                                        handleConfirmRenterCallback={handleConfirmRenterDetail}
                                        isLoading={isConfirmRenterDetailLoading}
                                        isNotEditable
                                    />}
                                    {(currentComponent.FIRST_STATE && !(currentComponent.SECOND_STATE || currentComponent.THIRD_STATE)) && <PaymentDetailForm
                                        title={"Payment Detail"}
                                        fields={paymentDetail}
                                        isLoading={isConfirmPaymentDetailLoading}
                                        handleBackPaymentDetailCallback={handleBackPaymentDetail}
                                        handleConfirmPaymentDetailCallback={handleConfirmPaymentDetail}
                                        updateFields={setPaymentDetail}
                                        transactionData={unitPaymentTransaction}
                                        isHalf={width > 767}
                                    />}
                                    {(currentComponent.SECOND_STATE && !currentComponent.THIRD_STATE && !bankData) && <AddRentReceivingAccount
                                        title={"Your Receiving Account"}
                                        handleBackAddReceivingAccountCallback={handleBackAddReceivingAccount}
                                        isFetchVoPayUrlLoading={isFetchVoPayUrlLoading}
                                        voPayUrlData={voPayUrlData}
                                        fetchVoPayUrl={fetchVoPayUrl}
                                        isFetchVoPayUrlSuccess={isFetchVoPayUrlSuccess}
                                        isHalf={width > 767}
                                    />}


                                    {(currentComponent.SECOND_STATE && !currentComponent.THIRD_STATE && !!bankData) && <RentPaymentAccount
                                        title={"Payment Account"}
                                        description={"The bank account that receives your regular payouts"}
                                        editBankAccount={fetchVoPayUrl}
                                        isEditBankAccountLoading={isFetchVoPayUrlLoading}
                                        isEditBankAccountSuccess={isFetchVoPayUrlSuccess}
                                        editBankAccountData={voPayUrlData}
                                        bankData={bankData}
                                        handleConfirmPaymentAccountCallback={handleConfirmBankAccount}
                                        isLoading={isConfirmBankAccountLoading}
                                        isHalf={width > 767}
                                    />}
                                    {currentComponent.THIRD_STATE && (
                                        <>
                                            <CustomTabs tabs={tabList} setSelectedTab={setSelectedTab} selectedTab={selectedTab}/>
                                            {selectedTab === "Transaction" && <PaymentTransactions unitPaymentTransaction={unitPaymentTransaction}/>}
                                            {selectedTab === "Renter's Detail" && <div className={styles.rent__collection__content__wrapper}>
                                                <PaymentDetailForm
                                                    isNotEditable
                                                    fields={paymentDetail}
                                                />
                                                <RentUserInformation
                                                    subtitle={"Your Renter"}
                                                    fields={renterDetail}
                                                    isNotEditable
                                                />
                                            </div>}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                </div>}
            </section>
            {/*<section className={styles.single__unit__wrapper}>*/}
            {/*    <UnitTitle title={unitData?.name}/>*/}
            {/*    <ComingSoon text={'Seamless integration with both the tenant and landlord banks to automate rent payment and collection'}/>*/}
            {/*</section>*/}
        </>
    )
}
export default RentCollection
