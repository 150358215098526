import React, {useEffect} from "react"
import styles from "../../../styles/common/modal.module.css"
import propTypes from "prop-types";
import ButtonLoader from "../loaders/button-loader";
import {RiErrorWarningLine} from "react-icons/ri"
import successIcon from "../../../assets/images/success-icon.png"

const InfoModal = ({handleModalClose,title,description,isLoading,mutate,isSuccess,confirmButtonText,cancelButtonText,onSuccess,reset,isConfirmationModal,isInfo}) => {
    const handleConfirmation = () =>{
        mutate()
    }

    useEffect(()=>{
        if(isSuccess){
            handleModalClose(false)
            if(reset){
                reset()
            }
            if(!!onSuccess){
                onSuccess()
            }
        }
    },[isSuccess])
    return(
        <>
            <section className={styles.warning__modal__wrapper}>
                {!isConfirmationModal && <h6>
                    <div className={isInfo ? styles.info__icon : styles.warning__icon}>
                        <span><RiErrorWarningLine/></span>
                    </div>
                </h6>}
                {isConfirmationModal && <div className={styles.top__img}><img src={successIcon} alt={"Success"}/></div>}
                <h5>{title}</h5>
                <p>{description}</p>
                <div className={isConfirmationModal ? styles.confirmation__button__wrapper : isInfo ? styles.info__button__wrapper :   styles.warning__button__wrapper}>
                    {isLoading ? <ButtonLoader/> : (
                        <>
                            <button onClick={() => handleModalClose(false)}>{cancelButtonText ? cancelButtonText : 'Cancel'}</button>
                            <button onClick={handleConfirmation}>{confirmButtonText}</button>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}
export default InfoModal;

InfoModal.propType = {
    handleModalClose : propTypes.func.isRequired,
    title : propTypes.string.isRequired,
    description : propTypes.string.isRequired,
    isLoading : propTypes.bool.isRequired,
    isSuccess : propTypes.bool.isRequired,
    mutate : propTypes.func.isRequired,
    confirmButtonText: propTypes.string.isRequired,
    onSuccess : propTypes.func,
    reset : propTypes.func
}
