import React, {useEffect, useState} from "react";
import propTypes from "prop-types";
import styles from "../../../../../styles/listing.module.css";
import TokenService from "../../../../../services/token.service";
import {useNavigate, useParams} from "react-router";
import UnitListingService from "../../../../../services/unit-listing.service";
import ButtonLoader from "../../../../common/loaders/button-loader";
import ApplicationListingService from "../../../../../services/application-listing.service";
import {toast} from "react-toastify";
import PrimaryModal from "../../../../common/modals/primary-modal";
import SchedulerModal from "../../../../common/modals/schedular-modal";
import ScheduleViewing from "../../../calendar/schedule-viewing";
import UnitViewingService from "../../../../../services/unit-viewing.service";
import {ROLES, UNIT_ACTION_REDIRECT} from "../../../../../utils/constants";
import useUrlQuery from "../../../../../hooks/use-url-query";

const SingleUnitPreviewInteractions = ({ownerId}) => {
    const userId = TokenService.getUser()?._id
    const isRenter = TokenService.getUser()?.role === ROLES.RENTER
    const applicationId = TokenService.getRenterApplicationId()
    const {unitId} = useParams()
    const {useHandleApplyForListingService} = UnitListingService()
    const {useFetchUserApplicationStatus,useHandleListApplicationService} = ApplicationListingService()
    const {useHandleAddViewing} = UnitViewingService()

    const [isFetchApplicationStatus,setIsFetchApplicationStatus] = useState(false)
    const {mutate : applyForListing, isLoading : isApplyForListingLoading ,isSuccess : isApplyForListingSuccess,error : applyForListingError,isError:isApplyForListingError} = useHandleApplyForListingService()
    const {data:listApplicationData,mutate : createOneApplication,isLoading : createApplicationLoading, isSuccess:isListApplicationSuccess} = useHandleListApplicationService()

    const {data : ApplicationStatus,isLoading : isApplicationStatusLoading ,isSuccess : isApplicationStatusSuccess} = useFetchUserApplicationStatus(isFetchApplicationStatus)
    const {mutate: handleAddViewing, isLoading: isAddViewingLoading, isSuccess: isAddViewingSuccess, reset:addViewingReset} = useHandleAddViewing()

    const navigate = useNavigate()

    const applyToListing = () => {
        if(!userId){
            navigate("/login")
            toast.info("Please Login To Apply To This Listing")
            return
        }
        applyForListing()
    }


    //Redirected from the public search website
    const url = useUrlQuery()
    const redirectResponse = url.get("redirect")
    useEffect(()=>{
        if (redirectResponse === UNIT_ACTION_REDIRECT.APPLY){
            if (ownerId !== userId){
                applyToListing()
            }else{
                toast.info("Please Login As A Renter To Apply")
            }
        }else if (redirectResponse === UNIT_ACTION_REDIRECT.BOOK_VIEWING){
            handleAddViewingModal(true)
        }
    },[])

    //If Application is not yet created, create the renter application
    useEffect(()=>{
        if(isApplyForListingError){
            if(applyForListingError.response.status === 405){
                createOneApplication()
            }
        }
    },[isApplyForListingError])


    //After the application is created once again try to apply for listing
    useEffect(()=>{
        if(isListApplicationSuccess){
            navigate(`/create-application-listing/${listApplicationData.data?.data?.applicationId}`,{state:{unitId}})
        }
    },[isListApplicationSuccess])

    useEffect(()=>{
        if(isApplyForListingSuccess){
            if(applicationId){
                navigate(`/create-application-listing/${applicationId}`,{state:{unitId}})
                setIsFetchApplicationStatus(false)
            }else{
                setIsFetchApplicationStatus(true)
            }
        }
    },[isApplyForListingSuccess])
    useEffect(()=>{
        if(isApplicationStatusSuccess && isApplyForListingSuccess){
            navigate(`/create-application-listing/${ApplicationStatus?.data?.data?.applicationId}`,{state:{unitId}})
            setIsFetchApplicationStatus(false)
        }
    },[isApplicationStatusSuccess])

    //Add Viewing
    const [isAddViewingModalOpen,setIsAddViewingModalOpen] = useState(false)
    const handleAddViewingModal = (open) => {
        setIsAddViewingModalOpen(open)
    }
    return(
        <>
            <div className={styles.interaction_buttons}>
                {(!isApplyForListingLoading && !isApplicationStatusLoading && !createApplicationLoading) && <button disabled={ownerId === userId} onClick={applyToListing}>Apply now</button>}
                {(isApplyForListingLoading || isApplicationStatusLoading || createApplicationLoading) && <ButtonLoader/>}
                <button disabled={!userId} type={"button"} onClick={handleAddViewingModal}>Book viewing</button>
            </div>
            {isAddViewingModalOpen &&
                <PrimaryModal
                    size={"lg"}
                    // customClass={"half__modal"}
                    isOpen={isAddViewingModalOpen}
                    modalContent={
                        <SchedulerModal
                            handleModalClose={handleAddViewingModal}
                            isCloseIcon
                            isFooter={false}
                            reset={addViewingReset}
                            Content={<ScheduleViewing onCreateSchedule={handleAddViewing} isAddViewingLoading={isAddViewingLoading} unitId={unitId} renterId={isRenter ? userId : false}/>}
                            isSuccess={isAddViewingSuccess}
                            title={"Schedule Viewing"}
                            confirmButtonText={"Schedule"}
                        />}
                />
            }
        </>
    )
}
export default SingleUnitPreviewInteractions

SingleUnitPreviewInteractions.propType = {
    ownerId : propTypes.string.isRequired
}
