import React, {Fragment, useContext, useEffect, useState} from "react";
import useRedirectToCurrentStep from "../../../../../hooks/use-redirect-to-current-step";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import {FORM_TYPE} from "../../../../../utils/constants";
import useForm from "../../../../../hooks/use-form";
import {AiOutlineClose} from "react-icons/ai";
import {IoAddCircleOutline} from "react-icons/io5"
import ApplicationListingService from "../../../../../services/application-listing.service";

const TransportationForm = ({applicationData}) => {
    const {previousStep, nextStep} = useWizard();
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)

    const {useHandleUpdateTransportationService} = ApplicationListingService()


    const [fields,setFields] = useState({
        isVehicle:false,
    })

    const [transportation,setTransportation] = useState([])


    const {details,inputEvent} = useForm(fields)

    const handleVehicleChange = (e) => {
        inputEvent(e,FORM_TYPE.CHECKBOX);
        if(e.target.checked){
            setTransportation([
                {
                    monthlyPayment: "",
                    _id : 123
                },
            ])
        }else{
            setTransportation([])
        }
    }
    useEffect(()=>{
        if(applicationData?.transportation?.length > 0){
            setTransportation(applicationData?.transportation)
            setFields({
                isVehicle: true
            })
        }
    },[applicationData?.transportation])

    const handleTransportationChange = (index, clickedInput) => (e) => {
        transportation[index] = {
            ...transportation[index],
            [`${clickedInput}`]: e.target.value,
        };
        setTransportation([...transportation]);
    };

    const removeTransportationField = (i) => {
        let newFormValues = [...transportation];
        newFormValues.splice(i, 1);
        setTransportation(newFormValues);
    }
    const addVehicle = () => {
        const _id = Math.floor(Math.random() * 100)
        setTransportation([
            ...transportation,
            // generating unique keys for each object
            {
                monthlyPayment: "",
                _id
            },
        ]);
    }

    const {mutate,isLoading,isSuccess} = useHandleUpdateTransportationService({transportation:transportation.map((el)=> {
            return{
                monthlyPayment: el.monthlyPayment
            }
    })})

    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()

        mutate()
    }

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    fifthStepperCompleted:true
                }
            })
            nextStep().then()
        }
    },[isSuccess])

    return(
        <>
            <Form className={styles.create__listing__form__alternate} onSubmit={goToNextStep}>
                {/*--OCCUPANTS--*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Group className={styles.form__check__group}>
                        <label>Do you have a vehicle?</label>
                        <Form.Check
                            type="switch"
                            name={"isVehicle"}
                            value={details.isVehicle}
                            onChange={handleVehicleChange}
                            checked={details.isVehicle}
                        />
                    </Form.Group>
                </Form.Group>
                {(details.isVehicle && transportation?.length > 0) && <div>
                    <Form.Group className={styles.form__group__wrapper}>
                        <div className={styles.form__group__outer__wrapper}>
                            <Form.Group className={styles.form__group}>
                                <Form.Label>How much is the monthly payment for your vehicle?</Form.Label>
                                {/*className={error["unitSize"] ? "error" : ""}*/}
                                <Form.Control type={"number"} name={"monthlyPayment"} value={transportation[0].monthlyPayment || ""}
                                              onChange={handleTransportationChange(0,"monthlyPayment")} placeholder={"$ 5000.00"} required/>
                            </Form.Group>
                        </div>
                    </Form.Group>
                    {/*<Form.Group className={styles.form__group__wrapper}>*/}
                    {/*    <div className={styles.form__group__outer__wrapper}>*/}
                    {/*        <Form.Group className={styles.form__group}>*/}
                    {/*            <Form.Label>How much do you get paid monthly after tax?</Form.Label>*/}
                    {/*            /!*className={error["unitSize"] ? "error" : ""}*!/*/}
                    {/*            <Form.Control type={"number"} name={"monthlyAfterTax"} value={transportation[0].monthlyAfterTax || ""}*/}
                    {/*                          onChange={handleTransportationChange(0,"monthlyAfterTax")} placeholder={"$ 5000.00"} required/>*/}
                    {/*        </Form.Group>*/}
                    {/*    </div>*/}
                    {/*</Form.Group>*/}
                </div>}
                {(details.isVehicle) &&
                    <div className={styles.additional__fields__wrapper}>
                        {transportation.map((val, index) => {
                            return (
                                <Fragment key={val?._id}>

                                    {index !== 0 && <div className={styles.additional__fields__inner__wrapper}>
                                        <button type={"button"} onClick={() => removeTransportationField(index)}
                                                className={styles.close__field}><AiOutlineClose/></button>

                                        <Form.Group className={styles.form__group__wrapper}>
                                            <div className={styles.form__group__outer__wrapper}>
                                                <Form.Group className={styles.form__group}>
                                                    <Form.Label>How much is the monthly payment for your vehicle?</Form.Label>
                                                    {/*className={error["unitSize"] ? "error" : ""}*/}
                                                    <Form.Control type={"number"} name={"monthlyPayment"} value={val.monthlyPayment || ""}
                                                                  onChange={handleTransportationChange(index,"monthlyPayment")} placeholder={"$ 5000.00"} required/>
                                                </Form.Group>
                                            </div>
                                        </Form.Group>
                                        {/*<Form.Group className={styles.form__group__wrapper}>*/}
                                        {/*    <div className={styles.form__group__outer__wrapper}>*/}
                                        {/*        <Form.Group className={styles.form__group}>*/}
                                        {/*            <Form.Label>How much do you get paid monthly after tax?</Form.Label>*/}
                                        {/*            /!*className={error["unitSize"] ? "error" : ""}*!/*/}
                                        {/*            <Form.Control type={"number"} name={"monthlyAfterTax"} value={val.monthlyAfterTax || ""}*/}
                                        {/*                          onChange={handleTransportationChange(index,"monthlyAfterTax")} placeholder={"$ 5000.00"} required/>*/}
                                        {/*        </Form.Group>*/}
                                        {/*    </div>*/}
                                        {/*</Form.Group>*/}
                                    </div>}
                                </Fragment>
                            )
                        })}
                    </div>}
                {details?.isVehicle && <button className={styles.add__vehicle__btn} type={"button"} onClick={addVehicle}>Add One More
                    Vehicle <IoAddCircleOutline/></button>}
                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading}
                />
            </Form>

        </>
    )
}
export default TransportationForm