import React from "react"
import styles from "../../../styles/common/text.module.css";
import home from "../../../assets/images/home.png";
import Title from "./title";

const UnitTitle = ({title}) => {
    return(
        <>
            <div className={styles.title__wrapper}>
                <img src={home} alt={"Home"}/>
                <Title title={title}/>
            </div>
        </>
    )
}
export default UnitTitle
