import React, {Fragment} from "react";
import styles from "../../../../../styles/viewing.module.css";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";


const durations = [15, 30, 45, 60]
const ViewingDuration = ({setDuration,fields}) => {
    const {details,inputEvent} = useForm(fields)

    const onDurationChange = (e) => {
        inputEvent(e)
        setDuration(parseInt(e.target.value))
    }
  return(
      <>
          <div>
              <Form.Group className={styles.form__group__wrapper}>
                  <Form.Label className={styles.label}>Duration</Form.Label>
                  <Form.Select
                      name={"duration"}
                      onChange={onDurationChange}
                      value={details.duration}
                      required={true}
                      className={styles.input}
                  >
                      {durations.map((duration,index)=>{
                          return(
                              <Fragment key={index}>
                                  <option value={duration}>{duration} Min</option>
                              </Fragment>
                          )
                      })}
                  </Form.Select>
              </Form.Group>
          </div>
      </>
  )
}
export default ViewingDuration
