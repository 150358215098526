import React, {Fragment, useContext} from "react";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import AssetUploader from "../../../../common/uploaders/asset-uploader";
import uploaderIcon from "../../../../../assets/images/uploader-icon.png"
import {useParams} from "react-router";
import TokenService from "../../../../../services/token.service";
import {useQueryClient} from "react-query";
import ViewMedia from "./view-media";
import {toast} from "react-toastify";
import {WEBSITE} from "../../../../../utils/constants";

const DocumentsForm = ({applicationData}) => {
    const {previousStep, nextStep} = useWizard();
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)
    const userData = TokenService.getUser()

    const formData = new FormData();
    const {applicationId} = useParams()
    const userId = TokenService?.getUser()?._id
    const queryClient = useQueryClient()


    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()
        setCompletedStepperInThisSession(prevValue => {
            return{
                ...prevValue,
                sixthStepperCompleted:true
            }
        })

        nextStep().then()
        // mutate()
    }
    const assetHandler = () => {
        // console.log(file)
    }


    const uploadFileToServer = (isApplicant,applicantName) => {
        return {
            process: (fieldName, file, metadata, load, error, progress, abort) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                formData.delete("name");
                formData.delete("media[]");
                formData.delete("isApplicant");
                const fileArray = [file]


                formData.append("name", applicantName);
                Array.from(fileArray).forEach(file => {
                    formData.append("media",file)
                })
                // formData.append("media", [file]);
                formData.append("isApplicant", isApplicant);

                const request = new XMLHttpRequest();
                request.open('PUT', `${process.env.REACT_APP_API_URL}api/v1/application/documents?userId=${userId}&applicationId=${applicationId}`);
                request.setRequestHeader("x-access-token",TokenService.getLocalAccessToken())

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                };
                // // Should call the load method when done and pass the returned server file id
                // // this server file id is then used later on when reverting or restoring a file
                // // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                        // the load method accepts either a string (id) or an object
                        queryClient.setQueryData([`application-listing-by-id`,applicationId], (oldQueryData)=>{
                            return{
                                ...oldQueryData,
                                data : {
                                    data:{
                                        ...JSON.parse(request.responseText)?.data
                                    }

                                }
                            }
                        })
                        toast.success(`${file?.name} uploaded`)
                        load(request.responseText);
                    } else {
                        // Can call the error method if something is wrong, should exit after
                        error('oh no');
                    }
                };

                request.send(formData);

                // // Should expose an abort method so the request can be cancelled
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    },
                };
            },
        }
    }
    return(
        <>
            <Form className={styles.add__media__form} onSubmit={goToNextStep}>
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>{`${userData?.firstName} ${userData?.lastName}`}</Form.Label>
                </Form.Group>
                <div>
                    <AssetUploader
                        notRequired
                        server={uploadFileToServer(true,`${userData?.firstName} ${userData?.lastName}`)}
                        customFileTypes={['image/png','image/jpg','image/jpeg','application/pdf']}
                        getUploadedAsset={assetHandler}
                        customClasses={"application__uploader"}
                        removeFilesOnUpload
                        placeholder={`
                            <img src=${uploaderIcon} alt='Icon'/><br/> 
                                <p class="renter__documents__uploader__label">
                                <span>Click to upload</span> or drag and drop <br/> 
                                SVG, PNG, JPG or PDF <small>(Max. 10 MB)</small>
                            </p>                
                        `}
                    />
                </div>
                <div className={styles.uploaded__file__list__wrapper}>
                    {applicationData?.documents
                        .filter((el)=>el?.name === `${userData?.firstName} ${userData?.lastName}` && el?.isApplicant)
                        .map((document)=>{
                            return(
                                <Fragment key={document?._id}>
                                    {document?.media?.length > 0 && document?.media?.map((media)=>{
                                        return(
                                            <Fragment key={media?._id}>
                                                <ViewMedia media={media}/>
                                            </Fragment>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                </div>
                {applicationData?.occupants?.adults?.map((adult)=>{
                    return(
                        <Fragment key={adult?._id}>
                            <div className={styles.document__form__wrapper}>
                                <Form.Group className={styles.form__group__wrapper}>
                                    <Form.Label className={styles.main__label}>{adult?.name}</Form.Label>
                                </Form.Group>
                                <div>
                                    <AssetUploader
                                        notRequired
                                        server={uploadFileToServer(false,adult?.name)}
                                        customFileTypes={['image/png','image/jpg','image/jpeg','application/pdf']}
                                        getUploadedAsset={assetHandler}
                                        customClasses={"application__uploader"}
                                        removeFilesOnUpload
                                        placeholder={`
                                            <img src=${uploaderIcon} alt='Icon'/><br/> 
                                            <p class="renter__documents__uploader__label">
                                                <span>Click to upload</span> or drag and drop <br/> 
                                                SVG, PNG, JPG or PDF <small>(Max. 10 MB)</small>
                                            </p>
                                        `}
                                    />
                                </div>
                                <div className={styles.uploaded__file__list__wrapper}>
                                    {applicationData?.documents
                                        .filter((el)=>el?.name === adult?.name && !el?.isApplicant)
                                        .map((document)=>{
                                            return(
                                                <Fragment key={document?._id}>
                                                    {document?.media?.length > 0 && document?.media?.map((media)=>{
                                                        return(
                                                            <Fragment key={media?._id}>
                                                                <ViewMedia media={media}/>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>
                                            )
                                        })}
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={false}
                />
            </Form>

        </>
    )
}
export default DocumentsForm
