import React, {createContext, useState} from "react";
import {Outlet} from "react-router";
import styles from "../../../styles/listing.module.css"
import CreateListingTopBar from "../../../components/widgets/create-listing-top-bar";
import CreateListingSidebar from "../../../components/widgets/create-listing-sidebar";
import PageLoader from "../../../components/common/loaders/page-loader";
import {LISTING} from "../../../utils/constants";

/***
 * Create Listing Route --> Protected, Covers the basic widgets and auth
 * @returns {JSX.Element}
 * @constructor
 */


export const ActiveStepContext = createContext("")

const CreateListingLayout = ({isLoading,listingData,stepperList,listing}) => {
    const [activeStep,setActiveStep] = useState(0)

    //If the user exits through exit popup (in sidebar) --> all the truthy steppers (below object) will be discarded
    const [completedStepperInThisSession,setCompletedStepperInThisSession] = useState({
        firstStepperCompleted: false,
        secondStepperCompleted: false,
        thirdStepperCompleted: false,
        fourthStepperCompleted: false,
        fifthStepperCompleted: false,
        sixthStepperCompleted: false,
        seventhStepperCompleted: false,
    })

    const [goTo,setGoTo] = useState(null)

    const handleGoToStepper = (index) => {
        setGoTo(index)
    }

    //Access the last key by "listing" if it is unit and by "data" if it is application,, thanks to the backend dev :)
    const listingObject = listingData?.data?.[listing === LISTING.UNIT_LISTING ? "listing" : "data"]

    return (
        <>
            {isLoading && <PageLoader/>}
            {!isLoading && <section className={`${styles.create__listing__router__wrapper} ${styles.router__wrapper}`}>
                <CreateListingTopBar
                    completionPercentage={Math.floor(listingObject?.completion)}
                    listing={listing}
                />
                <ActiveStepContext.Provider value={{activeStep,setActiveStep,goTo,setGoTo,setCompletedStepperInThisSession}}>
                    <main className={styles.router__content}>
                        <CreateListingSidebar
                            completedStepperInThisSession={completedStepperInThisSession}
                            goToStepper={handleGoToStepper}
                            stepperList={stepperList}
                            listing={listing}
                        />
                        <div className={styles.router__inner__content}>
                            <Outlet context={{
                                listingData:listingObject,
                            }}/>
                        </div>
                    </main>
                </ActiveStepContext.Provider>
            </section>}
        </>
    );
};

export default CreateListingLayout;
