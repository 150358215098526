import React from "react"
import AddCoApplicants from "../../../../stateful/listing/single-unit/contract/add-co-applicants";
import UnitContractService from "../../../../../services/unit-contract.service";

const AddCoApplicantsContainer = ({onAddCoApplicant,contractId}) => {
    const {useHandleAddCoApplicantsToUnitDocument} = UnitContractService()

    const {mutate:addCoApplicant,isLoading:isAddCoApplicantLoading} = useHandleAddCoApplicantsToUnitDocument(contractId)
    const onSaveAddCoApplicant = (e,details) => {
        e.preventDefault()
        addCoApplicant(details)
    }
    return(
        <>
            <AddCoApplicants
                onAddCoApplicant={onAddCoApplicant}
                onSaveAddCoApplicant={onSaveAddCoApplicant}
                isLoading={isAddCoApplicantLoading}
            />
        </>
    )
}
export default AddCoApplicantsContainer
