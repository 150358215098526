import React from "react"
import styles from "../../../styles/account-settings.module.css"
import TokenService from "../../../services/token.service";


const PersonalDetailsForm = ({details,inputEvent}) => {
    const isVerified = TokenService.getUser()?.isVerified
    return(
        <>
            <div className={`${styles.form__group__wrapper} ${styles.forms__group__wrapper}`}>
                <div className={styles.form__group}>
                    <label>First Name</label>
                    <input type={"text"} name={"firstName"} value={details.firstName} onChange={inputEvent} disabled={isVerified} required/>
                </div>
                <div className={styles.form__group}>
                    <label>Last Name</label>
                    <input type={"text"} name={"lastName"} value={details.lastName} onChange={inputEvent} disabled={isVerified}  required/>
                </div>
            </div>
            <div className={`${styles.form__group__wrapper} ${styles.forms__group__wrapper}`}>
                <div className={styles.form__group}>
                    <label>Phone Number</label>
                    <input type={"text"} name={"phone"} value={details.phone} onChange={inputEvent} required/>
                </div>
                <div style={{width:'100%'}}/>
            </div>
        </>
    )
}
export default PersonalDetailsForm
