
export const HTTP_STATUS = Object.freeze({
    PENDING: 'PENDING',
    FULFILLED: 'FULFILLED',
    REJECTED: 'REJECTED',
})

export const ROLES = Object.freeze({
    OWNER: 'Owner',
    RENTER: 'Renter',
    AGENT : 'Agent'
})

export const APP_ENVIRONMENTS = Object.freeze({
    DEVELOPMENT : "DEV",
    PRODUCTION : "PROD"
})

export const FORM_TYPE = Object.freeze({
    CHECKBOX : 1,
})

export const EXTERNAL_RESPONSE = Object.freeze({
    SUCCESS : "success",
    ERROR : "error"
})
export const EXTERNAL_RESPONSE_TYPE = Object.freeze({
    STRIPE : "stripe-response",
    PERSONA : "persona-response"
})

export const UNIT_ACTION_REDIRECT = Object.freeze({
    BOOK_VIEWING:"bookViewing",
    APPLY:"apply",
})

export const LISTING = Object.freeze({
    UNIT_LISTING : 1,
    APPLICATION_LISTING : 2,
})

export const WEBSITE = `${process.env.REACT_APP_API_URL}`

export const PUBLIC_ENDPOINTS = ['/user-auth/signup','/user-auth/email-verification'
    ,'/user-auth/login','/user-auth/forgot-password','/user-auth/forgot-password/change-password'
    ,'/user-auth/user-validation']

/***
 * Media BaseUrl
 * @type {string}
 */
// export const MEDIA_BASEURL = "https://dev.renloc.com/uploads/"
export const MEDIA_BASEURL = `${process.env.REACT_APP_API_URL}uploads/`

//Socket Connection
export const SOCKET_CONNECTION_URL = `${process.env.REACT_APP_API_URL}message`
