import React, {useCallback, useState} from "react"
import styles from "../../../../../styles/contract.module.css"
import {Form} from "react-bootstrap";
import addMedia from "../../../../../assets/images/add-media-alt.png"
import {useDropzone} from "react-dropzone";
import ViewMedia from "../../../../stateful/listing/create-listing/create-application/view-media";
import useForm from "../../../../../hooks/use-form";
import ButtonLoader from "../../../../common/loaders/button-loader";
import {FORM_TYPE} from "../../../../../utils/constants";

const fields = {
    name:"",
    isLeasedContract:false
}
const UploadDocumentBox = ({isApplicantAccepted,onFileUploaded,isUploadContractLoading}) => {
    const [file,setFile] = useState(null)
    const [binaryFile,setBinaryFile] = useState(null)
    const {details,inputEvent} = useForm(fields)

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file) => {

            const reader = new FileReader()
            reader.readAsDataURL(file);

            reader.onload = () => {
                const binaryStr = reader.result

                setFile(file)
                setBinaryFile({
                    type:file?.type?.split("/")[0],
                    file:binaryStr,
                    originalName:file?.name,
                    isLink:false,
                    size:file?.size, //into KB
                })

                // reader.readAsArrayBuffer(file)
            }
        })
    },[])

    const {getRootProps, getInputProps} = useDropzone({onDrop, maxFiles:1, accept: {'application/pdf': ['.pdf'] } })

    function resetMedia() {
        setBinaryFile(null)
        setFile(null)
    }
    return(
        <>
            <Form className={styles.add__media__form} onSubmit={(e) => onFileUploaded(e,{file,name:details.name,isLeasedContract:details.isLeasedContract})}>
                {!binaryFile && <div className={`${styles.add__media__main__wrapper} ${styles.add__media__bg} ${isApplicantAccepted ? styles.allow__add : ''}`}>
                    <div className={styles.add__media__wrapper}>
                        <div className={styles.add__media}>
                            {isApplicantAccepted && (
                                <>
                                    <img src={addMedia} alt={"Add Media"}/>
                                    <p>Drag & Drop or Select a file</p>
                                </>
                            )}
                            {!isApplicantAccepted && (
                                <>
                                    <h6>You must accept an applicant first</h6>
                                </>
                            )}
                        </div>
                    </div>
                    {isApplicantAccepted &&
                        <div {...getRootProps()} className={`${styles.media} ${styles.select__media}`}>
                            <input {...getInputProps()} />
                        </div>}
                </div>}
                {!!binaryFile && <div className={styles.uploaded__file__list__wrapper}>
                    <Form.Group className={`${styles.form__group__wrapper}`}>
                        <Form.Group>
                            <label className={`${styles.label} mb-2`}>Name Of Document</label>
                            <Form.Control type={"text"} name={"name"} value={details.name} onChange={inputEvent} placeholder={"Rental Applications"} required/>
                        </Form.Group>
                    </Form.Group>
                    <label htmlFor="isLeasedContract" className={`${styles.leased__contract__checkbox} ${styles.document__form__wrapper}`}>
                        <input
                            type="checkbox"
                            onChange={(e)=>inputEvent(e,FORM_TYPE.CHECKBOX)}
                            name="isLeasedContract"
                            checked={details.isLeasedContract}
                            className="form-check-input"
                            id="isLeasedContract"
                        />
                        <span>Is this a leased contract?</span>
                    </label>
                    <ViewMedia media={binaryFile} isNotDelete isReset resetMedia={resetMedia} isBinaryString/>
                </div>}
                <div className={styles.contract__button__wrapper}>
                    {!isUploadContractLoading && <button type={"submit"} className={styles.next__btn} disabled={(!isApplicantAccepted || !binaryFile)}>
                        Next
                    </button>}
                    {isUploadContractLoading && <ButtonLoader/>}
                </div>
            </Form>
        </>
    )
}
export default UploadDocumentBox
