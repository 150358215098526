import React, {Fragment, useState} from "react";
import {Form} from "react-bootstrap";
import useForm from "../../../../hooks/use-form";
import {SIGNATURE_TYPE} from "../../../../utils/constants/contract";
import styles from "../../../../styles/contract.module.css"
import SignatureImageUpload from "../../../stateful/contract/signing/signature-image-upload";
import DrawSignature from "../../../stateful/contract/signing/draw-signature";
import TextAsSignature from "../../../stateful/contract/signing/text-as-signature";

const fields = {
    signatureType : SIGNATURE_TYPE.DRAW
}
const SignatureOnContract = ({setFile,textRefCallback,isSignature,isTextSignatureImmutable}) => {
    const {details,inputEvent} = useForm(fields)

    return(
        <>
            <div className={styles.sign__wrapper}>
                <div className={`${styles.signature__form} ${styles.form__group__wrapper}`}>
                    <label className={styles.label}>Signature Type</label>
                    <Form.Select
                        name={"signatureType"}
                        onChange={inputEvent}
                        value={details.signatureType}
                        required
                    >
                        <option value={SIGNATURE_TYPE.DRAW}>Draw</option>
                        <option value={SIGNATURE_TYPE.TEXT}>Text</option>
                        {isSignature && <option value={SIGNATURE_TYPE.IMAGE}>Image</option>}
                    </Form.Select>
                </div>
                <div className={styles.signature}>
                    {(details.signatureType === SIGNATURE_TYPE.IMAGE && isSignature) && <div className={styles.image}>
                        <SignatureImageUpload onFileUploaded={setFile}/>
                    </div>}
                    {details.signatureType === SIGNATURE_TYPE.DRAW && <div className={styles.draw}>
                        <DrawSignature onSignatureDraw={setFile} />
                    </div>}
                    {details.signatureType === SIGNATURE_TYPE.TEXT && <div className={styles.text}>
                        <TextAsSignature textRefCallback={textRefCallback} isTextSignatureImmutable={isTextSignatureImmutable}/>
                    </div>}
                </div>
            </div>
        </>
    )
}
export default SignatureOnContract
