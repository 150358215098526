import React from "react"
import {NavLink} from "react-router-dom";
import {Image} from "react-bootstrap";
import propTypes from "prop-types";
import {MEDIA_BASEURL} from "../../utils/constants";

const UserProfilePicture = ({isLink,profilePic,link,dimensions,clickHandler}) => {
    // style={{ backgroundImage: `url(${placeholderImage})`}}
    return(
        <>
            {isLink ?
                <NavLink to={`${link ? `/profile/${link}` : '/profile/me'}`} className={`user__profile`}>
                    <Image src={`${MEDIA_BASEURL}${profilePic}`} alt={"User Profile"} className={`w-${dimensions} h-${dimensions}`}/>
                </NavLink>
                :
                <span className={`user__profile`}>
                    <Image src={`${MEDIA_BASEURL}${profilePic}`} alt={"User Profile"} className={`w-${dimensions} h-${dimensions}`} onClick={clickHandler && clickHandler}/>
                </span>
            }
        </>
    )
}
export default UserProfilePicture;

UserProfilePicture.propType = {
    isLink : propTypes.bool,
    profilePic: propTypes.any.isRequired,
    link: propTypes.any,
    dimensions : propTypes.number,
    clickHandler : propTypes.func
}