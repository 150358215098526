import React from "react"
import EditContractApplicants from "./edit-contract-applicants";
import UnitContractService from "../../../../../services/unit-contract.service";

const EditApplicantCardContainer = ({editData,onEditApplicantCard,contractId}) => {
    const {useHandleEditApplicantDataDocument} = UnitContractService()

    const {mutate:editApplicant,isLoading:isEditApplicantLoading} = useHandleEditApplicantDataDocument(contractId,editData.signerId)
    const onSaveEditApplicantData = (e,email) => {
        e.preventDefault()
        editApplicant(email)
    }
    return(
        <>
            <EditContractApplicants
                fields={editData.fields}
                onEditApplicantCard={onEditApplicantCard}
                onSaveEditData={onSaveEditApplicantData}
                isLoading={isEditApplicantLoading}
            />
        </>
    )
}
export default EditApplicantCardContainer
