import React from "react";
import styles from "../../../styles/common/container.module.css"
import ButtonLoader from "../loaders/button-loader";
import propTypes from "prop-types";
import successImage from "../../../assets/images/success.png"

const SuccessContainer = ({isLoading,clickHandler,title,description,buttonText}) => {
    return (
        <>
            <div className={styles.success__container__wrapper}>
                <div className={styles.success__container__information}>
                    <img src={successImage} alt={"Success"}/>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    {isLoading && <ButtonLoader/>}
                    {!isLoading && <button onClick={clickHandler}>{buttonText}</button>}
                </div>
            </div>
        </>
    )
};
export default SuccessContainer;

SuccessContainer.propType = {
    isLoading : propTypes.bool.isRequired,
    clickHandler : propTypes.func.isRequired,
    title : propTypes.string.isRequired,
    description : propTypes.string.isRequired,
    buttonText : propTypes.string.isRequired
}