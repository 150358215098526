import React, {Fragment, useContext, useEffect, useState} from "react";
import {useWizard} from "react-use-wizard";
import CreateListingFormButtons from "../../../../common/buttons/create-listing-form-buttons";
import {ActiveStepContext} from "../../../../../routes/private/create-listing/create-listing-layout";
import styles from "../../../../../styles/listing.module.css";
import {Form} from "react-bootstrap";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {FORM_TYPE} from "../../../../../utils/constants";
import useForm from "../../../../../hooks/use-form";
import {AiOutlineClose} from "react-icons/ai"
import {ANIMAL_TYPE, RELATIONSHIP} from "../../../../../utils/constants/application-listing";
import ApplicationListingService from "../../../../../services/application-listing.service";

const relationshipList = [
    {
        text:"Friend",
        value:RELATIONSHIP.FRIEND
    },
    {
        text:"Partner",
        value:RELATIONSHIP.PARTNER
    },
    {
        text:"Sibling",
        value:RELATIONSHIP.SIBLING
    },
    {
        text:"Spouse",
        value:RELATIONSHIP.SPOUSE
    }
]
const OccupantsForm = ({applicationData}) => {
    const {previousStep, nextStep} = useWizard();
    const {setCompletedStepperInThisSession} = useContext(ActiveStepContext)

    const {useHandleUpdateOccupantsInformationService} = ApplicationListingService()

    const [incrementerFields,setIncrementerFields] = useState({
        dependents:0,
        adults : 0,
        pets:0,
    })
    const [fields,setFields] = useState({
        isOccupants:false,
        isDependents:false,
        isPets:false,
    })

    //Adult
    const [adults, setAdults] = useState([]);

    const handleAdultChange = (index, clickedInput) => (e) => {
        adults[index] = {
            ...adults[index],
            [`${clickedInput}`]: e.target.value,
        };
        setAdults([...adults]);
    };
    const addAdultsFormFields = () => {
        const id = Math.floor(Math.random() * 100)
        setAdults([
            ...adults,
            // generating unique keys for each object
            {
                name: "",
                phone: "",
                email: "",
                willPay: "",
                relationship: RELATIONSHIP.FRIEND,
                id
            },
        ]);
    };
    const removeOccupantsField = (i) => {
        let newFormValues = [...adults];
        newFormValues.splice(i, 1);
        setAdults(newFormValues);
        incrementerEvent("decrement","adults")
    }



    const [pets,setPets] = useState([])

    const addPetsFormFields = () => {
        const id = Math.floor(Math.random() * 100)
        setPets([
            ...pets,
            // generating unique keys for each object
            {
                animalType: "",
                breed: "",
                weight: "",
                id
            },
        ]);
    };
    const handlePetsChange = (index, clickedInput) => (e) => {
        pets[index] = {
            ...pets[index],
            [`${clickedInput}`]: e.target.value,
        };
        setPets([...pets]);
    };
    const removePetsField = (i) => {
        let newFormValues = [...pets];
        newFormValues.splice(i, 1);
        setPets(newFormValues);
        incrementerEvent("decrement","pets")
    }


    useEffect(()=>{
        setIncrementerFields({
            adults : applicationData?.occupants?.adults?.length || 0,
            dependents:applicationData?.occupants?.dependents || 0,
            pets: applicationData?.occupants?.pets?.length || 0,
        })
        setFields({
            isOccupants: !!(applicationData?.occupants?.adults?.length) || false,
            isDependents:!!(applicationData?.occupants?.dependents),
            isPets:!!(applicationData?.occupants?.pets?.length) || false,
        })
        setAdults(applicationData?.occupants?.adults || [])
        setPets(applicationData?.occupants?.pets || [])
    },[applicationData])

    const {details,inputEvent,incrementerEvent,incrementer} = useForm(fields,incrementerFields)

    //Adding More Adults Field After every increment
    const incrementAdults = () => {
        incrementerEvent("increment","adults")
        addAdultsFormFields()
    }

    //Adding More Pets Field After every increment
    const incrementPets = () => {
        incrementerEvent("increment","pets")
        addPetsFormFields()
    }

    const {isLoading,mutate,isSuccess} = useHandleUpdateOccupantsInformationService({...incrementer,adults,pets})


    const goToPrevStep = () => {
        previousStep()
    }
    const goToNextStep =  (e) => {
        e.preventDefault()
        mutate()
    }

    useEffect(()=>{
        if(isSuccess){
            setCompletedStepperInThisSession(prevValue => {
                return{
                    ...prevValue,
                    secondStepperCompleted:true
                }
            })
            nextStep().then()
        }
    },[isSuccess])

    return(
        <>
            <Form className={styles.create__listing__form__alternate} onSubmit={goToNextStep}>

                {/*--OCCUPANTS--*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Occupants</Form.Label>
                    <Form.Group className={styles.form__check__group}>
                        <Form.Label htmlFor={"occupants__isOccupants"}>Will an adult be living with you?</Form.Label>
                        <Form.Check
                            type="switch"
                            id="occupants__isOccupants"
                            name={"isOccupants"}
                            value={details.isOccupants}
                            onChange={(e) => inputEvent(e,FORM_TYPE.CHECKBOX)}
                            checked={details.isOccupants}
                        />
                    </Form.Group>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    {details.isOccupants && <Form.Group className={styles.form__check__group}>
                        <Form.Label>How many adults? "Excluding yourself"</Form.Label>
                        <div className={styles.incrementer__wrapper}>
                            <button disabled={incrementer.adults <= 0} type={"button"} onClick={() => incrementerEvent("decrement","adults")}><AiOutlineMinus/></button>
                            <span>{incrementer.adults}</span>
                            <button type={"button"} onClick={() => incrementAdults()}><AiOutlinePlus/></button>
                        </div>
                    </Form.Group>}
                </Form.Group>
                {(incrementer?.adults > 0 && details.isOccupants) &&
                    <div className={styles.additional__fields__wrapper}>
                    {adults.map((val, index) => {
                        return (
                            <Fragment key={val?.id}>
                                <div className={styles.additional__fields__inner__wrapper}>
                                    <button type={"button"} onClick={() => removeOccupantsField(index)}
                                            className={styles.close__field}><AiOutlineClose/></button>

                                    <Form.Group className={styles.form__group__wrapper}>
                                        <div className={styles.form__group__outer__wrapper}>
                                            <Form.Group className={styles.form__group}>
                                                <Form.Label>Full Name</Form.Label>
                                                {/*className={error["unitSize"] ? "error" : ""}*/}
                                                <Form.Control type={"text"} name={"name"}  value={val?.name || ""} onChange={handleAdultChange(index,"name")} placeholder={"Full Name"} required/>
                                            </Form.Group>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className={styles.form__group__wrapper}>
                                        <div className={styles.form__group__outer__wrapper}>
                                            <Form.Group className={styles.form__group}>
                                                <Form.Label>Phone</Form.Label>
                                                {/*className={error["unitSize"] ? "error" : ""}*/}
                                                <Form.Control type={"number"} value={val.phone} name={"phone"}
                                                              onChange={handleAdultChange(index,"phone")} placeholder={"Phone"} required/>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group}>
                                                <Form.Label>Email</Form.Label>
                                                {/*className={error["unitSize"] ? "error" : ""}*/}
                                                <Form.Control type={"email"} value={val.email} name={"email"}
                                                              onChange={handleAdultChange(index,"email")} placeholder={"Email"} required/>
                                            </Form.Group>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className={styles.form__group__wrapper}>
                                        <div className={styles.form__group__outer__wrapper}>
                                            <Form.Group className={styles.form__group}>
                                                <Form.Label>How much will this person pay?</Form.Label>
                                                {/*className={error["unitSize"] ? "error" : ""}*/}
                                                <Form.Control type={"number"} value={val.willPay} name={"willPay"}
                                                              onChange={handleAdultChange(index,"willPay")} placeholder={"$1000 / $2200"} required/>
                                            </Form.Group>
                                            <Form.Group className={styles.form__group}>
                                                <Form.Label>Relationship</Form.Label>
                                                <Form.Select
                                                    name={"relationship"}
                                                    onChange={handleAdultChange(index,"relationship")}
                                                    value={val.relationship}
                                                    required
                                                    // className={error["unitType"] ? "error" : ""}
                                                >
                                                    {relationshipList.map((unit, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <option value={unit.value}>{unit.text}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>

                                        </div>
                                    </Form.Group>
                                </div>
                            </Fragment>
                        )
                    })}

                </div>}


                {/*---DEPENDANTS---*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Dependants</Form.Label>
                    <Form.Group className={styles.form__check__group}>
                        <Form.Label htmlFor={"occupants__isDependents"}>Do you have any children?</Form.Label>
                        <Form.Check
                            type="switch"
                            id="occupants__isDependents"
                            name={"isDependents"}
                            value={details.isDependents}
                            onChange={(e) => inputEvent(e,FORM_TYPE.CHECKBOX)}
                            checked={details.isDependents}
                        />
                    </Form.Group>
                    {details.isDependents && <Form.Group className={styles.form__check__group}>
                        <Form.Label>How many dependents do you have?</Form.Label>
                        <div className={styles.incrementer__wrapper}>
                            <button disabled={incrementer.dependents <= 0} type={"button"} onClick={() => incrementerEvent("decrement","dependents")}><AiOutlineMinus/></button>
                            <span>{incrementer.dependents}</span>
                            <button type={"button"} onClick={() => incrementerEvent("increment","dependents")}><AiOutlinePlus/></button>
                        </div>
                    </Form.Group>}
                </Form.Group>


                {/*---PETS---*/}
                <Form.Group className={styles.form__group__wrapper}>
                    <Form.Label className={styles.main__label}>Pets</Form.Label>
                    <Form.Group className={styles.form__check__group}>
                        <Form.Label htmlFor={"occupants__isPets"}>Do you have a pet?</Form.Label>
                        <Form.Check
                            type="switch"
                            id="occupants__isPets"
                            name={"isPets"}
                            value={details.isPets}
                            onChange={(e) => inputEvent(e,FORM_TYPE.CHECKBOX)}
                            checked={details.isPets}
                        />
                    </Form.Group>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    {details.isPets && <Form.Group className={styles.form__check__group}>
                        <Form.Label>How many pets do you have?</Form.Label>
                        <div className={styles.incrementer__wrapper}>
                            <button disabled={incrementer.pets <= 0} type={"button"} onClick={() => incrementerEvent("decrement","pets")}><AiOutlineMinus/></button>
                            <span>{incrementer.pets}</span>
                            <button type={"button"} onClick={() => incrementPets()}><AiOutlinePlus/></button>
                        </div>
                    </Form.Group>}
                </Form.Group>
                {(incrementer?.pets > 0 && details.isPets) &&
                    <div className={styles.additional__fields__wrapper}>
                        {pets.map((val, index) => {
                            return (
                                <Fragment key={val?.id}>
                                    <div className={styles.additional__fields__inner__wrapper}>
                                        <button type={"button"} onClick={() => removePetsField(index)}
                                                className={styles.close__field}><AiOutlineClose/></button>

                                        <Form.Group className={styles.form__group__wrapper}>
                                            <Form.Group className={styles.form__check__group} style={{marginTop:"10px"}}>
                                                <label>{`${index+1}. Select the type of animal`}</label>
                                                <div className="form-check">
                                                    <label htmlFor={`typeOfAnimal_1${index}`} className={parseInt(val?.animalType) === ANIMAL_TYPE.DOG ? styles.checked : ""}>
                                                        <input
                                                            type="radio"
                                                            name={`animalType${index}`}
                                                            value={ANIMAL_TYPE.DOG}
                                                            onChange={handlePetsChange(index,"animalType")}
                                                            className={`form-check-input`}
                                                            id={`typeOfAnimal_1${index}`}
                                                            checked={parseInt(val?.animalType) === ANIMAL_TYPE.DOG}
                                                            required
                                                        />
                                                        Dog
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label htmlFor={`typeOfAnimal_2${index}`} className={parseInt(val?.animalType) === ANIMAL_TYPE.CAT ? styles.checked : ""}>
                                                        <input
                                                            type="radio"
                                                            name={`animalType${index}`}
                                                            value={ANIMAL_TYPE.CAT}
                                                            onChange={handlePetsChange(index,"animalType")}
                                                            className={`form-check-input`}
                                                            id={`typeOfAnimal_2${index}`}
                                                            checked={parseInt(val?.animalType) === ANIMAL_TYPE.CAT}
                                                            required
                                                        />
                                                        Cat
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label htmlFor={`typeOfAnimal_3${index}`} className={parseInt(val?.animalType) === ANIMAL_TYPE.OTHER ? styles.checked : ""}>
                                                        <input
                                                            type="radio"
                                                            name={`animalType${index}`}
                                                            value={ANIMAL_TYPE.OTHER}
                                                            onChange={handlePetsChange(index,"animalType")}
                                                            className={`form-check-input`}
                                                            id={`typeOfAnimal_3${index}`}
                                                            checked={parseInt(val?.animalType) === ANIMAL_TYPE.OTHER}
                                                            required
                                                        />
                                                        Other
                                                    </label>
                                                </div>
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className={styles.form__group__wrapper}>
                                            <div className={styles.form__group__outer__wrapper}>
                                                <Form.Group className={styles.form__group}>
                                                    <Form.Label>Breed</Form.Label>
                                                    {/*className={error["unitSize"] ? "error" : ""}*/}
                                                    <Form.Control type={"text"} value={val.breed} name={"breed"}
                                                                  onChange={handlePetsChange(index,"breed")} placeholder={"Breed"} required/>
                                                </Form.Group>
                                                <Form.Group className={styles.form__group}>
                                                    <Form.Label>Weight</Form.Label>
                                                    {/*className={error["unitSize"] ? "error" : ""}*/}
                                                    <Form.Control type={"number"} value={val.weight} name={"weight"}
                                                                  onChange={handlePetsChange(index,"weight")} placeholder={"Kg"} required/>
                                                </Form.Group>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Fragment>
                            )
                        })}

                    </div>}


                <CreateListingFormButtons
                    prevStepHandler={goToPrevStep}
                    loader={isLoading}
                />
            </Form>

        </>
    )
}
export default OccupantsForm
