import React from "react"
import styles from "../../../../../styles/contract.module.css";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";
import DefaultLoader from "../../../../common/loaders/default-loader";

const fields = {
    name:"",
    email:""
}
const AddCoApplicants = ({onAddCoApplicant,onSaveAddCoApplicant,isLoading}) => {
    const {details,inputEvent} = useForm(fields)
    return(
        <>
            <Form className={styles.form__group__wrapper} onSubmit={(e)=>onSaveAddCoApplicant(e,details)}>
                <Form.Group className={styles.form__group__wrapper}>
                    <label className={styles.label}>Name</label>
                    <input type={"text"} required value={details.name} name={"name"} onChange={inputEvent} placeholder={"Co-Applicant Name"}/>
                </Form.Group>
                <Form.Group className={styles.form__group__wrapper}>
                    <label className={styles.label}>Email</label>
                    <input type={"email"} required value={details.email} name={"email"} onChange={inputEvent} placeholder={"Co-Applicant Email"}/>
                </Form.Group>
                <div className={styles.contract__button__wrapper}>
                    {isLoading && <DefaultLoader/>}
                    {!isLoading && (
                        <>
                            <button type={"button"} onClick={onAddCoApplicant} className={styles.prev__btn}>
                                Cancel
                            </button>
                            <button type={"submit"} className={styles.next__btn}>
                                Save
                            </button>
                        </>
                    )}

                </div>
            </Form>
        </>
    )
}
export default AddCoApplicants
