import React, {Fragment, useCallback, useState} from "react"
import styles from "../../../../styles/contract.module.css"
import addMedia from "../../../../assets/images/add-media-alt.png"
import {useDropzone} from "react-dropzone";

const SignatureImageUpload = ({onFileUploaded}) => {
    const [binaryFile,setBinaryFile] = useState(null)

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file) => {

            const reader = new FileReader()
            reader.readAsDataURL(file);

            reader.onload = () => {
                const binaryStr = reader.result

                onFileUploaded(file)
                setBinaryFile({
                    type:file?.type?.split("/")[0],
                    file:binaryStr,
                    originalName:file?.name,
                    isLink:false,
                    size:file?.size, //into KB
                })

                // reader.readAsArrayBuffer(file)
            }
        })
    },[])

    const {getRootProps, getInputProps} = useDropzone({onDrop,maxFiles:1,accept: {
            'image/*': ['.jpeg', '.png','.jpg']
        }})

    const onClear = () => {
        setBinaryFile("")
        onFileUploaded("")
    }
    return(
        <>
            <div className={styles.add__media__form}>
                {!!binaryFile && <button className={styles.clear__button} onClick={onClear}>
                    Clear
                </button>}
                <div className={`${styles.add__media__main__wrapper} ${styles.add__media__bg}`}>
                    {!binaryFile && (
                        <>
                            <div className={styles.add__media__wrapper}>
                                <div className={styles.add__media}>
                                    <img src={addMedia} alt={"Add Media"}/>
                                    <p>Drag & Drop or Select an Image</p>
                                </div>
                            </div>
                            <div {...getRootProps()} className={`${styles.media} ${styles.select__media}`}>
                                <input {...getInputProps()} />
                            </div>
                        </>
                    )}
                    {!!binaryFile && <div className={styles.viewing__media}>
                        <img src={binaryFile.file} alt={"Signature"}/>
                    </div>}
                </div>

            </div>
        </>
    )
}
export default SignatureImageUpload
