import React from "react"
import SingleUnitApplicants from "../../components/stateful/listing/single-unit/applicants/single-unit-applicants";
import UnitTitle from "../../components/common/text/unit-title";
import {useOutletContext} from "react-router";
import styles from "../../styles/listing.module.css"

const Applicants = () => {
    //Api is called from the parent component i.e. index.js
    const {data:unitData} = useOutletContext()

    return (
        <>
            <section className={styles.single__unit__wrapper}>
                <UnitTitle title={unitData?.name}/>
                <SingleUnitApplicants unitData={unitData}/>
            </section>
        </>
    )
}
export default Applicants;
