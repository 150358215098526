import React,{Fragment,useState} from "react"
import propTypes from "prop-types"
import styles from "../../../../../styles/listing.module.css";
import {Col, Row} from "react-bootstrap";
import {TiTick} from "react-icons/ti";
import {RiCloseFill} from "react-icons/ri";
import {BiBath, BiBed, BiDish, BiFridge} from "react-icons/bi";
import {FaCouch, FaPaw, FaSnowflake} from "react-icons/fa";
import {TbDisabled2} from "react-icons/tb";
import {GiCigarette, GiSpikedFence} from "react-icons/gi";
import {
    MdBalcony,
    MdLocalLaundryService,
    MdOutlineLocalLaundryService,
    MdOutlineLocalParking,
    MdOutlineShower
} from "react-icons/md";
import {BsArrowsMove} from "react-icons/bs";
import {AiOutlineBulb, AiOutlineFire, AiOutlineWifi} from "react-icons/ai";
import {FiMonitor} from "react-icons/fi";
import {stringTruncate} from "../../../../../utils/helpers";

const details = [
    {
        icons : <BiBed/>,
        text : "Bedrooms",
        key:"bedrooms",

    },
    {
        icons : <BiBath/>,
        text : "Bathrooms",
        key:"bathrooms"
    },
    {
        icons : <FaCouch/>,
        text : "Furnished",
        key:"furnished",
        checkBox:true
    },
    {
        icons : <TbDisabled2/>,
        text : "Accessibility",
        key:"accessibilityFeatures",
        checkBox:true
    },
    {
        icons : <FaPaw/>,
        text : "Pet Friendly",
        key:"petFriendly",
        checkBox:true
    },
    {
        icons : <GiSpikedFence/>,
        text : "Yard",
        key:"yard",
        checkBox:true
    },
    {
        icons : <MdBalcony/>,
        text : "Balcony",
        key:"balcony",
        checkBox:true
    },
    {
        icons : <GiCigarette/>,
        text : "Smoking permitted",
        key:"smokingPermitted",
        checkBox:true
    },
    {
        icons : <MdOutlineLocalParking/>,
        text : "Parkings included",
        key:"parkingSpaces",
    },
    {
        icons : <BsArrowsMove/>,
        text : "Sqft",
        key:"unitSize"
    },
]
const utilities = [
    {
        icons:<AiOutlineBulb/>,
        key:"hydro",
        text: "Hydro",
    },
    {
        icons : <AiOutlineFire/>,
        key:"heat",
        text: "Heat",
    },
    {
        icons : <MdOutlineShower/>,
        key:"water",
        text: "Water",
    },
    {
        icons : <FiMonitor/>,
        key:"cableTv",
        text: "Cable/ TV",
    },
    {
        icons : <AiOutlineWifi/>,
        key:"internet",
        text: "Internet",
    }
]
const appliances = [
    {
        icons:<MdLocalLaundryService/>,
        text : "Laundry (In Unit)",
        key: "laundaryInUnit"
    },
    {
        icons:<MdOutlineLocalLaundryService/>,
        text : "Laundry (In Building)",
        key:"laundaryInBuilding"
    },
    {
        icons:<BiDish/>,
        text : "Dishwasher",
        key:"dishwasher"
    },
    {
        icons:<BiFridge/>,
        text:"Fridge",
        key:"fridgeFreezer"
    },
    {
        icons:<FaSnowflake/>,
        text:"Air Conditioning",
        key:"airConditioner"
    }
]
const SingleUnitDetails = ({unitData}) => {
    const [show,setShow] = useState(false)
    const toggleDescription = () => {
        setShow(!show)
    }
    return(
        <>
            <div className={styles.unit__description__wrapper}>
                <Row>
                    <Col className={styles.description__col} md={6} lg={4}>
                        <div className={`${styles.unit__information}  ${styles.unit__description}`}>
                            <h5>Description</h5>
                            <div className={styles.description}>
                                <p>
                                    {unitData?.description.length > 500 ? (
                                        <>
                                            {show ? unitData?.description : stringTruncate(unitData?.description,500)}
                                            <button onClick={toggleDescription}>{show ? "Show Less" : "Show More"}</button>
                                        </>
                                    ) : unitData?.description}
                                </p>
                                {/*<LongText longText={unitData?.description} showTextLength={500}/>*/}
                            </div>
                        </div>
                    </Col>
                    <Col  className={styles.description__col} md={6} lg={4}>
                        <div className={`${styles.unit__information}  ${styles.unit__details}`}>
                            <h5>Unit Details</h5>
                            <div className={`${styles.details}`}>
                                <ul>
                                    {details
                                        // .filter((el)=>unitData[el.key])
                                        .map((detail,i)=>{
                                            return(
                                                <Fragment key={i}>
                                                    <li>
                                                        <span className={styles.icon}>{detail.icons}</span>
                                                        {/*{!detail.checkBox ? unitData[detail.key] : null} {` ${detail.text}`}*/}
                                                        <div>
                                                            <span className={unitData[detail.key] ? styles.checked : styles.not__checked}>
                                                                {unitData[detail.key] ? <TiTick/> : <RiCloseFill/>}
                                                            </span>
                                                            {!detail.checkBox ? unitData[detail.key] : null} {` ${detail.text}`}
                                                        </div>
                                                    </li>
                                                </Fragment>
                                            )
                                        })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col  className={styles.description__col} md={6} lg={2}>
                        <div className={`${styles.unit__information} ${styles.unit__utilities}`}>
                            <h5>Utilities Included</h5>
                            <div className={styles.utility}>
                                <ul>
                                    {utilities.map((utility,i)=>{
                                        return(
                                            <Fragment key={i}>
                                                <li>
                                                    <span className={styles.icon}>{utility.icons}</span>
                                                    <div>
                                                            <span className={unitData[utility.key] ? styles.checked : styles.not__checked}>
                                                                {unitData[utility.key] ? <TiTick/> : <RiCloseFill/>}
                                                            </span>
                                                        {utility.text}
                                                    </div>
                                                </li>
                                            </Fragment>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col  className={styles.description__col} md={6} lg={2}>

                        <div className={`${styles.unit__information} ${styles.unit__appliances}`}>
                            <h5>Appliances</h5>
                            <div className={styles.appliance}>
                                <ul>
                                    {appliances
                                        // .filter((el)=>unitData[el.key])
                                        .map((appliance,i)=>{
                                            return(
                                                <Fragment key={i}>
                                                    <li>
                                                        {/*<span>{appliance.icons}</span>*/}
                                                        {/*{appliance.text}*/}
                                                        <span className={styles.icon}>{appliance.icons}</span>
                                                        <div>
                                                            <span className={unitData[appliance.key] ? styles.checked : styles.not__checked}>
                                                                {unitData[appliance.key] ? <TiTick/> : <RiCloseFill/>}
                                                            </span>
                                                            {appliance.text}
                                                        </div>
                                                    </li>
                                                </Fragment>
                                            )
                                        })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default SingleUnitDetails

SingleUnitDetails.propType = {
    unitData : propTypes.object.isRequired
}
