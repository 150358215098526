import React, {Fragment, useState} from "react";
import styles from "../../../../../styles/rent-collection.module.css";
import Title from "../../../../common/text/title";
import {Form} from "react-bootstrap";
import useForm from "../../../../../hooks/use-form";
import {PAYMENT_TRANSACTION_STATUS, RENT_PAYMENT_TYPE} from "../../../../../utils/constants/listing";
import {nanoid} from "nanoid";
import {BiChevronDown} from "react-icons/bi"
import DatePicker from "react-datepicker";
import {differenceInCalendarMonths, getDate} from "date-fns";
import ButtonLoader from "../../../../common/loaders/button-loader";
import {dateFromISOString} from "../../../../../utils/helpers";

const PaymentDetailForm = ({title,handleConfirmPaymentDetailCallback,transactionData,isHalf,isNotEditable,fields,isLoading,updateFields,handleBackPaymentDetailCallback,handleLoadMoreTransactionsCallback}) => {
    const {inputEvent:formInputEvent,details} = useForm(fields)
    const dueDateArray = Array.from({length: 25}, (_, i) => i + 1)
    const [isFirstPaymentCalendarOpen,setIsFirstPaymentCalendarOpen] = useState(false)
    const [isFinalPaymentCalendarOpen,setIsFinalPaymentCalendarOpen] = useState(false)

    function handleFirstPaymentCalendar() {
        setIsFirstPaymentCalendarOpen(prev => !prev)
        setIsFinalPaymentCalendarOpen(false)
    }
    function handleFinalPaymentCalendar() {
        setIsFirstPaymentCalendarOpen(false)
        setIsFinalPaymentCalendarOpen(prev => !prev)
    }

    function isActiveDay(date) {
        //Disable every date accept the due date
        return getDate(date) == details.monthlyDueOn
    }

    function inputEvent(e) {
        formInputEvent(e)
        if (!!updateFields){
            updateFields(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
    }
    return(
      <>
          <div className={`${styles.payment__detail__wrapper} ${isHalf ? styles.half__width : ""}`}>
              {title && <Title title={title}/>}
              <Form className={styles.payment__detail__form} onSubmit={handleConfirmPaymentDetailCallback}>
                  {/*MONTHLY PAYMENTS*/}
                  <h4>Monthly Payment</h4>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Monthly Amount</Form.Label>
                      <Form.Control
                          type={"number"}
                          value={details.monthlyAmount}
                          name={"monthlyAmount"}
                          onChange={inputEvent}
                          placeholder={"$"}
                          disabled={isNotEditable}
                          required
                      />
                  </Form.Group>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Due On</Form.Label>
                      <Form.Select
                          name={"monthlyDueOn"}
                          onChange={inputEvent}
                          value={details.monthlyDueOn}
                          required
                          disabled={isNotEditable}
                      >
                          {dueDateArray.map(el =>(
                              <Fragment key={nanoid()}>
                                  <option value={el}>{
                                      el === 1 ? "1st of each month" :
                                          el === 2 ? "2nd of each month" :
                                              el === 3 ? "3rd of each month" :
                                                  `${el}th of each month`}
                                  </option>
                              </Fragment>
                          ))}
                      </Form.Select>
                  </Form.Group>
                  <div className={styles.form__group__multiple__wrapper}>
                      <Form.Group className={styles.form__group__wrapper}>
                          <Form.Label>First Payment</Form.Label>
                          <button disabled={isNotEditable} type={"button"} onClick={handleFirstPaymentCalendar} className={`${styles.payment__button} ${isFirstPaymentCalendarOpen ? styles.active__payment__button : ''}`}>
                              <span>{new Date(fields.monthlyFirstPayment).toDateString()}</span> <BiChevronDown/>
                          </button>
                      </Form.Group>
                      <Form.Group className={styles.form__group__wrapper}>
                          <Form.Label>Last Payment</Form.Label>
                          <button disabled={isNotEditable} type={"button"} onClick={handleFinalPaymentCalendar} className={`${styles.payment__button} ${isFinalPaymentCalendarOpen ? styles.active__payment__button : ''}`}>
                              <span>{new Date(fields.monthlyLastPayment).toDateString()} ({differenceInCalendarMonths(new Date(fields.monthlyLastPayment),new Date(fields.monthlyFirstPayment))} Months)</span> <BiChevronDown/>
                          </button>
                      </Form.Group>
                  </div>
                  <div className={styles.datepicker__wrapper}>
                      {isFirstPaymentCalendarOpen && <div className={`scheduler__datepicker ${styles.scheduler__datepicker}`}>
                          <DatePicker
                              selected={new Date(fields.monthlyFirstPayment)}
                              onChange={(date) => updateFields(prev => ({
                                  ...prev,
                                  monthlyFirstPayment:dateFromISOString(date)
                              }))}
                              filterDate={isActiveDay}
                              minDate={new Date()}
                              maxDate={new Date(fields.monthlyLastPayment)}
                              inline
                          />
                      </div>}
                      {isFinalPaymentCalendarOpen && <div className={`scheduler__datepicker ${styles.scheduler__datepicker}`}>
                          <DatePicker
                              selected={new Date(fields.monthlyLastPayment)}
                              onChange={(date) => updateFields(prev => ({
                                  ...prev,
                                  monthlyLastPayment:dateFromISOString(date)
                              }))}
                              minDate={new Date(fields.monthlyFirstPayment)}
                              filterDate={isActiveDay}
                              inline
                          />
                      </div>}
                  </div>

                  {/*ONE-TIME PAYMENTS*/}
                  <h4>One-Time Payment</h4>
                  <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Payment Type</Form.Label>
                      <Form.Select
                          name={"oneTimeType"}
                          onChange={inputEvent}
                          value={details.oneTimeType}
                          required
                          disabled={isNotEditable}
                      >
                          <option value={RENT_PAYMENT_TYPE.DEPOSIT}>Deposit</option>
                          <option value={RENT_PAYMENT_TYPE.LAST_MONTH_RENT}>Last Month Rent</option>
                      </Form.Select>
                  </Form.Group>
                  {details.oneTimeType === RENT_PAYMENT_TYPE.DEPOSIT && <Form.Group className={styles.form__group__wrapper}>
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                          type={"number"}
                          value={details.oneTimePrice}
                          name={"oneTimePrice"}
                          onChange={inputEvent}
                          placeholder={"$"}
                          required
                          disabled={isNotEditable}
                      />
                  </Form.Group>}

                  {/*SCHEDULED PAYMENTS*/}
                  {!!transactionData && (
                      <>
                          <h4>Scheduled Payments</h4>
                          <div className={styles.scheduled__payments__wrapper}>
                              {transactionData?.[0]?.payments?.length > 0 && transactionData?.[0]?.payments
                                  .filter(el => el?.status === PAYMENT_TRANSACTION_STATUS.UPCOMING)
                                      .map(el => (
                                          <div className={styles.scheduled__payments} key={el?._id}>
                                              <p>{new Date(el?.scheduleDate).toDateString()}</p>
                                              <h6>${el?.amount}</h6>
                                          </div>
                                      ))}
                          </div>
                      </>
                  )}

                  {/*<LoadMoreButton*/}
                  {/*    buttonText={"Show 20 more transactions"}*/}
                  {/*    clickCallback={handleLoadMoreTransactionsCallback}*/}
                  {/*/>*/}
                  {(handleConfirmPaymentDetailCallback && handleBackPaymentDetailCallback) && <div className={styles.rent__button__wrapper}>

                      {!isLoading && (
                          <>
                              <button type={"button"} onClick={handleBackPaymentDetailCallback} className={styles.prev__btn}>
                                  Back
                              </button>
                              <button type={"submit"} className={styles.next__btn}>
                                  Next
                              </button>
                          </>
                      )}
                      {isLoading && <ButtonLoader/>}
                  </div>}

              </Form>
          </div>
      </>
  )
}
export default PaymentDetailForm
