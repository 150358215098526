import React, {useContext} from "react"
import propTypes from "prop-types"
import styles from "../../styles/listing.module.css";
import WebsiteLogo from "./website-logo";
import {Step, Stepper} from "react-form-stepper";
import {ActiveStepContext} from "../../routes/private/create-listing/create-listing-layout";
import ExitListing from "../presentational/listing/create-listing/exit-listing";

const styleConfig = {
    inactiveBgColor:"transparent",
    activeBgColor:"transparent",
    activeTextColor:"#5987FF",
    inactiveTextColor:"#95A0B2",
    completedBgColor:"#5987FF",
}
const CreateListingSidebar =({completedStepperInThisSession,goToStepper,stepperList,listing}) => {
    const {activeStep} = useContext(ActiveStepContext)

    const handleStepperClick = (index) => {
        goToStepper(index)
    }
    return(
        <>
            <div className={styles.sidebar}>
                <div className={styles.sidebar__inner}>
                    <div className={styles.logo}>
                        <WebsiteLogo/>
                    </div>
                    <Stepper
                        activeStep={activeStep}
                        styleConfig={styleConfig}
                    >
                        {stepperList.map((step,index)=>{
                            return(
                                <Step key={index} label={step} onClick={()=>handleStepperClick(index)}/>
                            )
                        })}
                    </Stepper>
                    <ExitListing listing={listing} completedStepperInThisSession={completedStepperInThisSession}/>
                </div>
            </div>
        </>
    )
}
export default CreateListingSidebar

CreateListingSidebar.propType = {
    currentActiveStep : propTypes.number.isRequired,
}
