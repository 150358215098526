import React from "react"
import styles from "../../../styles/listing.module.css";
import {useWizard} from "react-use-wizard";
import propTypes from "prop-types";
import ButtonLoader from "../loaders/button-loader";
import {useNavigate} from "react-router";
import { ROLES} from "../../../utils/constants";
import TokenService from "../../../services/token.service";
import useWindowSize from "../../../hooks/use-window-size";

const CreateListingFormButtons = ({prevStepHandler,loader,isApplying}) => {
    const { isFirstStep,isLastStep } = useWizard();
    const {width} = useWindowSize()
    const navigate = useNavigate()
    const userRole =  TokenService.getUser()?.role
    const redirectUrl = userRole === ROLES.RENTER ? "/application" :  userRole === ROLES.OWNER ? "/home" : "/"
    return(
        <>
            <div className={styles.button__wrapper}>
                {width <= 767 && <button type={"button"} className={styles.save__button} onClick={() => navigate(redirectUrl)}>
                    Save And Continue Later
                </button>}
                {loader && <ButtonLoader class={styles.next__btn}/>}
                {!loader && (
                    <>
                        {!isFirstStep &&
                            <button onClick={prevStepHandler} type={"button"} className={styles.prev__btn}>Back</button>}
                        <button type={"submit"} className={styles.next__btn}>{!isLastStep ? "Next" : isApplying ? "Apply To Unit" : "Publish"}</button>
                    </>
                )}

            </div>

        </>
    )
}
export default CreateListingFormButtons

CreateListingFormButtons.propType = {
    prevStepHandler : propTypes.func.isRequired,
    loader: propTypes.bool.isRequired,
    isApplying: propTypes.bool
}
