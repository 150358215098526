import React from "react"
import propTypes from "prop-types";
import {CloseButton, Modal} from "react-bootstrap"

const PrimaryModal = (props) => {
    return(
        <>
            <Modal
                size={props.size}
                dialogClassName="modal-120w"
                className={`modal__wrapper ${props.customClass} ${props.isDashboard && "dashboard__modal__wrapper"}`}
                fullscreen={props.isFullScreen}
                show={props.isOpen}
                backdrop="static"
                scrollable={true}
                keyboard={false}
                centered
            >
                {(props.handleClose || props.title) &&
                    <Modal.Header className="modal__header">
                        {props.title && <h2>{props.title}</h2>}
                        {props.handleClose && <CloseButton variant={props.isDashboard ? null : "white"} aria-label="Hide"
                                      onClick={props.handleClose}/>}
                    </Modal.Header>
                }

                <Modal.Body className="modal__body">
                    {props.modalContent}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default PrimaryModal;

PrimaryModal.PropType = {
    handleClose : propTypes.func,
    modalContent: propTypes.any.isRequired,
    isOpen : propTypes.bool.isRequired,
    isFullScreen : propTypes.bool,
    isDashboard : propTypes.bool,
    size : propTypes.string.isRequired,
    customClass : propTypes.string,
    title: propTypes.string
}
