import React, {useState} from "react"
import styles from "../../../../styles/contract.module.css"

const TextAsSignature = ({textRefCallback,isTextSignatureImmutable}) => {
    const [text,setText] = useState("")
    return(
        <>
            <div className={styles.text__wrapper}>
                <div className={styles.text__input}>
                    <input type={"text"} onChange={event => setText(event.target.value)} value={text}  autoFocus={true} maxLength={50}/>
                    <div ref={textRefCallback} className={styles.span__wrapper}>
                        <span className={`${!text ? styles.no__text : ""} ${isTextSignatureImmutable ? styles.immutable__text : ""}`}>
                            {text ? text : "..."}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TextAsSignature
