import React, {Fragment} from "react";
import styles from "../../../../../styles/viewing.module.css"
import {Form} from "react-bootstrap";
import {BsPlusSquare,BsDash} from "react-icons/bs";
import {FaTrashAlt} from "react-icons/fa"
import useForm from "../../../../../hooks/use-form";
import {FORM_TYPE} from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import {nanoid} from "nanoid";
import ButtonLoader from "../../../../common/loaders/button-loader";
import differenceInMinutes from "date-fns/differenceInMinutes"
import getHours from "date-fns/getHours"
import getMinutes from "date-fns/getMinutes"

const SetupAvailability = ({availability,setAvailability,fields,fieldKeys,saveAvailability,isSaveAvailabilityLoading,durationInterval}) => {
    const {details,inputEvent} = useForm(fields)

    //Availability StartTime/EndTime Change
    const handleAvailabilityDataChange = (index,key,date,day) => {
        let changeInField = {...availability,[day]:availability[day]?.map((el,i)=>{
                if(i===index){
                    return {
                        ...el,
                        [key]:date
                    }
                }
                return el
            })}
        setAvailability(changeInField);
    }

    const handleAddDayCheckbox = (event,day) => {
        inputEvent(event,FORM_TYPE.CHECKBOX)
        if(!event.target.checked){
            handleUnAvailability(true,day)
        }else if(event.target.checked){
            handleUnAvailability(false,day)
        }

        if(availability[day]?.length <= 0){
            const _id = nanoid()
            setAvailability({
                ...availability,
                [day]:[
                    ...availability[day],
                    // generating unique keys for each object
                    {
                        startTime: new Date(),
                        endTime: new Date(),
                        isUnAvailable:false,
                        _id
                    },
                ]
            });
        }
    }
    const addAvailability = (day) => {
        const _id = nanoid()

        inputEvent({
            target:{
                name:`${day}`,
                value:true,
                checked:true
            }
        },FORM_TYPE.CHECKBOX)
        const previousAvailabilityDays = handleUnAvailability(false,day)
        setAvailability({
            ...availability,
            [day]:[
                ...previousAvailabilityDays,
                // generating unique keys for each object
                {
                    startTime: null,
                    endTime: null,
                    isUnAvailable:false,
                    _id
                },
            ]
        });
    }

    const removeAvailabilityField = (id,day) => {
        const filterAvailability = availability[day].filter((el)=>el?._id!==id)
        if(filterAvailability?.length <= 0){
            inputEvent({
                target:{
                    name:day,
                    value:false,
                    checked:false
                }
            },FORM_TYPE.CHECKBOX)
            handleUnAvailability(true,day)
        }
        setAvailability( {
            ...availability,
            [day]:[...filterAvailability]
        });
    }

    //Handling UnAvailability Of A Day
    const handleUnAvailability = (unAvailability,day) => {
        setAvailability({
            ...availability,
            [day]:availability[day].map(el=>({
                ...el,
                isUnAvailable:unAvailability,
            }))
        })
        return [...availability[day].map(el=>({
            ...el,
            isUnAvailable:unAvailability,
        }))]
    }

    const filterEndTime = (endTime,startTime,day,index) => {
        const filteredAvailabilities = availability[day].filter((_,i) => i !== index)
        let isAllow = true
        if (filteredAvailabilities.length > 0){
            isAllow = !filteredAvailabilities.some(el => {
                if (!el.startTime || !el.endTime){
                    return false
                }
                if (!!startTime && (getHours(startTime)+(getMinutes(startTime)/100)) < (getHours(el?.startTime)+(getMinutes(el?.startTime)/100)) && (getHours(endTime)+(getMinutes(endTime)/100)) > (getHours(el?.endTime)+(getMinutes(el?.endTime)/100))){
                    return true
                }
                return (getHours(endTime)+(getMinutes(endTime)/100)) > (getHours(el?.startTime)+(getMinutes(el?.startTime)/100)) && (getHours(endTime)+(getMinutes(endTime)/100)) <= (getHours(el?.endTime)+(getMinutes(el?.endTime)/100))

            })
        }
        if (!endTime || !startTime){
            return isAllow
        }
        return new Date(startTime).getTime() < new Date(endTime).getTime() && isAllow
    }
    const filterStartTime = (endTime,startTime,day,index) => {
        const filteredAvailabilities = availability[day].filter((_,i) => i !== index)
        let isAllow = true
        if (filteredAvailabilities.length > 0){
            isAllow = !filteredAvailabilities.some(el => {
                if (!el.startTime || !el.endTime){
                    return false
                }
                if (!!endTime && (getHours(endTime)+(getMinutes(endTime)/100)) > (getHours(el?.endTime)+(getMinutes(el?.endTime)/100)) && (getHours(startTime)+(getMinutes(startTime)/100)) < (getHours(el?.startTime)+(getMinutes(el?.startTime)/100))){
                    return false
                }
                return (getHours(startTime)+(getMinutes(startTime)/100)) >= (getHours(el?.startTime)+(getMinutes(el?.startTime)/100)) && (getHours(startTime)+(getMinutes(startTime)/100)) < (getHours(el?.endTime)+(getMinutes(el?.endTime)/100))
            })
        }
        if (!endTime || !startTime){
            return isAllow
        }
        return differenceInMinutes(endTime,startTime) > 0 && isAllow
    }
    return(
        <>
            <section>
                <Form>
                    {fieldKeys.map((key,index)=>{
                        return(
                            <Fragment key={index}>
                                <div className={styles.day__row}>
                                    <div className={styles.availability}>
                                        <Form.Group className={styles.form__check__group}>
                                            <label
                                                htmlFor={`availability__${key}`}
                                                className={`${styles.availability__label} ${details[key] ? styles.checked__label : ""}`}>
                                                {key}
                                            </label>
                                            <Form.Check
                                                type="checkbox"
                                                id={`availability__${key}`}
                                                name={`${key}`}
                                                value={details[key]}
                                                onChange={(e) => handleAddDayCheckbox(e,key)}
                                                checked={details[key]}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className={styles.availabilities__list}>
                                        {!availability[key]?.filter(el=>!el?.isUnAvailable).length > 0 &&
                                            <p className={styles.unavailable}>Unavailable</p>}
                                        {availability[key]?.filter(el=>!el?.isUnAvailable)?.length > 0 && availability[key]
                                            .filter(el=>!el?.isUnAvailable)
                                            .map((el,i) => {
                                                return(
                                                    <Fragment key={el?._id}>
                                                        <div className={styles.availability__fields}>
                                                            <div className={styles.availability__datepicker}>
                                                                <DatePicker
                                                                    name={"startTime"}
                                                                    onChange={(date)=>handleAvailabilityDataChange(i,"startTime",date,key)}
                                                                    selected={el?.startTime}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={durationInterval}
                                                                    filterTime={(startTime)=>filterStartTime(el?.endTime,startTime,key,i)}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    placeholderText={"Start Time"}
                                                                />
                                                            </div>

                                                            <span><BsDash/></span>
                                                            <div className={styles.availability__datepicker}>
                                                                <DatePicker
                                                                    name={"endTime"}
                                                                    onChange={(date)=>handleAvailabilityDataChange(i,"endTime",date,key)}
                                                                    selected={el?.endTime}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    filterTime={(endTime)=>filterEndTime(endTime,el?.startTime,key,i)}
                                                                    timeIntervals={durationInterval}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    placeholderText={"End Time"}
                                                                />
                                                            </div>
                                                            <div className={styles.availability__action__buttons}>
                                                                <button type={"button"} onClick={()=>removeAvailabilityField(el?._id,key)}>
                                                                    <FaTrashAlt/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                    </div>
                                    <div className={styles.add__availability__button}>
                                        <button type={"button"} onClick={()=>addAvailability(key)}><BsPlusSquare/></button>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })}
                    <div className={styles.save__button__wrapper}>
                        {isSaveAvailabilityLoading && <ButtonLoader/>}
                        {!isSaveAvailabilityLoading && <button type={"button"} onClick={() => saveAvailability(availability)}>Save</button>}
                    </div>
                </Form>
            </section>
        </>
    )
}
export default SetupAvailability
