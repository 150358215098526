import React from "react"
import axios from "../config/axios-instance";
import {toast} from "react-toastify";
import {viewError} from "../utils/helpers";
import {useNavigate} from "react-router";
import TokenService from "./token.service";
import { useMutation, useQuery, useQueryClient } from "react-query";


const AccountSettingsService = () => {
    const navigate = useNavigate()

    //Update Personal Information
    const useHandleUpdatePersonalInformationService = (personalInformationData) => {
        const userId = TokenService.getUser()?._id

        const handleUpdatePersonalInformationRequest = (data) => {
            return axios.put(`/account-settings/personal-information?userId=${userId}`,data)
        }

        const onSuccess = (response) => {
            toast.success(response.data.message)
            TokenService.updateUser('firstName',personalInformationData?.firstName)
            TokenService.updateUser('lastName',personalInformationData?.lastName)
            TokenService.updateUser('email',personalInformationData?.email)
            TokenService.updateUser('address',personalInformationData?.address)
            TokenService.updateUser('phone',personalInformationData?.phone)
            TokenService.updateUser('isSubscribedForMarketing',personalInformationData?.isSubscribedForMarketing || false)
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => handleUpdatePersonalInformationRequest(personalInformationData),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Verify Owner (with persona)
    const useHandleVerifyOwnerService = (personalInformationData) => {
        const userId = TokenService.getUser()?._id

        const handleVerifyOwnerRequest = () => {
            return axios.post(`/account-settings/verify?userId=${userId}`)
        }

        const onSuccess = (response) => {
            window.location.href = response?.data?.data
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => handleVerifyOwnerRequest(personalInformationData),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Saving Credit Card Information
    const useHandleSaveCardService = () => {
        const userId = TokenService.getUser()?._id

        const HandleSaveCardRequest = () => {
            return axios.post(`/account-settings/card?userId=${userId}`)
        }

        const onSuccess = (response) =>{
            window.location.href = response.data.data
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            HandleSaveCardRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Fetching Credit Card Information
    const useFetchSavedCardService = () => {
        const userId = TokenService.getUser()?._id

        const fetchSavedCardRequest = () => {
            return axios.get(`/account-settings/card?userId=${userId}`)
        }

        return useQuery(
            ["account_settings-saved-card",userId],
            fetchSavedCardRequest,
            {
                refetchOnWindowFocus:false,
                retry:0,
                select: (response)=>{
                    return response.data.data
                },


            }
        )
    }

    //Deleting Credit Card Information
    const useHandleDeleteSavedCardService = () => {
        const userId = TokenService.getUser()?._id
        const queryClient = useQueryClient()

        const handleDeleteSavedCardRequest = () => {
            return axios.delete(`/account-settings/card?userId=${userId}`)
        }

        const onSuccess = (response) =>{
            toast.success(response.data.message)
            queryClient.setQueryData(["account_settings-saved-card",userId], (oldQueryData)=>{
                return{
                    ...oldQueryData,
                    data : {}
                }
            })
        }
        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            handleDeleteSavedCardRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Account Usage
    const useFetchAccountUsageService = () => {
        const userId = TokenService.getUser()?._id

        const fetchAccountUsageService = () => {
            return axios.get(`/account-settings/usage?userId=${userId}`)
        }


        return useQuery(
            ['account-usage',userId],
            fetchAccountUsageService,
            {
                retry:0,
                select:(response)=>response?.data?.data
            }
        )

    }

    //Delete Account
    const useHandleDeleteAccountService = () => {
        const userId = TokenService.getUser()?._id

        const handleDeleteAccountRequest = () => {
            return axios.delete(`/account-settings/account?userId=${userId}`)
        }

        const onSuccess = (response) => {
            toast.success(response.data.message)
            TokenService.clearStorage()
            navigate("/login")
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            handleDeleteAccountRequest,
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Change Profile Picture
    const useHandleChangeProfilePictureService = (profilePic) => {
        const userId = TokenService.getUser()?._id

        const handleChangeProfilePictureRequest = (data) => {
            return axios.put(`/account-settings/profile-picture?userId=${userId}`,data)
        }

        const onSuccess = (response) => {
            toast.success(response.data.message)
            TokenService.updateUser("profilePicture",response.data.data?.image)
        }

        const onError = (error) => {
            toast.error(viewError(error.response.data.message))
        }

        return useMutation(
            () => handleChangeProfilePictureRequest(profilePic),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }


    return {useHandleUpdatePersonalInformationService,useHandleSaveCardService,
        useFetchSavedCardService,useHandleDeleteSavedCardService,useHandleDeleteAccountService,
        useHandleChangeProfilePictureService,useHandleVerifyOwnerService,useFetchAccountUsageService}
};

export default AccountSettingsService;
