import React from "react"
import styles from "../../../styles/account-settings.module.css"
import propTypes from "prop-types";

const LoginDetailsForm = ({details,inputEvent}) => {
    return(
        <>
            <div className={`${styles.form__group__wrapper}  ${styles.forms__group__wrapper}`}>
                <div className={styles.form__group}>
                    <label>Email</label>
                    <input type={"email"} name={"email"} value={details.email} onChange={inputEvent} required/>
                </div>
                <div className={`${styles.form__group} hidden`}/>
            </div>
        </>
    )
}

export default LoginDetailsForm;

LoginDetailsForm.propType={
    details : propTypes.object.isRequired,
    inputEvent : propTypes.func.isRequired
}